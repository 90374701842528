import React, { useState } from 'react'
import Header from '../CommonComponent/Header'
import Container from 'reactstrap/es/Container'
import Card from 'reactstrap/es/Card'
import CardBody from 'reactstrap/es/CardBody'
import CardHeader from 'reactstrap/es/CardHeader'
import { Controller, useForm } from 'react-hook-form'
import FormGroup from 'reactstrap/lib/FormGroup'
import Label from 'reactstrap/lib/Label'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import CardFooter from 'reactstrap/es/CardFooter'
import LoadingButton from '../CommonComponent/Button/LoadingButton'
import AsyncSelect from '../CommonComponent/AsyncSelect'
import { getUsers } from '../../Services/UserService'
import Select from 'react-select'
import { getProducts } from '../../Services/ProductService'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Button from 'reactstrap/es/Button'
import moment from 'moment'
import Common from '../../utils/common'
import { createPromotion } from '../../Services/PromotionService'

const PromotionProductForm = (props) => {
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit, errors, setValue, control } = useForm()
  const [type, setType] = useState('percent')

  const onSubmit = (values) => {
    const {
      name,
      note,
      products,
      daysInWeek,
      startEnd,
      percent,
      maximum,
      amount,
    } = values
    let pluginConditions = []
    const weekData = {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    }

    daysInWeek.forEach((day) => {
      weekData[day.value] = true
    })

    let pluginValueDaysInWeek = {
      Id: Common.guid(),
      PluginId: 'f033a5b6-8810-4053-9934-68a19035c769',
      PluginName: 'Ngày trong tuần',
      Data: JSON.stringify(weekData),
      Priority: 0,
    }
    pluginConditions.push(pluginValueDaysInWeek)

    let pluginValuePeriodTime = {
      Id: Common.guid(),
      PluginId: 'f033a5b6-8810-4053-9934-68a19035c768',
      PluginName: 'Áp dụng trong khoảng thời gian',
      Data: JSON.stringify({
        From: moment(startEnd.start).format('YYYY-MM-DD'),
        To: moment(startEnd.end).format('YYYY-MM-DD'),
      }),
      Priority: 0,
    }
    pluginConditions.push(pluginValuePeriodTime)

    let pluginResults = []

    if (type === 'amount') {
      let pluginResult = {
        Id: Common.guid(),
        PluginId: 'f033a5b6-8810-4053-9934-68a19035c763',
        PluginName: 'Giảm tiền',
        Data: JSON.stringify({
          Amount: amount,
        }),
        Priority: 0,
      }
      pluginResults.push(pluginResult)
    } else {
      let pluginResult = {
        Id: Common.guid(),
        PluginId: 'f033a5b6-8810-4053-9934-68a19035c767',
        PluginName: 'Giảm theo % và tối đa số tiền được giảm',
        Data: JSON.stringify({
          Percent: percent,
          MaxDiscountAmount: maximum,
        }),
        Priority: 0,
      }
      pluginResults.push(pluginResult)
    }
    setSubmitting(true)
    createPromotion({
      Name: name,
      Note: note,
      PluginConditions: pluginConditions,
      PluginResults: pluginResults,
      PromotionType: 1,
      TargetIds: products.map((p) => p.value),
    })
      .then(Common.handleResponse)
      .then(({success}) => {
        if (success) props.history.push('/promotion/list')
      })
      .catch(Common.handleError)
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <div>
      <Header
        title="Tạo chương trình giảm giá sản phẩm"
        iconClassName="pe-7s-cart"
      />
      <Container>
        <Card>
          <CardHeader>Tạo chương trình giảm giá sản phẩm</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>
                Tên <span className={'red'}>*</span>
              </Label>
              <input
                ref={register({
                  required: 'Không được để trống',
                })}
                className="form-control"
                name="name"
                placeholder="Tên chương tình giảm giá"
              />
              {errors.name ? (
                <FormFeedback className="d-block">
                  {errors.name.message}
                </FormFeedback>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Ghi chú</Label>
              <input ref={register} className="form-control" name="note" />
            </FormGroup>
            <FormGroup>
              <Label>
                Sản phẩm <span className="red">*</span>
              </Label>
              <Controller
                control={control}
                name="products"
                rules={{
                  required: 'Trường bắt buộc',
                }}
                render={({ onChange, value, name }) => (
                  <AsyncSelect
                    name={name}
                    value={value}
                    onChange={onChange}
                    isMulti={true}
                    loadOptions={getProducts}
                  />
                )}
              />
              {errors.products ? (
                <FormFeedback className="d-block">
                  {errors.products.message}
                </FormFeedback>
              ) : null}
            </FormGroup>
            <FormGroup>
              <label>
                Ngày trong tuần <span className={'red'}>*</span>
              </label>

              <Controller
                control={control}
                name="daysInWeek"
                rules={{
                  required: 'Trường bắt buộc',
                }}
                render={({ onChange, value, name }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    name={name}
                    isMulti={true}
                    options={[
                      {
                        value: 'Monday',
                        label: 'Thứ 2',
                      },
                      {
                        value: 'Tuesday',
                        label: 'Thứ 3',
                      },
                      {
                        value: 'Wednesday',
                        label: 'Thứ 4',
                      },
                      {
                        value: 'Thursday',
                        label: 'Thứ 5',
                      },
                      {
                        value: 'Friday',
                        label: 'Thứ 6',
                      },
                      {
                        value: 'Saturday',
                        label: 'Thứ 7',
                      },
                      {
                        value: 'Sunday',
                        label: 'Chủ nhật',
                      },
                    ]}
                  />
                )}
              />
              {errors.daysInWeek ? (
                <FormFeedback className="d-block">
                  {errors.daysInWeek.message}
                </FormFeedback>
              ) : null}
            </FormGroup>
            <FormGroup>
              <label>
                Ngày bắt đầu, ngày kết thúc <span className={'red'}>*</span>
              </label>

              <Controller
                control={control}
                name="startEnd"
                rules={{
                  required: 'Trường bắt buộc',
                }}
                render={({ onChange, value, name }) => (
                  <div>
                    <DateRangePicker
                      onApply={(event, picker) => {
                        onChange({
                          start: picker.startDate.toDate(),
                          end: picker.endDate.toDate(),
                        })
                      }}
                    >
                      <button type="button" className="btn btn-primary">
                        {value
                          ? `Từ ${moment(value.start).format(
                              'DD/MM/YYYY'
                            )}, đến: ${moment(value.end).format('DD/MM/YYYY')}`
                          : 'Chọn ngày'}
                      </button>
                    </DateRangePicker>
                  </div>
                )}
              />
              {errors.startEnd ? (
                <FormFeedback className="d-block">
                  {errors.startEnd.message}
                </FormFeedback>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Giảm theo</Label>
              <select
                value={type}
                onChange={(event) => {
                  setType(event.target.value)
                }}
                className={'form-control'}
              >
                <option value={'percent'}>Phần trăm</option>
                <option value={'amount'}>Số tiền</option>
              </select>
            </FormGroup>
            {type === 'percent' ? (
              <React.Fragment>
                <FormGroup>
                  <Label>
                    Phần trăm <span className={'red'}>*</span>
                  </Label>
                  <input
                    type={'number'}
                    ref={register({
                      required: 'Không được để trống',
                    })}
                    className="form-control"
                    name="percent"
                  />
                  {errors.percent ? (
                    <FormFeedback className="d-block">
                      {errors.percent.message}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>
                    Tối đa <span className={'red'}>*</span>
                  </Label>
                  <input
                    type={'number'}
                    ref={register({
                      required: 'Không được để trống',
                    })}
                    className="form-control"
                    name="maximum"
                  />
                  {errors.maximum ? (
                    <FormFeedback className="d-block">
                      {errors.maximum.message}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FormGroup>
                  <Label>
                    Số tiền <span className={'red'}>*</span>
                  </Label>
                  <input
                    type={'number'}
                    ref={register({
                      required: 'Không được để trống',
                    })}
                    className="form-control"
                    name="amount"
                  />
                  {errors.amount ? (
                    <FormFeedback className="d-block">
                      {errors.amount.message}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </React.Fragment>
            )}
          </CardBody>
          <CardFooter>
            <LoadingButton
              style={{
                zIndex: 0,
              }}
              loading={submitting}
              onClick={handleSubmit(onSubmit)}
            >
              Tạo
            </LoadingButton>
          </CardFooter>
        </Card>
      </Container>
    </div>
  )
}

export default PromotionProductForm
