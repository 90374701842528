import cx from "classnames";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import MetisMenu from "react-metismenu/lib/components/MetisMenu";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { getNavByUser, getNavPublished } from "../../Services/CmsMenuService";
import HeaderLogo from "../AppLogo";
import SearchMenu from "./SearchMenu";

function AppSidebar(props) {
  const [navItems, setNavItems] = useState([]);
  const [allNavItems, setAllNavItems] = useState([]);
  const scrollBarRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    window.scrollBar = scrollBarRef;
    setAllNavItems(props.navs);
  }, [props.navs]);

  useEffect(() => {
    if (!searchText) {
      setNavItems(allNavItems);
    } else {
      setNavItems(
        allNavItems.filter((item) => {
          const regex = new RegExp(searchText, "i");
          if (regex.test(item.name)) return true;
          for (const nav of item.navigation) {
            if (regex.test(nav.label)) return true;
          }
          return false;
        })
      );
    }
    setShowMenu(true);
  }, [searchText, allNavItems]);

  const setScrollTop = (value) => {
    localStorage.setItem("LeftNavScrollTop", value);
  };

  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
    setShowMenu(false);
  };

  let {
    backgroundColor,
    enableBackgroundImage,
    enableSidebarShadow,
    backgroundImage,
    backgroundImageOpacity,
  } = props;

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-sidebar", backgroundColor, {
          "sidebar-shadow": enableSidebarShadow,
        })}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <HeaderLogo />
        <PerfectScrollbar
          ref={scrollBarRef}
          onScrollY={(container) => setScrollTop(container.scrollTop)}
        >
          <SearchMenu value={searchText} onChange={handleSearchChange} />
          <div className="app-sidebar__inner">
            {showMenu && navItems != null
              ? navItems.map((navItem) => (
                  <div>
                    <h5
                      style={{
                        color: "#4db8ff",
                        cursor: "pointer",
                      }}
                      className="app-sidebar__heading"
                    >
                      {navItem.name}
                    </h5>
                    <MetisMenu
                      content={navItem.navigation}
                      activeLinkFromLocation
                      className="vertical-nav-menu"
                      iconNamePrefix=""
                      classNameStateIcon="pe-7s-angle-down"
                    />
                  </div>
                ))
              : null}
            {/*{}*/}
          </div>
        </PerfectScrollbar>
        <div
          className={cx("app-sidebar-bg", backgroundImageOpacity)}
          style={{
            backgroundImage: enableBackgroundImage ? "url(" + backgroundImage + ")" : null,
          }}
        ></div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
  infomationDataUser: state.Partial.infomationDataUser,
  expiredTime: state.System.expiredTime,
  navs: state.Navigation.navs,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
