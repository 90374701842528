import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { getData } from '../../utils/data'
import SelectFormik from './SelectFormik'

function CategoryPartial({ formik, name, type, title, ...props }) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    getData
      .getCategories({
        type: type,
        //  sort: 'name',
        //  order: 'asc'
      })
      .then((res) => {
        setOptions(res.categories.items)
      })
    return () => {}
  }, [])

  return (
    <div>
      <Card className="mb-2">
        <CardHeader>{title || "Phân loại"}</CardHeader>
        <CardBody>
          <SelectFormik formik={formik} name={name} options={options} isMulti />
        </CardBody>
      </Card>
    </div>
  )
}

export default CategoryPartial
