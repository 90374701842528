import React, { useEffect, useState } from "react";
import { Button, FormFeedback, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useForm } from "react-hook-form";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import { getCategories } from "../../Services/CategoryService";
import Configuration from "../../utils/configuration";
import LoadingButton from "../../CMS/CommonComponent/Button/LoadingButton";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import ImagesPicker from "../Images/ImagesPicker";
import { useSelector } from "react-redux";
import ImagePartialCallBack from "../../CMS/CommonComponent/ImagePartial/ImagePartialCallBack";
import Common from "../../utils/common";
import { postData } from "../../utils/data";

const Helpdesk = () => {
  const [showing, setShowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCategories, setErrorCategories] = useState([]);
  const { register, handleSubmit, setValue, getValues, errors } = useForm();
  const [thumbnailImages, setThumbnailImages] = useState([]);

  useEffect(() => {
    getCategories({
      type: 2,
      merchantId: Configuration.helpDeskMerchantId,
    }).then((res) => {
      if(res.categories != undefined){
        setErrorCategories(res.categories.items);
      }
      
    });
  }, []);

  const onSubmit = (values) => {
    const { errorType, name, description } = values;
    setLoading(true);
    const subDescription = JSON.stringify({
      userAgent: navigator.userAgent,
      href: window.location.href,
    });

    console.log(thumbnailImages);
    const body = {
      Id: Common.guid(),
      MerchantId: Configuration.helpDeskMerchantId,
      Name: name,
      SubDescription: subDescription,
      Description: description,
      MetaName: "",
      MetaKeyword: "",
      MetaDescription: "",
      MetaImage: "",
      Categories: Common.createRelationObjectArray([errorType]),
      Images: Common.createImagesRelationObjectArray([], thumbnailImages),
      LanguageId: localStorage.getItem(Configuration.tokenLanguage),
      CreatedDate: Common.formatDateTime(new Date()),
      CreatedBy: localStorage.getItem("userId"),
      CommandInformation: navigator.userAgent,
    };

    postData
      .sendCommand("Article", "CreateArticleVersion01", body)
      .then(Common.handleResponse)
      .catch(Common.handleError)
      .finally(() => {
        setLoading(false);
        setValue("name", "");
        setValue("description", "");
        setThumbnailImages([]);
      });
  };

  return (
    <div className={"ui-theme-settings " + (showing ? "settings-open" : "")}>
      <Button
        className="btn-open-options"
        id="TooltipDemo"
        color="warning"
        onClick={() => setShowing(!showing)}
      >
        <FontAwesomeIcon icon={faLifeRing} spin color="#573a04" fixedWidth={false} size="2x" />
      </Button>
      <UncontrolledTooltip placement="left" target={"TooltipDemo"}>
        Gửi thông báo lỗi tới nhà phát triển
      </UncontrolledTooltip>
      <div className="theme-settings__inner">
        <PerfectScrollbar>
          <div className="theme-settings__options-wrapper">
            <h3 className="themeoptions-heading">Help Desk</h3>
            <div className="p-3">
              <FormGroup>
                <Label>Phân loại lỗi</Label>
                <select className={"form-control"} ref={register} name={"errorType"}>
                  {errorCategories.map((cate) => (
                    <option value={cate.id}>{cate.name}</option>
                  ))}
                </select>
              </FormGroup>
              <FormGroup>
                <Label>
                  Tiêu đề <span className={"red"}>*</span>{" "}
                </Label>
                <input
                  className={"form-control"}
                  ref={register({
                    required: "Không được để trống",
                  })}
                  name={"name"}
                />
                {errors.name ? (
                  <FormFeedback className="d-block">{errors.name.message}</FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>Mô tả </Label>
                <textarea className={"form-control"} ref={register} name={"description"} />
              </FormGroup>
              {/*<ImagesPicker helpDesk={true} />*/}
              <ImagePartialCallBack
                thumbnail={true}
                thumbnailImages={thumbnailImages}
                loadPickedThumbnailImages={setThumbnailImages}
              />
              <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)}>
                Gửi
              </LoadingButton>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default Helpdesk;
