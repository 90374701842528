import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'
import CategoriesPicker from '../../Layout/Categories/CategoriesPicker'
import { getWarehouse, getWarehouses } from '../../Services/WarehouseService'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import AddressPartial from '../CommonComponent/AddressPartial'
import LoadingButton from '../CommonComponent/Button/LoadingButton'
import Footer from '../CommonComponent/Footer'
import Header from '../CommonComponent/Header'
import ImagePartial from '../CommonComponent/ImagePartial/ImagePartial'
import './WarehouseForm.scss'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Swal from 'sweetalert2'
import {
  loadPickedCategories,
  loadPickedImages,
  loadPickedThumbnailImages,
  updateAddress,
} from '../../reducers/Partial'

const defaultOption = { value: Common.GUID_EMPTY, label: '-' }
const measureUnits = [
  { value: 'Mm', label: 'mm', convertionNumber: 1 },
  { value: 'Cm', label: 'cm', convertionNumber: 10 },
  { value: 'M', label: 'm', convertionNumber: 1000 },
  { value: 'Km', label: 'km', convertionNumber: 1000000 },
]

function WarehouseForm(props) {
  const idWarehouse = props.match.params.id
  const {
    handleSubmit,
    register,
    errors,
    control,
    setError,
    clearErrors,
  } = useForm()
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [dimensionMeasure, setDimensionMeasure] = useState(measureUnits[0])
  const [positionMeasure, setPositionMeasure] = useState(measureUnits[0])
  const [warehouse, setWarehouse] = useState(null)
  const [warehouses, setWarehouses] = useState([])

  const dispatch = useDispatch()
  const pickedCategories = useSelector(
    (state) => state.Partial.pickedCategories
  )
  const thumbnailImages = useSelector((state) => state.Partial.thumbnailImages)
  const images = useSelector((state) => state.Partial.images)
  const address = useSelector((state) => state.Partial.address)
  const merchantId = useSelector((state) => state.System.merchantId)

  useEffect(() => {
    if (idWarehouse) {
      getWarehouse(idWarehouse).then((res) => {
        setWarehouse(res.storage)
        const { addresses = [], categories = [], images = [] } = res.storage
        dispatch(
          loadPickedCategories(
            categories.map((cate) => ({
              value: cate.id,
              label: cate.name,
            }))
          )
        )
        dispatch(
          loadPickedImages(
            images
              .filter((i) => !i.isFeatured)
              .map((img, i) => ({
                id: img.id,
                path: img.path,
                name: img.name,
                displayOrder: i,
              }))
          )
        )
        dispatch(
          loadPickedThumbnailImages(
            images
              .filter((i) => i.isFeatured)
              .map((img, i) => ({
                id: img.id,
                path: img.path,
                name: img.name,
                displayOrder: i,
              }))
          )
        )
        dispatch(
          updateAddress(
            addresses.length > 0
              ? {
                  value: addresses[0].id,
                  label: addresses[0].name,
                }
              : null
          )
        )
      })
    }
  }, [])

  useEffect(() => {
    getWarehouses()
      .then((result) => {
        const name = Object.keys(result)[0]
        let nextWarehouses = result[name].items
        setWarehouses(nextWarehouses)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const getDataSubmit = () => {
    let relationCategories = []
    pickedCategories.forEach(function (val, i) {
      relationCategories.push({
        Id: val.value,
        IsFeatured: false,
        DisplayOrder: i,
      })
    })

    let relationImages = []
    thumbnailImages.forEach(function (val, i) {
      if (!val) return
      relationImages.push({
        Id: val.id,
        IsFeatured: true,
        DisplayOrder: i,
      })
    })

    images.forEach(function (val, i) {
      if (!val) return
      relationImages.push({
        Id: val.id,
        IsFeatured: false,
        DisplayOrder: i,
      })
    })
    return {
      images: relationImages,
      categories: relationCategories,
    }
  }

  const onSubmit = async (values) => {
    if (!address || !address.value) {
      setError('address', {
        type: 'manual',
        message: 'Trường bắt buộc',
      })
      return
    }
    const {
      name,
      height,
      width,
      length,
      positionX,
      positionY,
      positionZ,
      sku,
      tags,
      subDescription,
      capacity,
      capacityUnit,
      parent,
    } = values
    const _width = Number(width) * dimensionMeasure.convertionNumber
    const _height = Number(height) * dimensionMeasure.convertionNumber
    const _length = Number(length) * dimensionMeasure.convertionNumber
    const _positionX = Number(positionX) * positionMeasure.convertionNumber
    const _positionY = Number(positionY) * positionMeasure.convertionNumber
    const _positionZ = Number(positionZ) * positionMeasure.convertionNumber

    let requestData = {
      id: idWarehouse ? idWarehouse : Common.guid(),
      width: _width,
      height: _height,
      length: _length,
      positionX: _positionX,
      positionY: _positionY,
      positionZ: _positionZ,
      languageId: localStorage.getItem(Configuration.tokenLanguage),
      parentId: parent.value,
      unit: capacityUnit,
      addresses: [
        {
          Id: address.value,
          IsFeatured: true,
          DisplayOrder: 0,
        },
      ],
      name,
      capacity,
      sku,
      tags,
      subDescription,
      description,
    }

    if (idWarehouse) {
      requestData.ModifiedDate = new Date()
      requestData.ModifiedBy = localStorage.getItem('userId')
    } else {
      requestData.merchantId = merchantId
      requestData.CreatedDate = new Date()
      requestData.CreatedBy = localStorage.getItem('userId')
    }

    requestData = { ...requestData, ...getDataSubmit() }

    try {
      setLoading(true)
      const result = await Common.sendCommand(
        'Storage',
        idWarehouse ? 'UpdateStorage' : 'CreateStorage',
        requestData
      )
      setLoading(false)
      const { Message, Success } = result.data

      Swal.fire({
        title: Success ? 'Thành công' : 'Thất bại',
        text: Message,
        icon: Success ? 'success' : 'error',
        showCancelButton: true,
        confirmButtonText: 'Về danh sách',
      }).then(({ value }) => {
        if (value) props.history.push('/warehouse/list')
      })
    } catch (err) {
      Swal.fire({
        title: 'Thất bại',
        text: err,
        icon: 'error',
      })
    }
  }

  const options = useMemo(() => {
    let result = []
    function findChilds(warehouses, id, result) {
      let childs = []
      for (let i = 0; i < warehouses.length; i++) {
        const room = warehouses[i]
        if (room.parentId === id) {
          childs = [...childs, room]
        }
      }
      if (childs.length) {
        result = [...result, childs]
        childs.forEach((f) => {
          result = findChilds(warehouses, f.id, result)
        })
      }
      return result
    }
    const toAvoidRooms = findChilds(warehouses, idWarehouse, result)
      .reduce((flat, toFlatten) => {
        return flat.concat(toFlatten)
      }, [])
      .map((m) => m.id)

    return [
      defaultOption,
      ...warehouses
        .filter((f) => !toAvoidRooms.includes(f.id) && f.id !== idWarehouse)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    ]
  }, [warehouses, idWarehouse])

  const selectLoading = useMemo(() => {
    return options.length === 0
  }, [options])

  const selectDefaultValue = useMemo(() => {
    const { parentId } = warehouse || {}
    const opt = options.find((f) => f.value === parentId)
    return opt || options[0]
  }, [warehouse, options])

  const getViewNumber = (data) => {
    if (data === 0) return 0
    return data || ''
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Header title="Kho hàng" description="Thông tin kho hàng" />
        <Row>
          <Col md="9">
            <CardHeader>Tạo mới</CardHeader>
            <Card className="main-card mb-3">
              <CardBody>
                <FormGroup>
                  <Label>Thuộc kho</Label>
                  <Controller
                    key={(selectDefaultValue && selectDefaultValue.value) || 0}
                    name={'parent'}
                    as={Select}
                    isSearchable
                    isLoading={selectLoading}
                    defaultValue={selectDefaultValue}
                    options={options}
                    control={control}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Tên kho<span style={{ color: 'red' }}>*</span>
                  </Label>
                  <input
                    placeholder="Nhập tên kho ..."
                    defaultValue={(warehouse && warehouse.name) || ''}
                    className="form-control"
                    ref={register({ required: true, maxLength: 256 })}
                    type="text"
                    name="name"
                  />
                  {errors.name && (
                    <span style={{ color: 'red' }}>
                      Trường này không được để trống
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Tags</Label>
                  <input
                    placeholder="Nhập tags ..."
                    defaultValue={(warehouse && warehouse.tags) || ''}
                    className="form-control"
                    ref={register}
                    type="text"
                    name="tags"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Sku</Label>
                  <input
                    placeholder="Nhập sku ..."
                    defaultValue={(warehouse && warehouse.sku) || ''}
                    className="form-control"
                    ref={register}
                    type="text"
                    name="sku"
                  />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col md="3">
                      <Label>Sức chứa tối đa</Label>
                      <input
                        placeholder="Nhập sức chứa ..."
                        min={1}
                        defaultValue={(warehouse && warehouse.capacity) || ''}
                        className="form-control"
                        ref={register}
                        type="number"
                        name="capacity"
                      />
                    </Col>
                    <Col md="3">
                      <Label>Đơn vị sức chứa</Label>
                      <input
                        placeholder="vd: cái, chiếc ..."
                        defaultValue={(warehouse && warehouse.unit) || ''}
                        className="form-control"
                        ref={register}
                        type="text"
                        name="capacityUnit"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label>Kích thước (dài - rộng - cao)</Label>
                  <div className="warehouse-form-inline">
                    <input
                      type="number"
                      min={1}
                      defaultValue={(warehouse && warehouse.height) || ''}
                      placeholder="dài ..."
                      className="form-control"
                      ref={register({
                        required: true,
                      })}
                      name="height"
                    />
                    <span className="warehouse-form-inline-unit">-</span>
                    <input
                      type="number"
                      min={1}
                      defaultValue={(warehouse && warehouse.width) || ''}
                      placeholder="rộng ..."
                      className="form-control"
                      ref={register({
                        required: true,
                      })}
                      name="width"
                    />
                    <span className="warehouse-form-inline-unit">-</span>
                    <input
                      type="number"
                      min={1}
                      defaultValue={(warehouse && warehouse.length) || ''}
                      placeholder="cao ..."
                      className="form-control"
                      ref={register({
                        required: true,
                      })}
                      name="length"
                    />
                    <div className="warehouse-form-inline-measure-select">
                      <Select
                        isSearchable={false}
                        value={dimensionMeasure}
                        onChange={(item) => {
                          setDimensionMeasure(item)
                        }}
                        options={measureUnits}
                      />
                    </div>
                  </div>
                  {(errors.width || errors.height || errors.length) && (
                    <span style={{ color: 'red' }}>
                      Thông tin kích thước không được để trống
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Vị trí (x - y - z)</Label>
                  <div className="warehouse-form-inline">
                    <input
                      type="number"
                      defaultValue={getViewNumber(
                        warehouse && warehouse.positionX
                      )}
                      placeholder="X ..."
                      className="form-control"
                      ref={register({
                        required: true,
                      })}
                      name="positionX"
                    />
                    <span className="warehouse-form-inline-unit">-</span>
                    <input
                      type="number"
                      defaultValue={getViewNumber(
                        warehouse && warehouse.positionY
                      )}
                      placeholder="Y ..."
                      className="form-control"
                      ref={register({
                        required: true,
                      })}
                      name="positionY"
                    />
                    <span className="warehouse-form-inline-unit">-</span>
                    <input
                      type="number"
                      defaultValue={getViewNumber(
                        warehouse && warehouse.positionZ
                      )}
                      placeholder="Z ..."
                      className="form-control"
                      ref={register({
                        required: true,
                      })}
                      name="positionZ"
                    />
                    <div className="warehouse-form-inline-measure-select">
                      <Select
                        isSearchable={false}
                        value={positionMeasure}
                        onChange={(item) => {
                          setPositionMeasure(item)
                        }}
                        options={measureUnits}
                      />
                    </div>
                  </div>
                  {(errors.positionX ||
                    errors.positionY ||
                    errors.positionZ) && (
                    <span style={{ color: 'red' }}>
                      Thông tin vị trí không được để trống
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Mô tả ngắn</Label>
                  <input
                    placeholder="Nhập mô tả ngắn ..."
                    defaultValue={(warehouse && warehouse.subDescription) || ''}
                    className="form-control"
                    ref={register}
                    type="text"
                    name="subDescription"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Mô tả</Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={(warehouse && warehouse.description) || ''}
                    className="form-control"
                    onChange={(e, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                    }}
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <CategoriesPicker
              type={Configuration.categoryTypes.PRODUCT}
              languageId={
                Common.getParameterByName('languageId')
                  ? Common.getParameterByName('languageId')
                  : localStorage.getItem(Configuration.tokenLanguage)
              }
            />
            <ImagePartial thumbnail />
            <ImagePartial />
            <AddressPartial
              error={(errors && errors.address && errors.address.message) || ''}
              onChange={() => {
                clearErrors('address')
              }}
            />
          </Col>
        </Row>
        <Footer
          left={
            <LoadingButton color="success" loading={loading} type="submit">
              Lưu
            </LoadingButton>
          }
        />
      </div>
    </form>
  )
}

export default WarehouseForm
