import _ from "lodash";
import React from "react";
import { Async } from "react-select";

function AsyncSelect({ isMulti, value, onChange, loadOptions, name, ...props }) {
  const loadOptionsDebounce = _.debounce((inputValue, callback) => {
    if(inputValue.length < 1){
      return;
    }
    return loadOptions({
      keyword: inputValue,
    }).then((res) => {
      const name = Object.keys(res)[0];
      callback(
        res[name].items.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    });
  }, 400);

  return (
    <Async
      placeholder="Xin hãy đánh từ cần tìm"
      cacheOptions
      defaultOptions
      isMulti={isMulti}
      name={name}
      value={value}
      onChange={onChange}
      loadOptions={loadOptionsDebounce}
      {...props}
    />
  );
}

AsyncSelect.propTypes = {};

export default AsyncSelect;
