import {
  default as Config,
  default as Configuration,
} from '../utils/configuration'
import axios from 'axios'

const { default: Common } = require('../utils/common')
const { getData } = require('../utils/data')

const tokenId = localStorage.getItem(Configuration.userCookie)

export const getEvouchers = (options) => {
  if (options === undefined) options = {}
  if (options.merchantId === undefined)
    options.merchantId = localStorage.getItem('merchantId')
  if (options.limit === undefined) options.limit = 100000
  if (options.offset === undefined) options.offset = 0
  options.keyword = options.keyword === undefined ? '' : options.keyword

  return getData.getDataGraphQl(`
    {
        evouchers(param:{limit:${options.limit},offset:${options.offset},order:"desc",sort:"createdDate"
        ,merchantId:"${options.merchantId}", keyword:"${options.keyword}"})
        {
          items{
            name,
            id,
            active,
            availableTo,
            availableFrom,
            createdBy,
            createdDate,
            merchantId,
            modifiedBy,
            modifiedDate,
            totalCode,
            totalImported,
            totalLocked,
            totalCodeExported,                
            totalCodeUsed,                
            value,
            eVoucherCodes{
              id,              
              code,
              allStatus,
              targetId,
              createdDate,
              modifiedDate,
              userName,
              userEmail,
              userMobile
            }
          },
          totalCount,
          success,
          message
        }
    }
    `)
}

export const getEvoucherCodes = (evoucherId) => {

  return axios
  .get(Configuration.url_api_apicommand + "/EVoucher/GetEVoucherCodeById?tokenUser="+tokenId+"&eVoucherId=" + evoucherId)
  .then((res) => {
    return res.data
  })

 
}
