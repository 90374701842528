import moment from 'moment'
import React, { useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Card, CardBody } from 'reactstrap'
import Header from '../CommonComponent/Header'

const localizer = momentLocalizer(moment)

function DetailUserPoint(props) {
  const userId = props.match.params.id
  const events = [
    {
      start: moment().toDate(),
      end: moment().add(1, 'days').toDate(),
      title: 'Some title',
    },
  ]

  useEffect(() => {
    // get User Info
    return () => {}
  }, [])

  const selectRangeHandle = () => {}

  return (
    <div>
      <Header
        title={`Thông tin điểm ${userId}`}
        description="User's point"
        iconClassName="pe-7s-user"
      />
      <Card>
        <CardBody>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={selectRangeHandle}
            style={{ height: 500 }}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default DetailUserPoint
