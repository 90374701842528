import React from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { loadPickedImages } from '../../../reducers/Partial'
import ListImages from './ListImages'
import ModalImage from './ModalImage'

function ImagePartial({ thumbnail }) {
  return (
    <Card className="main-card mb-2" style={{
      zIndex: 0
    }}>
      <CardHeader className="card-header-tab">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          {thumbnail ? 'Ảnh đại diện' : 'Ảnh'}
        </div>
        <div className="btn-actions-pane-right mr-3">
          <ModalImage thumbnail={thumbnail} />
        </div>
      </CardHeader>
      <CardBody
        style={{
          overflowY: 'auto',
          maxHeight: '400px',
        }}
      >
        <ListImages thumbnail={thumbnail} />
      </CardBody>
    </Card>
  )
}

export default connect(
  (state) => ({
    images: state.Partial.images,
  }),
  {
    loadPickedImages,
  }
)(ImagePartial)
