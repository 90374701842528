import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import reducers from '../reducers'
import ThemeNav from '../reducers/ThemeNav'
import Partial from '../reducers/Partial'
import Navigation from '../reducers/Navigation'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'

const defaultMiddlewares = [thunkMiddleware, promiseMiddleware]

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers,
      ThemeNav,
      Partial,
    }),
    {},
    compose(applyMiddleware(...defaultMiddlewares))
  )
}
