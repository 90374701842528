import "moment-timezone";
import "moment/locale/vi";
import React, { Fragment, useEffect } from "react";
import Moment from "react-moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { getNotificationsAction, pushNotification } from "../../Actions/Notification";
import { useMount } from "react-use";
import { messaging, VAPI_KEY } from "../../utils/initFcm";
import { postData } from "../../utils/data";
import Common from "../../utils/common";

const DEVICE = "izziDevice";

function Message() {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.Notification.notifications);
  const currentUser = useSelector((state) => state.System.user);

  useEffect(() => {
    dispatch(getNotificationsAction(0, 50));

    // setInterval(() => {
    //   dispatch(getNotificationsAction(0, 50))
    // }, 60000)
  }, []);
  useMount(() => {
    messaging &&
      messaging
        .requestPermission()
        .then(() => {
          messaging
            .getToken({
              vapiKey: VAPI_KEY,
            })
            .then((currentToken) => {
              if (currentToken) {
                const device = localStorage.getItem(DEVICE);

                if (device !== currentToken) {
                  localStorage.setItem(DEVICE, currentToken);
                  postData
                    .sendCommand("User", "AddDeviceTokenUser", {
                      Id: currentUser.Id,
                      Type: 1,
                      MerchantId: currentUser.MerchantId,
                      DeviceToken: currentToken,
                      UserToken: currentUser.TokenId,
                      ModifiedDate: Common.formatDateTimeMoment(new Date()),
                      ModifiedBy: currentUser.Id,
                    })
                    .catch(console.error);
                }
              }
            })
            .catch(console.log);
        })
        .catch(console.log);
  });
  useEffect(() => {
    const onMessage = (payload) => {
      // console.log("payload", payload.data);

      dispatch(pushNotification(payload.data));
    };

    navigator.serviceWorker.addEventListener(
      "message",
      (event) =>
        event.data["firebase-messaging-msg-data"] &&
        onMessage(event.data["firebase-messaging-msg-data"])
    );

    return () => {
      navigator.serviceWorker.removeEventListener("message", onMessage);
    };
  }, []);

  return (
    <Fragment>
      <div className="scroll-area-sm">
        <PerfectScrollbar>
          <div className="p-3">
            <VerticalTimeline
              className="vertical-time-simple vertical-without-time"
              layout="1-column"
            >
              {notification != null &&  notification.items != null &&
                notification.items.map((item, i) => (
                  <VerticalTimelineElement className="vertical-timeline-item">
                    <p>
                      {item.message}{" "}
                      <b className="text-danger">
                        {" "}
                        <Moment locale="vi" fromNow>
                          {item.createdDate}
                        </Moment>
                      </b>
                      {/* <a onClick={() => readNotification(i, item)} href={item.urlReturn}>chi tiết</a> */}
                    </p>
                  </VerticalTimelineElement>
                ))}

              {/* <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title">All Hands Meeting</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <p>
                                    Another meeting today, at <b className="text-danger">12:00 PM</b>
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title">Build the production release</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title">All Hands Meeting</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title text-success">FontAwesome Icons</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title">Build the production release</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <p>
                                    Another meeting today, at <b className="text-warning">12:00 PM</b>
                                </p>
                            </VerticalTimelineElement> */}
            </VerticalTimeline>
          </div>
        </PerfectScrollbar>
      </div>
    </Fragment>
  );
}

export default Message;
