import PropTypes from 'prop-types'
import React from 'react'

function Footer(props) {
  const { left, right } = props
  return (
    <div className="app-footer">
      <div className="app-footer__inner">
        <div className="app-footer-left">{left}</div>
        <div className="app-footer-right">{right}</div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
}

export default Footer
