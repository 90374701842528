import { useState } from 'react'

function useLocalDataTable(allData = []) {
  const [data, setData] = useState(allData)
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(data.length)

  const [lastParams, setLastParams] = useState({})

  const _fetchData = ({
    pageSize,
    pageIndex,
    keyword,
    categoryIds,
    ...props
  }) => {
    setLastParams({
      pageSize,
      pageIndex,
      keyword,
      categoryIds,
      ...props,
    })
    let dataAfterFilter = []
    if (keyword)
      dataAfterFilter = allData.filter((item) => {
        return new RegExp(keyword.toLowerCase()).test(item.name.toLowerCase())
      })
    setData(
      dataAfterFilter.slice(
        pageIndex * pageSize,
        pageIndex * pageSize + pageSize
      )
    )
    // setTotalCount(result[name].totalCount);
    // alert(Math.ceil(allData / pageSize))
    setPageCount(Math.ceil(dataAfterFilter.length / pageSize))
  }

  return [data, pageCount, _fetchData, totalCount, setData]
}

export default useLocalDataTable
