import React from 'react'
import { Col, Row } from 'reactstrap'
import Common from '../../../utils/common'
import Configuration from '../../../utils/configuration'
import ThemeLoadingSkeleton from '../../Settings/ThemeLoadingSkeleton'
import './../../../Layout/Images/ImagesPicker.css'
function GridImage({ images, setPickImages, pickImages, removePickImage }) {
  return (
    <Row>
      {images ? (
        images.length > 0 ? (
          images.map((image, index) => {
            const isActive = pickImages.map((img) => img.id).includes(image.id)
            return (
              <Col md="3" className={`mb-3`}>
                <div
                  className={`${isActive ? 'img-active' : ''}`}
                  onClick={() => {
                    if (isActive) removePickImage(image)
                    else setPickImages(image)
                  }}
                  style={{
                    width: '100%',
                    height: '190px',
                    backgroundImage: `url(${Common.cropImage(
                      Configuration.image_url + image.path,
                      400,
                      300
                    )})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </Col>
            )
          })
        ) : (
          <p className="mx-auto d-block">Empty</p>
        )
      ) : (
        <ThemeLoadingSkeleton md="3" count={12} />
      )}
    </Row>
  )
}

export default GridImage
