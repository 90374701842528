import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import BlockUi from "react-block-ui";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import { Loader } from "react-loaders";
import Select from "react-select";
import { usePagination, useRowSelect, useSortBy, useTable, useExpanded } from "react-table";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import Common from "../../../utils/common";
import Configuration from "../../../utils/configuration";
import { getData } from "../../../utils/data";
import ModalImportCSV from "../../CommonComponent/ModalImportCSV";
import useModal from "../CustomHooks/useModal";

const getPickItems = (data, selectedRowIds) => {
  const items = data.filter((val, i) => {
    return Object.keys(selectedRowIds).includes(`${i}`);
  });

  return items;
};

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  return (
    <>
      <input className={"disableonclick"} type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const onShowColumnChecked = (target, column) => {
  const LocalStorageKey = `list-${window.location.hash}`;
  const strLocalColumns = localStorage.getItem(LocalStorageKey);
  let localColumns = [];
  if (strLocalColumns) {
    localColumns = JSON.parse(strLocalColumns);
  }

  if (localColumns.includes(column.id)) {
    if (target.checked) {
      localColumns = localColumns.filter((col) => col !== column.id);
    } else {
      localColumns.push(column.id);
    }
  } else {
    if (!target.checked) {
      localColumns.push(column.id);
    }
  }

  localStorage.setItem(LocalStorageKey, JSON.stringify(localColumns));
};

const getHiddenColumnsLocalStorage = (columns) => {
  const LocalStorageKey = `list-${window.location.hash}`;
  const strLocalColumns = localStorage.getItem(LocalStorageKey);
  if (strLocalColumns) return JSON.parse(strLocalColumns);
  else {
    return columns
      .filter((col) => col.show !== undefined && col.show === false)
      .map((col) => col.id);
  }
};

function ReactTableList(
  {
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    totalCount,
    columns,
    columnsSelection,
    exportable,
    categorySubType,
    deleteCommand,
    changeStatusCommand,
    addToCategoryCommand,
    removeCategoryCommand,
    categoryType,
    importable,
    hideFunction = false,
    pickActions,
    disableItemIds = [],
    ExpandComponent,
    customFilter,
    refetch = false,
    ...props
  },
  ref
) {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addToCategoryOpen, setAddToCategoryOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({
    keyword: "",
  });
  const [tempFilterParams, setTempFilterParams] = useState({});

  const [categories, setCategories] = useState([]);
  const [categoriesSubType, setCategoriesSubType] = useState([]);
  const [pickCategories, setPickCategories] = useState([]);
  const [isOpenModalImportExcel, toggleModalImportExcel] = useModal();

  const categoryPickRef = useRef();
  const categorySubTypePickRef = useRef();
  const categoryAddPickRef = useRef();
  const searchRef = useRef();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: getHiddenColumnsLocalStorage(columns),
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      autoResetPage: false,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (columnsSelection)
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div className="text-center">
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const checkProps = {};
              if (disableItemIds.includes(row.original.id)) {
                checkProps.checked = true;
                checkProps.disabled = true;
                checkProps.style = {
                  cursor: "disabled",
                };
              }
              return (
                <div className="text-center">
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} {...checkProps} />
                </div>
              );
            },
          },
          ...columns,
        ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    allColumns,
    pageCount,
    gotoPage,
    setPageSize,
    toggleRowExpanded,
    state: { selectedRowIds, pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffect(() => {
    gotoPage(0);
    fetchData({
      pageIndex: 0,
      pageSize,
      // keyword: searchText,
      // categoryIds: pickCategories ? pickCategories.map((category) => category.value) : null,
      ...filterParams,
    });
  }, [pickCategories, filterParams]);

  useEffect(() => {
    const params = {
      pageIndex,
      pageSize,
      // keyword: searchText,
      // categoryIds: pickCategories ? pickCategories.map((category) => category.value) : null,
      ...filterParams,
    };
    if (sortBy.length > 0) {
      params.sort = sortBy[0].id;
      params.order = sortBy[0].desc ? "desc" : "asc";
    }
    fetchData(params);
  }, [pageIndex, pageSize, sortBy]);

  const deleteHandler = async (e) => {
    e.preventDefault();
    const { value } = await Swal.fire({
      title: "Vui lòng xác nhận",
      text: "Bạn có chắc muốn xóa bản ghi này không",
      icon: "question",
      showCancelButton: true,
    });
    if (value) {
      const ids = getPickItems(data, selectedRowIds).map((val) => val.id);
      setActionLoading(true);
      const res = await deleteCommand(ids);
      setActionLoading(false);
      const { Success, Message } = res.data;
      if (!Success) {
        toast["error"](Message);
        return;
      }

      fetchData({ pageIndex, pageSize, ...filterParams });
    }
  };
  useEffect(() => {
    if (refetch) {
      fetchData({ pageIndex, pageSize, ...filterParams });
    }
  }, [refetch]);
  useEffect(() => {
    if (categoryType)
      getData.getSelectionCategories({ type: categoryType }).then((res) => {
        setCategories([
          { name: "Lọc không theo phân loại", id: Common.GUID_EMPTY },
          ...res.categories.items,
        ]);
      });
    if (categorySubType)
      getData.getSelectionCategories({ type: categorySubType }).then((res) => {
        setCategoriesSubType([
          { name: "Lọc không theo phân loại", id: Common.GUID_EMPTY },
          ...res.categories.items,
        ]);
      });
    return () => {};
  }, []);

  const updateStatusHandler = async (e) => {
    e.preventDefault();
    const items = getPickItems(data, selectedRowIds);
    if (items.length < 1) return;
    const isPublish = Object.keys(items[0]).includes("published");
    setActionLoading(true);
    const { Success, Message } = await changeStatusCommand(
      items.map((item) => {
        if (isPublish)
          return {
            Id: item.id,
            Publish: item.published,
          };
        return {
          Id: item.id,
          Activate: item.active,
        };
      })
    );
    setActionLoading(false);
    if (!Success) toast["error"](Message);

    fetchData({ pageIndex, pageSize, ...filterParams });
  };

  const filterHandler = (e) => {
    e.preventDefault();
    // there are a problem here
    const searchText = searchRef.current.value;
    const _filterParams = {
      keyword: searchText,
      categoryIds: []
    };
    if (categoryPickRef.current) {
      _filterParams.categoryIds = [
        ..._filterParams.categoryIds,
        ...(categoryPickRef.current.state.value || []).map((val) => val.value),
      ];
    }
    if (categorySubTypePickRef.current) {
      _filterParams.categoryIds = [
        ..._filterParams.categoryIds,
        ...(categorySubTypePickRef.current.state.value || []).map((val) => val.value),
      ];
    }
    if (customFilter) {
      const parsedFilterParams = customFilter.parseParams(tempFilterParams) || {};
      for (let key of Object.keys(parsedFilterParams)) {
        _filterParams[key] = parsedFilterParams[key];
      }
    }

    setFilterParams(_filterParams);
  };

  const addToCategoryHandler = async (e) => {
    const pickCates = categoryAddPickRef.current.state.value;
    if (pickCates.length > 0) {
      const items = getPickItems(data, selectedRowIds);
      if (items.length < 1) return;

      setActionLoading(true);
      const { Success, Message } = await addToCategoryCommand(
        items.map((item) => item.id),
        pickCates.map((val) => val.value)
      );
      setActionLoading(false);
      if (!Success) toast["error"](Message);

      fetchData({ pageIndex, pageSize });
    }
  };

  const removeCategoryHandler = async (e) => {
    const pickCates = categoryAddPickRef.current.state.value;
    if (pickCates.length > 0) {
      const items = getPickItems(data, selectedRowIds);
      if (items.length < 1) return;

      setActionLoading(true);
      const { Success, Message } = await removeCategoryCommand(
        items.map((item) => item.id),
        pickCates.map((val) => val.value)
      );
      setActionLoading(false);
      if (!Success) toast["error"](Message);

      fetchData({ pageIndex, pageSize });
    }
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      fetchData({ pageIndex, pageSize, ...filterParams });
    },
  }));

  return (
    <div id="table">
      {!hideFunction ? (
        <Fragment>
          <Row>
            <Col xs="8">
              <button
                onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                className="mb-2 mr-2 btn-icon btn-square btn btn-light"
              >
                <i className="pe-7s-filter btn-icon-wrapper"> </i>
                Lọc
              </button>
              <CSVLink filename="izzi-data.csv" data={data} target="_blank">
                <button className="mb-2 mr-2 btn-icon btn-icon-only btn btn-light btn-square">
                  <i className="pe-7s-download btn-icon-wrapper"> </i> Xuất dữ liệu
                </button>
              </CSVLink>
              {/* {exportable ? (
                        ) : null} */}
              {importable ? (
                <ModalImportCSV isOpen={isOpenModalImportExcel} toggle={toggleModalImportExcel} />
              ) : null}
              {importable ? (
                <UncontrolledDropdown className="d-inline-block btn-icon" setActiveFromChild>
                  <DropdownToggle tag="div" className="d-inline-block">
                    <button className="mb-2 mr-2 btn-icon btn-icon-only btn btn-light btn-square">
                      <i className="pe-7s-upload btn-icon-wrapper"> </i> Nhập dữ liệu
                    </button>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a" target="_blank" href={Configuration.sampleUserExcelLink}>
                      File mẫu
                    </DropdownItem>
                    <button className="dropdown-item" onClick={toggleModalImportExcel}>
                      Import
                    </button>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
              <Dropdown
                className="d-inline-block mb-2 mr-2 "
                isOpen={dropdownOpen}
                toggle={() => {
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                <DropdownToggle title="Các cột hiển thị" className="btn-light btn-square">
                  <i className="pe-7s-edit btn-icon-wrapper"></i> Nội dung hiển thị
                </DropdownToggle>
                <DropdownMenu>
                  {allColumns
                    .filter((column) => column.id !== "selection")
                    .map((column) => {
                      return (
                        <div className="ml-5">
                          <FormGroup className="mb-1">
                            <Label>
                              <Input
                                type="checkbox"
                                name="colum"
                                {...column.getToggleHiddenProps()}
                                onChange={(e) => {
                                  column.getToggleHiddenProps().onChange(e);
                                  onShowColumnChecked && onShowColumnChecked(e.target, column);
                                }}
                                //onChange = {(event) => {onShowColumnChecked(event.target.checked, column)}}
                              />
                              {column.Header}
                            </Label>
                          </FormGroup>
                        </div>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col xs="4">
              <div className="d-flex justify-content-end">
                <div className="mr-2"></div>
                <Pagination
                  activeClass=""
                  linkClass="text-dark"
                  activeLinkClass="bg-gray text-light"
                  hideDisabled
                  pageRangeDisplayed={3}
                  activePage={pageIndex + 1}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={pageCount * pageSize}
                  onChange={(page) => {
                    return gotoPage(page - 1);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Collapse isOpen={isOpenCollapse}>
            <Row className="mb-2">
              {customFilter ? (
                <Col md={12}>
                  <customFilter.Component
                    setFilterParams={setTempFilterParams}
                    filterParams={tempFilterParams}
                  />
                </Col>
              ) : null}
              <Col md="12">
                <Form inline className="mb-2" onSubmit={filterHandler}>
                  <FormGroup>
                    <input
                      ref={searchRef}
                      className="form-control mr-2"
                      type="text"
                      placeholder="Tìm kiếm"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          filterHandler(e);
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    {categoryType ? (
                      <Select
                      className="mr-2"
                        ref={categoryPickRef}
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                            minWidth: "200",
                          }),
                        }}
                        isMulti
                        placeholder="Lọc theo phân loại"
                        options={categories.map((val) => ({
                          label: val.name,
                          value: val.id,
                        }))}
                      />
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    {categorySubType ? (
                      <Select
                        ref={categorySubTypePickRef}
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                            minWidth: "250",
                          }),
                        }}
                        isMulti
                        placeholder="Lọc theo hạng thành viên"
                        options={categoriesSubType.map((val) => ({
                          label: val.name,
                          value: val.id,
                        }))}
                      />
                    ) : null}
                  </FormGroup>
                  <Button className="ml-2" color="focus" onClick={filterHandler}>
                    Áp dụng
                  </Button>
                </Form>
              </Col>
              <Col md="4"></Col>
            </Row>
          </Collapse>
        </Fragment>
      ) : null}
      <BlockUi tag="div" blocking={loading} loader={<Loader type="ball-scale-ripple" />}>
        <table
          className="table table-striped table-hover"
          style={{
            overflowX: "visible",
          }}
          {...getTableProps()}
        >
          <thead
            style={{
              backgroundColor: "#E5E5E5",
            }}
          >
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                        <span
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i
                                style={{
                                  fontSize: 20,
                                }}
                                className="pe-7s-angle-down"
                              ></i>
                            ) : (
                              <i
                                style={{
                                  fontSize: 20,
                                }}
                                className="pe-7s-angle-up"
                              ></i>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {Object.keys(selectedRowIds).length > 0 ? (
              <tr role="role">
                <td colSpan="100%">
                  {Object.keys(selectedRowIds).length} bản ghi đã chọn -{" "}
                  {deleteCommand ? (
                    <a
                      href="asdasd"
                      className={` ${actionLoading ? "disabled" : "text-danger"}`}
                      onClick={deleteHandler}
                    >
                      Xóa{" / "}
                    </a>
                  ) : null}
                  {changeStatusCommand ? (
                    <a
                      href="asdasd"
                      className={` ${actionLoading ? "disabled" : "text-info"}`}
                      onClick={updateStatusHandler}
                    >
                      Đổi trạng thái{" / "}
                    </a>
                  ) : null}
                  {pickActions && pickActions.length > 0
                    ? pickActions.map((action) => (
                        <a
                          href="asdasd"
                          className={` ${actionLoading ? "disabled" : "text-info"}`}
                          onClick={(event) => {
                            event.preventDefault();
                            const items = getPickItems(data, selectedRowIds);
                            action.handleClick(items);
                          }}
                        >
                          {action.name}{" "}
                        </a>
                      ))
                    : null}
                  {addToCategoryCommand ? (
                    <a
                      href="ádasdd"
                      className={` ${actionLoading ? "disabled" : "text-primary"}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setAddToCategoryOpen(!addToCategoryOpen);
                      }}
                    >
                      Thêm vào/xóa khỏi - phân loại
                    </a>
                  ) : null}
                </td>
              </tr>
            ) : null}
            {Object.keys(selectedRowIds).length > 0 && addToCategoryOpen ? (
              <tr role="role">
                <td colSpan="2">
                  <Select
                    isMulti
                    ref={categoryAddPickRef}
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        minWidth: "200",
                      }),
                    }}
                    placeholder="Thêm vào phân loại"
                    options={categories.map((val) => ({
                      label: val.name,
                      value: val.id,
                    }))}
                  />
                </td>
                <td>
                  <Button onClick={addToCategoryHandler}>Thêm</Button> 
                  &nbsp;
                  <Button onClick={removeCategoryHandler}>Xóa</Button>
                </td>
                <td colSpan="100%"></td>
              </tr>
            ) : null}
            {
              // Loop over the table rows
              page.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  <Fragment>
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td
                              onClick={(event) => {
                                const className = event.target.getAttribute("class");
                                if (className && className.includes("disableonclick")) {
                                  return;
                                }
                                toggleRowExpanded(row.id);
                              }}
                              {...cell.getCellProps()}
                            >
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                    {ExpandComponent && row.isExpanded ? (
                      <tr
                        style={{
                          backgroundColor: "#f1f4f6",
                        }}
                      >
                        <td colSpan={100}>
                          <ExpandComponent row={row} />
                        </td>
                      </tr>
                    ) : null}
                  </Fragment>
                );
                if (i === 3) {
                }
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
          {/* )} */}
          <tr>
            <td colSpan="10000">
              Hiển thị từ {pageIndex * pageSize + 1} - {pageIndex * pageSize + page.length} trên{" "}
              {totalCount} bản ghi
            </td>
          </tr>
        </table>
      </BlockUi>

      {!hideFunction ? (
        <Row>
          <Col md="6">
            <Form inline>
              <Input
                type="select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[1, 5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Hiển thị {pageSize}
                  </option>
                ))}
              </Input>{" "}
              <Label className="ml-2">bản ghi mỗi trang</Label>
            </Form>
          </Col>
          <Col md="6">
            <div className="d-flex justify-content-end">
              <Pagination
                activeClass=""
                linkClass="text-dark"
                activeLinkClass="bg-gray text-light"
                hideDisabled
                activePage={pageIndex + 1}
                itemsCountPerPage={pageSize}
                totalItemsCount={pageCount * pageSize}
                onChange={(page) => {
                  // return alert(page)

                  const element = document.getElementById("table");
                  const y = element.getBoundingClientRect().top + window.pageYOffset - 60;

                  window.scrollTo({
                    top: y,
                    behavior: "smooth",
                  });

                  return gotoPage(page - 1);
                }}
              />
            </div>
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

ReactTableList.propTypes = {};

export default forwardRef(ReactTableList);
