import classnames from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import { getData } from '../../../utils/data'
import GridImage from './GridImage'
import IzziLibrary from './IzziLibrary'
import UploadImagePartial from './UploadImagePartial'

function ModalImage({ pickOne, onChange, ToggleButton }) {
  const [modal, setModal] = useState(false)
  const [images, setImages] = useState(null)
  const [pickImages, setPickImages] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [activeTab, toggleTab] = useState('1')

  const [activePage, setActivePage] = useState(1)
  const PER_PAGE = 12

  useEffect(() => {
    getData
      .getImages({ limit: PER_PAGE, offset: PER_PAGE * (activePage - 1) })
      .then((result) => {
        setImages(result.images.items)
        setTotalCount(result.images.totalCount)
      })

    return () => {}
  }, [])

  const toggle = () => {
    if (modal) {
      setPickImages([])
    }
    return setModal(!modal)
  }

  const handlePageChange = (page) => {
    setImages(null)
    getData
      .getImages({ limit: PER_PAGE, offset: PER_PAGE * (page - 1) })
      .then((result) => {
        setImages(result.images.items)
        setTotalCount(result.images.totalCount)
      })
    setActivePage(page)
  }

  return (
    <Fragment>
      {ToggleButton ? (
        <ToggleButton onClick={toggle} />
      ) : (
        <Button color="primary" onClick={toggle}>
          Chọn ảnh
        </Button>
      )}
      <Modal
        toggle={toggle}
        isOpen={modal}
        style={{
          maxWidth: '75%',
        }}
      >
        <ModalHeader toggle={toggle}>
          <Nav>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '1',
                })}
                onClick={() => {
                  toggleTab('1')
                }}
              >
                Thư viện
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '2',
                })}
                onClick={() => {
                  toggleTab('2')
                }}
              >
                Upload
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '3',
                })}
                onClick={() => {
                  toggleTab('3')
                }}
              >
                Thư viện IZZI
              </NavLink>
            </NavItem>
          </Nav>
        </ModalHeader>
        <ModalBody
          style={{
            minHeight: '400px',
          }}
        >
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <GridImage
                images={images}
                setPickImages={
                  pickOne
                    ? function (image) {
                        setPickImages([image])
                      }
                    : function (image) {
                        setPickImages([...pickImages, image])
                      }
                }
                removePickImage={function (image) {
                  setPickImages(pickImages.filter((img) => image.id !== img.id))
                }}
                pickImages={pickImages}
              />
              <div className="d-flex justify-content-center">
                <Pagination
                  prevPageText="prev"
                  nextPageText="next"
                  firstPageText="first"
                  lastPageText="last"
                  activePage={activePage}
                  itemsCountPerPage={PER_PAGE}
                  totalItemsCount={totalCount}
                  onChange={handlePageChange}
                />
              </div>
            </TabPane>
            <TabPane tabId="2">
              <UploadImagePartial
                toggleTab={toggleTab}
                setImages={setImages}
                images={images}
              />
            </TabPane>
            <TabPane tabId="3">
              <IzziLibrary
                pickImages={pickImages}
                setPickImages={setPickImages}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Hủy
          </Button>
          <Button
            id="btn-submit"
            color="primary"
            disabled={pickImages.length === 0}
            onClick={function () {
              if (onChange) {
                toggle()
                return onChange(pickImages)
              }
            }}
          >
            Lưu
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default ModalImage
