import { ProductCategories, UserCategories } from "../Layout/AppNav/NavItems";
import Common from "../utils/common";
import Configuration from "../utils/configuration";
import { getData, postData } from "../utils/data";

const languageId = localStorage.getItem(Configuration.tokenLanguage);
const merchantId = localStorage.getItem(Configuration.merchantId);

export const getProducts = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 1000000;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  //if (options.type === undefined) options.type = Configuration.ProductTypes.Undefined
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

  return getData.getDataGraphQl(`
        {
            products(param:{limit:${options.limit},offset:${options.offset},order:"${
    options.order
  }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", ${categoryIds},
   ${options.type === undefined ? "" : "type:" + options.type}  
})
            {
              items{
                id,
                name,
                friendlyUrl,
                subDescription
                price,
                categories {
                    id,
                    name
                },
                sku,
                published,
                images {
                    id,
                    path
                },
                createdDate,
                modifiedDate,
                limitAdmin,
                limitDomain,
                limitStorage,
                limitUser,
                limitEmailPerMonth,
                functions,
                capacity,
                referenceId,
                productVariants{
                  name
                }
                displayOrder
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const getProductsSubMerchant = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 1000000;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  if (options.type === undefined) options.type = Configuration.ProductTypes.Undefined;
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

  return getData.getDataGraphQl(`
        {
            submerchantproducts(param:{limit:${options.limit},offset:${options.offset},order:"${
    options.order
  }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", ${categoryIds}, type:${
    options.type == undefined ? 1:options.type
  }})
            {
              items{
                merchantCode
                merchantType
                id,
                name,
                friendlyUrl,
                subDescription
                price,
                categories {
                    id,
                    name
                },
                sku,
                published,
                images {
                    id,
                    path
                },
                createdDate,
                modifiedDate,
                limitAdmin,
                limitDomain,
                limitStorage,
                limitUser,
                limitEmailPerMonth,
                functions,
                capacity,
                referenceId,
                productVariants{
                  name
                }
                displayOrder
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const getDeposits = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 1000000;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  if (options.type === undefined) options.type = Configuration.ProductTypes.VacationExchange;
  options.keyword = options.keyword === undefined ? "" : options.keyword;

  return getData.getDataGraphQl(`
        {
          depositorders(param:{limit:${options.limit},offset:${options.offset},order:"${
    options.order
  }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", type:${options.type}})
            {
              items{
                code,
                id
                orderType,
                status
                price
                costPrice
                addressName
                fromDate
                toDate
                point
                owner
                customerName
                createdDate
                status
                productName
                displayStatus
              },
              totalCount,
              success,
              message
            }
        }
        `);
};
export const getDeposit = (id) => {
  return getData.getDataGraphQl(`      
      {
        depositorder(param:{id: "${id}",merchantId:"${localStorage.getItem(
    "merchantId"
  )}", languageId:"${Configuration.languageVi}"})
        {
     
            code,
            id
            orderType,
            status
            price
            costPrice
            referenceCode
            ownerImages{
              id
              path
            }
            addressName
            ownerEmail 
            images{
              path
              id
              
            }
            productId
            ownerMobile
            fromDate
            toDate
            point
            owner
            note
            createdDate
            status
            productName
            displayStatus
          
        }
    }`);
};

export const getProduct = (id, languageId) => {
  if (!languageId) languageId = Configuration.languageVi;

  return getData.getDataGraphQl(`      
      {
        product(param:{id: "${id}"
        ,merchantId:"${localStorage.getItem("merchantId")}", languageId:"${languageId}"})
        {
          autoFlow,
          id,
          categories {
            id,
            name
          },
          feeCategories {
            id,
            name
          },
          images {
            id,
            path,
            name,
            displayOrder,
            isFeatured
          },
          capacity,
          evoucherId,
          eVouchers {
            id
            name
          },
          name,
          attributeValueIds,
          friendlyUrl,
          price,
          sku,
          subDescription,
          description,
          displayOrder,
          productVariants {
            id,
            bookingId,
            supplierId,
            supplierName,
            originalPrice,
            fromDate,
            toDate,
            expireDate,
            attributeValues {
            attributeId,
              id,
              name,
              value
            },
            name,
            sku,
            price,
            images {
              id,
              name,
              path
            },
            sold,
            paid
          }
          addresses {
            id,
            name
          }
          limitAdmin,
          limitDomain,
          limitStorage,
          limitEmailPerMonth,
          limitUser,
          functions,
          membershipId,
          membership {
            id,
            name
          },
          sendingPointInterval,
          sendingTimes,
          numberOfPointsPerSend,
          referenceId,
          authors {
            id,
            name
          }
          userCategories {
            id,
            name
          }
          ratingId,
          exchangePoint,
          adultsStandard, 
          childStandard, 
          childAgeFrom, 
          childAgeTo, 
          adultsExtra, 
          childExtra,
          adultExtraFeeProductId,
          childExtraFeeProductId,
          adultExtraFeeProductName,
          childExtraFeeProductName
        }
    }`);
};

export const createVacationProduct = ({
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  attributeValues,
  categories,
  images,
  productVariants,
  friendlyUrl,
  addressId,
  feeCategories,
  languageId,
  adultsStandard,
  childStandard,
  childAgeFrom,
  childAgeTo,
  adultsExtra,
  childExtra,
  adultExtraFeeProductId,
  childExtraFeeProductId,
}) => {
  return postData.sendCommand("Sale", "CreateVacationProduct", {
    Id: Common.guid(),
    AddressId: addressId,
    MerchantId: merchantId,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    AttributeValues: attributeValues,
    Categories: categories,
    Images: images,
    ProductVariants: productVariants,
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    FeeCategories: Common.createRelationObjectArray(feeCategories),
    AdultsStandard: adultsStandard,
    ChildStandard: childStandard,
    ChildAgeFrom: childAgeFrom,
    ChildAgeTo: childAgeTo,
    AdultsExtra: adultsExtra,
    ChildExtra: childExtra,
    AdultExtraFeeProductId: adultExtraFeeProductId,
    ChildExtraFeeProductId: childExtraFeeProductId,
  });
};

export const createVacationPremiumPassProduct = ({
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  attributeValues,
  categories,
  images,
  evouchers,
  productVariants,
  friendlyUrl,
  addressId,
  feeCategories,
  languageId,
}) => {
  return postData.sendCommand("Product", "CreateVacationPremiumPassProduct", {
    Id: Common.guid(),
    AddressId: addressId,
    MerchantId: merchantId,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Evouchers: evouchers,
    Price: price,
    AttributeValues: attributeValues,
    Categories: categories,
    Images: images,
    ProductVariants: productVariants,
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    FeeCategories: Common.createRelationObjectArray(feeCategories),
  });
};

export const createVacationExchangeProduct = ({
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  attributeValues,
  categories,
  images,
  friendlyUrl,
  addressId,
  feeCategories,
  languageId,
  adultsStandard,
  childStandard,
  childAgeFrom,
  childAgeTo,
  adultsExtra,
  childExtra,
  adultExtraFeeProductId,
  childExtraFeeProductId,
}) => {
  return postData.sendCommand("Product", "CreateVacationExchangeProduct", {
    Id: Common.guid(),
    AddressId: addressId,
    MerchantId: merchantId,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    AttributeValues: attributeValues,
    Categories: categories,
    Images: images,
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    FeeCategories: Common.createRelationObjectArray(feeCategories.map((cate) => cate.value)),
    AdultsStandard: adultsStandard,
    ChildStandard: childStandard,
    ChildAgeFrom: childAgeFrom,
    ChildAgeTo: childAgeTo,
    AdultsExtra: adultsExtra,
    ChildExtra: childExtra,
    AdultExtraFeeProductId: adultExtraFeeProductId,
    ChildExtraFeeProductId: childExtraFeeProductId,
  });
};

export const createFeeProduct = ({
  merchantId,
  name,
  sku,
  price,
  userCategories,
  productCategories,
}) => {
  return postData.sendCommand("Product", "CreateFeeProduct", {
    Id: Common.guid(),
    MerchantId: merchantId,
    Name: name,
    Sku: sku,
    Price: price,
    UserCategories: Common.createRelationObjectArray(userCategories),
    ProductCategories: Common.createRelationObjectArray(productCategories),
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
  });
};

export const updateFeeProduct = ({
  id,
  merchantId,
  name,
  sku,
  price,
  userCategories,
  productCategories,
}) => {
  return postData.sendCommand("Product", "UpdateFeeProduct", {
    Id: id,
    MerchantId: merchantId,
    Name: name,
    Sku: sku,
    Price: price,
    UserCategories: Common.createRelationObjectArray(userCategories),
    ProductCategories: Common.createRelationObjectArray(productCategories),
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
  });
};

export const updateVacationProduct = ({
  id,
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  attributeValues,
  categories,
  images,
  productVariants,
  friendlyUrl,
  addressId,
  feeCategories,
  languageId,
  adultsStandard,
  childStandard,
  childAgeFrom,
  childAgeTo,
  adultsExtra,
  childExtra,
  adultExtraFeeProductId,
  childExtraFeeProductId,
}) => {
  return postData.sendCommand("Sale", "UpdateVacationProduct", {
    Id: id,
    AddressId: addressId,
    MerchantId: merchantId,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    AttributeValues: attributeValues,
    Categories: categories,
    Images: images,
    ProductVariants: productVariants,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    AdultsStandard: adultsStandard,
    ChildStandard: childStandard,
    ChildAgeFrom: childAgeFrom,
    ChildAgeTo: childAgeTo,
    AdultsExtra: adultsExtra,
    ChildExtra: childExtra,
    AdultExtraFeeProductId: adultExtraFeeProductId,
    ChildExtraFeeProductId: childExtraFeeProductId,
    FeeCategories: Common.createRelationObjectArray(feeCategories),
  });
};

export const updateVacationPremiumPassProduct = ({
  id,
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  attributeValues,
  categories,
  images,
  evouchers,
  productVariants,
  friendlyUrl,
  addressId,
  feeCategories,
  languageId,
}) => {
  return postData.sendCommand("Product", "UpdateVacationPremiumPassProduct", {
    Id: id,
    AddressId: addressId,
    MerchantId: merchantId,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    AttributeValues: attributeValues,
    Categories: categories,
    Images: images,
    Evouchers: evouchers,
    ProductVariants: productVariants,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    FeeCategories: Common.createRelationObjectArray(feeCategories),
  });
};

export const updateVacationExchangeProduct = ({
  id,
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  attributeValues,
  categories,
  images,
  friendlyUrl,
  addressId,
  feeCategories,
  languageId,
  adultsStandard,
  childStandard,
  childAgeFrom,
  childAgeTo,
  adultsExtra,
  childExtra,
  adultExtraFeeProductId,
  childExtraFeeProductId,
}) => {
  return postData.sendCommand("Product", "UpdateVacationExchangeProduct", {
    Id: id,
    AddressId: addressId,
    MerchantId: merchantId,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    AttributeValues: attributeValues,
    Categories: categories,
    Images: images,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    FeeCategories: Common.createRelationObjectArray(feeCategories.map((cate) => cate.value)),
    AdultsStandard: adultsStandard,
    ChildStandard: childStandard,
    ChildAgeFrom: childAgeFrom,
    ChildAgeTo: childAgeTo,
    AdultsExtra: adultsExtra,
    ChildExtra: childExtra,
    AdultExtraFeeProductId: adultExtraFeeProductId,
    ChildExtraFeeProductId: childExtraFeeProductId,
  });
};

export const createCardProduct = ({
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  sendingPointInterval,
  categories,
  images,
  sendingTimes,
  friendlyUrl,
  numberOfPointsPerSend,
  membershipId,
  evoucherId,
  languageId,
}) => {
  return postData.sendCommand("Product", "CreateCardProduct", {
    Id: Common.guid(),
    MerchantId: merchantId,
    EVoucherId: evoucherId,
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    SendingPointInterval: sendingPointInterval,
    SendingTimes: sendingTimes,
    NumberOfPointsPerSend: numberOfPointsPerSend,
    MembershipId: membershipId,
    Categories: categories,
    Images: images,
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem("userId"),
  });
};

export const updateCardProduct = ({
  id,
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  evoucherId,
  categories,
  images,
  sendingPointInterval,
  friendlyUrl,
  sendingTimes,
  numberOfPointsPerSend,
  membershipType,
  languageId,
}) => {
  return postData.sendCommand("Product", "UpdateCardProduct", {
    Id: id,
    MerchantId: merchantId,
    EVoucherId: evoucherId,
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    SendingPointInterval: sendingPointInterval,
    SendingTimes: sendingTimes,
    NumberOfPointsPerSend: numberOfPointsPerSend,
    MembershipType: membershipType,
    Categories: categories,
    Images: images,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
  });
};

// Start Exchange point product
export const createExchangePointProduct = ({
  id,
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  categories,
  images,
  friendlyUrl,
  exchangePoint,
  attributeValues,
  languageId,
}) => {
  return postData.sendCommand("Product", "CreateExchangePointProduct", {
    Id: id ? id : Common.guid(),
    MerchantId: merchantId,
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    Categories: categories,
    Images: images,
    ExchangePoint: exchangePoint,
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem("userId"),
    AttributeValues: attributeValues,
  });
};

export const updateExchangePointProduct = ({
  id,
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  categories,
  images,
  friendlyUrl,
  exchangePoint,
  attributeValues,
  languageId,
}) => {
  return postData.sendCommand("Product", "UpdateInformationExchangePointProduct", {
    Id: id,
    MerchantId: merchantId,
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    Categories: categories,
    Images: images,
    ExchangePoint: exchangePoint,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
    AttributeValues: attributeValues,
  });
};
// End Exchange point product

// Product member subcription
export const createProductMemberSubcription = ({
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  friendlyUrl,
  categories,
  images,
  membershipId,
  attributeValues,
  languageId,
  displayOrder,
  autoFlow,
}) => {
  return postData.sendCommand("Product", "CreateMemberPackageProduct", {
    Id: Common.guid(),
    MerchantId: merchantId,
    AutoFlow: autoFlow,
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    DisplayOrder: displayOrder,
    MembershipId: membershipId,
    Categories: categories,
    Images: images,
    AttributeValues: attributeValues,
    ActionBy: localStorage.getItem("userId"),
  });
};

export const updateProductMemberSubcription = ({
  id,
  merchantId,
  name,
  subDescription,
  description,
  sku,
  price,
  friendlyUrl,
  categories,
  images,
  membershipId,
  attributeValues,
  languageId,
  displayOrder,
  autoFlow,
}) => {
  return postData.sendCommand("Product", "UpdateMemberPackageProduct", {
    Id: id,
    AutoFlow: autoFlow,
    MerchantId: merchantId,
    LanguageId: languageId,
    FriendlyUrl: friendlyUrl,
    Name: name,
    SubDescription: subDescription,
    Description: description,
    Sku: sku,
    Price: price,
    DisplayOrder: displayOrder,
    MembershipId: membershipId,
    Categories: categories,
    Images: images,
    AttributeValues: attributeValues,
    ActionBy: localStorage.getItem("userId"),
  });
};

// end product member subcription

export const createDeposit = ({
  ownerId,
  exchangeProductId,
  bookingId,
  originalPrice,
  price,
  fromDate,
  toDate,
  cutOfDate,
  promotionId,
  feeCategories,
  cutOfDateFeeCategoryIds,
  languageId,
}) => {
  return postData.sendCommand("Sale", "CreateVacationExchangeDepositProduct", {
    Id: Common.guid(),
    OwnerId: ownerId,
    DepositProductId: exchangeProductId,
    BookingId: bookingId,
    OriginalPrice: originalPrice,
    Price: price,
    FromDate: fromDate,
    ToDate: toDate,
    CutOfDate: cutOfDate,
    PromotionId: promotionId,
    FeeCategories: feeCategories,
    CutOfDateFeeCategoryIds: cutOfDateFeeCategoryIds,
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem("userId"),
    LanguageId: languageId,
  });
};

export const confirmVacationExchangeProductOrder = ({ orderId, note }) => {
  return postData.sendCommand("Sale", "ConfirmVacationExchangeProductOrder", {
    Id: orderId,
    Notes: note,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
  });
};
export const updatePriceVacationExchangeProductOrder = ({ price, productId, originalPrice }) => {
  return postData.sendCommand("Product", "UpdatePriceProduct", {
    Id: productId,
    Price: price,
    OriginalPrice: originalPrice,
    ActionBy: localStorage.getItem("userId"),
  });
};

export const rejectVacationExchangeProductOrder = ({ orderId, note }) => {
  return postData.sendCommand("Sale", "RejectVacationExchangeProductOrder", {
    Id: orderId,
    Notes: note,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem("userId"),
  });
};

export const updateDisplayOrderProducts = (categoryId, productIds) => {
  return postData.sendCommand("Product", "UpdateDisplayOrderProducts", {
    Id: Common.guid(),
    CategoryId: categoryId,
    ProductPositions: productIds.map((id, index) => ({
      Id: id,
      DisplayOrder: index,
    })),
    ActionBy: localStorage.getItem("userId"),
  });
};

export const addProductsToCategory = (categoryId, productIds) => {
  return postData.sendCommand("Product", "AddProductsToCategory", {
    Id: Common.guid(),
    ProductIds: productIds,
    CategoryId: categoryId,
    ModifiedDate: Common.getCurrentDate(),
    ModifiedBy: localStorage.getItem("userId"),
  });
};

export const removeProductsFromCategory = (categoryId, productIds) => {
  return postData.sendCommand("Product", "RemoveProductsFromCategory", {
    Id: Common.guid(),
    ProductIds: productIds,
    CategoryId: categoryId,
    ModifiedDate: Common.getCurrentDate(),
    ModifiedBy: localStorage.getItem("userId"),
  });
};
