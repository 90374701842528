import classnames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  loadPickedImages,
  loadPickedThumbnailImages,
  updateAllImages,
} from "../../../reducers/Partial";
import { getData } from "../../../utils/data";
import GridImage from "./GridImage.1";
import IzziLibrary from "./IzziLibrary";

function ModalImageCallBack({
  pickOne,
  thumbnail,
  loadPickedImages,
  loadPickedThumbnailImages,
  onChange,
  getPickImages,
}) {
  const [modal, setModal] = useState(false);
  const [images, setImages] = useState(null);
  const [pickImages, setPickImages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, toggleTab] = useState("1");

  const [activePage, setActivePage] = useState(1);
  const PER_PAGE = 11;

  let loadPickedImage = thumbnail ? loadPickedThumbnailImages : loadPickedImages;

  useEffect(() => {
    getData.getImages({ limit: PER_PAGE, offset: PER_PAGE * (activePage - 1) }).then((result) => {
      // updateAllImages(result.images.items);
      if(result.images != null){
        setImages(result.images.items);
        setTotalCount(result.images.totalCount);
      }
      
    });
  }, []);

  const { handleSubmit, register } = useForm();
  const onSubmit = (values) => {
    getData
      .getImages({
        keyword: values.keyword,
        limit: PER_PAGE,
        offset: PER_PAGE * (activePage - 1),
      })
      .then((result) => {
        console.log(result.images.items);
        // updateAllImages(result.images.items);
        setImages(result.images.items);
        setTotalCount(result.images.totalCount);
      });
  };

  const toggle = () => {
    if (modal) {
      setPickImages([]);
    }
    return setModal(!modal);
  };

  const handlePageChange = (page) => {
    setImages(null);
    getData.getImages({ limit: PER_PAGE, offset: PER_PAGE * (page - 1) }).then((result) => {
      setImages(result.images.items);
      setTotalCount(result.images.totalCount);
    });
    console.log(page);
    setActivePage(page);
  };

  return (
    <Fragment>
      <Button color="primary" onClick={toggle}>
        Chọn
      </Button>
      <Modal
        toggle={toggle}
        isOpen={modal}
        style={{
          maxWidth: "920px",
        }}
      >
        <ModalHeader toggle={toggle}>
          <Nav>
            <NavItem>
              <NavLink
                href="javascript:void(0);"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Ảnh
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="javascript:void(0);"
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                Thư viện IZZI
              </NavLink>
            </NavItem>
          </Nav>
        </ModalHeader>
        <ModalBody
          style={{
            minHeight: "400px",
          }}
        >
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col md="12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group">
                      <input
                        placeholder="Từ khóa"
                        name="keyword"
                        ref={register()}
                        type="text"
                        className="form-control"
                      />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-focus">
                          Tìm kiếm
                        </button>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
              <br />
              <GridImage
                setImages={setImages}
                images={images}
                setPickImages={
                  pickOne
                    ? function (image) {
                        setPickImages([image]);
                      }
                    : function (image) {
                        setPickImages([...pickImages, image]);
                      }
                }
                removePickImage={function (image) {
                  setPickImages(pickImages.filter((img) => image.id !== img.id));
                }}
                pickImages={pickImages}
              />
              <div className="d-flex justify-content-center">
                <Pagination
                  prevPageText="<"
                  nextPageText=">"
                  firstPageText="<<"
                  lastPageText=">>"
                  activePage={activePage}
                  itemsCountPerPage={PER_PAGE}
                  totalItemsCount={totalCount}
                  onChange={handlePageChange}
                />
              </div>
            </TabPane>

            <TabPane tabId="3">
              <IzziLibrary pickImages={pickImages} setPickImages={setPickImages} />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Thoát
          </Button>
          <Button
            id="btn-submit"
            color="primary"
            disabled={pickImages.length === 0}
            onClick={function () {
              if (onChange) {
                return onChange(pickImages);
              }
              loadPickedImage(pickImages);
              if (getPickImages !== undefined) {
                getPickImages(pickImages);
              }

              toggle();
            }}
          >
            Chọn
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default ModalImageCallBack;
