import axios from "axios";
import Common from "./common";
import Configuration from "./configuration";

export const getData = {
  getDataGraphQl(query) {
    return axios.post(Configuration.url_graphQl, { query: query }).then((res) => {
      return res.data.data;
    });
  },
  getDataById(domain, id, fields, languageId) {
    var query = `{
                ${domain}(param:{id: "${id}",languageId:"${
      languageId ? languageId : localStorage.getItem(Configuration.tokenLanguage)
    }", merchantId:"${localStorage.getItem("merchantId")}"}){
                  ${fields}             
                }
              }`;
    return axios.post(Configuration.url_graphQl, { query: query }).then((res) => {
      return res.data.data;
    });
  },
  getConfigMerchant(options, id) {
    if (id === undefined) id = localStorage.getItem("merchantId");
    return Common.getDataGraphQl(`
            {
                merchant(param:{id:"${id}"})
                {
                  code,
                  configuration,
                  createdBy,
                  createdDate
                  ${options !== undefined && options.length > 0 ? options.join(",") : ""}
                }
              }
            `);
  },
  getAddresses: (options = {}) => {
    const limit = options.limit === undefined ? 1000000 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const order = options.order === undefined ? "desc" : options.order;
    const sort = options.sort === undefined ? "createdDate" : options.sort;
    const keyword = options.keyword === undefined ? "" : options.keyword;
    const languageId =
      options.languageId === undefined
        ? localStorage.getItem(Configuration.tokenLanguage)
        : options.languageId;

    return getData.getDataGraphQl(`
        {
            addresses(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
            ,merchantId:"${localStorage.getItem(
              "merchantId"
            )}", keyword:"${keyword}", languageId:"${languageId}"})
            {
              items{
                id,
                name,
                latitude,
                longitude,
              },
              totalCount,
              success
            }
        }`);
  },
  getEvents: (options = {}) => {
    const limit = options.limit === undefined ? 1000000 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const order = options.order === undefined ? "desc" : options.order;
    const sort = options.sort === undefined ? "createdDate" : options.sort;
    const keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            events(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
            ,merchantId:"${localStorage.getItem("merchantId")}",
            tokenUser:"${localStorage.getItem(Configuration.userCookie)}",
            keyword:"${keyword}", languageId:"${localStorage.getItem(
      Configuration.tokenLanguage
    )}"})
            {
              items{
                id,
                name,
                friendlyUrl,
                type,
                startDateTime,
                endDateTime,
                eventSchedules {
                    id,
                    eventId,
                    name,
                    startDateTime,
                    endDateTime,
                }
                images {
                    id,
                    name,
                    path
                },
                published,
                createdDate
              },
              totalCount,
              success
            }
        }`);
  },
  getFiles: (options = {}) => {
    const limit = options.limit === undefined ? 100 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const type = options.type === undefined ? Configuration.FileTypes.UNDEFINED : options.type;

    return getData.getDataGraphQl(`
    {
        files(param:{merchantId:"${localStorage.getItem(
          "merchantId"
        )}", limit: ${limit}, offset: ${offset}, type: ${type}}){
          items {
            id,
            typeStorage,
            name,
            published,
            path,
            description
          },
          message,
          success,
          totalCount
        }
      }
    `);
  },
  getBookingTransactions(from, to) {
    return getData.getDataGraphQl(
      `
      {
        bookingtransactions(param:{merchantId:"${localStorage.getItem("merchantId")}", limit:100, 
              ${from && to ? `from:"${from}",to:"${to}"` : ""}
            })
        {
          items {
            id,
            bookingDate,
            bookingId,
            userId,
            mobile,
            name,
            email,
            message
          }
        }
      }
`
    );
  },
  getBooking(limit = 10000) {
    return getData.getDataGraphQl(
      `
  {
    bookings(param: {limit: ${limit}, merchantId: "${localStorage.getItem(
        "merchantId"
      )}",languageId:"838aef56-78bb-11e6-b5a6-00155d582814"})
    {
      items {
        id,
        fromTime,
        dayOfWeek,
        toTime
        quota
      },
      totalCount
    }
  }
`
    );
  },
  /**
   *'address':type = 1;
   *'article':type = 2
   *'customer':type = 4
   *'product':type = 3
   *'rank':type = 8
   *'cart':type = 10
   *'home':type = 32
   *'order':type = 1024
   *'template':type = 128
   *'tag':type = 32768
   *default:type = 2
   */
  getCategories(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.languageId === undefined)
      options.languageId = localStorage.getItem(Configuration.tokenLanguage);
    if (options.limit === undefined) options.limit = 100000;
    if (options.offset === undefined) options.offset = 0;
    if (options.order === undefined) options.order = "desc";
    if (options.sort === undefined) options.sort = "createdDate";
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    // if (options.parentId === undefined) options.parentId = Common.GUID_EMPTY
    // if (options.type === undefined) options.type = 'articles'
    let type;
    if (typeof options.type === "number") type = options.type;
    else
      switch (options.type) {
        case "address":
          type = 1;
          break;
        case "article":
          type = 2;
          break;
        case "product":
          type = 3;
          break;
        case "customer":
          type = 4;
          break;
        case "rank":
          type = 8;
          break;
        case "cart":
          type = 10;
          break;
        case "home":
          type = 32;
          break;
        case "menu":
          type = 256;
          break;
        case "order":
          type = 1024;
          break;
        case "template":
          type = 128;
          break;
        case "tag":
          type = 32768;
          break;
        default:
          type = null;
          break;
      }

    return this.getDataGraphQl(`
        {
            categories(param:{${type !== null ? `type:${type}` : ""}, keyword:"${
      options.keyword
    }",limit:${options.limit},offset:${options.offset},order:"${options.order}",sort:"${
      options.sort
    }"
              ,languageId:"${options.languageId}",merchantId:"${options.merchantId}", ${
      options.parentId !== undefined ? `parentId:"${options.parentId}"` : ""
    }}) 
            {
                totalCount,
                message, 
                success,
                items{
                    id,
                    name,
                    postTime,
                    friendlyUrl,
                    subDescription,
                    pathName,
                    description,
                    images {
                        id, 
                        path,
                        isFeatured,
                        displayOrder
                    },
                    parentId,
                    articleCategories {
                        id
                    },
                    value,
                    quotaQuantities{
                      quantity,
                      id,
                      name
                    }
                    displayOrder,
                    published,
                    createdDate,
                    modifiedDate,
                }
            }
        }
        `);
  },
  getSelectionCategories(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.languageId === undefined)
      options.languageId = localStorage.getItem(Configuration.tokenLanguage);
    if (options.limit === undefined) options.limit = 100000;
    if (options.offset === undefined) options.offset = 0;
    if (options.order === undefined) options.order = "desc";
    if (options.sort === undefined) options.sort = "createdDate";
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    // if (options.parentId === undefined) options.parentId = Common.GUID_EMPTY
    // if (options.type === undefined) options.type = 'articles'
    let type;
    if (typeof options.type === "number") type = options.type;
    else
      switch (options.type) {
        case "address":
          type = 1;
          break;
        case "article":
          type = 2;
          break;
        case "product":
          type = 3;
          break;
        case "customer":
          type = 4;
          break;
        case "rank":
          type = 8;
          break;
        case "cart":
          type = 10;
          break;
        case "home":
          type = 32;
          break;
        case "menu":
          type = 256;
          break;
        case "order":
          type = 1024;
          break;
        case "template":
          type = 128;
          break;
        case "tag":
          type = 32768;
          break;
        default:
          type = null;
          break;
      }

    return this.getDataGraphQl(`
        {
            categories(param:{${type !== null ? `type:${type}` : ""}, keyword:"${
      options.keyword
    }",limit:${options.limit},offset:${options.offset},order:"${options.order}",sort:"${
      options.sort
    }"
              ,languageId:"${options.languageId}",merchantId:"${options.merchantId}", ${
      options.parentId !== undefined ? `parentId:"${options.parentId}"` : ""
    }}) 
            {
                totalCount,
                message, 
                success,
                items{
                    id,
                    name,                  
                    pathName,
                    displayOrder,                    
                }
            }
        }
        `);
  },
  getArticle(id, options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.languageId === undefined) options.languageId = Configuration.languageVi;
    return Common.getDataGraphQl(`
        {
            article(param:{id:"${id}",languageId:"${options.languageId}",merchantId:"${options.merchantId}"})
            {
                id,
                name,
                subDescription,
                description,
                postTime,
                categories {
                  id,
                  name
                },
                products {
                  id
                  name
                }
                images {
                  id, 
                  path,
                  name,
                  displayOrder,
                  isFeatured
                },
                authors {
                    id,
                    name
                }
                targetId,
                sections {
                    id,
                    name
                },
                friendlyUrl,
                metaName,
                metaDescription,
                metaImage,
                metaKeyword,
                files {
                    id,
                    name,
                    path
                }
            }
          }
        `);
  },

  getEvent(id, options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.languageId === undefined) options.languageId = Configuration.languageVi;
    return Common.getDataGraphQl(`
        {
            event(param:{id:"${id}",languageId:"${options.languageId}",merchantId:"${options.merchantId}"})
            {
                id,
                name,
                subDescription,
                description,
                startDateTime,
                endDateTime,
                externalLink,
                images {
                  id, 
                  path,
                  name,
                  displayOrder,
                  isFeatured
                },
                eventSchedules {
                    id,
                    eventId,
                    name,
                    subDescription,
                    description,
                    startDateTime,
                    endDateTime,
                },
                authors {
                    id,
                    name
                    email
                },
                users {
                    id,
                    name
                    email
                    categories{
                      id
                      name
                    }
                },
                invitedUsers {
                    id,
                    name
                    email
                    categories{
                      id
                      name
                    }
                },
                allInvitedUsers {
                    id,
                    name
                    email
                    categories{
                      id
                      name
                    }
                },
                  products {
                    id,
                    name
                },
                invitedUserCategories {
                    id,
                    name
                },
                articles {
                  id,
                  name
              },
                  forms {
                    id,
                    name
                },
                 addresses {
                    id,
                    name
                }
                friendlyUrl,
                metaName,
                metaDescription,
                metaImage,
                metaKeyword,
                type,
                files {
                    id,
                    name,
                    path
                },
                fileVideos {
                  id,
                  name,
                  path
              }
            }
          }
        `);
  },
  getArticles(options = {}) {
    const type = options.type === undefined ? 0 : options.type;
    const limit = options.limit === undefined ? 100000 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const order = options.order === undefined ? "desc" : options.order;
    const sort = options.sort === undefined ? "createdDate" : options.sort;
    const keyword = options.keyword === undefined ? "" : options.keyword;
    const languageId =
      options.languageId === undefined
        ? localStorage.getItem(Configuration.tokenLanguage)
        : options.languageId;
    const merchantId =
      options.merchantId === undefined ? localStorage.getItem("merchantId") : options.merchantId;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;
    const targetId = options.targetId === undefined ? "" : `targetId:"${options.targetId}"`;
    const published = options.published === undefined ? "" : `published:${options.published}`;
    return Common.getDataGraphQl(`
        {
            articles(param:{type:${type},keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                ,languageId:"${languageId}",merchantId:"${merchantId}", ${categoryIds}, ${targetId}, ${published}})
            {
                totalCount,
                message, 
                success,
                items{
                        id,
                        name,
                        subDescription,
                        friendlyUrl,
                        description,
                        categories {
                            id,
                            name
                        },
                        images {
                            id, 
                            path
                        },
                        authors {
                            id,
                            name
                        },
                        modifiedDate,
                        createdDate,
                        published
                },
                
            }
          }
        `);
  },
  getArticlesAcrossMerchant(options = {}) {
    const type = options.type === undefined ? 0 : options.type;
    const limit = options.limit === undefined ? 100000 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const order = options.order === undefined ? "desc" : options.order;
    const sort = options.sort === undefined ? "createdDate" : options.sort;
    const keyword = options.keyword === undefined ? "" : options.keyword;
    const languageId =
      options.languageId === undefined
        ? localStorage.getItem(Configuration.tokenLanguage)
        : options.languageId;
    const merchantId =
      options.merchantId === undefined ? localStorage.getItem("merchantId") : options.merchantId;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;
    const merchantIds =
      options.merchantIds === undefined ||
      options.merchantIds === null ||
      options.merchantIds.length === 0
        ? ""
        : `merchantIds:[${options.merchantIds.map((val) => `"${val}"`).join(",")}]`;
    const targetId = options.targetId === undefined ? "" : `targetId:"${options.targetId}"`;
    return Common.getDataGraphQl(`
        {
            accrossmerchantarticles(param:{type:${type},keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                ,languageId:"${languageId}",merchantId:"${merchantId}", ${categoryIds}, ${targetId}, ${merchantIds}})
            {
                totalCount,
                message, 
                success,
                items{
                        id,
                        merchantCode
                        name,
                        subDescription,
                        friendlyUrl,
                        description,
                        categories {
                            id,
                            name
                        },
                        images {
                            id, 
                            path
                        },
                        authors {
                            id,
                            name
                        },
                        modifiedDate,
                        createdDate,
                        published
                },
                
            }
          }
        `);
  },
  getAttributes(options = {}) {
    const type = options.type === undefined ? 1 : options.type;
    const subType = options.subType === undefined ? 0 : options.subType;
    const limit = options.limit === undefined ? 100000 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const order = options.order === undefined ? "desc" : options.order;
    const sort = options.sort === undefined ? "createdDate" : options.sort;
    const keyword = options.keyword === undefined ? "" : options.keyword;
    const languageId =
      options.languageId === undefined
        ? localStorage.getItem(Configuration.tokenLanguage)
        : options.languageId;
    const categoryIds =
      options.categoryIds === undefined || options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;
    const targetId = options.targetId === undefined ? "" : `targetId:"${options.targetId}"`;
    return Common.getDataGraphQl(`
        {
            attributes(param:{type:${type}, subType:${subType} ,keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                ,languageId:"${languageId}",merchantId:"${localStorage.getItem(
      "merchantId"
    )}", ${categoryIds}, ${targetId}})
            {
                totalCount,
                message, 
                success,
                items{
                    attributeValues {
                      id,
                      attributeId,
                      name,
                      value
                    },
                    name,
                    id,
                    published,
                    createdDate
                },
                
            }
          }
        `);
  },
  getAttribute(id, options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.languageId === undefined) {
       options.languageId = Common.getParameterByName("languageId")
      ? Common.getParameterByName("languageId")
      : localStorage.getItem(Configuration.tokenLanguage);
    }
    return Common.getDataGraphQl(`
        {
            attribute(param:{id:"${id}",languageId:"${options.languageId}",merchantId:"${options.merchantId}"})
            {
                id,
                name,
                description,
                type,
                subType,
                displayOrder, 
                code
                displayType,
                dataType,
                isRequired,                
                images {
                  id,
                  path,
                  isFeatured
                },
                attributeValues {
                  id,
                  value,
                  imagePath,
                  displayOrder                
                },
                attributeTextValues {
                    id
                    value
                    imagePath
                    displayOrder
                    isDefaultValue
                  },
                  attributeIntValues {
                    id,
                    value,
                    imagePath,
                    displayOrder,
                    isDefaultValue,
                  },
                  attributeDecimalValues {
                    id,
                    value,
                    imagePath,
                    displayOrder,
                    isDefaultValue,
                  },
                  attributeDateTimeValues {
                    id,
                    value,
                    imagePath,
                    displayOrder,
                    isDefaultValue,
                  },
                  attributeVarcharValues {
                    id,
                    value,
                    imagePath,
                    displayOrder,
                    isDefaultValue,
                  }

            }
          }
        `);
  },
  getUser(id) {
    const languageId = Common.getParameterByName("languageId")
      ? Common.getParameterByName("languageId")
      : localStorage.getItem(Configuration.tokenLanguage);
    return getData.getDataGraphQl(
      `
    {
        user(param:{id: "${id}",languageId:"${languageId}", merchantId:"${localStorage.getItem(
        "merchantId"
      )}"}){
            active,
            categories {
              id,
              name
            },
            createdBy,
            createdDate,
            email,
            id,
            allType,
            images {
              id,
              path,
              displayOrder,
              isFeatured
            },
            description,
            subDescription,
            merchantId,
            mobile,
            modifiedBy,
            modifiedDate,
            name,
            userName,
        }
      }
      
    `
    );
  },
  getUsers(options = {}) {
    const limit = options.limit === undefined ? 1000000 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const order = options.order === undefined ? "desc" : options.order;
    const sort = options.sort === undefined ? "createdDate" : options.sort;
    const keyword = options.keyword === undefined ? "" : options.keyword;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;
    const type = options.type === undefined ? "" : `type:${options.type}`;
    const allType = options.allType === undefined ? "" : `allType:${options.allType}`;
    return Common.getDataGraphQl(`
        {
            selectionusers(param:{limit:${limit}, keyword:"${keyword}",offset:${offset},order:"${order}",sort:"${sort}",languageId:"${
      Configuration.languageVi
    }"
                ,merchantId:"${localStorage.getItem("merchantId")}", ${categoryIds}, ${type}, ${allType}})
            {
                totalCount,
                message, 
                success,
                items{
                    active,
                    categories {
                      id,
                      name
                    },
                    createdBy,
                    createdDate,
                    email,
                    id,
                    images {
                      id
                    },
                    merchantId,
                    mobile,
                    modifiedBy,
                    modifiedDate,
                    name,
                    allType,
                    userName,
                    merchantCode,
                    attributeValues{
                      id, 
                      name,
                      value
                    }
                },
                
            }
          }
        `);
  },
  getCourseUsers(options = {}) {
    const targetId =
      options.targetId === undefined ? "90f737d8-9cd6-84af-5d45-f7c898e060bc" : options.targetId;
    const limit = options.limit === undefined ? 1000000 : options.limit;
    const offset = options.offset === undefined ? 0 : options.offset;
    const order = options.order === undefined ? "desc" : options.order;
    const sort = options.sort === undefined ? "createdDate" : options.sort;
    const keyword = options.keyword === undefined ? "" : options.keyword;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;
    const type = options.type === undefined ? "" : `type:${options.type}`;
    return Common.getDataGraphQl(`
        {
            courseusers(param:{limit:${limit},targetId:"${targetId}", keyword:"${keyword}",offset:${offset},order:"${order}",sort:"${sort}",languageId:"${
      Configuration.languageVi
    }"
                ,merchantId:"${localStorage.getItem("merchantId")}", ${categoryIds}, ${type}})
            {
                totalCount,
                message, 
                success,
                items{
                    active,
                    percent,
                    courseCertificatePercent
                    categories {
                      id,
                      name
                    },
                    courses{
                      id,
                      name
                    },
                    createdBy,
                    createdDate,
                    email,
                    id,
                    images {
                      id,
                      path
                    },
                    merchantId,
                    mobile,
                    modifiedBy,
                    modifiedDate,
                    name,
                    allType,
                    userName,
                    merchantCode,
                    userQuizzes{
                      id,
                      name,
                      conditionValue,
                      startTime,
                      endTime, 
                      status, 
                      point, 
                      score, 
                      quizId,
                      lessonId, 
                      lessonName,         
                    }
                },
                
            }
          }
        `);
  },
  getThemeWebs(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 1000000;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return this.getDataGraphQl(`
        {
            themewebs(param:{limit:${options.limit},offset:${options.offset},order:"asc",sort:"createdDate"
              , keyword:"${options.keyword}"}) 
              {
                totalCount,       
                items{
                  id,
                  name,
                    sections {
                      id
                  },
                  images {
                    id,
                    path
                  },
                  subDescription,
                  createdDate,
                  description,
                  modifiedDate,
                  published
                }
              }
        }
        `);
  },
  getThemeWeb(id) {
    return getData.getDataGraphQl(`
        {
            themeweb(param:{id:"${id}"})
          {
            id,
            name,
            subDescription,
            images {
              id,
              path
            }
          }
        }
        `);
  },
  getProducts(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 50;
    if (options.offset === undefined) options.offset = 0;
    if (options.order === undefined) options.order = "desc";
    if (options.sort === undefined) options.sort = "createdDate";
    if (options.type === undefined) options.type = Configuration.ProductTypes.None;
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

    return getData.getDataGraphQl(`
        {
            products(param:{limit:${options.limit},offset:${options.offset},order:"${
      options.order
    }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
      options.keyword
    }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", ${categoryIds}, type:${
      options.type
    }})
            {
              items{
                id,
                name,
                friendlyUrl,
                subDescription
                price,
                categories {
                    id,
                    name
                },
                sku,
                published,
                images {
                    id,
                    path
                },
                createdDate,
                modifiedDate,
                capacity,
                type,
                attributeValues{
                  id, 
                  name, 
                  value
                }
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },

  getListProducts(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 50;
    if (options.offset === undefined) options.offset = 0;
    if (options.order === undefined) options.order = "desc";
    if (options.sort === undefined) options.sort = "createdDate";
    if (options.type === undefined) options.type = Configuration.ProductTypes.None;
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

    return getData.getDataGraphQl(`
        {
          listproducts(param:{limit:${options.limit},offset:${options.offset},order:"${
      options.order
    }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
      options.keyword
    }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", ${categoryIds}, type:${
      options.type
    }})
            {
              items{
                id,
                name,
                friendlyUrl,
                
                price,
                categories {
                    id,
                    name
                },
                sku,
                published,
                images {
                    id,
                    path
                },
                createdDate,
                modifiedDate,                
                type,
                
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },

  getSelectionProducts(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 50;
    if (options.offset === undefined) options.offset = 0;
    if (options.order === undefined) options.order = "desc";
    if (options.sort === undefined) options.sort = "createdDate";
    if (options.type === undefined) options.type = Configuration.ProductTypes.None;
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

    return getData.getDataGraphQl(`
        {
            products(param:{limit:${options.limit},offset:${options.offset},order:"${
      options.order
    }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
      options.keyword
    }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", ${categoryIds}, type:${
      options.type
    }})
            {
              items{
                id,
                name,              
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },

  getProduct(id, languageId) {
    return getData.getDataGraphQl(
      `      
      {
        product(param:{id: "${id}"
        ,merchantId:"${localStorage.getItem("merchantId")}", languageId:"${
        languageId || localStorage.getItem(Configuration.tokenLanguage)
      }"})
        {
          id,
          categories {
            id,
            name
          },
          forms{
            name
            id
          }
          relationProducts {
            id
            name
          }
          images {
            id,
            path,
            name,
            displayOrder,
            isFeatured
          },
          evoucherId,
          name,
          friendlyUrl,
          price,
          sku,
          subDescription,
          description,
          productVariants {
            id,
            attributeValues {
            attributeId,
              id,
              name,
              value
            },
            name,
            sku,
            price,
            images {
                id,
                name,
                path
              }
          }
          addresses {
              id,
              name
          }
          capacity
          utilities
        }
    }`
    );
  },
  getPages(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100;
    if (options.languageId === undefined)
      options.languageId = localStorage.getItem(Configuration.tokenLanguage);
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            pages(param:{limit:${options.limit},offset:${options.offset},order:"desc",sort:"createdDate"
            ,merchantId:"${options.merchantId}", keyword:"${options.keyword}", languageId:"${options.languageId}"})
            {
              items{
                id,
                name,
                friendlyUrl,
                subDescription,
                published,
                createdDate,
                modifiedDate
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },
  getOrder(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
           orders(param:{limit:${options.limit},offset:${options.offset},order:"asc",sort:"asc"
            ,merchantId:"${localStorage.getItem("merchantId")}"
            ,languageId:"${localStorage.getItem(Configuration.tokenLanguage)}"
            ${options.from && options.to ? `from:"${options.from}",to:"${options.to}"` : ""}
            })
            {
                items{
                  id,
                  code,
                  createdBy,
                  createdDate,
                  giftCodeAmount,
                  grandTotal,
                  merchantId,
                  modifiedBy,
                  modifiedDate,
                  allStatus,
                  subTotal,
                  userName,
                  userEmail,
                  userMobile,
               
                      paymentMethodName,
                      paymentAmount,
                      paymentAllStatus,
                  delivery{
                      receivingTime,
                      
                      latitude,
                      longitude,
                      addressDetail,
                      amount,
                      allStatus,
                      note
                  }

                  orderLines{
                    id,
                    price,
                    targetName,
                    originalPrice,
                    quantity,
                    total
                  }
                  
                },
                totalCount,
                success,
                message
              }
        }
        `);
  },
  getCurrentThemeweb() {
    return getData
      .getDataGraphQl(
        `
      {
        merchant(param:{merchantId:"${localStorage.getItem(
          "merchantId"
        )}", languageId:"${localStorage.getItem(
          Configuration.tokenLanguage
        )}", id:"${localStorage.getItem("merchantId")}"}) 
          {
            themeWeb {
              id,
              name
            },
            
          }
    }
      `
      )
      .then((res) => res.merchant.themeWeb);
  },
  getOrderAffiliate(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
           reportaffiliateorders(param:{limit:${options.limit},offset:${
      options.offset
    },order:"desc",sort:"createdDate"
            ,merchantId:"${localStorage.getItem("merchantId")}"
            ,languageId:"${localStorage.getItem(Configuration.tokenLanguage)}"
            ,${options.from && options.to ? `from:"${options.from}",to:"${options.to}"` : ""}
            })
            {
                items{
                  id,
                  code,
                  createdBy,
                  affiliateName,
                  createdTime,
                  createdDate,
                  giftCodeAmount,
                  affiliateCommission,
                  grandTotal,
                  merchantId,
                  modifiedBy,
                  modifiedDate,
                  subTotal,
                  userName,
                  userEmail,
                  userMobile,
               
                      paymentMethodName,
                      paymentAmount,
                      paymentAllStatus,              
                  
                },
                totalCount,
                success,
                message
              }
        }
        `);
  },
  getQuiz(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100000;
    if (options.offset === undefined) options.offset = 0;
    if (options.type === undefined) options.type = 1;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            quizs(
              param:{
                limit:${options.limit},
                offset:${options.offset},order:"asc",sort:"asc",
                keyword:"${options.keyword}",
                languageId:"${localStorage.getItem(Configuration.tokenLanguage)}",                
                tokenUser:"${localStorage.getItem(Configuration.userCookie)}",
                merchantId:"${options.merchantId}", 
                type:${options.type}
              }
            )
            {
              items{
                  id,
                  name,
                  subDescription,
                  description,
                  merchantId,
                  published,
                  createdDate,
                  categories{
                      id,
                      name,
                      displayOrder,
                  }
                  images {
                    id,
                    name,
                    path,
                    isFeatured,
                  },
                  option,
                  duration,     
                },
                totalCount,
                success,
                message
              }
        }
        `);
  },
  getForm(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 50;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            forms(param:{limit:${options.limit},
              keyword:"${options.keyword}",
            offset:${options.offset},order:"desc",sort:"createdDate",               
            ,merchantId:"${options.merchantId}"})
            {
                items{
                    id,
                    published,
                    name,
                    type,
                    typeOnSubmit,
                    valueOnSubmit,
                    modifiedDate,
                    createdDate   
                },
                totalCount,
                success,
                message
              }
        }
        `);
  },
  getDetailForm(id) {
    return getData.getDataGraphQl(`
        {
            form(param: {id: "${id}", 
            merchantId: "${localStorage.getItem(Configuration.merchantId)}"}) {
            id,
            name,
            description,
            subDescription,
            actions,
            typeOnSubmit,
            valueOnSubmit
            }
          }`);
  },
  getQuestions(options) {
    if (options === undefined) options = {};
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    return Common.getDataGraphQl(`
        {
            questions(param:{
                limit:${options.limit},offset:${options.offset},
                languageId:"${localStorage.getItem(Configuration.tokenLanguage)}",
                keyword:"${options.keyword}",
                merchantId: "${localStorage.getItem(Configuration.merchantId)}"})
            {
                items{
                    id
                    name
                    point
                    
                    subDescription
                    displayOrder
                    type
                    description
                    createdDate
                    images {
                        id
                        displayOrder
                        path
                        isFeatured
                      }
                    answers {
                      id
                      name
                      isCorrect
                      displayOrder
                      images {
                        id
                        displayOrder
                        path
                        isFeatured
                      }
                    }
                  },               
                  
                totalCount,
                success,
                message
            }

        }`);
  },
  getNotifications(options) {
    if (options === undefined) options = {};
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    return Common.getDataGraphQl(`
        {
            notifications(param:{
                limit:${options.limit},offset:${options.offset},
                languageId:"${localStorage.getItem(Configuration.tokenLanguage)}",
                keyword:"${options.keyword}",
                merchantId: "${localStorage.getItem(Configuration.merchantId)}"})
            {
                items{
                    id,
                    name,
                  createdDate,
                  configuration,
                  targetIds
                  targetEntity,
                  action
                  published
                  },               
                  
                totalCount,
                success,
                message
            }

        }`);
  },
  getTotalQuiz() {
    return getData.getDataGraphQl(`
        {
            quizs(param:{limit:${10000},offset:${0},order:"asc",sort:"asc"
            ,merchantId:"${localStorage.getItem(Configuration.merchantId)}"})
            {
               
                totalCount,
                success,
                message
              }
        }
        `);
  },
  getDetailQuiz(idQuiz) {
    return getData.getDataGraphQl(
      `
            {
                quiz(param: {id: "${idQuiz}",
                 merchantId: "${localStorage.getItem(Configuration.merchantId)}",
                 languageId:"${localStorage.getItem(Configuration.tokenLanguage)}",
                 tokenUser:"${localStorage.getItem(Configuration.userCookie)}",
                }
                 ) {
                    id
                    name
                    duration
                    subDescription
                    description
                    option
                    categories {
                      id
                      displayOrder
                    }
                    images {
                        id,
                        name,
                        path,
                        displayOrder
                        isFeatured
                    }
                    questions {
                      id
                      name
                      point
                      images {
                        id
                        name
                        path
                        displayOrder
                      }
                      subDescription
                      description
                      type
                      displayOrder
                      answers {
                        id
                        name
                        images {
                          id
                          name
                          path
                          displayOrder
                        }
                        isCorrect
                        displayOrder
                      }
                    }
                }
              }                  
              
            `
    );
  },
  getCategoryQuiz() {
    return getData.getDataGraphQl(
      `
            {
                categories(param: {type: 512, 
                    published: true,
                    languageId: "${localStorage.getItem(
                      Configuration.tokenLanguage
                    )}", offset: 0, limit: 10000, merchantId: "${localStorage.getItem(
        Configuration.merchantId
      )}"}) {
                  items {
                    id
                    name
                    createdDate
                  }
                  totalCount
                  message
                }
              }                   
              
            `
    );
  },
  getPromotion(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            promotions(param:{limit:${options.limit},offset:${options.offset},order:"asc",sort:"createdDate"
            ,merchantId:"${options.merchantId}"})
            {
              items{
                  id,
               active,
                createdBy,
                createdDate,
                
                merchantId,
                name
                
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },
  getEvoucher(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100000;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            evouchers(param:{limit:${options.limit},offset:${options.offset},order:"desc",sort:"createdDate"
            ,merchantId:"${options.merchantId}"})
            {
              items{
                name,
                id,
                active,
                availableTo,
                availableFrom,
                createdBy,
                createdDate,
                merchantId,
                modifiedBy,
                modifiedDate,
                totalCode,
                totalImported,
                totalLocked,
                totalCodeExported,                
                totalCodeUsed,                
                value,
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },
  getPromotions(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            promotions(param:{limit:${options.limit},offset:${options.offset},order:"asc",sort:"asc"
            ,merchantId:"${options.merchantId}", keyword:""})
            {
              items{
               name,
               note,
               id,
                type,
                modifiedBy,
                modifiedDate,
                merchantId,
                createdBy,
                createdDate,
                active
               
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },
  getEmailTemplates(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 10000000;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            emailtemplates(param:{limit:${options.limit},offset:${options.offset},order:"desc",sort:"templateName"
            ,merchantId:"${options.merchantId}", keyword:"${options.keyword}"})
            {
              items{
                id,
                merchantId,
                subject,
                template,
                templateName,
                templateSystemName
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },
  getEmails(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            emails(param:{limit:${options.limit},offset:${
      options.offset
    },order:"asc",sort:"createdDate"
            ,merchantId:"${options.merchantId}", keyword:"${
      options.keyword
    }",languageId:"${localStorage.getItem(Configuration.tokenLanguage)}"})
            {
              items{
                id,
                allStatus,
                from,
                fromName,
                subject,
                to,
                toName,
                createdDate,
                sendAt
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },
  getEmail(id) {
    return getData.getDataGraphQl(`
        {
            email(param:{id:"${id}"
            ,merchantId:"${localStorage.getItem("merchantId")}", })
            {
                id,
                allStatus,
                body,
                from,
                fromName,
                subject,
                to,
                toName,
                createdDate,
                sendAt
            }
        }
        `);
  },
  getImages(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 1000;
    if (options.offset === undefined) options.offset = 0;
    options.categoryIds =
      options.categoryIds === undefined || options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;
    options.keyword = options.keyword === undefined ? "" : options.keyword;

    return getData.getDataGraphQl(`
        {
            images(param:{limit:${options.limit},offset:${options.offset},order:"desc",sort:"createdDate", languageId:"${Configuration.languageVi}",
            ,merchantId:"${options.merchantId}", keyword:"${options.keyword}", ${options.categoryIds} })
            {
              items{
                id,
                categories {
                    id,
                    name
                },
                name,
                seoFileName,
                titleAttribute,
                altAttribute,
                createdDate,
                path
              },
              totalCount,
              success,
              message
            }
        }
        `);
  },
  getSections(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 100;
    if (options.offset === undefined) options.offset = 0;
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    if (options.themeWebId === undefined || options.themeWebId === "") options.themeWebId = "";
    else options.themeWebId = `themeWebId: "${options.themeWebId}"`;

    return this.getDataGraphQl(`
        {
            sections(param:{${options.themeWebId},limit:${options.limit},offset:${options.offset},order:"asc",sort:"asc"
              ,merchantId:"${options.merchantId}", keyword:"${options.keyword}"}) 
              {
                items {
                    id,
                    name,
                    html,
                    description,
                    displayOrder,
                    defaultHtml,
                    published,
                    createdDate,
                    isDefault,
                    type
                  },
                  message,
                  success,
                  totalCount
              }
        }
        `);
  },
  getFormTransactions(idForm, from, to) {
    return this.getDataGraphQl(`
        {
            formtransactions(param: {offset: 0, limit: 9999, formId: "${idForm}",
            ${from && to ? `from:"${from}",to:"${to}"` : ""}
        
        }) {
              items {
                id,
                data,
              createdDate  
              }
              totalCount
              success
              message
            }
          }
          
        `);
  },
  getReportDashboard(options) {
    if (!options.tokenUser) {
      options.tokenUser = localStorage.getItem("tokenId");
    }
    return Common.getDataGraphQl(`{
            report(param:{tokenUser: "${options.tokenUser}", languageId:"${Configuration.languageVi}"
            ,type:${options.type}})
            {
              revenue,
              newUser,
              previousNewUser
              previousProduct
              previousRevenue
              product
              revenueDate {
                date
                value
              }
              userDate {
                date
                value
              }
              bestSellingProducts{
                name,
                value
              },
              learnUsers{
                name,
                value
              },
              richestUsers{
                name,
                value
              }
            }
        }
        `);
  },
  getReportGoogleAnalytics({ token, type }) {
    return Common.getDataGraphQl(`
        {
            analyticsAvgSessionReport  (param:{tokenUser: "${token}"
                ,type:${type}})
            {
              previousAvgDurationSession,
        
              previousNewGuest,
              previousTotalSession,
              avgDurationSession,
              newGuest,
              totalSession
        
                }
        }
        `);
  },
  getMerchants(options) {
    if (options === undefined) options = {};
    if (options.merchantId === undefined) options.merchantId = localStorage.getItem("merchantId");
    if (options.limit === undefined) options.limit = 1000000;
    if (options.offset === undefined) options.offset = 0;
    if (options.order === undefined) options.order = "desc";
    if (options.sort === undefined) options.sort = "createdDate";
    options.keyword = options.keyword === undefined ? "" : options.keyword;
    const categoryIds =
      options.categoryIds === undefined ||
      options.categoryIds === null ||
      options.categoryIds.length === 0
        ? ""
        : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

    return getData.getDataGraphQl(`
        {
                merchants(param:{limit:${options.limit},offset:${options.offset},order:"${options.order}",sort:"${options.sort}"
                , keyword:"${options.keyword}", ${categoryIds}})
                {
                items{
                    id,
                    code
                    active
                    expiredTime
                    type
                    domains
                    createdDate
                }
                totalCount
            }
        }
        `);
  },
};

export const postData = {
  sendCommand(domain, commandName, body) {
    const dataSend = {
      CommandName: commandName,
      Domain: domain,
      Content: JSON.stringify(body),
      TimeOutSecond: 20,
    };

    const api_url = Configuration.url_api + "/Command/SendSync";
    return axios.post(api_url, dataSend);
  },
  changeStatusCommand(domain, commandName, changeStatuses) {
    const body = {
      Id: Common.guid(),
      ChangeStatuses: changeStatuses,
      ModifiedBy: localStorage.getItem("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
    };
    return postData.sendCommand(domain, commandName, body);
  },
  deleteItemCommand(domain, commandName, ids) {
    const body = {
      Id: Common.guid(),
      Ids: ids,
      ModifiedBy: localStorage.getItem("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
    };

    return postData.sendCommand(domain, commandName, body);
  },
  // sau sua command nay tu them user => them vao cai nao cung duoc
  addItemToCategories(domain, commandName, ids, categoryIds) {
    const body = {
      Id: Common.guid(),
      ModifiedDate: new Date(),
      ModifiedBy: localStorage.getItem("userId"),
      UserIds: ids,
      CategoryIds: categoryIds,
    };
    return postData.sendCommand(domain, commandName, body);
  },

  removeItemToCategories(domain, commandName, ids, categoryIds) {
    const body = {
      Id: Common.guid(),
      ModifiedDate: new Date(),
      ModifiedBy: localStorage.getItem("userId"),
      UserIds: ids,
      CategoryIds: categoryIds,
    };
    return postData.sendCommand(domain, commandName, body);
  },

  changeCategoryForUser({ userId, fromCategoryId, toCategoryId }) {
    const body = {
      Id: Common.guid(),
      UserId: userId,
      FromCategoryId: fromCategoryId,
      ToCategoryId: toCategoryId,
      ModifiedBy: localStorage.getItem("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
    };
    return this.sendCommand("User", "ChangeCategoryForUser", body);
  },
  sendCategory: function (body, isUpdate) {
    const CommanName = isUpdate ? "UpdateInformationCategoryVersion01" : "CreateCategoryVersion01";
    return this.sendCommand("Category", CommanName, {
      Id: isUpdate ? "" : Common.guid(),
      ParentId: "00000000-0000-0000-0000-000000000000",
      DisplayOrder: 0,
      LanguageId: localStorage.getItem(Configuration.tokenLanguage),
      Type: 2,
      MerchantId: localStorage.getItem("merchantId"),
      SubDescription: "",
      Description: "",      
      // ImageRelations: null,
      ...body,
    });
  },
  /**
   * @param {items:[{Id: String, Publish: boolean}], domain: String, CommandName: String}
   */
  changePublishStatus: function (items, domain, CommandName) {
    const body = {
      Id: Common.guid(),
      ChangeStatuses: items.map((val) => ({
        Id: val.id,
        Publish: val.published,
      })),
      ModifiedBy: localStorage.getItem("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
    };
    return postData.sendCommand(domain, CommandName, body);
  },
  /**
   * @param {body: {
   *   name: String,
   *   password: String,
   *   email: Email,
   *   mobile: String,
   *   type: String,
   *   categories: <RelationObject>,
   *   images: <RelationObject>,
   * },
   * CommandName: {enum: ["CreateCustomer" , "UpdateCustomer"]}}
   */
  postCustomer: function (commandName, body) {
    if (commandName === "CreateCustomer") {
      return postData.sendCommand("User", commandName, {
        Id: body.id,
        MerchantId: localStorage.getItem("merchantId"),
        Name: body.name,
        Password: body.password,
        LanguageId: body.languageId,
        Email: body.email,
        Mobile: body.mobile,
        SubDescription: body.subDescription,
        Description: body.description,
        AllType: body.type,
        Categories: body.categories,
        Images: body.images,
        CreatedDate: Common.formatDateTime(new Date()),
        CreatedBy: localStorage.getItem("userId"),
      });
    }
    return postData.sendCommand("User", commandName, {
      Id: body.id,
      MerchantId: localStorage.getItem("merchantId"),
      Name: body.name,
      LanguageId: body.languageId,
      Email: body.email,
      Mobile: body.mobile,
      AllType: body.type,
      SubDescription: body.subDescription,
      Description: body.description,
      Categories: body.categories,
      Images: body.images,
      CreatedDate: Common.formatDateTime(new Date()),
      CreatedBy: localStorage.getItem("userId"),
    });
  },
  updateInformationImage: function ({ id, seoFileName, altAttribute, titleAttribute, categories }) {
    return postData.sendCommand("Image", "UpdateInformationImage", {
      Id: id,
      SeoFileName: seoFileName,
      AltAttribute: altAttribute,
      TitleAttribute: titleAttribute,
      Categories: categories,
      ModifiedDate: new Date(),
      ModifiedBy: localStorage.getItem("userId"),
    });
  },
  changePasswordUser: function (id, oldPassword, newPassword) {
    return postData.sendCommand("User", "ChangePasswordUser", {
      Id: id,
      OldPassword: oldPassword,
      Password: newPassword,
      ModifiedBy: localStorage.getItem("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
    });
  },
  recoveryPassword: function (uid) {
    return postData.sendCommand("User", "RecoveryPassword", {
      Id: uid,
      ModifiedBy: localStorage.getItem("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
    });
  },
  recoveryPasswordByEmail: function (email) {
    return postData.sendCommand("CustomerRelationshipManagement", "RecoveryPasswordByEmail", {
      Id: Common.guid(),
      Email: email,
      Domain: window.location.host,
      ModifiedBy: Common.GUID_EMPTY,
      ModifiedDate: Common.formatDateTime(new Date()),
    });
  },

  checkEmailExist: async function (email, isFocussingEmailField, defaultEmail) {
    console.log({isFocussingEmailField,defaultEmail},"2136")
    if (email === defaultEmail) return true;
    if (isFocussingEmailField) return true;
    const dataSend = {
      Field: "email",
      Value: email,
      MerchantId: localStorage.getItem("merchantId"),
    };

    const api_url = Configuration.url_api + "/User/CheckUserExist";
    const data = await axios.post(api_url, dataSend);
    return !data.data.Success;
  },
  checkEmailExist2: async function (email) {
    const dataSend = {
      Field: "email",
      Value: email,
      MerchantId: localStorage.getItem("merchantId"),
    };

    const api_url = Configuration.url_api + "/User/CheckUserExist";
    const data = await axios.post(api_url, dataSend);
    return data.data.Success;
  },

  checkIfUserHadConsented: async function (email) {
    const api_url = Configuration.url_api + "/Google/CheckUserVerified?emailAddress=" + email;
    const data = await axios.get(api_url);
    return data.data;
  },

  getGoogleConsentUrl: async function (email) {
    const api_url =
      Configuration.url_api +
      "/Google/CreateOauthUri?emailAddress=" +
      email +
      "&merchantId=" +
      localStorage.getItem("merchantId");
    const data = await axios.post(api_url);
    return data.data;
  },

  getGoogleToken: async function (param) {
    const api_url =
      Configuration.url_api +
      "/Google/GetTokenByCode" +
      param +
      "&merchantId=" +
      localStorage.getItem("merchantId");
    const data = await axios.get(api_url, {
      withCredential: true,
    });
    return data.data;
  },
};
