import { useState } from 'react'

function useModal() {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState({})

  const toggle = (data = {}) => {
    setData(data)
    setIsOpen(!isOpen)
  }

  const setDataModal = (attribute, value) =>
    setData({
      ...data,
      [attribute]: value,
    })

  return [isOpen, toggle, data, setDataModal, setData]
}

export default useModal
