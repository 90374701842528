import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'

function Header({ title, description, iconClassName, actionsComponent }) {
  const history = useHistory()
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className="page-title-icon">
            <i className={iconClassName}></i>
          </div>
          <div>
            {title}
            <div className="page-title-subheading">{description}</div>
          </div>
        </div>
        <div className="page-title-actions d-none d-lg-block">
          {actionsComponent ? (
            actionsComponent
          ) : (
            <Button
              onClick={() => history.goBack()}
              className="mb-2 mr-2 btn-icon btn-hover-shine"
              color="focus"
            >
              <i className="pe-7s-angle-left btn-icon-wrapper mb-1"> </i>
              Trở về
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {}

export default Header
