import cx from "classnames";
import $ from "jquery";
import moment from "moment";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import ResizeDetector from "react-resize-detector";
import { withRouter } from "react-router-dom";
import AppMain from "../Layout/AppMain";
import { updateAllLanguage, updateInformationDateUser } from "../reducers/Partial";
import { updateNavs } from "../reducers/Navigation";
import {
  updateExpiredTime,
  updateMerchant,
  updateMerchantCode,
  updateMerchantId,
  updateMerchantType,
  updateUserId,
  updateUserInfo,
} from "../reducers/System";
import {
  getMerchantTypeWithNavs,
  getNavByUser,
  getNavPublished,
  getNavsByMerchantType,
} from "../Services/CmsMenuService";
import { getMerchant } from "../Services/MerchantService";
import Common from "../utils/common";
import Configuration from "../utils/configuration";
import SearchFunction from "./CommonComponent/SearchFunction";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
    var self = this;

    getMerchant().then((res) => {
      let expireDate;
      if (Configuration.turnOffCheckExpire) {
        expireDate = moment().add(1, "years").toDate();
      } else {
        expireDate = moment(res.merchant.expiredTime).toDate();
      }
      self.props.updateExpiredTime(expireDate);
    });
  }

  componentDidMount() {
    let tempUrl = "";
    if (window.location.href.split("#").length > 1) {
      tempUrl = window.location.href.split("#")[1];
    }
    var self = this;
    // check user is logged in
    if (localStorage.getItem(Configuration.userCookie) !== "") {
      $.ajax({
        url:
          Configuration.url_api +
          `/User/GetUserDataByToken?tokenId=${localStorage.getItem(Configuration.userCookie)}`,
        type: "get",
        contentType: "application/json",
        processData: false,
        async: true,
        dataType: "json",

        // user logged in
        success: function (res) {
          if (res.Success) {
            const dataUser = JSON.parse(res.Message);
            self.props.updateInformationDateUser(dataUser);
            self.props.updateUserInfo(dataUser);

            const { MerchantType, TokenId, AllType } = dataUser;

            // neu merchant type ko phai admin, check route legit theo merchant type

            const publishedLegitRoutes = [
              "/#/",
              "/#/401",
              "/#/login",
              "/#/user/change-password",
              "/#/dashboards/home",
            ];
            const unpublishedLegitRoutes = [];

            // add /# or / there for the link is general format: /#/abc/asd
            const formatUrl = (inputUrl) => {
              let formattedUrl = "";
              if (!inputUrl) return "";
              if (inputUrl[0] === "#") {
                formattedUrl = "/" + inputUrl;
              } else if (inputUrl[0] === "/" && inputUrl[1] !== "#") formattedUrl = "/#" + inputUrl;
              else {
                formattedUrl = inputUrl;
              }
              return formattedUrl;
            };

            // get navigation from server
            //console.log(this.state.navs);
 //get languages
 let merchant;
 if (window.location.href.includes("localhost")) {
   merchant = Common.queryDataMerchant(Configuration.merchantLocal); //local
 } else {
   merchant = Common.queryDataMerchant(window.location.hostname.split(".")[0]); //sever
 }
 var tempMerchant = merchant.languages.find((e) => e.id === merchant.languageId);//.code
 if(tempMerchant != undefined){
  localStorage.setItem(
    Configuration.languageCode,
    tempMerchant.code
  );
 }else{
  localStorage.setItem(
    Configuration.languageCode,
    merchant.languages[0].code
  );
 }
 
 self.props.updateAllLanguage(merchant.languages);
 const merchantId = JSON.parse(res.Message).MerchantId;
 const userId = JSON.parse(res.Message).Id;
 const merchantType = JSON.parse(res.Message).MerchantType;
 const merchantCode = JSON.parse(res.Message).MerchantCode;
 Common.setCookie("merchantId", merchantId);
 localStorage.setItem("merchantId", merchantId);
 Common.setCookie("userId", userId);
 localStorage.setItem("userId", userId);
 Common.setCookie("merchantType", merchantType);
 localStorage.setItem("merchantType", merchantType);
 Common.setCookie("merchantCode", merchantCode);
 localStorage.setItem("merchantCode", merchantCode);
 if(localStorage.getItem("cmsLanguage") == undefined || localStorage.getItem("cmsLanguage") == ''){
   localStorage.setItem("cmsLanguage","838aef56-78bb-11e6-b5a6-00155d582814");
 }

 self.props.updateMerchant(merchant);
 self.props.updateMerchantCode(merchantCode);
 self.props.updateMerchantId(merchantId);
 self.props.updateMerchantType(merchantType);
 self.props.updateUserId(userId);

 if (localStorage.getItem(Configuration.tokenLanguage) === "") {
   Common.setCookie(Configuration.tokenLanguage, Configuration.languageVi);
   localStorage.setItem(Configuration.tokenLanguage, Configuration.languageVi);
 }

            if(Common.getCookie("navs") != "" && localStorage.getItem("navs") != null){
              self.props.updateNavs(JSON.parse(localStorage.getItem("navs")));         
            }
            else{
              getNavByUser(TokenId, MerchantType, AllType).then((navs) => {
              
                //Recursive function to get "publishedLegitRoutes" and "unpublishedLegitRoutes"
                function getLegitRoutes(navs, publishedLegitRoutes, unpublishedLegitRoutes) {
                  for (const nav of navs) {
                    const url = formatUrl(nav.to);
                    if (url) {
                      if (nav.Published) {
                        publishedLegitRoutes.push(url);
                      } else {
                        unpublishedLegitRoutes.push(url);
                      }
                    }
                    if (Array.isArray(nav.content) && nav.content.length > 0) {
                      getLegitRoutes(nav.content, publishedLegitRoutes, unpublishedLegitRoutes);
                    }
                  }
                }
                navs.forEach((nav) => {
                  getLegitRoutes(nav.navigation, publishedLegitRoutes, unpublishedLegitRoutes);
                });
  
                // Neu ko phai merchant Admin
                if (parseInt(MerchantType) !== Configuration.merchantType.admin) {
                  // if (true) {
                  //   listen for router change, if current route not legit, redirect to 401 page
                  self.unlisten = self.props.history.listen((location, action) => {
                    const currentRoute = "/#" + location.pathname;
                    let isLegit = false;
                    for (const legitRoute of publishedLegitRoutes) {
                      if (legitRoute == currentRoute) {
                        isLegit = true;
                        break;
                      }
                    }
                    if (!isLegit) {
                      for (const unpublishedLegitRoute of unpublishedLegitRoutes) {
                        if (currentRoute.includes(unpublishedLegitRoute)) {
                          isLegit = true;
                          break;
                        }
                      }
                    }
                    if (!isLegit) {
                      self.props.history.replace("/401");
                    }
                  });
                }
  
                // set navs (which item is publish) and set to navigation redux
                const navPublished = navs.map((r) => ({
                  ...r,
                  navigation: getNavPublished(r.navigation),
                }));
                
                Common.setCookie("navs", true,1);
                localStorage.setItem("navs",JSON.stringify(navPublished));
                //Common.setCookie("navs", navPublished,1);
                //console.log(navPublished);
                self.props.updateNavs(navPublished);              
              });
            }
            

           
          } else {
            if (!window.location.href.includes("login")) {
              window.location.href = "#/login?returnUrl=" + tempUrl;
            }
          }
        },
      });
    } else {
      // user not logged in
      if (window.location.href.split("?").length < 2) {
        if (!window.location.href.includes("login")) {
          window.location.href = "#/login?returnUrl=" + tempUrl;
        }
      }
    }
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                {
                  "fixed-sidebar": enableFixedSidebar || width < 1250,
                },
                { "fixed-footer": enableFixedFooter },
                {
                  "closed-sidebar": enableClosedSidebar || width < 1250,
                },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}
            >
              <AppMain />
              <SearchFunction />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  // enablePageTabsAlt: state.Partial.enablePageTabsAlt,
  allLanguage: state.Partial.allLanguage,
});
const mapDispatchToProps = {
  updateInformationDateUser,
  updateAllLanguage,
  updateMerchantCode,
  updateUserInfo,
  updateMerchant,
  updateMerchantId,
  updateMerchantType,
  updateUserId,
  updateExpiredTime,
  updateNavs,
};
export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
