
import React, { Fragment, useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getAttributes } from "../../../Services/AttributeService";
import _ from 'lodash';
import { useList } from "react-use";
import Configuration from "../../../utils/configuration";

const DATATYPE = Configuration.attributeConfiguration.dataType;
const DISPLAYTYPE = Configuration.attributeConfiguration.displayType;

const AttributeValueForm = ({
    target,
    targetType,
    attributeErrors,
    attributeSelecteds,
    currentAttributeValues,
    onChange,
    ...props }) => {

    const [attributes, setAttributes] = useState();
    const [attributeFormValues, { set: setAttributeFormValues,
        updateAt: updateAttributeFormValuesAt,
        update: updateAttributeFormValues }] = useList([]);
    //const [attributeForm, setAttributeForm] = useState([]);

    const [isFormDataLoaded, setIsFormDataLoaded] = useState(false);

    useEffect(() => {
        //console.log("thay doi roi", attributeFormValues);
        onChange(attributeFormValues);
    }, [attributeFormValues])

    useEffect(() => {
        //console.log("currentAttributeValues", currentAttributeValues);
        getProductAttributeAndDefaultValue(currentAttributeValues);
    }, [currentAttributeValues])

    const [tempAttributeData, setTempAttributeData] = useState();

    const getProductAttributeAndDefaultValue = (currentAttributeValues) => {
        getAttributes({ type: target, subType: targetType, published: true, sort: "displayOrder", order: "asc" }).then((res) => {

            var attributeItems = [];
            var defaultValue = [];
            //if (attributes != undefined) {
            res.attributes.items.forEach((item) => {
                // Text = 1, //input text
                // Ratio = 2,
                // Checkbox = 3,
                // Selection = 4,
                // MultiSelection = 5,
                // TextArea = 6, //text area editor
                // DateTime = 7 //pick date
                //display type: multiselection

                //get default values

                let defaultVarcharValue = item.attributeVarcharValues ? item.attributeVarcharValues.filter((a) => a.isDefaultValue) : null;
                let defaultTextValue = item.attributeTextValues ? item.attributeTextValues.filter((a) => a.isDefaultValue) : null;
                let defaultIntValue = item.attributeIntValues ? item.attributeIntValues.filter((a) => a.isDefaultValue) : null;
                let defaultDecimalValue = item.attributeDecimalValues ? item.attributeDecimalValues.filter((a) => a.isDefaultValue) : null;
                let defaultDateTimeValue = item.attributeDateTimeValues ? item.attributeDateTimeValues.filter((a) => a.isDefaultValue) : null;

                if (currentAttributeValues) {
                    let attributeVarcharValues = currentAttributeValues.attributeVarcharValue;
                    let attributeTextValues = currentAttributeValues.attributeTextValue;
                    let attributeIntValues = currentAttributeValues.attributeIntValue;
                    let attributeDecimalValues = currentAttributeValues.attributeDecimalValue;
                    let attributeDateTimeValues = currentAttributeValues.attributeDateTimeValue;

                    if (attributeVarcharValues && attributeVarcharValues.length && attributeVarcharValues.filter((t) => t.attributeId == item.id)) {
                        defaultVarcharValue = attributeVarcharValues.filter((t) => t.attributeId == item.id);
                    }

                    if (attributeTextValues && attributeTextValues.length && attributeTextValues.filter((t) => t.attributeId == item.id)) {
                        defaultTextValue = attributeTextValues.filter((t) => t.attributeId == item.id);
                    }

                    if (attributeIntValues && attributeIntValues.length && attributeIntValues.filter((t) => t.attributeId == item.id)) {
                        defaultIntValue = attributeIntValues.filter((t) => t.attributeId == item.id);
                    }

                    if (attributeDecimalValues && attributeDecimalValues.length && attributeDecimalValues.filter((t) => t.attributeId == item.id)) {
                        defaultDecimalValue = attributeDecimalValues.filter((t) => t.attributeId == item.id);
                    }

                    if (attributeDateTimeValues && attributeDateTimeValues.length && attributeDateTimeValues.filter((t) => t.attributeId == item.id)) {
                        defaultDateTimeValue = attributeDateTimeValues.filter((t) => t.attributeId == item.id);
                    }
                }

                var attributeItem = getAttributeItemValues(item, defaultVarcharValue, defaultTextValue, defaultIntValue, defaultDecimalValue, defaultDateTimeValue);

                attributeItems.push(attributeItem);
            });

            setAttributeFormValues(attributeItems);
            setAttributes(res.attributes.items);
        });
    }

    const extractValuesFromList = (items, attribute) => {
        let values = []
        items.map((item) => {
            //if (attribute.displayType == 2 || attribute.displayType == 3) {
            if (attribute.displayType === DISPLAYTYPE.RADIO || attribute.displayType === DISPLAYTYPE.CHECKBOX) {
                values.push(item.id);
            }
            //else if (attribute.displayType == 4 || attribute.displayType == 5) {
            else if (attribute.displayType === DISPLAYTYPE.SELECTION || attribute.displayType == DISPLAYTYPE.MUILTISECTION) {
                values.push({ value: item.id, label: item.value });
            }
            else {
                values.push(item.value);
            }
        });
        return values;
    }

    const getAttributeItemValues = (attribute, varcharValues, textValues, intValues, decimalValues, dateTimeValues) => {

        //let attribute = []
        let values = []
        let datevalues = [];
        switch (attribute.dataType) {
            case DATATYPE.VARCHAR: // varchar
                if (varcharValues) {

                    values = extractValuesFromList(varcharValues, attribute);
                };
                break;
            case DATATYPE.TEXT: //text
                if (textValues) {

                    values = extractValuesFromList(textValues, attribute);
                };
                break;
            case DATATYPE.INT: //int
                if (intValues) {

                    values = extractValuesFromList(intValues, attribute);
                };
                break;
            case DATATYPE.DECIMAL: //decimal
                if (decimalValues) {

                    values = extractValuesFromList(decimalValues, attribute);
                };
            case DATATYPE.DATETIME: //datetime
                if (dateTimeValues) {
                    dateTimeValues.map((item) => {
                        //if (attribute.displayType == 2 || attribute.displayType == 3) {
                        if (attribute.displayType === DISPLAYTYPE.RADIO || attribute.displayType === DISPLAYTYPE.CHECKBOX) {
                            values.push(item.id);
                        }
                        else if (attribute.displayType === DISPLAYTYPE.SELECTION || attribute.displayType === DISPLAYTYPE.MUILTISECTION) {
                            values.push({ value: item.id, label: item.value });
                        }
                        else {
                            datevalues.push(item.value);
                        }
                    });
                };
        }

        return {
            AttributeItem: attribute,
            Values: values,
            DateValues: datevalues
        }
    }

    //get state of attribute value according to attribute field
    const getAttributeValueContent = (itemId, type) => {
        if (attributeFormValues != undefined) {
            var field = attributeFormValues.filter((e) => e.AttributeItem.id === itemId)[0];
            var values = field.Values;
            var dateValues = field.DateValues;
            if (type !== DISPLAYTYPE.DATETIME) { //datetime
                if (values != undefined && values.length > 0) {
                    //if (type === 2 || type === 3 || type === 5) { //radio and checkbox and multi select
                    if (type === DISPLAYTYPE.RADIO || type === DISPLAYTYPE.CHECKBOX || type === DISPLAYTYPE.MUILTISECTION) { //radio and checkbox and multi select
                        return values;
                    }
                    else {
                        return values[0];
                    }
                }
                else {
                    return getDefaultAttributeValues(type);
                }
            }
            else {
                if (dateValues != undefined && dateValues.length > 0) {
                    return dateValues[0]
                }
                else {
                    return null;
                    //return getDefaultAttributeValues(type);
                }
            }
            //return getDefaultAttributeValues(type);
        }
    }

    const getDefaultAttributeValues = (type) => {
        switch (type) {
            //case 7:
            case DISPLAYTYPE.DATETIME:
                return new Date();
            default:
                return "";
        }
    }

    const setAttributeValueContent = (content, itemId) => {
        if (attributeFormValues) {
            // alert("set setAttributeFormValues ")
            var attr = attributeFormValues.forEach((item, index) => {
                if (item.AttributeItem.id === itemId) {
                    //if (item.AttributeItem.displayType != 7) {
                    if (item.AttributeItem.displayType !== DISPLAYTYPE.DATETIME) {
                        if (Array.isArray(content)) {
                            //return { ...item, Values: content }
                            updateAttributeFormValuesAt(index, { ...item, Values: content });
                        }
                        else {
                            //return { ...item, Values: [content] }
                            updateAttributeFormValuesAt(index, { ...item, Values: [content] });
                        }
                    }
                    else {
                        //return { ...item, DateValues: [content] }
                        updateAttributeFormValuesAt(index, { ...item, DateValues: [content] });
                    }
                }
                //return item;
            });
        }
    };


    return (
        <div>
            { attributes !== undefined && attributeFormValues &&
                attributes.map((item, i) => {

                    // Text = 1, //input text
                    // Ratio = 2,
                    // Checkbox = 3,
                    // Selection = 4,
                    // MultiSelection = 5,
                    // TextArea = 6, //text area editor
                    // DateTime = 7 //pick date
                    //display type: multiselection
                    const options = [];

                    const defaultValues = [];

                    const attributeValues = [];

                    switch (item.dataType) {
                        case DATATYPE.VARCHAR:
                            item.attributeVarcharValues.map((item) => {
                                attributeValues.push(item);
                            });
                            break;
                        case DATATYPE.TEXT:
                            item.attributeTextValues.map((item) => {
                                attributeValues.push(item);
                            });
                            break;
                        case DATATYPE.INT:
                            item.attributeIntValues.map((item) => {
                                attributeValues.push(item);
                            });
                            break;
                        case DATATYPE.DECIMAL:
                            item.attributeDecimalValues.map((item) => {
                                attributeValues.push(item);
                            });
                            break;
                        case DATATYPE.DATETIME:
                            item.attributeDateTimeValues.map((item) => {
                                attributeValues.push({ id: item.id, value: moment(item.value).format("DD/MM/yyyy") });
                            });
                            break;


                        default: {
                            item.attributeValues.map((item) => {
                                attributeValues.push(item);
                            });
                            break;
                        }
                    }

                    attributeValues.map(
                        (subItem) => {
                            //if (item.displayType == 4 || item.displayType == 5 || item.displayType == 3 || item.displayType == 2) {
                            if (item.displayType === DISPLAYTYPE.SELECTION
                                || item.displayType === DISPLAYTYPE.MUILTISECTION
                                || item.displayType === DISPLAYTYPE.RADIO || item.displayType === DISPLAYTYPE.CHECKBOX) {
                                options.push({
                                    value: subItem.id,
                                    label: <span dangerouslySetInnerHTML={{ __html: subItem.value }} />,
                                });
                            }
                            else {
                                options.push({
                                    value: subItem.id,
                                    label: subItem.value
                                });
                            }

                            if (subItem.isDefaultValue) {
                                //if (item.displayType == 4 || item.displayType == 5 || item.displayType == 3 || item.displayType == 2) {
                                if (item.displayType === DISPLAYTYPE.SELECTION
                                    || item.displayType === DISPLAYTYPE.MUILTISECTION
                                    || item.displayType === DISPLAYTYPE.RADIO || item.displayType === DISPLAYTYPE.CHECKBOX) {
                                    defaultValues.push({
                                        value: subItem.id,
                                        label: <span dangerouslySetInnerHTML={{ __html: subItem.value }} />,
                                    });
                                }
                                else {
                                    defaultValues.push({
                                        value: subItem.id,
                                        label: subItem.value
                                    });
                                }
                            }
                        }
                    );

                    if (item.displayType === DISPLAYTYPE.TEXTBOX) {
                        let defaultValue = getAttributeValueContent(item.id, item.displayType);
                        //console.log("type1", item.id, defaultValue);
                        // if (getValues(item.id) === undefined) {
                        //     setValue(item.id,defaultValue )
                        // }
                        return (
                            <FormGroup>
                                <Label>{item.name} {item.isRequired ? <span class="red">*</span> : ""}</Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={item.id}
                                    value={defaultValue}
                                    onChange={(e) => {
                                        //alert("textbox onchange");
                                        setAttributeValueContent(e.target.value, item.id);
                                    }} >
                                </input>
                                {(attributeErrors && attributeErrors.filter((i) => i.id == item.id).length > 0) ? <div><span class="red">{item.name} is required</span></div> : ""}
                            </FormGroup>
                        );
                    }
                    else if (item.displayType === DISPLAYTYPE.RADIO) {
                        //ratio
                        const selectedValue = getAttributeValueContent(item.id, item.displayType);
                        return (
                            <FormGroup>
                                <Label>{item.name} {item.isRequired ? <span class="red">*</span> : ""}</Label>
                                <Fragment>
                                    {options.map((option, i) => {
                                        //let checked = false;
                                        let inputField;
                                        if (selectedValue && (option.value == selectedValue || selectedValue.includes(option.value))) {
                                            inputField = <Input
                                                type="radio"
                                                // checked={attributeSelecteds.length > 0 ? (attributeSelecteds.filter((f) => f.value == option.value).length > 0) :
                                                //     defaultValues.length > 0 ? defaultValues.filter((f) => f.value == option.value).length > 0 : ""}
                                                //checked={option.value == selectedValue ? true : false}
                                                //{attributeSelecteds.length > 0 ? attributeSelecteds[0].value : defaultValues.length > 0 ? defaultValues[0].value : ""}
                                                name={item.id}
                                                checked={true}
                                                {...props}
                                                value={option.value}
                                                // onClick={(e) => {
                                                //     //console.log(e);
                                                //     //alert("radio onchange");
                                                //     setAttributeValueContent(e.target.value, item.id)
                                                // }}
                                                onClick={(e) => {
                                                    //console.log(e);
                                                    // alert("radio onchange");
                                                    if (e.target.checked) {
                                                        setAttributeValueContent(e.target.value, item.id)
                                                    }
                                                }}
                                            />
                                        }
                                        else {
                                            inputField = <Input
                                                type="radio"
                                                // checked={attributeSelecteds.length > 0 ? (attributeSelecteds.filter((f) => f.value == option.value).length > 0) :
                                                //     defaultValues.length > 0 ? defaultValues.filter((f) => f.value == option.value).length > 0 : ""}
                                                //checked={option.value == selectedValue ? true : false}
                                                //{attributeSelecteds.length > 0 ? attributeSelecteds[0].value : defaultValues.length > 0 ? defaultValues[0].value : ""}
                                                name={item.id}
                                                checked={false}
                                                {...props}
                                                value={option.value}
                                                onClick={(e) => {
                                                    //console.log(e);
                                                    // alert("radio onchange");
                                                    if (e.target.checked) {
                                                        setAttributeValueContent(e.target.value, item.id)
                                                    }
                                                }}
                                            />
                                        }

                                        return (
                                            <FormGroup key={i} check>
                                                <Label check>
                                                    {
                                                        inputField
                                                    }
                                                    {" "}
                                                    {option.label}
                                                </Label>
                                            </FormGroup>
                                        );
                                    })}
                                </Fragment>
                                {(attributeErrors && attributeErrors.filter((i) => i.id == item.id).length > 0) ? <div><span class="red">{item.name} is required</span></div> : ""}
                            </FormGroup>
                        );
                    }
                    else if (item.displayType === DISPLAYTYPE.CHECKBOX) {
                        const selectedValue = getAttributeValueContent(item.id, item.displayType);
                        //console.log("selectedValue_3", selectedValue, options);
                        //checkbox
                        return (
                            <FormGroup>
                                <Label>{item.name} {item.isRequired ? <span class="red">*</span> : ""} cc</Label>
                                <Fragment>
                                    {options.map((option, i) => {
                                        let inputField;
                                        //console.log("option.value", option.value)
                                        if (selectedValue && (selectedValue.includes(option.value))) {
                                            console.log("checked", selectedValue, option.value);
                                            inputField = <Input
                                                type="checkbox"
                                                name={item.id}
                                                //defaultChecked
                                                checked={true}
                                                {...props}
                                                value={option.value}
                                                onClick={(e) => {
                                                    //alert("checked click");
                                                    let newSelected = getAttributeValueContent(item.id, item.displayType);
                                                    //console.log("checkbox", e.target.value, e.target.checked);
                                                    //console.log("newSelected_111111111111", newSelected);
                                                    if (!e.target.checked) {
                                                        //newSelected = newSelected.filter((t) => t != e.target.value);
                                                        if (newSelected)
                                                            newSelected = newSelected.filter((t) => t != e.target.value);
                                                        else {
                                                            newSelected = [];
                                                        }
                                                    }
                                                    else {
                                                        if (!newSelected.includes(e.target.value)) {
                                                            newSelected = [...newSelected, e.target.value];
                                                        }
                                                    }

                                                    //console.log("newSelected_111111111111", newSelected);
                                                    //alert("checkbox onchange");

                                                    setAttributeValueContent(newSelected, item.id)
                                                }}
                                            />
                                        }
                                        else {
                                            console.log("unchecked", selectedValue, option.value);
                                            inputField = <Input
                                                type="checkbox"
                                                // checked={attributeSelecteds.length > 0 ? (attributeSelecteds.filter((f) => f.value == option.value).length > 0) :
                                                //     defaultValues.length > 0 ? defaultValues.filter((f) => f.value == option.value).length > 0 : ""}
                                                //checked={option.value == selectedValue ? true : false}
                                                name={item.id}
                                                //defaultChecked={false}
                                                checked={false}
                                                {...props}
                                                value={option.value}
                                                onClick={(e) => {
                                                    //alert("click");
                                                    let newSelected = getAttributeValueContent(item.id, item.displayType);
                                                    //console.log("newSelected_111111111111", newSelected);
                                                    //console.log("checkbox", e.target.value, e.target.checked);
                                                    if (!e.target.checked) {
                                                        if (newSelected)
                                                            newSelected = newSelected.filter((t) => t != e.target.value);
                                                        else {
                                                            newSelected = [];
                                                        }
                                                    }
                                                    else {
                                                        if (!newSelected.includes(e.target.value)) {
                                                            newSelected = [...newSelected, e.target.value];
                                                        }
                                                    }
                                                    //alert("checkbox onchange");
                                                    //console.log("newSelected_111111111111", newSelected);

                                                    setAttributeValueContent(newSelected, item.id)
                                                }}
                                            />
                                        }
                                        return (
                                            <FormGroup key={i} check>
                                                <Label check>
                                                    {inputField}
                                                    {" "}
                                                    {option.label}
                                                </Label>
                                            </FormGroup>
                                        );
                                    })}
                                </Fragment>
                                {(attributeErrors && attributeErrors.filter((i) => i.id == item.id).length > 0) ? <div><span class="red">{item.name} is required</span></div> : ""}
                            </FormGroup>
                        );
                    }
                    else if (item.displayType === DISPLAYTYPE.SELECTION) {
                        let selectedValue = getAttributeValueContent(item.id, item.displayType);
                        //console.log("selectedValues_4", item.id, selectedValue, options);
                        return (
                            <FormGroup>
                                <Label>{item.name} {item.isRequired ? <span class="red">*</span> : ""}</Label>
                                <Select
                                    name={item.id}
                                    menuPlacement={i === attributes.length - 1 ? "top" : "auto"}
                                    //value = {selectedValue ? selectedValue : null}
                                    value={selectedValue ? options.filter((option) => option.value == selectedValue.value) :
                                        defaultValues.length > 0 ? options.filter((option) => (defaultValues.filter((f) => f.value == option.value).length > 0)) : null}

                                    // defaultValue={attributeSelecteds.length > 0 ? options.filter((option) => (attributeSelecteds.filter((f) => f.value == option.value).length > 0)) :
                                    //     defaultValues.length > 0 ? options.filter((option) => (defaultValues.filter((f) => f.value == option.value).length > 0)) : null}
                                    options={options}
                                    onChange={(option) => {
                                        //alert("single select onchange");
                                        setAttributeValueContent(option, item.id)
                                    }}
                                />
                                {(attributeErrors && attributeErrors.filter((i) => i.id == item.id).length > 0) ? <div><span class="red">{item.name} is required</span></div> : ""}
                            </FormGroup>
                        );

                    }
                    else if (item.displayType === DISPLAYTYPE.MUILTISECTION) {
                        let selectedValues = getAttributeValueContent(item.id, item.displayType);
                        //console.log("selectedValues_5", item.id, selectedValues, options);

                        return (
                            <FormGroup>
                                <Label>{item.name} {item.isRequired ? <span class="red">*</span> : ""}</Label>
                                <Select
                                    isMulti
                                    menuPlacement={i === attributes.length - 1 ? "top" : "auto"}
                                    name={item.id}
                                    // value={selectedValues && selectedValues.length > 0 ? options.filter((option) => (selectedValues.filter((f) => f.value == option.value).length > 0)) :
                                    //     defaultValues.length > 0 ? options.filter((option) => (defaultValues.filter((f) => f.value == option.value).length > 0)) : null}

                                    value={selectedValues ? selectedValues : null}
                                    // value={attributeSelecteds.length > 0 ? options.filter((option) => (attributeSelecteds.filter((f) => f.value == option.value).length > 0)) :
                                    //     defaultValues.length > 0 ? options.filter((option) => (defaultValues.filter((f) => f.value == option.value).length > 0)) : null}

                                    // defaultValue={attributeSelecteds.length > 0 ? options.filter((option) => (attributeSelecteds.filter((f) => f.value == option.value).length > 0)) :
                                    //     defaultValues.length > 0 ? options.filter((option) => (defaultValues.filter((f) => f.value == option.value).length > 0)) : null}

                                    options={options}
                                    onChange={(options) => {
                                        //alert("multi select onchange");
                                        setAttributeValueContent(options, item.id)
                                    }}
                                //rules={{ required: true }}
                                />
                                {(attributeErrors && attributeErrors.filter((i) => i.id == item.id).length > 0) ? <div><span class="red">{item.name} is required</span></div> : ""}
                            </FormGroup>
                        );
                    }
                    else if (item.displayType === DISPLAYTYPE.TEXTAREA) {
                        //textarea
                        const editorContent = getAttributeValueContent(item.id, item.displayType)
                        //console.log("editorContent", editorContent);
                        return (
                            <FormGroup>
                                <Label>{item.name} {item.isRequired ? <span class="red">*</span> : ""}</Label>
                                <CKEditor

                                    key={item.id}
                                    editor={ClassicEditor}
                                    data={editorContent}
                                    // onReady={editor => {
                                    //     // You can store the "editor" and use when it is needed.
                                    //     //console.log('Editor is ready to use!', editorContent);
                                    //     // let editorContent = getAttributeValueContent(item.id, item.displayType);

                                    //     // setTimeout(() => {
                                    //     //     if (editorContent)
                                    //     //         editor.setData(editorContent);
                                    //     // }, 100);
                                    // }}

                                    className="form-control"
                                    onChange={(e, editor) => {
                                        //alert("html select onchange");
                                        const data = editor.getData();
                                        setAttributeValueContent(data, item.id)
                                    }}
                                />
                                {(attributeErrors && attributeErrors.filter((i) => i.id == item.id).length > 0) ? <div><span class="red">{item.name} is required</span></div> : ""}
                            </FormGroup>
                        );
                    }
                    else if (item.displayType === DISPLAYTYPE.DATETIME) {
                        const selectedDate = getAttributeValueContent(item.id, item.displayType);
                        //console.log("selectedDate", item.id, selectedDate)
                        //datetime
                        return (
                            <FormGroup>
                                <Label>
                                    {item.name}
                                    {item.isRequired ? <span class="red">*</span> : ""}
                                </Label>
                                <DatePicker
                                    className="form-control"
                                    selected={selectedDate != undefined ? new Date(selectedDate) : null}
                                    onChange={(date) => {
                                        //alert("date picker select onchange");
                                        setAttributeValueContent(date, item.id)
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                />
                                {(attributeErrors && attributeErrors.filter((i) => i.id == item.id).length > 0) ? <div><span class="red">{item.name} is required</span></div> : ""}
                            </FormGroup>
                        );
                    }
                })
            }
        </div>
    );
}

AttributeValueForm.prototype = {};

export default AttributeValueForm;
