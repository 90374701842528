import Swal from "sweetalert2";
import Common from "../utils/common";
import Config from "../utils/configuration";
import { getData, postData } from "../utils/data";
import Configuration from "../utils/configuration";

const merchantId = localStorage.getItem(Config.merchantId);
const tokenId = localStorage.getItem(Config.userCookie);
const userId = localStorage.getItem(Config.userId);

export const readNotification = (id, urlReturn) => {
  let objData = {
    Id: id,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: userId,
  };
  Common.sendAsyncCommand(
    "UserReadNotification",
    "NotificationTransaction",
    objData,
    () => {
      window.location.href = urlReturn;
    },
    () => {
      Swal.fire("Lỗi khi đọc thông báo!");
    }
  );
};

export const getNotifications = (offset, limit) => {
  var query = `
       {
        notificationtransactions(param:{limit:${limit},offset:${offset},
            order:"desc",sort:"createdDate",
          tokenUser:"${tokenId}",
        
        ,merchantId:"${merchantId}"
       })
        {
              items {
                id
                  message,
        urlReturn,
        allStatus,
                 createdDate,
              }
              ,totalCount,
          message
        }
      }
`;
  return Common.getDataGraphQl(query);
};

export const sendNotificationTransaction = ({
  userIds,
  categoryIds,
  images,
  thumbnailImages,
  message,
  urlReturn,
  title,
  merchantId,
}) => {
  return postData.sendCommand("Notification", "SendNotificationTransaction", {
    Title: title,
    Id: Common.guid(),
    MerchantId: merchantId,
    Users: Common.createRelationObjectArray(userIds),
    Categories: Common.createRelationObjectArray(categoryIds),
    Images: Common.createImagesRelationObjectArray(images, thumbnailImages),
    Message: message,
    UrlReturn: urlReturn,
    ModifiedBy: localStorage.getItem("userId"),
    ModifiedDate: Common.getCurrentDate(),
  });
};

export const createMerchantNotification = ({
  action,
  targetMerchantId,
  userIds,
  categoryIds,
  images,
  thumbnailImages,
  configuration,
  name,
}) => {
  return postData.sendCommand("Notification", "CreateMerchantNotification", {
    Id: Common.guid(),
    MerchantId: localStorage.getItem("merchantId"),
    Action: action,
    TargetMerchantId: targetMerchantId,
    Users: Common.createRelationObjectArray(userIds),
    Categories: Common.createRelationObjectArray(categoryIds),
    Images: Common.createImagesRelationObjectArray(images, thumbnailImages),
    CreatedBy: localStorage.getItem("userId"),
    CreatedDate: Common.getCurrentDate(),
    Configuration: configuration,
    Name: name,
  });
};

export const updateMerchantNotification = ({
  id,
  action,
  targetMerchantId,
  userIds,
  categoryIds,
  images,
  thumbnailImages,
  configuration,
  name,
}) => {
  return postData.sendCommand("Notification", "UpdateInformationMerchantNotification", {
    Id: id,
    MerchantId: localStorage.getItem("merchantId"),
    Action: action,
    TargetMerchantId: targetMerchantId,
    Users: Common.createRelationObjectArray(userIds),
    Categories: Common.createRelationObjectArray(categoryIds),
    Images: Common.createImagesRelationObjectArray(images, thumbnailImages),
    ModifiedBy: localStorage.getItem("userId"),
    ModifiedDate: Common.getCurrentDate(),
    Configuration: configuration,
    Name: name,
  });
};

export const getNotification = (id) => {
  return getData.getDataGraphQl(`      
      {
        notification(param:{id: "${id}"
        ,merchantId:"${localStorage.getItem("merchantId")}", languageId:"${localStorage.getItem(
    Configuration.tokenLanguage
  )}", tokenUser:"${localStorage.getItem("tokenId")}"})
        {
          id,
          action,
          configuration,
          name
          targetEntity
          merchants {
            id,
            code
          }
          users {
            id,
            name
          }
          categories {
            id
            name
          }
          images {
            id
            path
            name
            displayOrder
            isFeatured
          }
        }
    }`);
};

export const getNotificationTransactionById = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 1000000;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  //if (options.type === undefined) options.type = Configuration.ProductTypes.Undefined
  options.keyword = options.keyword === undefined ? "" : options.keyword;

  return getData.getDataGraphQl(`
        {
            notificationtransactionsbyid(param:{limit:${options.limit},offset:${
    options.offset
  },order:"${options.order}",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}",
  ${options.targetId ? `targetId:"${options.targetId}"` : ""},
  tokenUser:"${localStorage.getItem("tokenId")}"
})
            {
              items{
                id,
                message,
                allStatus,
                notificationId
                targetId
                urlReturn
                createdDate
                createdBy
                allStatus
                merchantCode
                userName
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const createNotificationTemplate = ({
  id,
  name,
  subDescription,
  imageId,
  iconId,
  urlReturn,
  title,
  content,
  languageId,
}) => {
  return postData.sendCommand("Template", "CreateNotificationTemplate", {
    Id: id,
    Name: name,
    Description: "",
    SubDescription: subDescription,
    ImageId: imageId,
    IconId: iconId,
    UrlReturn: urlReturn,
    Title: title,
    Content: content,
    LanguageId: languageId ? languageId : localStorage.getItem(Configuration.tokenLanguage),
    MerchantId: localStorage.getItem("merchantId"),
    ActionBy: localStorage.getItem("userId"),
  });
};

export const updateNotificationTemplate = ({
  id,
  name,
  subDescription,
  imageId,
  iconId,
  urlReturn,
  title,
  content,
  languageId,
}) => {
  return postData.sendCommand("Template", "UpdateNotificationTemplate", {
    Id: id,
    Name: name,
    Description: "",
    SubDescription: subDescription,
    ImageId: imageId,
    IconId: iconId,
    UrlReturn: urlReturn,
    Title: title,
    Content: content,
    LanguageId: languageId ? languageId : localStorage.getItem(Configuration.tokenLanguage),
    MerchantId: localStorage.getItem("merchantId"),
    ActionBy: localStorage.getItem("userId"),
  });
};
