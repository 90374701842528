import Axios from 'axios'
import Common from '../utils/common'
import Configuration from '../utils/configuration'
import { postData } from '../utils/data'

const merchantId = localStorage.getItem(Configuration.merchantId)
const userId = localStorage.getItem(Configuration.userId)

export const genCodes = (value) => {
  const dataSend = {
    CommandName: 'GeneratePromotionCode',
    Domain: 'Promotion',
    Content: JSON.stringify(value),
    TimeOutSecond: 7,
  }

  const api_url = Configuration.url_api + '/Command/SendSync'
  return Axios.post(api_url, dataSend)
}

export const sendGiftCodeToUsers = (value) => {
  const dataSend = {
    CommandName: 'SendGiftCodeToUsers',
    Domain: 'Promotion',
    Content: JSON.stringify(value),
    TimeOutSecond: 7,
  }

  const api_url = Configuration.url_api + '/Command/SendSync'
  return Axios.post(api_url, dataSend)
}

export const active = (id) => {
  const body = {
    Id: id,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: userId,
  }

  const dataSend = {
    CommandName: 'ActivePromotion',
    Domain: 'Promotion',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }

  const api_url = Configuration.url_api + '/Command/SendSync'
  return Axios.post(api_url, dataSend)
}

export const deactive = (id) => {
  const body = {
    Id: id,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: userId,
  }

  const dataSend = {
    CommandName: 'DeactivePromotion',
    Domain: 'Promotion',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }

  const api_url = Configuration.url_api + '/Command/SendSync'
  return Axios.post(api_url, dataSend)
}

export const getPromotions = (option = {}) => {
  const limit = option.limit === undefined ? 100000 : option.limit
  const offset = option.offset === undefined ? 0 : option.offset
  const order = option.order === undefined ? 'desc' : option.order
  const sort = option.sort === undefined ? 'createdDate' : option.sort
  const keyword = option.keyword === undefined ? '' : option.keyword

  return Common.getDataGraphQl(`
        {
            promotions(param:{keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
            ,merchantId:"${merchantId}"
            ,${option.type ? `type:${option.type}` : ''}
            , ${option.active ? `active:${option.active}` : ''}            
             , ${
               option.from && option.to
                 ? `from:"${option.from.toLocaleString()}",to:"${option.to.toLocaleString()}"`
                 : ''
             }
            })
            {
              items{
                    id,
               active,
                createdBy,
                createdDate,
                totalUsed,
                totalAmountUsed,
                totalCodes,
                type,
                merchantId,
                name,
                pluginDatas{
                  id,
                  pluginId,
                  pluginName,
                  pluginType,
                  data,
                  priority
                  
                }
              },
              totalCount,
              success,
              message
            }
        }
        `)
}

export const createPromotion = ({
  Name,
  Note,
  PluginConditions,
  PluginResults,
  PromotionType,
  TargetIds,
}) => {
  return postData.sendCommand('Promotion', 'CreatePromotion', {
    Name: Name,
    Note: Note,
    PromotionType: PromotionType,
    ConditionValues: PluginConditions,
    ResultValues: PluginResults,
    Priority: 0,
    TargetIds: TargetIds,
    CreatedDate: Common.getCurrentDate(),
    CreatedBy: localStorage.getItem('userId'),
    MerchantId: localStorage.getItem('merchantId'),
    Id: Common.guid(),
  })
}
