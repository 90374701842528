import Common from '../utils/common'
import Configuration from '../utils/configuration'
import { getData, postData } from '../utils/data'

export const getKanbanCategories = (parentId) => {
  return getData.getDataGraphQl(`
    {
        kanbancategories(param:{merchantId:"${localStorage.getItem(
          'merchantId'
        )}", parentId:"${parentId}", languageId:"${localStorage.getItem(
    Configuration.tokenLanguage
  )}", tokenUser: "${localStorage.getItem('tokenId')}"})
        {
            items {
                id
                version
                displayOrder
                name
                orders {
                  id
                  version
                  code
                  createdBy
                  createdDate
                  grandTotal
                  customerId
                  userName
                  userMobile
                  userEmail
                }
            }
            totalCount
        }
    }
    `)
}

export const ChangeCategoryForOrder = ({
  orderId,
  fromCategoryId,
  toCategoryId,
  version,
}) => {
  const body = {
    Id: Common.guid(),
    OrderId: orderId,
    FromCategoryId: fromCategoryId,
    ToCategoryId: toCategoryId,
    ModifiedBy: localStorage.getItem('userId'),
    ModifiedDate: Common.formatDateTime(new Date()),
    Version: version,
  }
  return postData.sendCommand('Sale', 'ChangeCategoryForOrder', body)
}

export const createTicketArticle = ({ id, name, subDescription, orderId }) => {
  return postData.sendCommand('Article', 'CreateTicketArticle', {
    Id: id,
    MerchantId: localStorage.getItem('merchantId'),
    Name: name,
    SubDescription: subDescription,
    OrderId: orderId,
    LanguageId: localStorage.getItem(Configuration.tokenLanguage),
    CreatedDate: Common.formatDateTimeMoment(new Date()),
    CreatedBy: localStorage.getItem('userId'),
  })
}

export const getPoints = ({ limit = 10, offset = 0, keyword = '' }) => {
  return getData.getDataGraphQl(`
  {
    points(param:{merchantId:"${localStorage.getItem(
      'merchantId'
    )}", languageId:"${localStorage.getItem(
    Configuration.tokenLanguage
  )}", offset:${offset}, limit:${limit}, keyword:"${keyword}"})
    {
        items {
            id
            targetName
            pointTransactions {
              id,
              createdDate
              point
            }
            active
            totalPoint
            targetId
            version
          }
        totalCount
    }
  }
  `)
}

export const getMembers = ({
  limit = 10,
  offset = 0,
  keyword = '',
  order = 'desc',
  sort = 'createdDate',
}) => {
  return getData.getDataGraphQl(`
  {
    userMembers(param: {merchantId: "${localStorage.getItem(
      'merchantId'
    )}", offset:${offset}, limit:${limit}, keyword:"${keyword}", order:"${order}", sort:"${sort}", languageId:"${localStorage.getItem(
    Configuration.tokenLanguage
  )}" }) {
    items {
      id
      totalPoint
      pointTransactions {
        id
        point
        transactionType
        targetId
        createdDate
        targetName
      }
      categories{
        id, 
        name
      } 
      images{
        id, 
        path
      }
      active
      createdBy
      createdDate
      deleted
      merchantId
      modifiedBy
      modifiedDate
      totalPositvePoint
      totalNegativePoint
      userName
      email
      mobile
    }
    totalCount
    }
  }
  `)
}

export const getMemberships = ({
  limit = 10,
  offset = 0,
  keyword = '',
  order = 'desc',
  sort = 'createdDate',
}) => {
  return getData.getDataGraphQl(`
  {
    usermemberships(param: {merchantId: "${localStorage.getItem(
      'merchantId'
    )}", offset:${offset}, limit:${limit}, keyword:"${keyword}", order:"${order}", sort:"${sort}", languageId:"${localStorage.getItem(
    Configuration.tokenLanguage
  )}" }) {
    items {
      id
     
      categories{
        id, 
        name,
        expiredDate
      } 
      images{
        id, 
        path
      }
      active
      createdBy
      createdDate
      deleted
      merchantId
      modifiedBy
      modifiedDate  
      userName
      email
      mobile
    }
    totalCount
    }
  }
  `)
}
