import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { NOTIFICATION } from "../config/Constants/NotificationConstant";
import { FAILURE, REQUEST, SUCCESS } from "./ActionType";

const initialState = {
  notifications: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "notification",
    blacklist: ["notifications", "isLoading"],
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case NOTIFICATION.PUSH_NOTIFICATION:
        const notifications = state.notifications || {};

        return {
          ...state,
          notifications: {
            ...notifications,
            items: [action.payload, ...(notifications.items || [])],
          },
        };
      case REQUEST(NOTIFICATION.GET_NOTIFICATIONS):
        return {
          ...state,
          isLoading: true,
        };
      case FAILURE(NOTIFICATION.GET_NOTIFICATIONS):
        return {
          ...state,
          isLoading: false,
        };
      case SUCCESS(NOTIFICATION.GET_NOTIFICATIONS):
        return {
          ...state,
          notifications: action.payload.notificationtransactions,
          isLoading: false,
        };

      case REQUEST(NOTIFICATION.READ_NOTIFICATION):
        return {
          ...state,
          isLoading: true,
        };
      case FAILURE(NOTIFICATION.READ_NOTIFICATION):
        return {
          ...state,
          isLoading: false,
        };
      case SUCCESS(NOTIFICATION.READ_NOTIFICATION):
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  }
);
