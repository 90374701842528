import { useFormik } from 'formik'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { ZOOM_IN } from 'react-ladda/dist/constants'
import LaddaButton from 'react-ladda/dist/LaddaButton'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap'
import Swal from 'sweetalert2'
import CkEditor from '../../Layout/Editor/CkEditor'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import { getData, postData } from '../../utils/data'
import Footer from '../CommonComponent/Footer'
import Header from '../CommonComponent/Header'
import Select from '../CommonComponent/Select'

function ExportCode({ ...props }) {
  const idEvoucher = props.match.params.id
  const [emailContent, setEmailContent] = useState('')
  const [users, setUsers] = useState([])
  const [usersCategories, setUsersCategories] = useState([])
  const [evoucher, setEvoucher] = useState(null)
  const [emailTemplate, setEmailTemplate] = useState(null)
  const [emailTitle, setEmailTitle] = useState(null)
  const [loading, setLoading] = useState(false)

  const validate = (values) => {
    const errors = {}
    if (!values.sender) {
      errors.sender = 'Required'
    }
    if (!values.sendEmail) {
      errors.sendEmail = 'Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.sendEmail)
    ) {
      errors.sendEmail = 'Invalid email address'
    }

    // if (!emailTemplate)
    //     errors.emailTemplate = "Vui lòng chọn email template";

    return errors
  }

  useEffect(() => {
    if (!idEvoucher) {
      Swal.fire({
        title: 'Lỗi',
        text: 'Không có id evoucher',
        icon: 'error',
      }).then(() => {
        props.history.push('/evoucher/list')
      })
    }
  }, [])

  const exportCodeForm = useFormik({
    initialValues: {
      sender: '',
      sendEmail: '',
      numberCode: 0,
      emailTitle: '',
    },
    validate,
    onSubmit: function (values) {

      const { sender, sendEmail, numberCode } = values
      var templateId =null;
      if(emailTemplate != null){
        templateId = emailTemplate.id;
      }
      const body = {
        Id: evoucher.id,
        UsersCategories:  usersCategories.map((user) => ({
          Id: user.id,
          Name: user.name,
        })),
        Users: users.map((user) => ({
          Id: user.id,
          Name: user.name,
          Mobile: user.mobile,
          Email: user.email,
        })),
        QuantityEachUser: numberCode,
        ModifiedDate: new Date(),
        ModifiedBy: localStorage.getItem('userId'),
        FromName: sender,
        EmailTemplateId: templateId,
        Subject: emailTitle,
        Template: emailContent,
        From: sendEmail,
      }    
      setLoading(true)
      // console.log(body);
      // return;
      postData
        .sendCommand('Sale', 'ExportEVoucherCodeToUsersByEmailTemplate', body)
        .then((result) => {
          const { Message, Success } = result.data
          if (Success) {
            Swal.fire({
              title: 'Thành công',
              text: Message,
              icon: 'success',
            })
          } else {
            Swal.fire({
              title: 'Thất bại',
              text: Message,
              icon: 'error',
            })
          }
        })
        .catch((e) => {
          Swal.fire({
            title: 'Thất bại',
            text: e,
            icon: 'error',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <div>
      <Header
        title="Xuất mã e-voucher"
        description="Export Code"
        iconClassName="pe-7s-gift"
      />
      <Form onSubmit={exportCodeForm.handleSubmit}>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>Thông tin</CardHeader>
              <CardBody>
                <CardTitle>Thông tin người gửi</CardTitle>
                <FormGroup>
                  <Label>
                    Người gửi <span className="red">*</span>
                  </Label>
                  <Input
                    invalid={exportCodeForm.errors.sender}
                    type="text"
                    name="sender"
                    value={exportCodeForm.values.sender}
                    onChange={exportCodeForm.handleChange}
                  />
                  {exportCodeForm.errors.sender && (
                    <FormFeedback>{exportCodeForm.errors.sender}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    Email gửi <span className="red">*</span>
                  </Label>
                  <Input
                    invalid={exportCodeForm.errors.sendEmail}
                    type="text"
                    name="sendEmail"
                    value={exportCodeForm.values.sendEmail}
                    onChange={exportCodeForm.handleChange}
                  />
                  {exportCodeForm.errors.sendEmail && (
                    <FormFeedback>
                      {exportCodeForm.errors.sendEmail}
                    </FormFeedback>
                  )}
                </FormGroup>
                <hr />
                <CardTitle>Thông tin người nhận</CardTitle>
                <FormGroup>
                  <Label>Thành viên</Label>
                  <Select
                    apiCommand={getData.getUsers}
                    isMulti
                    onChange={(selectUsers) => setUsers(selectUsers)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Phân loại</Label>
                  <Select
                    apiCommand={(options) =>
                      getData.getCategories({
                        ...options,
                        type: Configuration.categoryTypes.CUSTOMER,
                      })
                    }
                    isMulti
                    onChange={(values) => setUsersCategories(values)}
                  />
                </FormGroup>
                <hr />
                <CardTitle>Thông tin E-voucher</CardTitle>
                <FormGroup>
                  <Label>E-voucher</Label>

                  <Select
                    apiCommand={getData.getEvoucher}
                    defaultValueIds={[idEvoucher]}
                    onChange={(value) => {
                      return setEvoucher(value)
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Số lượng mã xuất/ 1 khách <span className="red">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="numberCode"
                    value={exportCodeForm.values.numberCode}
                    onChange={exportCodeForm.handleChange}
                  />
                  <FormText>
                    Số lượng mã xuất không được lớn hơn số mã còn lại (
                    {Common.getParameterByName('totalImported')})
                  </FormText>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>Giao diện Email Template</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>E-mail template</Label>
                  <Select
                    isClearable
                    apiCommand={getData.getEmailTemplates}
                    name="templateName"
                    onChange={(value) => {
                      exportCodeForm.setFieldError('emailTemplate', '')
                      setEmailContent(_.get(value, 'template'))
                      setEmailTitle(_.get(value, 'subject'))
                      return setEmailTemplate(value)
                    }}
                  />
                  {exportCodeForm.errors.emailTemplate ? (
                    <FormFeedback className="d-block">
                      Vui lòng chọn Email-template
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                {/* {emailTemplate ? ( */}
                <Fragment>
                  <FormGroup>
                    <Label>Tiêu đề</Label>
                    <Input
                      type="text"
                      name="emailTitle"
                      value={emailTitle}
                      onChange={(e) => setEmailTitle(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Nội dung email</Label>
                    <CkEditor
                      onchange={(content) => setEmailContent(content)}
                      content={emailContent}
                    />
                  </FormGroup>
                </Fragment>
                {/* ) : (
                                    <p>
                                        <i>Vui lòng chọn Email template</i>
                                    </p>
                                )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer
          left={
            <Fragment>
              <LaddaButton
                className="btn btn-shadow btn-success"
                color="success"
                loading={loading}
                data-style={ZOOM_IN}
                // onClick={exportCodeForm.handleSubmit}
              >
                Gửi
              </LaddaButton>
            </Fragment>
          }
        />
      </Form>
    </div>
  )
}

export default ExportCode
