import Common from '../utils/common'
import Configuration from '../utils/configuration'
import { postData } from '../utils/data'

const languageId = localStorage.getItem(Configuration.tokenLanguage)
const merchantId = localStorage.getItem(Configuration.merchantId)

export const getWarehouses = (option = {}) => {
  const limit = option.limit === undefined ? 50 : option.limit
  const offset = option.offset === undefined ? 0 : option.offset
  const order = option.order === undefined ? 'desc' : option.order
  const sort = option.sort === undefined ? 'createdDate' : option.sort
  const keyword = option.keyword === undefined ? '' : option.keyword

  var query = `
    {
      storages(param: {
        limit:${limit},
        offset:${offset},
        order:"${order}",
        sort:"${sort}",
        keyword:"${keyword}",
        languageId:"${languageId}",
        merchantId:"${merchantId}",
        ${option.allStatus ? `allStatus:${option.allStatus},` : ''}
        ${
          option.from && option.to
            ? `from:"${option.from.toLocaleString()}", to:"${option.to.toLocaleString()}"`
            : ''
        }
      }) {
        totalCount
        message
        success
        items {
          capacity
          createdBy
          createdDate
          deleted
          description
          height
          id
          length
          merchantId
          modifiedBy
          modifiedDate
          name
          parentId
          positionX
          positionY
          positionZ
          storedObjects {
            id
          }
          subDescription
          tags
          unit
          width
        }
      }
    }`
  return Common.getDataGraphQl(query)
}

export const getWarehouse = (id) => {
  const query = `
  {
    storage(param: {id: "${id}", languageId: "${languageId}", merchantId: "${merchantId}"}) {
      addresses {
        id
        name
      }
      categories {
        id
        name
      }
      images {
        id
        path
        name
        displayOrder
        isFeatured
      }
      sku
      capacity
      createdBy
      createdDate
      deleted
      description
      height
      id
      length
      merchantId
      modifiedBy
      modifiedDate
      name
      parentId
      positionX
      positionY
      positionZ
      storedObjects {
        id
      }
      subDescription
      tags
      unit
      width
    }
  }  
  `
  return Common.getDataGraphQl(query)
}
