import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Button } from "reactstrap";
import { AppContext } from "../../Layout/AppMain";
import WithLoading from "../../Layout/Loading/WithLoading";
import Common from "../../utils/common";
import Configuration from "../../utils/configuration";
import { getData, postData } from "../../utils/data";
import useFetchDataTable from "../CommonComponent/CustomHooks/useFetchDataTable";
import useOnClickOutside from "../CommonComponent/CustomHooks/useOnClickOutside";
import Header from "../CommonComponent/Header";
import ReactTableList from "../CommonComponent/TableList/ReactTableList";

const getPermissions = (options = {}) => {
  const limit = options.limit === undefined ? 1000000 : options.limit;
  const offset = options.offset === undefined ? 0 : options.offset;
  const order = options.order === undefined ? "desc" : options.order;
  const sort = options.sort === undefined ? "createdDate" : options.sort;
  const keyword = options.keyword === undefined ? "" : options.keyword;

  return getData.getDataGraphQl(
    `
{
  permissionmemberuserread(param:{ limit:${limit}, keyword:"${keyword}",offset:${offset},order:"${order}",sort:"${sort}", languageId:"${localStorage.getItem(
      Configuration.tokenLanguage
    )}", merchantId:"${localStorage.getItem("merchantId")}"})
{
    items {
      id,
      name,
      articleCategories {
        id,
        name
      },
      bookCategories {
        id,
        name
      },
      courseCategories {
        id,
        name
      },
    }
  }   
}
`
  );
};

function ReadPrivilege(props) {
  console.log(props);
  const [articleCategories, setArticleCategories] = useState([]);
  const [bookCategories, setBookCategories] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);

  const columns = [
    {
      Header: "Hạng thành viên",
      accessor: "name",
      resizable: false,
      width: 400,
    },
    {
      id: "group-articles",
      Header: "Phân loại bài viết",
      accessor: (d) => d,
      resizable: false,
      Cell: (props) => {
        return (
          <GroupArticlesInputWithLoading
            id={props.value.id}
            articleCategories={props.value.articleCategories}
            allArticleCategories={articleCategories}
            type={0}
          />
        );
      },
    },
    ,
    {
      id: "group-books",
      Header: "Phân loại sách",
      accessor: (d) => d,
      resizable: false,
      Cell: (props) => {
        return (
          <GroupArticlesInputWithLoading
            id={props.value.id}
            articleCategories={props.value.bookCategories}
            allArticleCategories={bookCategories}
            type={1}
          />
        );
      },
    },
    {
      id: "group-course",
      Header: "Phân loại khóa học",
      accessor: (d) => d,
      resizable: false,
      Cell: (props) => {
        return (
          <GroupArticlesInputWithLoading
            id={props.value.id}
            articleCategories={props.value.courseCategories}
            allArticleCategories={courseCategories}
            type={512}
          />
        );
      },
    },
  ];

  const [data, loading, pageCount, fetchData, totalCount] = useFetchDataTable(getPermissions);

  useEffect(() => {
    getData
      .getCategories({
        type: Configuration.categoryTypes.ARTICLE,
      })
      .then((res) => setArticleCategories(res.categories.items));
    getData
      .getCategories({
        type: Configuration.categoryTypes.BOOK,
      })
      .then((res) => setBookCategories(res.categories.items));
    getData
      .getCategories({
        type: Configuration.categoryTypes.COURSE,
      })
      .then((res) => setCourseCategories(res.categories.items));
  }, []);

  return (
    <Fragment>
      <Header
        title="Phân quyền dữ liệu hạng thành viên"
        description="Phân quyền hạng thành viên được xem dữ liệu trên trang member elearn"
        iconClassName="pe-7s-notebook"
      />

      <ReactTableList
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalCount={totalCount}
        // deleteCommand={(ids) =>
        //     postData.deleteItemCommand(
        //         "Article",
        //         "DeleteArticlesVersion01",
        //         ids
        //     )
        // }
        // changeStatusCommand={(changeStatuses) =>
        //     postData.changeStatusCommand(
        //         "Article",
        //         "ChangeStatusPublishArticle",
        //         changeStatuses
        //     )
        // }
      />
      {/* <ReactTable
                data={this.state.userCategories}
                columns={}
                defaultPageSize={10}
            /> */}
    </Fragment>
  );
}

function GroupArticlesInput({ id, articleCategories, allArticleCategories, type = 0 }) {
  const ref = useRef();
  const [isInputAppear, SetInputAppear] = useState(false);
  const [choosedArticleCategories, choose] = useState(false);
  const [articleCategoriesDisplay, setArticleCategoriesDisplay] = useState([]);

  useOnClickOutside(ref, () => {
    if (isInputAppear) {
      console.log(articleCategoriesDisplay);
      choose(
        articleCategoriesDisplay.map((article) => ({
          label: article.name,
          value: article.id,
        }))
      );
      SetInputAppear(false);
    }
  });

  const context = useContext(AppContext);

  useEffect(() => {
    if (articleCategories != undefined) {
      choose(
        articleCategories.map((val) => ({
          label: val.name,
          value: val.id,
        }))
      );
      setArticleCategoriesDisplay(articleCategories);
    }
  }, [articleCategories]);

  const onCategoriesChangeHandler = (choosedItems) => {
    choose(choosedItems);
  };

  const updateArticleCategoryRelation = () => {
    const body = {
      Id: id,
      Ids: choosedArticleCategories.map((item) => item.value),
      ModifiedDate: new Date(),
      ModifiedBy: localStorage.getItem("userId"),
    };
    var command = "";
    if (type == 0) {
      command = "UpdateArticleCategoryRelation";
    } else if (type == 512) {
      command = "UpdateCourseCategoryRelation";
    } else {
      command = "UpdateBookCategoryRelation";
    }
    postData
      .sendCommand("Category", command, body)
      .then((res) => {
        context.alert["success"]("Thành Công");
        setArticleCategoriesDisplay(
          choosedArticleCategories.map((val) => ({
            name: val.label,
          }))
        );
        SetInputAppear(false);
      })
      .catch((err) => context.alert["error"]("Thất bại", err.toString()));
  };

  return (
    <div className="d-flex" ref={ref}>
      <div onClick={() => SetInputAppear(true)}>
        <span
          style={{
            display: isInputAppear ? "none" : "block",
          }}
        >
          {articleCategoriesDisplay.length > 0 ? (
            articleCategoriesDisplay.reduce((acc, cur, index) => {
              if (index === 0) return cur.name;
              return acc + ", " + cur.name;
            }, "")
          ) : (
            <span
              style={{
                color: "gray",
                fontSize: "10px",
                fontStyle: "italic",
              }}
            >
              Chưa có nhóm bài nào, ấn vào đây để chọn nhóm bài viết...
            </span>
          )}
        </span>
        <div
          style={{
            display: isInputAppear ? "" : "none",
            minWidth: "400px",
          }}
        >
          <Select
            isMulti
            name="category"
            options={
              allArticleCategories &&
              allArticleCategories.map((val) => ({
                label: val.name,
                value: val.id,
              }))
            }
            value={choosedArticleCategories}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={onCategoriesChangeHandler}
          />
        </div>
      </div>
      <Button
        color="success"
        className="ml-2"
        style={{
          display: isInputAppear ? "" : "none",
        }}
        onClick={() => updateArticleCategoryRelation()}
      >
        Lưu
      </Button>
      {/* <Button
        color="danger"
        className="ml-2"
        style={{
          display: isInputAppear ? '' : 'none',
        }}
        onClick={() => {
          SetInputAppear(false)
        }}
      >
        X
      </Button> */}
    </div>
  );
}

const GroupArticlesInputWithLoading = WithLoading(GroupArticlesInput);

export default ReadPrivilege;
