export const UPDATE_MERCHANT_ID = 'UPDATE_MERCHANT_ID'
export const UPDATE_MERCHANT_TYPE = 'UPDATE_MERCHANT_TYPE'
export const UPDATE_MERCHANT_CODE = 'UPDATE_MERCHANT_CODE'
export const UPDATE_USER_ID = 'UPDATE_USER_ID'
export const UPDATE_EXPIRED_TIME = 'UPDATE_EXPIRED_TIME'

export const UPDATE_MERCHANT = 'UPDATE_MERCHANT'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'

const initState = {
  merchantId: null,
  merchantType: null,
  merchantCode: null,
  userId: null,
  expiredTime: null,
  merchant: {},
  user: {},
}

export const updateMerchant = (payload) => ({
  type: UPDATE_MERCHANT,
  payload,
})

export const updateMerchantId = (payload) => ({
  type: UPDATE_MERCHANT_ID,
  payload,
})

export const updateMerchantType = (payload) => ({
  type: UPDATE_MERCHANT_TYPE,
  payload,
})

export const updateMerchantCode = (payload) => ({
  type: UPDATE_MERCHANT_CODE,
  payload,
})

export const updateUserId = (payload) => ({
  type: UPDATE_USER_ID,
  payload,
})

export const updateExpiredTime = (payload) => ({
  type: UPDATE_EXPIRED_TIME,
  payload,
})

export const updateUserInfo = (payload) => ({
  type: UPDATE_USER_INFO,
  payload,
})

export default function reducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_MERCHANT:
      return {
        ...state,
        merchant: action.payload,
      }
    case UPDATE_MERCHANT_ID:
      return {
        ...state,
        merchantId: action.payload,
      }
    case UPDATE_MERCHANT_TYPE:
      return {
        ...state,
        merchantType: action.payload,
      }
    case UPDATE_MERCHANT_CODE:
      return {
        ...state,
        merchantCode: action.payload,
      }
    case UPDATE_USER_ID:
      return {
        ...state,
        userId: action.payload,
      }
    case UPDATE_EXPIRED_TIME:
      return {
        ...state,
        expiredTime: action.payload,
      }
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: action.payload,
      }

    default:
      return state
  }
}
