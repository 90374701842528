import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'
import Configuration from '../../utils/configuration'
import { getData } from '../../utils/data'
import Header from '../CommonComponent/Header'
import FooterActions from '../CommonComponent/TableList/FooterActions'
import TableList from '../CommonComponent/TableList/TableList'

function ThemeTemplateList() {
  const tableRef = useRef()
  const [checkItems, setCheckItems] = useState([])
  const [themes, setThemes] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      Header: 'STT',
      accessor: 'index',
      Cell: ({ value }) => <div className="mx-auto">{value}</div>,
      width: 50,
    },
    {
      Header: 'Tên',
      accessor: 'name',
    },
    {
      accessor: 'published',
      Header: 'Trạng thái',
      filterable: false,
      Cell: (props) =>
        props.value ? (
          <div className="mx-auto d-block badge badge-success">Hoạt động</div>
        ) : (
          <div className="mx-auto d-block badge badge-danger">
            Không hoạt động
          </div>
        ),
      width: 150,
    },
    {
      Header: 'Thao tác',
      id: 'thao-tac',
      accessor: (d) => d,
      Cell: ({ value }) => (
        <div className="mx-auto">
          <Link to={`edit/${value.id}`}>
            <Button color="primary">Sửa</Button>
          </Link>
        </div>
      ),
      filterable: false,
    },
  ]

  useEffect(() => {
    setLoading(true)
    getData
      .getThemeWebs()
      .then((res) => {
        setThemes(res.themewebs.items)
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {}
  }, [])
  return (
    <div>
      <Header title="Danh sách theme template" iconClassName="pe-7s-photo" />
      <Card>
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup row>
                <Label sm="2">Theme</Label>
                <Col sm="10">
                  <Select
                    isLoading={loading}
                    isClearable
                    options={themes.map((theme) => ({
                      label: theme.name,
                      value: theme.id,
                    }))}
                    onChange={(theme) => {
                      if (theme) {
                        tableRef.current.filterByTargetId([theme.value])
                      } else {
                        tableRef.current.filterByTargetId(null)
                      }
                    }}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <TableList
            ref={tableRef}
            columns={columns}
            checkItems={checkItems}
            setCheckItems={setCheckItems}
            itemSelectable
            apiCommand={(options) =>
              getData.getArticles({
                type: Configuration.articleType.THEME_WEB_TEMPLATE.type,
                ...options,
              })
            }
          />
        </CardBody>
      </Card>

      <FooterActions
        checkItems={checkItems}
        setCheckItems={setCheckItems}
        load={_.get(tableRef, 'current.load')}
        commands={{
          delete: {
            domain: 'Article',
            commandName: 'DeleteArticlesVersion01',
          },
          changeStatus: {
            domain: 'Article',
            commandName: 'ChangeStatusPublishArticle',
          },
        }}
      />
    </div>
  )
}

ThemeTemplateList.propTypes = {}

export default ThemeTemplateList
