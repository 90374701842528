export const SET_DATA = 'SET_DATA'
export const SET_STATE_ADDING_LANE = 'SET_STATE_ADDING_LANE'

const initState = {
  dataKanban: {
    lanes: [],
  },
  isAddingLane: false,
}

export const setData = (data) => ({
  type: SET_DATA,
  data,
})

export const setStateAddingLane = (data) => ({
  type: SET_STATE_ADDING_LANE,
  data,
})

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        dataKanban: action.data,
      }
    case SET_STATE_ADDING_LANE:
      return {
        ...state,
        isAddingLane: action.data,
      }
    default:
      return state
  }
}
