import React from 'react'
import { Async } from 'react-select'

function SelectServerSide({
  loadOptions,
  value,
  onChange,
  name,
  defaultValue,
  ...props
}) {
  const _loadOptions = (inputValue) => {
    return loadOptions({
      keyword: inputValue,
    }).then((res) => {
      const name = Object.keys(res)[0]
      return res[name].items.map((item) => ({
        value: item.id,
        label: item.sku? item.name + " - " + item.sku : item.name,
      }))
    })
  }

  return (
    <Async
      {...props}
      name={name}
      // options={allUsers}
      defaultOptions
      loadOptions={_loadOptions}
      value={value}
      className="basic-multi-select"
      defaultValue={defaultValue}
      classNamePrefix="select"
      onChange={onChange}
    />
  )
}

export default SelectServerSide
