import React, { Fragment } from 'react'
import { Link, Route } from 'react-router-dom'
import Configuration from '../../../utils/configuration'
import Header from '../../CommonComponent/Header'
import RoomBookingSettingForm from '../../RoomBooking/Setting/RoomBookingSettingForm'
import RoomList from '../../RoomBooking/Setting/RoomList'
import OfficeConfigGeneral from './OfficeConfigGeneral'

function Setting(props) {
  return (
    <div>
      <Header
        title="Thiết lập Office"
        description="Setting booking room"
        iconClassName="pe-7s-settings"
        actionsComponent={
          <Fragment></Fragment>
          // <Link to="/room-booking/setting/create">
          //     <Button color="focus">Tạo phòng họp mới</Button>
          // </Link>
        }
      />
      <div className="app-inner-layout">
        <div className="app-inner-layout__wrapper">
          <div className="app-inner-layout__content card">
            <div className="p-3">
              <Route
                path={`${props.match.url}/list`}
                render={(props) => (
                  <RoomList
                    {...props}
                    type={Configuration.ProductTypes.OfficeBooking}
                  />
                )}
              />
              <Route
                path={`${props.match.url}/create`}
                render={(props) => (
                  <RoomBookingSettingForm
                    {...props}
                    type={Configuration.ProductTypes.OfficeBooking}
                  />
                )}
              />
              <Route
                path={`${props.match.url}/edit/:id`}
                render={(props) => (
                  <RoomBookingSettingForm
                    {...props}
                    type={Configuration.ProductTypes.OfficeBooking}
                  />
                )}
              />
              <Route
                path={`${props.match.url}/config`}
                component={OfficeConfigGeneral}
              />
            </div>
          </div>
          <div className="app-inner-layout__sidebar card">
            <ul className="nav flex-column">
              <li className="nav-item-header nav-item">Cài đặt</li>
              <li className="nav-item">
                <Link to="/office-booking/setting/config" className="nav-link">
                  <i className="nav-link-icon pe-7s-config"> </i>
                  <span>Cài đặt chung</span>
                </Link>
                <Link
                  to="/office-booking/setting/list"
                  className="nav-link active-link"
                >
                  <i className="nav-link-icon pe-7s-network"> </i>
                  <span>Danh sách phòng</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setting
