import axios from 'axios';
import React, { Fragment } from 'react';
import CustomLoader from '../../Layout/Loading/CustomLoader';
import Common from '../../utils/common';
import Configuration from '../../utils/configuration';
import { getData } from "../../utils/data";

export default class FormWraper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: "",
                subject: "",
                template: ""
            },
            optionsCategories: [],
            modalInfo: {
                show: false,
                message: "",
                type: "error"
            },
            isSending: false
        };
    }

    componentDidMount() {
        // Get Categpries and set to selection box
        getData.getCategories()
            .then(data => {
                //TO DO: Them parent ID cho moi category
                const categories = data.categories.items;
                const optionsCategories = categories.map(category => {
                    return {
                        value: category.id,
                        label: category.name
                    };
                });
                this.setState({
                    optionsCategories: optionsCategories
                });
            })
            .catch(err => console.log(err, "err"));

        if (this.props.id != undefined)
            this.props.getDataFillForm(this.props.id).then(response => {
                console.log(response);
                const data = {
                    name: response.emailtemplate.templateName,
                    subject: response.emailtemplate.subject,
                    template: response.emailtemplate.template
                };
                this.setState({
                    data: data
                });
            });
        else {
        }
    }

    submitFormHandler = (event, value) => {
        // event.preventDefault();

        this.setState({
            isSending: true
        });

        event.preventDefault();
        // const formData = new FormData(event.target)

        const body = {
            Id: this.props.id !== undefined ? this.props.id : Common.guid(),
            MerchantId: localStorage.getItem("merchantId"),
            Name: value.name,
            Subject: value.subject, // formData.get('subDescription'),
            Template: this.state.data.template,
            LanguageId: Configuration.languageVi,
            CreatedDate: Common.formatDateTime(new Date()),
            CreatedBy: localStorage.getItem("userId")
        };

        const dataSend = {
            CommandName:
                this.props.id !== undefined
                    ? "UpdateInformationEmailTemplate"
                    : "CreateEmailTemplate",
            Domain: "EmailTemplate",
            Content: JSON.stringify(body),
            TimeOutSecond: 7
        };

        const api_url = Configuration.url_api + "/Command/SendSync";
        axios
            .post(api_url, dataSend)
            .then(response => {
                if (response.data.Success)
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thành công",
                            responesMessage: response.data.Message,
                            type: "success"
                        }
                    });
                else
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thất bại",
                            responesMessage: response.data.Message,
                            type: "error"
                        }
                    });
            })
            .catch(err => {
                this.setState({
                    modalInfo: {
                        show: true,
                        message: "Thất bại",
                        responesMessage: err + "",
                        type: "error"
                    }
                });
            })
            .finally(() => {
                this.setState({
                    isSending: false
                });
            });
    };

    onSubmit = (body, commandInfo) => {
        this.setState({
            isSending: true
        });

        const dataSend = {
            CommandName:
                this.props.id !== undefined
                    ? commandInfo.CommandName[1]
                    : commandInfo.CommandName[0],
            Domain: commandInfo.Domain,
            Content: JSON.stringify(body),
            TimeOutSecond: 7
        };

        const api_url = Configuration.url_api + "/Command/SendSync";
        axios
            .post(api_url, dataSend)
            .then(response => {
                if (response.data.Success)
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thành công",
                            responesMessage: response.data.Message,
                            type: "success"
                        }
                    });
                else
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thất bại",
                            responesMessage: response.data.Message,
                            type: "error"
                        }
                    });
            })
            .catch(err => {
                this.setState({
                    modalInfo: {
                        show: true,
                        message: "Thất bại",
                        responesMessage: err + "",
                        type: "error"
                    }
                });
            })
            .finally(() => {
                this.setState({
                    isSending: false
                });
            });
    };

    render() {
        return (
            <CustomLoader
                isSending={this.state.isSending}
                modalInfo={this.state.modalInfo}
                redirectPath="#/email/list"
            >
                {!this.props.id ? (
                    <Fragment>
                        {this.props.render(this.onSubmit)}
                    </Fragment>
                ) : (
                        <Fragment>
                            {this.props.render(
                                this.onSubmit,
                                this.state.data
                            )}
                        </Fragment>
                    )}
            </CustomLoader>
        );
    }
}