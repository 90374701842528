import { toast } from 'react-toastify'
import { getProduct } from '../Services/ProductService'
import Common from '../utils/common'
import { FAILURE, REQUEST, SUCCESS } from './ActionType'

export const SET_PRODUCT = 'SET_PRODUCT'
export const EDIT_CONTENT_VARIATION = 'EDIT_CONTENT_VARIATION'
export const DELETE_VARIATION = 'DELETE_VARIATION'
export const ADD_VARIATION = 'ADD_VARIATION'
export const EDIT_VALUES_ATTRIBUTE = 'EDIT_VALUES_ATTRIBUTE'
export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE'
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE'
export const AUTOMATE_CREATE_VARIATIONS_FROM_ATTRIBUTES =
  'AUTOMATE_CREATE_VARIATIONS_FROM_ATTRIBUTES'
export const ADD_VALUE_ATTRIBUTE = 'ADD_VALUE_ATTRIBUTE'
export const VALIDATE_ATTRIBUTE = 'VALIDATE_ATTRIBUTE'
export const VALIDATE_VARIATION = 'VALIDATE_VARIATION'

export const PRODUCT = {
  GET_BY_ID: 'product/GET_BY_ID',
  GET_LIST: 'product/GET_LIST',
}

const initialState = {
  producrts: [],
  product: {},
  attributes: [],
  variations: [],
}

function isDuplicate(variation1, variation2) {
  const getAttributeDictionary = (attributes) => {
    const result = {}
    attributes.forEach((attribute) => {
      if (result[attribute.id]) throw Error('Dupplicate attribute')
      result[attribute.id] = attribute.value.id
    })
    return result
  }
  const attribute1 = getAttributeDictionary(variation1.attributes)
  const attribute2 = getAttributeDictionary(variation2.attributes)
  for (let key in attribute1) {
    if (attribute2[key] !== attribute1[key]) return false
  }
  return true
}

export const getProductAction = (id, languaId) => ({
  type: PRODUCT.GET_BY_ID,
  payload: getProduct(id, languaId),
})

export const setProduct = (payload) => ({
  type: SET_PRODUCT,
  payload,
})

export const editContentVariation = (id, name, payload) => ({
  type: EDIT_CONTENT_VARIATION,
  id,
  name,
  payload,
})

export const deleteVatiation = (id) => ({
  type: DELETE_VARIATION,
  id,
})

export const addVatiation = (payload) => ({
  type: ADD_VARIATION,
  payload,
})

export const editValuesAtrtibute = (id, payload) => ({
  type: EDIT_VALUES_ATTRIBUTE,
  id,
  payload,
})

export const addAtrtibute = (payload) => ({
  type: ADD_ATTRIBUTE,
  payload,
})

export const deleteAtrtibute = (id) => ({
  type: DELETE_ATTRIBUTE,
  id,
})

export const createVariationsFromAttributes = () => ({
  type: AUTOMATE_CREATE_VARIATIONS_FROM_ATTRIBUTES,
})

export const addValueAttribute = (id, payload) => ({
  type: ADD_VALUE_ATTRIBUTE,
  id,
  payload,
})

export const validateAttribute = () => ({
  type: VALIDATE_ATTRIBUTE,
})

export const validateVariation = () => ({
  type: VALIDATE_VARIATION,
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT:
      return action.payload

    case REQUEST(PRODUCT.GET_BY_ID):
      return {
        ...state,
        isLoading: true,
      }

    case FAILURE(PRODUCT.GET_BY_ID):
      return {
        ...state,
        isLoading: false,
      }
    case SUCCESS(PRODUCT.GET_BY_ID):
      return {
        ...state,
        isLoading: false,
        product: action.payload.product,
      }

    case EDIT_CONTENT_VARIATION:
      return {
        ...state,
        variations: state.variations.map((variation) => {
          if (variation.id === action.id) {
            const tempVariation = {
              ...variation,
            }
            tempVariation[action.name] = action.payload
            return tempVariation
          }

          return variation
        }),
      }
    case DELETE_VARIATION:
      return {
        ...state,
        variations: state.variations.filter((variation) => {
          return variation.id !== action.id
        }),
      }
    case ADD_VARIATION:
      return {
        ...state,
        variations: [
          {
            id: Common.guid(),
            code: '',
            image: null,
            name: '_blank',
            price: 0,
            attributes: state.attributes.map((attribute) => ({
              ...attribute,
              value: attribute.values[0],
            })),

            ...action.payload,
          },
          ...state.variations,
        ],
      }
    case EDIT_VALUES_ATTRIBUTE:
      const result = {
        variations: state.variations.map((variation) => {
          return {
            ...variation,
            attributes: variation.attributes.map((attribute) => {
              if (attribute.id === action.id) {
                if (attribute.value) {
                  if (
                    !action.payload
                      .map((val) => val.id)
                      .includes(attribute.value.id)
                  )
                    return {
                      ...attribute,
                      value: null,
                      // value: {
                      //     id: null,
                      //     name: "_blank",
                      // },
                    }
                }
              }
              return attribute
            }),
          }
        }),
        attributes: state.attributes.map((attribute) => {
          if (attribute.id === action.id) {
            return {
              ...attribute,
              values: action.payload,
            }
          }
          return attribute
        }),
      }
      return result
    case ADD_ATTRIBUTE:
      for (let attr of state.attributes) {
        if (attr.id === action.payload.id) {
          toast['error']('Thuộc tính đã được thêm')
          return state
        }
      }
      const newAttribute = {
        id: action.payload.id ? action.payload.id : Common.guid(),
        name: action.payload.name ? action.payload.name : '_blank',
        values: [],
        allValues: action.payload.allValues ? action.payload.allValues : [],
      }
      return {
        variations: state.variations.map((variation) => ({
          ...variation,
          attributes: [
            ...variation.attributes,
            {
              id: newAttribute.id,
              name: newAttribute.name,
              value: null,
            },
          ],
        })),
        attributes: [newAttribute, ...state.attributes],
      }
    case DELETE_ATTRIBUTE:
      return {
        variations: state.variations.map((variation) => ({
          ...variation,
          attributes: variation.attributes.filter(
            (attribute) => attribute.id !== action.id
          ),
        })),
        attributes: state.attributes.filter((attribute) => {
          return attribute.id !== action.id
        }),
      }
    case AUTOMATE_CREATE_VARIATIONS_FROM_ATTRIBUTES:
      // let allValues = [];
      // state.attributes.forEach((attribute) => {
      //     allValues.push(attribute);
      // });

      let tempVariation = new Array(state.attributes.length)
      let allVariations = []
      function visit(n) {
        for (let i = 0; i < state.attributes[n].values.length; i++) {
          tempVariation[n] = {
            id: state.attributes[n].id,
            name: state.attributes[n].name,
            value: state.attributes[n].values[i],
          }
          if (n === state.attributes.length - 1) {
            allVariations.push([...tempVariation])
          } else {
            visit(n + 1)
          }
        }
      }
      visit(0)
      allVariations = allVariations
        .map((variation) => {
          const id = Common.guid()
          let code = ''
          let name = ''
          variation.forEach((attr, i) => {
            if (i === 0) {
              code += attr.value.name.toLowerCase()
              name += attr.value.name
              return
            }
            code += `_${attr.value.name.toLowerCase()}`
            name += ` ${attr.value.name}`
          })
          return {
            id: id,
            code: code,
            image: null,
            name: name,
            price: 0,
            attributes: variation,
          }
        })
        .map((variation) => {
          for (let v of state.variations) {
            if (isDuplicate(variation, v)) {
              return { ...v }
            }
          }
          return variation
        })
      return {
        ...state,
        variations: allVariations,
      }
    case ADD_VALUE_ATTRIBUTE:
      return {
        ...state,
        attributes: state.attributes.map((attribute) => {
          if (attribute.id === action.id) {
            return {
              ...attribute,
              values: [...attribute.values, action.payload],
              allValues: [...attribute.allValues, action.payload],
            }
          }
          return attribute
        }),
      }
    case VALIDATE_ATTRIBUTE:
      return {
        ...state,
        attributes: state.attributes.map((attribute) => {
          if (attribute.values.length < 1) {
            return {
              ...attribute,
              errors: 'Vui lòng chọn ít nhất một giá trị',
            }
          }
          return {
            ...attribute,
            errors: null,
          }
        }),
      }
    case VALIDATE_VARIATION:
      // {
      //     id: "1231298fdj18290",
      //     code: "1231298fdj18290",
      //     image: {
      //         id: "2w13123123",
      //         path:
      //             "https://screenshotlayer.com/images/assets/placeholder.png",
      //     },
      //     name: "Xin chao",
      //     price: 20000,
      //     attributes: [
      //         {
      //             id: "123123123213",
      //             name: "Màu sắc",
      //             value: {
      //                 id: "312123",
      //                 name: "Red",
      //             },
      //         },
      //         {
      //             id: "213123",
      //             name: "Chat lieu",
      //             value: {
      //                 id: "asdad21",
      //                 name: "Vai",
      //             },
      //         },
      //     ],
      // },

      const variations = state.variations.map((variation, i) => {
        for (let j = 0; j < i; j++) {
          if (isDuplicate(variation, state.variations[j])) {
            return {
              ...variation,
              errors: 'duplicate',
            }
          }
        }
        return {
          ...variation,
          errors: null,
        }
      })
      return {
        ...state,
        variations: variations,
      }
    default:
      return state
  }
}
