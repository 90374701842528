import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import ListImages from './ListImages'
import ModalImage from './ModalImage'

function ImagePartial({ formik, name, title }) {
  return (
    <Card className="main-card mb-2" style={{
      zIndex:0
    }}>
      <CardHeader className="card-header-tab">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          {title}
        </div>
        <div className="btn-actions-pane-right mr-3">
          <ModalImage onChange={(imgs) => formik.setFieldValue(name, imgs)} />
        </div>
      </CardHeader>
      <CardBody
        style={{
          overflowY: 'auto',
          maxHeight: '400px',
        }}
      >
        <ListImages
          images={formik.values[name]}
          setImages={(imgs) => formik.setFieldValue(name, imgs)}
        />
      </CardBody>
    </Card>
  )
}

export default ImagePartial
