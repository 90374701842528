import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

function Select({
  type = 0,
  apiCommand,
  isMulti = false,
  onChange,
  name = 'name',
  defaultValueIds,
  ...props
}) {
  const [options, setOptions] = useState([])

  const [flag, setFlag] = useState(false)

  useEffect(() => {
    var option = { limit: 10 }
    if (type !== 0) {
      option.type = type
    }
    apiCommand(option).then((result) => {
      let key = Object.keys(result)[0]
      return setOptions(result[key].items)
    })
    return () => {}
  }, [])

  const [valuePropObject, setValuePropObject] = useState({})

  useEffect(() => {
    if (defaultValueIds) {
      if (defaultValueIds.length > 0 && options.length > 0 && !flag) {
        if (isMulti) {
          onChange(
            options.filter((option) => defaultValueIds.includes(option.id))
          )
        } else {
          onChange(
            options.find((option) => defaultValueIds.includes(option.id))
          )
        }
        setValuePropObject({
          value: options
            .filter((option) => defaultValueIds.includes(option.id))
            .map((option) => ({
              value: option.id,
              label: option.name,
            })),
        })
        setFlag(true)
      }
    }

    return () => {}
  }, [defaultValueIds, options])

  return (
    <ReactSelect
      {...props}
      isMulti={isMulti}
      options={options.map((option) => ({
        value: option.id,
        label: option[name],
      }))}
      onInputChange={(value) => {
        apiCommand({
          keyword: value,
        }).then((result) => {
          let key = Object.keys(result)[0]
          setOptions(result[key].items)
        })
      }}
      {...valuePropObject}
      onChange={(selectItems) => {
        setValuePropObject({})
        if (selectItems === null) return onChange(null)
        if (!Array.isArray(selectItems))
          return onChange(
            options.find((option) => option.id === selectItems.value)
          )
        const IdSelectItems = selectItems.map((item) => item.value)
        onChange(options.filter((option) => IdSelectItems.includes(option.id)))
      }}
    />
  )
}

Select.propTypes = {}

export default Select
