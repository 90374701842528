import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'
import Col from 'reactstrap/lib/Col'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import CategoriesPicker from '../../Layout/Categories/CategoriesPicker'
import {
  loadPickedCategories,
  loadPickedImages,
  loadPickedThumbnailImages,
} from '../../reducers/Partial'
import { getCategories } from '../../Services/CategoryService'
import { getEvouchers } from '../../Services/EvoucherService'
import {
  createCardProduct,
  getProduct,
  updateCardProduct,
} from '../../Services/ProductService'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import LoadingButton from '../CommonComponent/Button/LoadingButton'
import CkEditor5 from '../CommonComponent/CkEditor5/CkEditor5'
import Footer from '../CommonComponent/Footer'
import Header from '../CommonComponent/Header'
import ImagePartial from '../CommonComponent/ImagePartial/ImagePartial'

function ProductMemberForm(props) {
  const id = props.match.params.id

  const [loading, setLoading] = useState(false)
  const [evouchers, setEvouchers] = useState([])
  const [memberships, setMemberships] = useState([])
  const ck5Ref = useRef()
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  } = useForm()

  const pickedCategories = useSelector(
    (state) => state.Partial.pickedCategories
  )
  const images = useSelector((state) => state.Partial.images)
  const thumbnailImages = useSelector((state) => state.Partial.thumbnailImages)
  const merchantId = useSelector((state) => state.System.merchantId)
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      getProduct(id, Common.getParameterByName("languageId")).then((res) => {
        setValue('sku', res.product.sku)
        setValue('name', res.product.name)
        setValue('price', res.product.price)
        setValue('friendlyUrl', res.product.friendlyUrl)
        setValue('subDescription', res.product.subDescription)
        ck5Ref.current.setText(res.product.description)
        setValue('membershipId', res.product.membershipId)
        setValue('evoucherId', res.product.evoucherId)
        setValue('sendingPointInterval', res.product.sendingPointInterval)
        setValue('sendingTimes', res.product.sendingTimes)
        setValue('numberOfPointsPerSend', res.product.numberOfPointsPerSend)
        dispatch(
          loadPickedCategories(
            res.product.categories.map((cat) => ({
              label: cat.id,
              value: cat.name,
            }))
          )
        )
        dispatch(
          loadPickedImages(
            res.product.images.filter((i) => !i.isFeatured)
            // .map((cat) => ({
            //     id: cate,
            //     path: "path",
            //     name: "Asdasd",
            //     displayOrder: 1,
            //     //
            //     label: cat.id,
            //     value: cat.name,
            // }))
          )
        )
        dispatch(
          loadPickedThumbnailImages(
            res.product.images.filter((i) => i.isFeatured)
          )
        )
      })
    }
  }, [id])
  useEffect(() => {
    getCategories({
      type: Configuration.categoryTypes.MEMBERSHIP,
      limit: 100000,
    }).then((res) => setMemberships(res.categories.items))
  }, [])

  useEffect(() => {
    getEvouchers().then((res) => {
      setEvouchers(res.evouchers.items)
    })
  }, [])

  const onSubmit = (values) => {
    console.log(values)
    const { evoucherId } = values
    setLoading(true)
    let command = createCardProduct
    if (id) {
      command = updateCardProduct
    }
 

    
    command({
      id: id,
      ...values,
      merchantId: merchantId,
      evoucherId: evoucherId,      
      categories: Common.createRelationObjectArray(
        [values.membershipId]
      ),
      images: Common.createImagesRelationObjectArray(images, thumbnailImages),
      languageId: Common.getParameterByName("languageId") ||
      localStorage.getItem(Configuration.tokenLanguage),
    })
      .then(Common.handleResponse)
      .then(({ success }) => {
        if (success) {
          props.history.push('/product-member/list')
        }
      })
      .catch(Common.handleError)
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <Header
        title="Sản phẩm thẻ"
        description="Member products"
        iconClassName="pe-7s-cart"
      />
      <Container>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>Thông tin</CardHeader>
              <CardBody>
                <FormGroup row>
                  <Label sm="3">Mã sản phẩm</Label>
                  <Col sm="9">
                    <input
                      name="sku"
                      className="form-control"
                      ref={register}
                      placeholder="Mã tự sinh nếu bỏ trống"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">
                    Tên sản phẩm <span className="red">*</span>
                  </Label>
                  <Col sm="9">
                    <input
                      name="name"
                      className="form-control"
                      onBlur={() => {
                        setValue('friendlyUrl', getValues('name'))
                      }}
                      ref={register({
                        required: 'Trường bắt buộc',
                      })}
                    />
                    {errors.name ? (
                      <FormFeedback className="d-block">
                        {errors.name.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">
                    Giá (VNĐ) <span className="red">*</span>
                  </Label>
                  <Col sm="9">
                    <input
                      name="price"
                      className="form-control"
                      ref={register({
                        required: 'Trường bắt buộc',
                      })}
                    />
                    {errors.price ? (
                      <FormFeedback className="d-block">
                        {errors.price.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">
                    Link thân thiện <span className="red">*</span>
                  </Label>
                  <Col sm="9">
                    <input
                      name="friendlyUrl"
                      className="form-control"
                      ref={register({
                        required: 'Trường bắt buộc',
                      })}
                    />
                    {errors.friendlyUrl ? (
                      <FormFeedback className="d-block">
                        {errors.friendlyUrl.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">Trích dẫn</Label>
                  <Col sm="9">
                    <textarea
                      name="subDescription"
                      className="form-control"
                      ref={register}
                    ></textarea>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">Mô tả</Label>
                  <Col sm="9">
                    <Controller
                      control={control}
                      name="description"
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <CkEditor5
                          ref={ck5Ref}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
            <Card className="mt-3">
              <CardHeader>Dịch vụ</CardHeader>
              <CardBody>
                <FormGroup row>
                  <Label sm="3">Hạng thành viên</Label>
                  <Col sm="9">
                    <select
                      name="membershipId"
                      className="form-control"
                      ref={register({
                        required: 'Trường bắt buộc',
                      })}
                    >
                      <option value="00000000-0000-0000-0000-000000000000">
                        -
                      </option>
                      {memberships.map((membership) => (
                        <option key={membership.id} value={membership.id}>
                          {membership.name}
                        </option>
                      ))}
                    </select>
                    {errors.membershipId ? (
                      <FormFeedback className="d-block">
                        {errors.membershipId.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">Số điểm gửi mỗi lần</Label>
                  <Col sm="9">
                    <input
                      name="numberOfPointsPerSend"
                      type="number"
                      className="form-control"
                      ref={register({
                        required: 'Trường bắt buộc',
                      })}
                    />
                    {errors.numberOfPointsPerSend ? (
                      <FormFeedback className="d-block">
                        {errors.numberOfPointsPerSend.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">Số lần gửi điểm</Label>
                  <Col sm="9">
                    <input
                      name="sendingTimes"
                      type="number"
                      className="form-control"
                      ref={register({
                        required: 'Trường bắt buộc',
                        min: 0,
                      })}
                    />
                    {errors.sendingTimes ? (
                      <FormFeedback className="d-block">
                        {errors.sendingTimes.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">Thời gian giữa mỗi lần gửi</Label>
                  <Col sm="9">
                    <select
                      name="sendingPointInterval"
                      className="form-control"
                      ref={register({
                        required: 'Trường bắt buộc',
                      })}
                    >
                      {new Array(12).fill(1).map((val, i) => (
                        <option value={i + 1}>{i + 1} Tháng</option>
                      ))}
                    </select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="3">Evoucher</Label>
                  <Col sm="9">
                    <select
                      name="evoucherId"
                      className="form-control"
                      ref={register({
                        required: 'Trường bắt buộc',
                      })}
                    >
                      <option value="00000000-0000-0000-0000-000000000000">
                        -
                      </option>
                      {evouchers.map((evoucher) => (
                        <option key={evoucher.id} value={evoucher.id}>
                          {evoucher.name}
                        </option>
                      ))}
                    </select>
                    {errors.evoucherId ? (
                      <FormFeedback className="d-block">
                        {errors.evoucherId.message}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <CategoriesPicker type={Configuration.categoryTypes.PRODUCT} />
            <ImagePartial thumbnail />
            <ImagePartial />
          </Col>
        </Row>
      </Container>
      <Footer
        left={
          <LoadingButton
            className="btn btn-success"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Lưu
          </LoadingButton>
        }
      />
    </div>
  )
}

export default ProductMemberForm
