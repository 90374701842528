import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  cancelOrder,
  closePayment,
  failPayment,
  getAffiliateOrders,
  getOrderDetail,
  getOrders,
  processDelivery,
} from '../Services/OrderService'
import Configuration from '../utils/configuration'
import { FAILURE, REQUEST, SUCCESS } from './ActionType'

export const ORDER = {
  GET_ORDER: 'order/GET_ORDER',
  GET_AFFILIATE_ORDER: 'order/GET_AFFILIATE_ORDER',
  GET_ORDER_DETAIL: 'order/SELECT_ORDER',
  SELECT_ORDER: 'order/SELECT_ORDER',
  CANCEL_ORDER: 'order/CANCEL_ORDER',
  CLEAR_ORDER_INFO_MESS: 'order/CLEAR_ORDER_INFO_MESS',
  ACCEPT: 'order/ACCEPT',
  REJECT: 'order/REJECT',
  PROCESS_DELIVERY_ORDER: 'order/PROCESS_DELIVERY_ORDER',

  CLOSE_PAYMENT: 'order/CLOSE_PAYMENT',
  FAIL_PAYMENT: 'order/FAIL_PAYMENT',
}

export const closePaymentAction = (id) => ({
  type: ORDER.CLOSE_PAYMENT,
  payload: closePayment(id),
})
export const failPaymentAction = (id) => ({
  type: ORDER.FAIL_PAYMENT,
  payload: failPayment(id),
})

export const processDeliveryAction = (value) => ({
  type: ORDER.PROCESS_DELIVERY_ORDER,
  payload: processDelivery(value),
})

export const getOrdersAction = (option) => ({
  type: ORDER.GET_ORDER,
  payload: getOrders(option),
})

export const getAffiliateOrdersAction = (
  textSearch,
  allStatus,
  sort,
  order,
  limt,
  offset
) => ({
  type: ORDER.GET_AFFILIATE_ORDER,
  payload: getAffiliateOrders(textSearch, allStatus, sort, order, limt, offset),
})

export const getOrderDetailAction = (orderId) => ({
  type: ORDER.GET_ORDER_DETAIL,
  payload: getOrderDetail(orderId),
})

export const cancelOrderAction = (cancelOrderDTO) => ({
  type: ORDER.CANCEL_ORDER,
  payload: cancelOrder(cancelOrderDTO),
})

export const clearOrderMessageAction = () => ({
  type: ORDER.CLEAR_ORDER_INFO_MESS,
  payload: {},
})

export const acceptOrder = (id) => ({
  type: ORDER.ACCEPT,
  id: id,
})

export const rejectOrder = (id) => ({
  type: ORDER.REJECT,
  id: id,
})

const initialState = {
  orders: [],
}

export const reducer = persistReducer(
  {
    storage,
    key: 'order',
    blacklist: ['orders', 'isLoading', 'cancelOrderResult'],
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case REQUEST(ORDER.CLOSE_PAYMENT):
        return {
          ...state,
          isLoading: true,
        }
      case FAILURE(ORDER.CLOSE_PAYMENT):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(ORDER.CLOSE_PAYMENT):
        var temp = action.payload
        if (temp.data.Success) {
          return {
            ...state,
            orders: {
              ...state.orders,
              items: state.orders.items.map((order) => {
                if (order.paymentId === action.payload.data.Id) {
                  //order.paymentAllStatus = 33554432;
                  //order.delivery.trackingCode = action.value.TrackingCode;
                  return {
                    ...order,
                    paymentAllStatus: Configuration.statusPayment.SUCCESS,
                  }
                }
                return order
              }),
            },
          }
        }
        return {
          ...state,
        }

      case REQUEST(ORDER.GET_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          isLoading: true,
        }
      case FAILURE(ORDER.GET_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          isLoading: false,
        }
      case SUCCESS(ORDER.GET_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          orders: action.payload.orderconfirmeds,
          isLoading: false,
        }
      case REQUEST(ORDER.GET_AFFILIATE_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          isLoading: true,
        }
      case FAILURE(ORDER.GET_AFFILIATE_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          isLoading: false,
        }
      case SUCCESS(ORDER.GET_AFFILIATE_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          orders: action.payload.affiliateorders,
          isLoading: false,
        }

      case REQUEST(ORDER.CANCEL_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          isLoading: true,
        }
      case FAILURE(ORDER.CANCEL_ORDER):
        return {
          ...state,
          cancelOrderResult: '',
          isLoading: false,
        }

      case SUCCESS(ORDER.CANCEL_ORDER):
        return {
          ...state,
          cancelOrderResult: action.payload.Success
            ? 'Hủy đơn hàng thành công'
            : action.payload.Message,
          isLoading: false,
        }
      case REQUEST(ORDER.PROCESS_DELIVERY_ORDER):
        return {
          ...state,
          isLoading: false,
        }
      case FAILURE(ORDER.PROCESS_DELIVERY_ORDER):
        return {
          ...state,
          // orders: {
          //     ...state.orders,
          //     items: state.orders.items.map((order) => {
          //         if (order.id === action.orderId) {
          //             order.delivery.allStatus = 1;
          //             order.delivery.trackingCode = "";
          //             return {
          //                 ...order,
          //                 deliveryAllStatus: order.delivery.allStatus

          //             };
          //         }
          //         return order;
          //     }),
          // },
        }
      case SUCCESS(ORDER.PROCESS_DELIVERY_ORDER):
        var temp = action.payload
        if (temp.data.Success) {
          return {
            ...state,
            orders: {
              ...state.orders,
              items: state.orders.items.map((order) => {
                if (
                  order.delivery != null &&
                  order.delivery.id === action.payload.data.Id
                ) {
                  order.delivery.allStatus =
                    Configuration.statusDelivery.PROCESS
                  //order.delivery.trackingCode = action.value.TrackingCode;
                  return {
                    ...order,
                    deliveryAllStatus: order.delivery.allStatus,
                  }
                }
                return order
              }),
            },
          }
        }
        return {
          ...state,
        }

      case ORDER.CLEAR_ORDER_INFO_MESS:
        return {
          ...state,
          cancelOrderResult: '',
        }
      case ORDER.ACCEPT:
        return {
          ...state,
          orders: {
            ...state.orders,
            items: state.orders.items.map((order) => {
              if (order.id === action.id) {
                return {
                  ...order,
                  allStatus: Configuration.statusOrder.SUCCESS,
                }
              }
              return order
            }),
          },
        }
      case ORDER.REJECT:
        return {
          ...state,
          orders: {
            ...state.orders,
            items: state.orders.items.map((order) => {
              if (order.id === action.id) {
                return {
                  ...order,
                  allStatus: Configuration.statusOrder.FAIL,
                }
              }
              return order
            }),
          },
        }

      default:
        return state
    }
  }
)
