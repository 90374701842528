import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import CategoriesPicker from "../../Layout/Categories/CategoriesPicker";
import {
  loadPickedCategories,
  loadPickedThumbnailImages,
  updatePickSortable,
} from "../../reducers/Partial";
import { sendThemeTemplate } from "../../Services/ThemeTemplateService";
import Common from "../../utils/common";
import Configuration from "../../utils/configuration";
import { getData } from "../../utils/data";
import LoadingButton from "../CommonComponent/Button/LoadingButton";
import Footer from "../CommonComponent/Footer";
import LaboFormikGroup from "../CommonComponent/Form/LaboFormikGroup";
import Header from "../CommonComponent/Header";
import ImagePartial from "../CommonComponent/ImagePartial/ImagePartial";
import PickSortablePartial from "../CommonComponent/PickSortablePartial/PickSortablePartial";

function ThemeTemplateForm(props) {
  const idThemeTemplate = props.match.params.id;
  const [themeWebs, setThemeWebs] = useState([]);
  const [themeTemplate, setThemeTemplate] = useState({
    theme: null,
    name: "",
    subDescription: "",
  });
  const [loading, setLoading] = useState(false);
  const sections = useSelector((state) => state.Partial.pickSortable.sections);

  const images = useSelector((state) => state.Partial.thumbnailImages);
  const pickCategories = useSelector((state) => state.Partial.pickedCategories);
  const dispatch = useDispatch();

  const updateSections = (sections) =>
    dispatch(
      updatePickSortable({
        sections: sections,
      })
    );

  useEffect(() => {
    if (idThemeTemplate) {
      getData.getArticle(idThemeTemplate).then((res) => {
        setThemeTemplate({
          theme: res.article.targetId,
          name: res.article.name,
          subDescription: res.article.subDescription,
        });
        updateSections(res.article.sections ? res.article.sections : []);
        dispatch(loadPickedThumbnailImages(res.article.images));
        dispatch(
          loadPickedCategories(
            res.article.categories.map((category) => ({
              value: category.id,
              label: category.name,
            }))
          )
        );
      });
    }
    return () => {};
  }, []);

  const themeTemplateForm = useFormik({
    enableReinitialize: true,
    initialValues: themeTemplate,
    onSubmit: async (values) => {
      const { theme, name, subDescription } = values;
      try {
        setLoading(true);
        const body = {
          id: idThemeTemplate,
          name: name,
          themeId: theme,
          categories: pickCategories,
          images: images,
          sections: sections,
          subDescription: subDescription,
          isUpdate: idThemeTemplate ? true : false,
        };
        const res = await sendThemeTemplate(body);
        setLoading(false);
        const { Message, Success } = res.data;
        Swal.fire({
          title: Success ? "Thành công" : "Thất bại",
          text: Message,
          icon: Success ? "success" : "error",
          showCancelButton: true,
          confirmButtonText: "Đến danh sách",
        }).then(({ value }) => {
          if (value) props.history.push("/theme-template/list");
        });
      } catch (err) {
        Swal.fire({
          title: "Thất bại",
          text: err,
          icon: "error",
          showCancelButton: true,
        });
      }
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) errors.name = "Tiêu đề không được để trống";
      if (!values.subDescription) errors.subDescription = "trích dẫn không được để trống";

      return errors;
    },
  });

  useEffect(() => {
    getData.getCurrentThemeweb().then((res) => {
      const currentThemeWebId = res.id;
      getData.getThemeWebs().then((res) => {
        if (!idThemeTemplate) {
          themeTemplateForm.setFieldValue(
            "theme",

            currentThemeWebId
          );
        }
        setThemeWebs(
          res.themewebs.items.map((item) => {
            if (item.id === currentThemeWebId)
              return {
                ...item,
                name: item.name + " (Theme hiện tại)",
              };
            return item;
          })
        );
      });
    });
    return () => {
      dispatch(loadPickedCategories([]));
      dispatch(loadPickedThumbnailImages([]));
      updateSections([]);
    };
  }, []);

  return (
    <div>
      <Header title="Theme template" iconClassName="pe-7s-display2" />
      <Row>
        <Col md="8">
          <Card className="mb-2">
            <CardBody>
              <LaboFormikGroup
                formik={themeTemplateForm}
                label="Theme"
                name="theme"
                type="select"
                options={themeWebs.map((themeWeb) => ({
                  value: themeWeb.id,
                  label: themeWeb.name,
                }))}
                disabled={idThemeTemplate ? true : false}
              />
              <Row>
                <Col md="6">
                  <LaboFormikGroup
                    formik={themeTemplateForm}
                    label="Tiêu đề <span class='red'>*</span>"
                    name="name"
                    type="text"
                  />
                </Col>
                <Col md="6">
                  <LaboFormikGroup
                    formik={themeTemplateForm}
                    label="Trích dẫn  <span class='red'>*</span>"
                    name="subDescription"
                    type="text"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          {themeTemplateForm.values.theme ? (
            <PickSortablePartial
              items={sections}
              updateItems={updateSections}
              title="Section"
              apiCommand={(options) =>
                getData.getSections({
                  themeWebId: themeTemplateForm.values.theme,
                  ...options,
                })
              }
            />
          ) : null}
        </Col>
        <Col md="4">
          <CategoriesPicker
            type={Configuration.categoryTypes.THEME_WEB_TEMPLATE}
            languageId={
              Common.getParameterByName("languageId")
                ? Common.getParameterByName("languageId")
                : localStorage.getItem(Configuration.tokenLanguage)
            }
          />

          <ImagePartial thumbnail />
        </Col>
      </Row>
      <Footer
        left={
          <LoadingButton loading={loading} onClick={themeTemplateForm.handleSubmit}>
            Lưu
          </LoadingButton>
        }
      />
    </div>
  );
}

export default ThemeTemplateForm;
