import React from 'react'
import LaboFormGroup from './LaboFormGroup'

function LaboFormikGroup({ formik, name, ...props }) {
  return (
    <LaboFormGroup
      value={formik.values[name]}
      error={formik.errors[name]}
      name={name}
      onChange={(e) => {
        formik.handleChange(e)
      }}
      {...props}
    />
  )
}

LaboFormikGroup.propTypes = {}

export default LaboFormikGroup
