export const UPDATE_NAV = "UPDATE_NAV";

const initState = {
  navs: [],
};

export const updateNavs = (payload) => ({
  type: UPDATE_NAV,
  payload,
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_NAV:
      return {
        ...state,
        navs: action.payload,
      };
    default:
      return state;
  }
}
