import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import LaboUploadAdapter from './LaboUploadAdapter'

// const ClassicEditor = require("@ckeditor/custom-build/build/ckeditor");

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new LaboUploadAdapter(loader)
  }
}

function CkEditor5({ defaultValue, onChange, ...props }, ref) {
  const editorRef = useRef()
  const [editor, setEditor] = useState(null)

  const setText = (value) => {
    editor.setData(value)
  }

  useImperativeHandle(ref, () => ({
    setText,
  }))

  // function setData(data) {
  //     return editor.insertText(data);
  // }

  // useEffect(() => {
  //     if (editor && value) {
  //         editor.setData(value)
  //         editor.model.change((writer) => {
  //             writer.setSelection( editor.model.document.getRoot(), 'end');
  //             // const insertPosition = editor.model.document.selection.getLastPosition();
  //             // writer.insert(value, insertPosition);
  //         });

  //         // if (editor.getData() === "") editor.setData(formik.values[name]);
  //     }
  // }, [value, editor]);

  useEffect(() => {
    ClassicEditor.create(editorRef.current, {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'indent',
          'outdent',
          '|',
          'MathType',
          'ChemType',
          'specialCharacters',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'undo',
          'redo',
        ],
      },
      language: 'en',
      image: {
        toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },
      licenseKey: '',
      extraPlugins: [MyCustomUploadAdapterPlugin],
    })
      .then((editor) => {
        setEditor(editor)
        editor.model.document.on('change:data', () => {
          if (onChange) onChange(editor.getData())
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return <div ref={editorRef}></div>
}

CkEditor5.propTypes = {}

export default forwardRef(CkEditor5)
