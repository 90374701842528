import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation'
import React, { Fragment, useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import Autocomplete from 'react-google-autocomplete'
import Loader from 'react-loaders'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Card, CardBody, CardHeader, Label } from 'reactstrap'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import { updateAddress } from '../../reducers/Partial'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import { getData, postData } from '../../utils/data'

function AddressPartial({ address, updateAddress, error, onChange,isMulti }) {
  const [isSending, setIsSending] = useState(false)
  const [allAddresses, setAllAddresses] = useState([])

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    getData.getAddresses().then((result) => {
      if(result.addresses != null){
        setAllAddresses(
          result.addresses.items.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
      
    })

    return () => {
      updateAddress({})
    }
  }, [])

  const onCreateAddress = (e, values) => {
    const { name, description } = values
    const { lat, lng } = address.mapPosition

    const newId = Common.guid()

    const body = {
      Id: newId,
      MerchantId: localStorage.getItem('merchantId'),
      Latitude: lat,
      Longitude: lng,
      Type: 4,
      Name: name,

      DetailAddress: description,

      LanguageId: localStorage.getItem(Configuration.tokenLanguage),
      CreatedDate: new Date(),
      CreatedBy: localStorage.getItem('userId'),
    }
    setIsSending(true)
    postData
      .sendCommand('Address', 'QuickCreateAddress', body)
      .then((result) => {
        if (result.data.Success) {
          setAllAddresses([
            ...allAddresses,
            {
              label: name,
              value: newId,
            }, //new Address
          ])
          toast['success']('Thành công')
        }
      })
      .catch((err) => toast['error'](err + ''))
      .finally(() => {
        setIsOpen(false)
        setIsSending(false)
      })
  }

  const getArea = (addressArray) => {
    let city = ''
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name
        return city
      }
    }
  }

  const getCity = (addressArray) => {
    let state = ''
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name
          return state
        }
      }
    }
  }

  const onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      area = getArea(addressArray),
      city = getCity(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng()
    // Set these values in the state.
    const pickAddress = {
      address: address ? address : '',
      area: area ? area : '',
      city: city ? city : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    }
    console.log(pickAddress)
    updateAddress(pickAddress)
  }

  return (
    <BlockUi
      tag="div"
      blocking={isSending}
      className="block-overlay-dark"
      loader={<Loader color="#ffffff" active />}
    >
      <Card className="mb-2">
        <CardHeader>
          Địa chỉ
          <div className="btn-actions-pane-right actions-icon-btn">
            <Button
              className="btn-icon btn-icon-only"
              color="link"
              onClick={() => setIsOpen(!isOpen)}
            >
              <i className="pe-7s-cloud-download btn-icon-wrapper" />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Select
            isMulti={isMulti}
            options={allAddresses}
            className="mb-3"
            onChange={(address) => {
              console.log(address,"161")
              if (onChange) {
                onChange(address)
              }
              return updateAddress(address)
            }}
            value={address}
          />
          {error ? (
            <FormFeedback
              className="d-block"
              style={{
                fontSize: '15px',
              }}
            >
              {error}
            </FormFeedback>
          ) : null}
          {isOpen ? (
            <Fragment>
              <hr></hr>
              <AvForm onValidSubmit={onCreateAddress}>
                <AvGroup>
                  <Label>Tên địa điểm</Label>
                  <AvField name="name" type="text" />
                </AvGroup>
                <AvGroup>
                  <Label>Mô tả địa điểm</Label>
                  <AvField name="description" type="text" />
                </AvGroup>
                <Autocomplete
                  className="form-control mb-3"
                  onPlaceSelected={onPlaceSelected}
                  types={['address']}
                  componentRestrictions={{ country: 'vn' }}
                />
                <Button color="primary" className="mx-auto d-block">
                  Lưu
                </Button>
              </AvForm>
            </Fragment>
          ) : null}
        </CardBody>
      </Card>
    </BlockUi>
  )
}

function mapStateToProps(state) {
  return {
    address: state.Partial.address,
  }
}

const mapDispatchToProps = {
  updateAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressPartial)
