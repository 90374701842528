import Common from "../utils/common";

export const UPDATE_PICKED_CATEGORIES = "UPDATE_PICKED_CATEGORIES";
export const UPDATE_PICKED_IMAGES = "UPDATE_PICKED_IMAGES";
export const UPDATE_PICKED_THUMBNAIL_IMAGES = "UPDATE_PICKED_THUMBNAIL_IMAGES";
export const GET_CURRENT_PATH_IMG = "GET_CURRENT_PATH_IMG";
export const UPDATE_ALL_IMAGES = "UPDATE_ALL_IMAGES";
export const UPDATE_ALL_LIBRARY_IMAGES = "UPDATE_ALL_LIBRARY_IMAGES";
export const UPDATE_PICKED_IMAGES_HELP_DESK = "UPDATE_PICKED_IMAGES_HELP_DESK";
export const UPDATE_AUTHENTICATION = "UPDATE_AUTHENTICATION";
export const UPDATE_INFOMATION_DATA_USER = "UPDATE_INFOMATION_DATA_USER";
export const UPDATE_ALL_LANGUAGE = "UPDATE_ALL_LANGUAGE";
export const UPDATE_CONTENT_QUESTION = "UPDATE_CONTENT_QUESTION";
export const UPDATE_INFO_QUIZ = "UPDATE_INFO_QUIZ";
export const UPDATE_LIST_QUESTION = "UPDATE_LIST_QUESTION";
export const UPDATE_FILES = "UPDATE_FILES";
export const UPDATE_FILEVIDEOS = "UPDATE_FILEVIDEOS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_FEATURE_USERS = "UPDATE_FEATURE_USERS";

export const UPDATE_ARTICLES = "UPDATE_ARTICLES";
export const UPDATE_FEATURE_ARTICLES = "UPDATE_FEATURE_ARTICLES";

export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const UPDATE_PICK_PRODUCT = "UPDATE_PICK_PRODUCT";
export const UPDATE_PICK_FORM = "UPDATE_PICK_FORM";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_PICK_SORTABLE_ITEMS = "UPDATE_PICK_SORTABLE_ITEMS";

//Schedule
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const EDIT_SCHEDULE = "EDIT_SCHEDULE";
export const SET_SCHEDULE = "SET_SCHEDULE";

const initState = {
  pickedCategories: [],
  images: [],
  thumbnailImages: [],
  imagesHelpDesk: [],
  currentPathImg: "",
  allImages: [],
  allLibraryImages: [],
  //authentication
  authed: false,
  //user
  infomationDataUser: {},
  //all language
  allLanguage: [],
  //========quiz==========
  infoQuiz: {
    id: "",
    name: "",
    subDescription: "",
    description: "",
    duration: 0,
    option: {},
    images: [],
    categories: [],
    questions: [],
  },
  listQuestion: [],
  question: {
    id: Common.guid(), // xoa
    name: "",
    type: "",
    grading: "",
    answers: [
      {
        id: Common.guid(), //xoa
        name: "",
        name2: "",
        isCorrect: "true",
      },
    ],
  },
  listFile: [],
  fileVideos:[],
  users: [],
  featureUsers: [],
  //Calendar
  events: [],
  pickProducts: [],
  pickForms: [],
  address: null,
  schedules: [],
  pickSortable: {},
  articles:[],
  
};

//Schedule
export const addSchedule = (payload) => ({
  type: ADD_SCHEDULE,
  payload,
});

export const deleteSchedule = (idSchedule) => ({
  type: DELETE_SCHEDULE,
  idSchedule,
});

export const editSchedule = (idSchedule, scheduleInfo) => ({
  type: EDIT_SCHEDULE,
  idSchedule,
  scheduleInfo,
});

export const setSchedules = (payload) => ({
  type: SET_SCHEDULE,
  payload,
});
//end schedule

export const updateUsers = (data) => ({
  type: UPDATE_USERS,
  data,
});

export const updateFeatureUsers = (data) => ({
  type: UPDATE_FEATURE_USERS,
  data,
});

export const updateArticles = (data) => ({
  type: UPDATE_ARTICLES,
  data,
});

export const updateFeatureArticles = (data) => ({
  type: UPDATE_FEATURE_ARTICLES,
  data,
});

export const updateAddress = (data) => ({
  type: UPDATE_ADDRESS,
  data,
});

export const updateEvents = (data) => ({
  type: UPDATE_EVENTS,
  data,
});

export const updatePickForms = (data) => ({
  type: UPDATE_PICK_FORM,
  data,
});

export const updateFiles = (data) => ({
  type: UPDATE_FILES,
  data,
});

export const updateFileVideos = (data) => ({
  type: UPDATE_FILEVIDEOS,
  data,
});

export const updateListQuestion = (data) => ({
  type: UPDATE_LIST_QUESTION,
  data,
});
export const updateInfoQuiz = (objData) => ({
  type: UPDATE_INFO_QUIZ,
  objData,
});

export const updateContentQuestion = (data) => ({
  type: UPDATE_CONTENT_QUESTION,
  data,
});
export const updateAllLanguage = (data) => ({
  type: UPDATE_ALL_LANGUAGE,
  data,
});
export const updateAuthentication = () => ({
  type: UPDATE_AUTHENTICATION,
});
export const updateInformationDateUser = (data) => ({
  type: UPDATE_INFOMATION_DATA_USER,
  data,
});
export const loadPickedCategories = (data) => ({
  type: UPDATE_PICKED_CATEGORIES,
  data,
});

export const updateAllImages = (data) => ({
  type: UPDATE_ALL_IMAGES,
  data,
});

export const updateAllLibraryImages = (data) => ({
  type: UPDATE_ALL_LIBRARY_IMAGES,
  data,
});

export const loadPickedImages = (data) => ({
  type: UPDATE_PICKED_IMAGES,
  data,
});

export const loadPickedThumbnailImages = (data) => ({
  type: UPDATE_PICKED_THUMBNAIL_IMAGES,
  data,
});

export const loadPickedImagesHelpDesk = (data) => ({
  type: UPDATE_PICKED_IMAGES_HELP_DESK,
  data,
});

export const getCurrentPathImg = (path) => ({
  type: GET_CURRENT_PATH_IMG,
  path,
});

export const updatePickProducts = (data) => ({
  type: UPDATE_PICK_PRODUCT,
  data,
});

export const updatePickSortable = (data) => ({
  type: UPDATE_PICK_SORTABLE_ITEMS,
  data,
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    //Schedule
    case ADD_SCHEDULE:
      return {
        ...state,
        schedules:
          state.schedules.length > 0 ? [...state.schedules, action.payload] : [action.payload],
      };

    case DELETE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter((schedule) => schedule.id !== action.idSchedule),
      };

    case EDIT_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.map((schedule) => {
          if (schedule.id === action.idSchedule)
            return {
              ...schedule,
              ...action.scheduleInfo,
            };
          return schedule;
        }),
      };

    case SET_SCHEDULE:
      return {
        ...state,
        schedules: [...action.payload],
      };
    //end schedule

    // Pick Sortable Items

    case UPDATE_PICK_SORTABLE_ITEMS:
      return {
        ...state,
        pickSortable: {
          ...state.pickSortable,
          ...action.data,
        },
      };

    case UPDATE_EVENTS:
      return {
        ...state,
        events: action.data,
      };

    case UPDATE_ADDRESS:
      return {
        ...state,
        address: action.data,
      };

    case UPDATE_PICK_PRODUCT:
      return {
        ...state,
        pickProducts: action.data,
      };

    case UPDATE_PICK_FORM:
      return {
        ...state,
        pickForms: action.data,
      };

    case UPDATE_USERS:
      return {
        ...state,
        users: action.data,
      };

      case UPDATE_ARTICLES:
      return {
        ...state,
        articles: action.data,
      };

    case UPDATE_FEATURE_USERS:
      return {
        ...state,
        featureUsers: action.data,
      };

    case UPDATE_FILES:
      return {
        ...state,
        listFile: action.data,
      };

      case UPDATE_FILEVIDEOS:
      return {
        ...state,
        fileVideos: action.data,
      };

    case UPDATE_LIST_QUESTION:
      return {
        ...state,
        listQuestion: action.data,
      };

    case UPDATE_INFO_QUIZ:
      return {
        ...state,
        infoQuiz: action.objData,
      };

    case UPDATE_CONTENT_QUESTION:
      return {
        ...state,
        question: action.data,
      };
    case UPDATE_ALL_LANGUAGE:
      return {
        ...state,
        allLanguage: action.data,
      };
    case UPDATE_INFOMATION_DATA_USER:
      return {
        ...state,
        infomationDataUser: action.data,
      };
    case UPDATE_AUTHENTICATION:
      return {
        ...state,
        authed: true,
      };
    case UPDATE_PICKED_CATEGORIES:
      return {
        ...state,
        pickedCategories: action.data,
      };
    case UPDATE_PICKED_IMAGES:
      return {
        ...state,
        images: action.data,
      };
    case UPDATE_PICKED_THUMBNAIL_IMAGES:
      return {
        ...state,
        thumbnailImages: action.data,
      };
    case UPDATE_PICKED_IMAGES_HELP_DESK:
      return {
        ...state,
        imagesHelpDesk: action.data,
      };
    case GET_CURRENT_PATH_IMG:
      return {
        ...state,
        currentPathImg: action.path,
      };
    case UPDATE_ALL_IMAGES:
      return {
        ...state,
        allImages: action.data,
      };
    case UPDATE_ALL_LIBRARY_IMAGES:
      return {
        ...state,
        allLibraryImages: action.data,
      };
    default:
      return state;
  }
}
