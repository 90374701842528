import { useFormik } from 'formik'
import React, { useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'
import Common from '../../utils/common'
import { postData } from '../../utils/data'
import LoadingButton from '../CommonComponent/Button/LoadingButton'
import LaboFormikGroup from '../CommonComponent/Form/LaboFormikGroup'
import Header from '../CommonComponent/Header'
import './evoucher-code.css'

function UseEVoucherCodes() {
  const [loading, setLoading] = useState(false)
  const [evoucherInfo, setEvoucherInfo] = useState(null)

  const formik = useFormik({
    initialValues: {
      eCode: '',
    },
    validate: (values) => {
      const { eCode } = values
      const errors = {}
      if (!eCode) {
        errors.eCode = 'Trường này không được để trống'
      }
      return errors
    },
    onSubmit: async (values) => {      
      const body = {
        Id: Common.guid(),
        ModifiedDate: new Date(),
        ModifiedBy: localStorage.getItem('userId'),
        TargetIds: null,
        Code: values.eCode,
        MerchantId: localStorage.getItem('merchantId'),
      }
      setLoading(true)
      const result = await postData.sendCommand(
        'Evoucher',
        'CheckEVoucherCodes',
        body
      )
      const { Success, Message, Data } = result.data

      setEvoucherInfo({
        name: Data? Data.EVoucherName : "",
        value: Data? Data.EVoucherValue: "",
        receiverEmail: Data? Data.ReceiverEmail: "",
        receiverMobile: Data? Data.ReceiverMobile: "",
        receiverName: Data? Data.ReceiverName: "",
        code: Data? Data.EVoucherCode : "",
        message: Message,
        success: Success,
      })
      
      setLoading(false)
    },
  })

  const activateEvoucherHandler = (code) => {
    const body = {
      Id: Common.guid(),
      ModifiedDate: new Date(),
      ModifiedBy: localStorage.getItem('userId'),
      TargetIds: null,
      MerchantId: localStorage.getItem('merchantId'),
      Code: code,
    }

    setLoading(true)
    postData
      .sendCommand('Evoucher', 'UseEVoucherCodes', body)
      .then(Common.handleResponse)
      .catch(Common.handleError)
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <Header title="Kiểm tra mã Evoucher" iconClassName="pe-7s-credit" />
      <Row>
        <Col md="6">
          <Card className="mb-2">
            <CardHeader>Kiểm tra mã Evoucher</CardHeader>
            <CardBody>
              <LaboFormikGroup
                label="Mã evoucher <span class='red'>*</span>"
                formik={formik}
                name="eCode"
              />
              <LoadingButton
                loading={loading}
                color="primary"
                onClick={formik.handleSubmit}
              >
                Kiểm tra
              </LoadingButton>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <ReactCSSTransitionGroup
            transitionName="example"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            {evoucherInfo ? (
              <Card>
                <CardHeader>Thông tin Evoucher</CardHeader>
                {evoucherInfo.success ? ( 
                  <CardBody>                
                    <FormGroup row>
                      <Label className="pt-0" sm="3">
                        <strong>Tên Evoucher:</strong>
                      </Label>
                      <Col md="9">{evoucherInfo.name}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="pt-0" sm="3">
                        <strong>Mã Evoucher:</strong>
                      </Label>
                      <Col md="9">{evoucherInfo.code} <div className="badge badge-success">{evoucherInfo.message}</div></Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="pt-0" sm="3">
                        <strong>Giá trị:</strong>
                      </Label>
                      <Col md="9">{evoucherInfo.value}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="pt-0" sm="3">
                        <strong>Tên khách hàng:</strong>
                      </Label>
                      <Col md="9">{evoucherInfo.receiverName}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="pt-0" sm="3">
                        <strong>Email khách hàng:</strong>
                      </Label>
                      <Col md="9">{evoucherInfo.receiverEmail}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="pt-0" sm="3">
                        <strong>SĐT khách hàng:</strong>
                      </Label>
                      <Col md="9">{evoucherInfo.receiverMobile}</Col>
                    </FormGroup> 
                    <LoadingButton
                      loading={loading}
                      className="btn btn-primary"
                      onClick={() => activateEvoucherHandler(evoucherInfo.code)}
                    >
                      Kích hoạt
                    </LoadingButton>                  
                  </CardBody>
                ) : (
                  <CardBody>
                    <FormGroup row>
                      <Label className="pt-0" sm="3">
                        <strong>Thông tin:</strong>
                      </Label>
                      <Col md="9"><div className="mb-2 mr-2 badge badge-danger">{evoucherInfo.message}</div></Col>
                    </FormGroup>                              
                  </CardBody>
                )}
              </Card>
            ) : null}
          </ReactCSSTransitionGroup>
        </Col>
      </Row>
    </div>
  )
}

export default UseEVoucherCodes
