import Configuration from '../utils/configuration'
import { getData } from '../utils/data'
import Common from '../utils/common'

export const getRatings = (options = {}) => {
  const limit = options.limit === undefined ? 100000 : options.limit
  const offset = options.offset === undefined ? 0 : options.offset
  const order = options.order === undefined ? 'desc' : options.order
  const sort = options.sort === undefined ? 'createdDate' : options.sort
  const keyword = options.keyword === undefined ? '' : options.keyword
  const languageId =
    options.languageId === undefined
      ? localStorage.getItem(Configuration.tokenLanguage)
      : options.languageId
  const categoryIds =
    options.categoryIds === undefined || options.categoryIds.length === 0
      ? ''
      : `categoryIds:[${options.categoryIds
          .map((val) => `"${val}"`)
          .join(',')}]`
  return getData.getDataGraphQl(`
                  {
                    ratings(param:{keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                          ,languageId:"${languageId}",merchantId:"${localStorage.getItem(
    'merchantId'
  )}", ${categoryIds}})
                      {
                          totalCount,
                          message, 
                          success,
                          items{
                              id,
                              name,
                              createdBy,
                              createdDate,
                              maxScore,
                              published,
                              subDescription
              
                          },
                          
                      }
                    }
                  `)
}

export const getRatingTransactions = (id, options = {}) => {
  const limit = options.limit === undefined ? 100000 : options.limit
  const offset = options.offset === undefined ? 0 : options.offset
  const order = options.order === undefined ? 'desc' : options.order
  const sort = options.sort === undefined ? 'createdDate' : options.sort
  const keyword = options.keyword === undefined ? '' : options.keyword
  const languageId =
    options.languageId === undefined
      ? localStorage.getItem(Configuration.tokenLanguage)
      : options.languageId
  const categoryIds =
    options.categoryIds === undefined || options.categoryIds.length === 0
      ? ''
      : `categoryIds:[${options.categoryIds
          .map((val) => `"${val}"`)
          .join(',')}]`
  return getData.getDataGraphQl(`
                  {
                    ratingtransactions(param:{targetId: "${id}",keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                          ,languageId:"${languageId}",merchantId:"${localStorage.getItem(
    'merchantId'
  )}", ${categoryIds}})
                      {
                          totalCount,
                          message, 
                          success,
                          items{
                              id,
                              createdBy,
                              createdDate,
                              comment,
                              ratingId,
                              relationId,
                              score,
                              targetId,
                              relationName,
                              userName,
              
                          },
                          
                      }
                    }
                  `)
}

export const getRating = (id, options) => {
  if (options === undefined) options = {}
  if (options.merchantId === undefined)
    options.merchantId = localStorage.getItem('merchantId')
  if (options.languageId === undefined)
    options.languageId = Configuration.languageVi
  return getData.getDataGraphQl(`
    {
        rating(param:{id:"${id}",languageId:"${options.languageId}",merchantId:"${options.merchantId}"})
        {
            id,
            name,
            subDescription,
            createdDate,
            createdBy,
            maxScore,
            ratingTransactions {
                id,
                score
            }
        }
      }
    `)
}
