import React from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import moment from 'moment'

function ModalDetailUserPoint({ isOpen, toggle, dataModal }) {
  if (!dataModal.pointTransactions) {
    dataModal.pointTransactions = []
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{`Thành viên ${dataModal.userName}`}</ModalHeader>
      <ModalBody>
        <VerticalTimeline layout="1-column">
          {!dataModal.pointTransactions ||
          dataModal.pointTransactions.length === 0 ? (
            <p>Không có giao dịch nào</p>
          ) : (
            dataModal.pointTransactions.map((trans) => (
              <VerticalTimelineElement
                className="vertical-timeline-item"
                icon={
                  <i className="badge badge-dot badge-dot-xl badge-primary">
                    {' '}
                  </i>
                }
                date={moment(new Date(trans.createdDate)).format('DD/MM/YYYY')}
              >
                <h4 className="timeline-title">
                  Điểm :{' '}
                  <span
                    className={
                      parseInt(trans.point) > 0 ? 'text-success' : 'text-danger'
                    }
                  >
                    {parseInt(trans.point) > 0
                      ? `+${trans.point}`
                      : trans.point}
                  </span>
                </h4>
                <p>
                  {/* TargetId <a href="javascript:void(0);">{trans.targetId}</a> */}
                  <a href="javascript:void(0);">{trans.targetName}</a>
                </p>
              </VerticalTimelineElement>
            ))
          )}
        </VerticalTimeline>
      </ModalBody>
    </Modal>
  )
}

export default ModalDetailUserPoint
