import { AvField, AvGroup } from 'availity-reactstrap-validation'
import AvForm from 'availity-reactstrap-validation/lib/AvForm'
import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { Button, Card, CardBody, CardTitle, Col, Label, Row } from 'reactstrap'
import { AppContext } from '../../Layout/AppMain'
import CustomLoader from '../../Layout/Loading/CustomLoader'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import { getData } from '../../utils/data'
import Validation from '../../utils/validation'
import Header from '../CommonComponent/Header'
import moment from 'moment'

class EvoucherForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item: {
        name: '',
        price: '',
        datefrom: '',
        dateto: '',
      },
      optionsCategories: [],
      modalInfo: {
        show: false,
        message: '',
        type: 'error',
      },
      isSending: false,
    }
  }

  static contextType = AppContext

  // componentDidMount() {
  //   getData
  //     .getDataGraphQl(
  //       `
  //       {
  //           evoucher(param:{id:"${this.props.match.params.id}",
  //           languageId:"838aef56-78bb-11e6-b5a6-00155d582814",
  //           merchantId:"ce5cb104-e000-46f2-9dce-8019f38b3a5c",
  //           tokenUser:"75ab80fa-4524-4447-b387-827eb615f650-fe8f8e16-4655-caec-fd8e-6dd2bc07b228"})
  //           {
  //             id,
  //             name,
  //             value,
  //             availableFrom,
  //             availableTo
  //           }
  //       }
  //       `
  //     )
  //     .then((result) => {
  //       const dateFrom = new Date(result.evoucher.availableFrom)
  //       const dateTo = new Date(result.evoucher.availableTo)
  //       this.setState({
  //         article: {
  //           name: result.evoucher.name,
  //           price: result.evoucher.value,
  //           datefrom: dateFrom.toISOString().substr(0, 10),
  //           dateto: dateTo.toISOString().substr(0, 10),
  //         },
  //       })
  //     })
  // }

  onSubmidHandler = (event, value) => {
    const from = moment(new Date(value.datefrom))
    const to = moment(new Date(value.dateto))
    if (from.isAfter(to)) {
      return toast['error']('Ngày bắt đầu phải trước ngày kết thúc')
    } else if (to.isBefore(moment())) {
      return toast['error']('Ngày kết thúc phải sau hiện tại')
    }
    this.setState({
      isSending: true,
    })

    console.log(value)
    const body = {
      Id: this.props.id === undefined ? Common.guid() : this.props.id,
      MerchantId: localStorage.getItem('merchantId'),
      Name: value.name,
      Value: value.price,
      AvailableFrom: value.datefrom,
      AvailableTo: value.dateto,
      LanguageId: Configuration.languageVi,
      CreatedDate: Common.formatDateTime(new Date()),
      CreatedBy: localStorage.getItem('userId'),
    }

    const dataSend = {
      CommandName:
        this.props.match.params.id !== undefined
          ? 'UpdateInformationEVoucherVersion01'
          : 'CreateEVoucherVersion01',
      Domain: 'EVoucher',
      Content: JSON.stringify(body),
      TimeOutSecond: 7,
    }

    const api_url = Configuration.url_api + '/Command/SendSync'
    axios
      .post(api_url, dataSend)
      .then((response) => {
        if (response.data.Success)
          this.setState({
            modalInfo: {
              show: true,
              message: 'Thành công',
              responesMessage: response.data.Message,
              type: 'success',
            },
          })
        else
          this.setState({
            modalInfo: {
              show: true,
              message: 'Thất bại',
              responesMessage: response.data.Message,
              type: 'error',
            },
          })
      })
      .catch((err) => {
        this.setState({
          modalInfo: {
            show: true,
            message: 'Thất bại',
            responesMessage: err + '',
            type: 'error',
          },
        })
      })
      .finally(() => {
        this.setState({
          isSending: false,
        })
      })
  }

  render() {
    return (
      <CustomLoader
        isSending={this.state.isSending}
        modalInfo={this.state.modalInfo}
        redirectPath="#/evoucher/list"
      >
        <Header
          title="Evoucher"
          description="evoucher"
          iconClassName="pe-7s-safe"
        />
        <Row>
          <Col md="9">
            <Card className="main-card mb-3">
              <CardBody>
                {this.props.match.params.id ? (
                  <CardTitle>Cập nhật</CardTitle>
                ) : (
                  <CardTitle>Tạo mới</CardTitle>
                )}
                <Row>
                  <Col md="9">
                    <AvForm onValidSubmit={this.onSubmidHandler}>
                      <AvGroup>
                        <Label for="name">
                          Tên E-Voucher{' '}
                          <label style={{ color: 'red' }}>*</label>
                        </Label>
                        <AvField
                          className="form-control"
                          name="name"
                          id="name"
                          type="text"
                          value={this.state.item.name}
                          validate={Validation.notEmpty}
                        ></AvField>
                      </AvGroup>
                      <AvGroup>
                        <Label for="price">
                          Giá trị E-Voucher (Đơn vị VNĐ)
                          <label style={{ color: 'red' }}>*</label>
                        </Label>
                        <AvField
                          className="form-control"
                          name="price"
                          id="price"
                          type="number"
                          value={this.state.item.price}
                          validate={Validation.notEmpty}
                        ></AvField>
                      </AvGroup>
                      <AvGroup>
                        <Label for="datefrom">
                          Thời hạn sử dụng E-Voucher
                          <label style={{ color: 'red' }}>*</label>
                        </Label>

                        <AvField
                          className="form-control"
                          name="datefrom"
                          id="datefrom"
                          type="date"
                          value={this.state.item.datefrom}
                          validate={Validation.notEmpty}
                        ></AvField>
                      </AvGroup>
                      <AvGroup>
                        <Label for="dateto">
                          Đến ngày <label style={{ color: 'red' }}>*</label>
                        </Label>

                        <AvField
                          className="form-control"
                          name="dateto"
                          id="dateto"
                          type="date"
                          value={this.state.item.dateto}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Trường này không được để trống',
                            },
                          }}
                        ></AvField>
                      </AvGroup>
                      <AvGroup>
                        <Button size="lg" className="mt-3" color="primary">
                          Tạo mới E-Voucher
                        </Button>
                      </AvGroup>
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* { <Route path={`${this.props.match.url}/create`} component={EvoucherForm} /> } */}
        </Row>
      </CustomLoader>
    )
  }
}
export default EvoucherForm
