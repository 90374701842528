import AvField from 'availity-reactstrap-validation/lib/AvField'
import AvForm from 'availity-reactstrap-validation/lib/AvForm'
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup'
import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import Common from '../../utils/common'
import { postData } from '../../utils/data'
import FormWraper from '../CommonComponent/FormWraper'

export default class PromotionForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const getDataCommand = () => {}

    return (
      <FormWraper
        id={this.props.match.params.id}
        getDataFillForm={getDataCommand}
        render={(onSubmit, dataFetch) => (
          <Form
            onSubmit={onSubmit}
            dataFetch={dataFetch}
            idParam={this.props.match.params.id}
          />
        )}
      />
    )
  }
  state = {
    name: '',
    from: '',
    note: '',
    to: '',
    type: '',
    percent: '',
    amount: '',
    maxMoney: '',
    id: '',
    active: true,
  }
}

function Form({ onSubmit, dataFetch, idParam }) {
  const [data, setData] = useState({})
  const [type, setType] = useState('percent')

  useEffect(() => {
    setData(dataFetch)
  }, [dataFetch])

  const onTypeChanged = (event) => {
    setType(event.target.value)
  }

  const submitFormHandler = (event, value) => {
    console.log(value)
    // public class PluginValue
    // {
    //     public Guid Id => Guid.NewGuid();
    //     public Guid PluginId { get; set; }
    //     public string PluginName { get; set; }
    //     public string Data { get; set; }
    //     public int Priority { get; set; }
    // }

    var pluginConditions = []

    var pluginValueMinimumGrandTotal = {
      Id: Common.guid(),
      PluginId: 'f033a5b6-8810-4053-9934-68a19035c760',
      PluginName: 'Giá trị đơn hàng tối thiểu',
      Data: JSON.stringify({
        MinimumGrandTotal: value.min,
      }),
      Priority: 0,
    }
    pluginConditions.push(pluginValueMinimumGrandTotal)

    var pluginValuePeriodTime = {
      Id: Common.guid(),
      PluginId: 'f033a5b6-8810-4053-9934-68a19035c768',
      PluginName: 'Áp dụng trong khoảng thời gian',
      Data: JSON.stringify({
        From: value.from,
        To: value.to,
      }),
      Priority: 0,
    }
    pluginConditions.push(pluginValuePeriodTime)
    
    var pluginResults = []

    var temp = $("input[name='type']:checked").val()
    if (temp === 'amount') {
      var pluginResult = {
        Id: Common.guid(),
        PluginId: 'f033a5b6-8810-4053-9934-68a19035c763',
        PluginName: 'Giảm tiền',
        Data: JSON.stringify({
          Amount: value.amount,
        }),
        Priority: 0,
      }
      pluginResults.push(pluginResult)
    } else {
      var pluginResult = {
        Id: Common.guid(),
        PluginId: 'f033a5b6-8810-4053-9934-68a19035c767',
        PluginName: 'Giảm theo % và tối đa số tiền được giảm',
        Data: JSON.stringify({
          Percent: value.percent,
          MaxDiscountAmount: value.maxMoney,
        }),
        Priority: 0,
      }
      pluginResults.push(pluginResult)
    }
    const body = {
      Name: value.name,
      Note: value.note,
      CreatedDate: Common.formatDateTime(new Date()),
      CreatedBy: localStorage.getItem('userId'),
      PromotionType: 4,
      ConditionValues: pluginConditions,
      ResultValues: pluginResults,
      Priority: 0,
      MerchantId: localStorage.getItem('merchantId'),
      // Active : active,
      // Percent:value.percent,
      // Amount:value.amount,
      // Maxmoney:value.maxMoney,
      Id: Common.guid(),
    }

    postData.sendCommand(
      'Promotion',
      'CreatePromotion',

      body
    )
    window.location.href = '#/promotion/list'
  }

  return (
    <AvForm onValidSubmit={submitFormHandler}>
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <CardTitle>Promotion</CardTitle>
              <AvGroup>
                <Label>Tên</Label>
                <AvField className="form-control" type="text" name="name" />
              </AvGroup>
              <AvGroup>
                <Label>Ghi chú</Label>
                <AvField className="form-control" type="text" name="note" />
              </AvGroup>
              <AvGroup>
                <Label>Áp dụng đơn tối thiểu</Label>
                <AvField className="form-control" type="number" name="min" />
              </AvGroup>
              <AvGroup>
                <Label>Từ ngày</Label>
                <AvField className="form-control" type="date" name="from" />
              </AvGroup>
              <AvGroup>
                <Label>Đến ngày</Label>
                <AvField className="form-control" type="date" name="to" />
              </AvGroup>
              <AvGroup>
                <Label>
                  Giảm theo
                  <FormGroup
                    className="ml-5"
                    style={{
                      display: 'inline-block',
                      marginBottom: 0,
                    }}
                  >
                    <Label>
                      <Input
                        type="radio"
                        name="type"
                        value="percent"
                        checked={type === 'percent'}
                        onChange={onTypeChanged}
                      />
                      Phần trăm
                    </Label>
                  </FormGroup>
                  {/* Bai viet */}
                  <FormGroup
                    className="ml-5"
                    style={{
                      display: 'inline-block',
                      marginBottom: 0,
                    }}
                  >
                    <Label>
                      <Input
                        type="radio"
                        name="type"
                        value="amount"
                        checked={type === 'amount'}
                        onChange={onTypeChanged}
                      />
                      Số tiền
                    </Label>
                  </FormGroup>
                </Label>
                <div
                  style={{
                    display: type === 'percent' ? '' : 'none',
                  }}
                >
                  <AvField
                    className="form-control"
                    type="number"
                    name="percent"
                  />
                </div>
                <div
                  style={{
                    display: type === 'amount' ? '' : 'none',
                  }}
                >
                  <AvField
                    className="form-control"
                    type="number"
                    name="amount"
                  />
                </div>
              </AvGroup>
              <AvGroup
                style={{
                  display: type === 'percent' ? '' : 'none',
                }}
              >
                <Label>Tối đa</Label>
                <AvField
                  className="form-control"
                  type="number"
                  name="maxMoney"
                />
              </AvGroup>
              <div className="text-center">
                <Button color="success">Lưu</Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </AvForm>
  )
}
