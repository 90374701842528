import React from 'react'
import moment from "moment";
import { Col, Row } from 'reactstrap'
import Common from '../../../utils/common'
import Configuration from '../../../utils/configuration'
import ThemeLoadingSkeleton from '../../Settings/ThemeLoadingSkeleton'
import UploadImagePartial from './UploadImagePartial.1'
import './../../../Layout/Images/ImagesPicker.css'
function GridImage({
  images,
  setPickImages,
  pickImages,
  setImages,
  removePickImage,
}) {
  //const [images, setImages] = useState();
  return (
    <Row>
      <Col md="3" className={`mb-2`}>
        <UploadImagePartial
          // toggleTab={toggleTab}
          setImages={setImages}
          images={images}
        />
      </Col>

      {images ? (
        images.length > 0 ? (
          images.map((image, index) => {
            const isActive = pickImages.map((img) => img.id).includes(image.id)
            return (
              <Col md="3" className={`mb-2`}>
                <div
                  className={`${isActive ? 'img-active' : ''}`}
                  onClick={() => {
                    if (isActive) removePickImage(image)
                    else setPickImages(image)
                  }}
                  style={{
                    width: '200px',
                    boxShadow:
                      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    backgroundColor: 'white',
                    marginBottom: '25px',
                  }}
                >
                  <img
                    src={Common.cropImage(
                      Configuration.image_url + image.path,
                      268,
                      180
                    )}
                    alt={image.name}
                    style={{ width: '100%' }}
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '10px 20px',
                      wordWrap: 'break-word',
                      height: '75px',
                    }}
                  >
                    <p>{image.name.split("_")[1]}
                    <br/>
                    {image.categories != undefined && image.categories.map((category, i) => 
                          category.name
                          //{category.name}
                      )}
                      <br/>{moment(new Date(image.createdDate)).format("DD/MM/YYYY")}
                    </p>                   
                    
                  </div>
                </div>
              </Col>
            )
          })
        ) : (
          <p className="mx-auto d-block">Empty</p>
        )
      ) : (
        <ThemeLoadingSkeleton md="3" count={12} />
      )}
    </Row>
  )
}

export default GridImage
