import _ from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  Badge,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { createTicketArticle } from '../../Services/CrmService'
import { getOrder } from '../../Services/OrderService'
import Common from '../../utils/common'
import LoadingButton from '../CommonComponent/Button/LoadingButton'
import useLocalDataTable from '../CommonComponent/CustomHooks/useLocalDataTable'
import ReactTableList from '../CommonComponent/TableList/ReactTableList'

const statusOrder = {
  1: {
    name: 'Mới tạo',
    color: 'warning',
  },
  32: {
    name: 'Đang xử lý',
    color: 'info',
  },
  32768: {
    name: 'Thất bại',
    color: 'danger',
  },
  1048576: {
    name: 'Thành công',
    color: 'success',
  },
}
const columns = [
  {
    Header: 'Tên',
    accessor: 'targetName',
  },
  {
    Header: 'Giá',
    accessor: 'price',
    Cell: (props) => Common.formatMoney(parseInt(props.value), 0),
  },
  {
    Header: 'Số lượng',
    accessor: 'quantity',
  },
  {
    Header: 'Giảm',
    accessor: 'discountAmount',
  },
  {
    Header: 'Tổng',
    accessor: 'total',
    Cell: (props) => Common.formatMoney(parseInt(props.value), 0),
  },
]

function ModalInfoCardKanban({ isOpen, toggle, dataModal }) {
  const [order, setOrder] = useState(null)
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, setValue } = useForm()

  const [data, pageCount, fetchData, totalCount, setData] = useLocalDataTable()

  const user = useSelector((state) => state.System.user)

  const onSubmit = (values, e) => {
    setValue('subDescription', '')
    setLoading(true)
    const id = Common.guid()
    createTicketArticle({
      id: id,
      name: user.FullName,
      orderId: order.id,
      subDescription: values.subDescription,
    })
      .then((res) => {
        if (res.data.Success) {
          setOrder({
            ...order,
            tickets: [
              {
                id: id,
                createdName: user.FullName,
                subDescription: values.subDescription,
                createdDate: new Date(),
              },
              ...order.tickets,
            ],
          })
        } else toast['error'](res.data.Message)
      })
      .catch((err) => toast['error'](err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (dataModal.id)
      getOrder(dataModal.id, localStorage.getItem('merchantId')).then((res) => {
        setOrder(res.order)
        setTimeout(() => {
          setData(res.order.orderLines)
        }, 0)
      })
  }, [dataModal.id])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      {order ? (
        <Fragment>
          <ModalHeader>Đơn hàng #: {order.code}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <div className="card-shadow-warning border mb-3 card card-body border-warning mb-3">
                  <div className="card-title">Chi tiết đơn hàng</div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">
                      Đơn hàng #:
                    </label>
                    <div className="col-sm-7">
                      <b>{order.code}</b>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">
                      Khách hàng:
                    </label>
                    <div className="col-sm-7">
                      <b>
                        {order.userName}
                        <br />
                        {order.userMobile}
                        <br />
                        {order.userEmail}
                      </b>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">
                      Ngày tạo đơn hàng:
                    </label>
                    <div className="col-sm-7">
                      <b>
                        {moment(new Date(order.createdDate)).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </b>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">
                      Trạng thái đơn hàng:
                    </label>
                    <div className="col-sm-7">
                      {(function () {
                        const statuses = Common.allStatusToStatuses(
                          order.allStatus
                        )
                        return (
                          <Badge
                            className="mr-1"
                            color={_.get(
                              statusOrder[statuses[statuses.length - 1]],
                              'color'
                            )}
                          >
                            {_.get(
                              statusOrder[statuses[statuses.length - 1]],
                              'name'
                            )}
                          </Badge>
                        )
                      })()}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">
                      Tổng tiền:
                    </label>
                    <div className="col-sm-7">
                      <b>{Common.formatMoney(order.grandTotal, 0)}</b>
                    </div>
                  </div>
                </div>
                <ReactTableList
                  data={data}
                  columns={columns}
                  fetchData={fetchData}
                  pageCount={pageCount}
                  totalCount={totalCount}
                  hideFunction
                />
              </Col>
              <Col md="6">
                <CardTitle>Ticket</CardTitle>
                <hr />
                <div
                  style={{
                    maxHeight: '500px',
                    overflow: 'auto',
                    marginBottom: '10px',
                  }}
                >
                  <table className="table table-striped table-hover">
                    <tbody role="rowgroup">
                      {order.tickets
                        ? _.orderBy(order.tickets, ['createdDate'], 'desc').map(
                            (ticket, i) => (
                              <tr role="row">
                                <td role="cell">{ticket.subDescription}</td>
                                <td role="cell">{ticket.createdName}</td>
                                <td role="cell">
                                  <Moment fromNow>{ticket.createdDate}</Moment>
                                </td>
                              </tr>
                            )
                          )
                        : []}
                    </tbody>
                  </table>
                </div>

                <Row>
                  <Col md="10" className="pr-1">
                    <textarea
                      name="subDescription"
                      ref={register}
                      className="form-control"
                    />
                  </Col>
                  <Col md="2" className="pl-1">
                    <LoadingButton
                      className="btn btn-primary"
                      style={{
                        height: '100%',
                      }}
                      loading={loading}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Tạo ticket
                    </LoadingButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </Fragment>
      ) : (
        <Fragment>
          <ModalHeader>loading...</ModalHeader>
          <ModalBody>loading</ModalBody>
        </Fragment>
      )}
    </Modal>
  )
}

export default ModalInfoCardKanban
