import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
// import PageTitle from '../../Layout/AppMain/PageTitle';
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
// import ThemeOptions from '../../Layout/ThemeOptions'
import AppSidebar from '../../Layout/AppSidebar'
import DetailUserPoint from './DetailUserPoint'
import MemberList from './MemberList'
import MemberPointList from './MemberPointList'
import MemberPrivilege from './MemberPrivilege'
// import CategoryFormCreate from './Create/CategoryFormCreate';

class UserPoint extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  <Route
                    path={`${this.props.match.url}/list`}
                    component={MemberList}
                  />
                  <Route
                    path={`${this.props.match.url}/pointlist`}
                    component={MemberPointList}
                  />
                  <Route
                    path={`${this.props.match.url}/detail/:id`}
                    component={DetailUserPoint}
                  />
                   <Route
                    path={`${this.props.match.url}/data-privilege`}
                    component={MemberPrivilege}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default UserPoint
