import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import "./assets/base.scss";
import Main from "./CMS";
import GoogleLoginCallback from "./CMS/UserPages/Login/googleLoginCallback";
import configureStore from "./config/configureStore";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/google-login-callback"
            name="Google Login Page"
            render={(props) => <GoogleLoginCallback {...props} />}
          />
          <HashRouter path="/">
            <Component />
          </HashRouter>
        </Switch>
      </BrowserRouter>
    </Provider>,
    rootElement
  );
};
renderApp(Main);
if (module.hot) {
  module.hot.accept("./CMS", () => {
    const NextApp = require("./CMS").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
