import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Col, Form, FormGroup, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import Common from '../../../utils/common'
import { getData, postData } from '../../../utils/data'

function FooterActions({
  checkItems,
  setCheckItems,
  commands,
  categoryType,
  publishOrActive,
  load,
}) {
  const actions = {
    ADD_TO_CATEGORY: 'ADD_TO_CATEGORY',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
  }

  const [action, setAction] = useState(actions.ADD_TO_CATEGORY)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])

  const [pickCategories, setPickCategories] = useState([])

  useEffect(() => {
    getData.getCategories({ type: categoryType }).then((result) => {
      setCategories(result.categories.items)
    })
  }, [])

  const doAction = (event) => {
    event.preventDefault()
    const category = pickCategories[0]
    if (checkItems.length === 0)
      return toast['error']('Vui lòng chọn ít nhất một item')
    switch (action) {
      case actions.ADD_TO_CATEGORY:
        if (!category || category === '-')
          return toast['error']('Vui lòng chọn ít nhất một phân loại')
        const body = {
          Id: Common.guid(),
          ModifiedDate: new Date(),
          ModifiedBy: localStorage.getItem('userId'),
          UserIds: checkItems.map((item) => item.id),
          CategoryIds: [category],
        }
        setLoading(true)
        postData
          .sendCommand(
            commands.addCategory.domain,
            commands.addCategory.commandName,
            body
          )
          .then((result) => {
            Swal.fire(
              result.data.Success ? 'Thành công' : 'Thất bại',
              result.data.Message,
              result.data.Success ? 'success' : 'error'
            ).then(() => load())
          })
          .finally(() => {
            setLoading(false)
            setCheckItems([])
          })
        // .then((err) => {
        //     Swal.fire("Thất bại", err + "", "error");
        // });
        break
      case actions.DELETE_CATEGORY:
        console.log(checkItems)
        break

      default:
        break
    }
  }

  return (
    <div>
      <div className="app-footer">
        <div className="app-footer__inner">
          <div className="app-footer-left">
            <Fragment>
              {commands.changeStatus ? (
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true)
                    postData
                      .changeStatusCommand(
                        commands.changeStatus.domain,
                        commands.changeStatus.commandName,
                        checkItems.map((item) => {
                          const temp = {
                            Id: item.id,
                          }
                          console.log(
                            publishOrActive === 'active'
                              ? 'Activate'
                              : 'Publish',
                            item[
                              publishOrActive === 'active'
                                ? 'active'
                                : 'published'
                            ]
                          )
                          temp[
                            publishOrActive === 'active'
                              ? 'Activate'
                              : 'Publish'
                          ] =
                            item[
                              publishOrActive === 'active'
                                ? 'active'
                                : 'published'
                            ]
                          return temp
                        })
                      )
                      .finally(() => {
                        setLoading(false)
                        setCheckItems([])
                        load()
                      })
                  }}
                >
                  Chuyển trạng thái
                </Button>
              ) : null}
              <Button
                className="ml-2"
                color="danger"
                disabled={loading}
                onClick={() => {
                  setLoading(true)
                  postData
                    .deleteItemCommand(
                      commands.delete.domain,
                      commands.delete.commandName,
                      checkItems.map((item) => item.id)
                    )
                    .finally(() => {
                      setLoading(false)
                      setCheckItems([])
                      load()
                    })
                }}
              >
                Xóa
              </Button>
            </Fragment>
          </div>
          {commands.addCategory ? (
            <div className="app-footer-right">
              <Fragment>
                <Row>
                  <Col className="mr-0" md="12">
                    <Form onSubmit={doAction}>
                      <div className="form-inline">
                        <FormGroup>
                          <select
                            className="form-control ml-2"
                            value={action}
                            onChange={(event) => setAction(event.target.value)}
                          >
                            <option value={actions.ADD_TO_CATEGORY}>
                              Thêm vào phân loại
                            </option>
                            {/* <option
                                                value={
                                                    actions.DELETE_CATEGORY
                                                }
                                            >
                                                Xóa phân loại
                                            </option> */}
                          </select>
                          {action === actions.ADD_TO_CATEGORY ||
                          action === actions.DELETE_CATEGORY ? (
                            <select
                              name="category"
                              className="form-control ml-2"
                              value={pickCategories[0]}
                              onChange={(e) => {
                                setPickCategories([e.target.value])
                              }}
                            >
                              <option>-</option>
                              {categories.map((category, index) => (
                                <option key={index} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          ) : null}
                          <Button
                            className="ml-2"
                            color="primary"
                            disabled={loading}
                            // onClick={() => doAction()}
                          >
                            Action
                          </Button>
                        </FormGroup>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Fragment>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

FooterActions.propTypes = {
  checkItems: PropTypes.array.isRequired,
  categoryType: PropTypes.any.isRequired,
}

export default FooterActions
