import Common from "../utils/common";
import Configuration from "../utils/configuration";
import { postData } from "../utils/data";

const languageId = localStorage.getItem(Configuration.tokenLanguage);
const merchantId = localStorage.getItem(Configuration.merchantId);
const tokenUser = localStorage.getItem("tokenId");

export const getUsers = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.languageId === undefined) options.languageId = languageId;
  if (options.limit === undefined) options.limit = 100;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  const type = options.type === undefined ? "" : `type:${options.type}`;
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

  return Common.getDataGraphQl(`
        {
            users(param:{limit:${options.limit},offset:${options.offset},order:"${
    options.order
  }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", ${categoryIds}, ${type}})
            {
              items{
                id,
                name,
                email,
                mobile,   
                memberName   
                active,
                allType,          
                createdDate,
                modifiedDate,
                categories{
                  id,
                  name
                }
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const getSelectionUsers = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.languageId === undefined) options.languageId = languageId;
  if (options.limit === undefined) options.limit = 100;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  const type = options.type === undefined ? "" : `type:${options.type}`;
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

  return Common.getDataGraphQl(`
        {
          selectionusers(
            param:{
              limit:${options.limit},
              offset:${options.offset},
              order:"${options.order}",
              sort:"${options.sort}",
              merchantId:"${options.merchantId}", 
              keyword:"${options.keyword}", 
              languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", 
              ${categoryIds}, 
              ${type}})
          {
            items{
              id,
              name,
              email,
              mobile,   
              userName   
              active,
              allType,          
              createdDate,
              modifiedDate,
              categories{
                id,
                name
              }
            },
            totalCount,
            success,
            message
          }          
        }
        `);
};
export const getUserReport = (options) => {
  return Common.getDataGraphQl(`
        {
            userReport(param:{merchantId:"${merchantId}",id:"${
    options.id
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}"})
            {
              name
              id
              companyName
              avatar
              memberName
              mobile
              totalPoint
              email
              courses{
                name
                description
                published
                createdDate
              }
              categories{
                name
                published
                createdDate
                subDescription
              }
              events{
                startDateTime
                endDateTime
            type
                createdDate
                name
                id
                published
                images{
                  name
                  path
                }
                files{
                  name
                  path
                }
               

              }
              attributeValues{
                name
              }
            }
        }
        `);
};
export const getNoAdminAndUsers = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 100;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  const type = options.type === undefined ? "" : `type:${options.type}`;
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

  return Common.getDataGraphQl(`
        {
            nonaminanddefaultusers(param:{limit:${options.limit},offset:${options.offset},order:"${
    options.order
  }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}", ${categoryIds}, ${type}})
            {
              items{
                id,
                name,
                email,
                mobile,      
                active,
                allType,          
                createdDate,
                modifiedDate,
                categories{
                  id,
                  name
                }
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const getUsersSelection = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 100;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  // const type = options.type === undefined ? "" : `type:${options.type}`;
  // const categoryIds =
  //     options.categoryIds === undefined ||
  //     options.categoryIds === null ||
  //     options.categoryIds.length === 0
  //         ? ""
  //         : `categoryIds:[${options.categoryIds
  //               .map((val) => `"${val}"`)
  //               .join(",")}]`;

  return Common.getDataGraphQl(`
        {
          selectionusers(param:{limit:${options.limit},offset:${options.offset},order:"${
    options.order
  }",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${
    options.keyword
  }", languageId:"${localStorage.getItem(Configuration.tokenLanguage)}"})
            {
              items{
                id,
                name,
                email,
                mobile,                
                createdDate,
                modifiedDate,
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const getUser = (id, merchantId) => {
  return Common.getDataGraphQl(
    `
{
    user(param:{id: "${id}",languageId:"${Configuration.languageVi}", merchantId:"${merchantId}"}){
        active,
        categories {
          id,
          name
        },
        createdBy,
        createdDate,
        email,
        id,
        allType,
        images {
          id,
          path,
          displayOrder,
          isFeatured
        },
        description,
        subDescription,
        merchantId,
        mobile,
        modifiedBy,
        modifiedDate,
        name,
        userName,
    }
  }
  
`
  );
};

export const getUserCertificate = (userId) => {
  return Common.getDataGraphQl(`
        {
          articleusercertificates(param:{tokenUser:"${tokenUser}" targetId:"${userId}",merchantId:"${merchantId}", languageId:"${languageId}"})
            {
              items{
                files {
                  name
                  path
                  id
                  __typename
                }
                id
                name              
                description
                subDescription
                message
                createdDate
          
              },
              totalCount,
              success,
              message
            }
        }
        `);
};

export const createuser = ({
  id,
  name,
  password,
  email,
  mobile,
  subDescription = "",
  description = "",
  type = Configuration.UserTypes.DEFAULT,
  categories = [],
  images = [],
}) => {
  if (!id) id = Common.guid();

  return postData.sendCommand("User", "CreateCustomer", {
    Id: id,
    MerchantId: localStorage.getItem("merchantId"),
    Name: name,
    Password: password,
    LanguageId: languageId,
    Email: email,
    Mobile: mobile,
    SubDescription: subDescription,
    Description: description,
    AllType: type,
    Categories: categories,
    Images: images,
    CreatedDate: Common.formatDateTime(new Date()),
    CreatedBy: localStorage.getItem("userId"),
  });
};

export const createUpdateUserWithAttribute = (commandName, body) => {
  if (commandName === "CreateCustomerWithAttributes") {
    return postData.sendCommand("User", commandName, {
      Id: body.id,
      MerchantId: localStorage.getItem("merchantId"),
      Name: body.name,
      Password: body.password,
      LanguageId: body.languageId,
      Email: body.email,
      Mobile: body.mobile,
      SubDescription: body.subDescription,
      Description: body.description,
      AllType: body.type,
      Categories: body.categories,
      Images: body.images,
      AttributeValues: body.attributeValues,
      CreatedDate: Common.formatDateTime(new Date()),
      CreatedBy: localStorage.getItem("userId"),
    });
  }
  return postData.sendCommand("User", commandName, {
    Id: body.id,
    MerchantId: localStorage.getItem("merchantId"),
    Name: body.name,
    LanguageId: body.languageId,
    Email: body.email,
    Mobile: body.mobile,
    AllType: body.type,
    SubDescription: body.subDescription,
    Description: body.description,
    Categories: body.categories,
    Images: body.images,
    AttributeValues: body.attributeValues,
    CreatedDate: Common.formatDateTime(new Date()),
    CreatedBy: localStorage.getItem("userId"),
  });
};

export const removeUsersFromCategory = (categoryId, userIds) => {
  return postData.sendCommand("User", "RemoveUsersFromCategory", {
    Id: Common.guid(),
    UserIds: userIds,
    CategoryId: categoryId,
    ModifiedDate: Common.getCurrentDate(),
    ModifiedBy: localStorage.getItem("userId"),
  });
};
