import Axios from 'axios'
import Common from '../utils/common'
import Configuration from '../utils/configuration'
import { getData } from '../utils/data'
import Swal from 'sweetalert2'

const userId = localStorage.getItem('userId')
const api_url = Configuration.url_api + '/Command/SendSync'

export const publishCourse = (id) => {
  const body = {
    Id: Common.guid(),
    ChangeStatuses: [{ Id: id, Publish: false }],
    ModifiedBy: localStorage.getItem('userId'),
    ModifiedDate: Common.formatDateTime(new Date()),
  }
  const dataSend = {
    CommandName: 'ChangeStatusPublishCourse',
    Domain: 'Sale',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }

  return Axios.post(api_url, dataSend).then((res) => {
    console.log(res)
    if (res.data.Success) {
      Swal.fire('Phát hành thành công')
    } else {
      Swal.fire('Lỗi phát hành')
    }
  })
}

export const addRelationsCourseLesson = (body) => {
  const dataSend = {
    CommandName: 'AddRelationsCourseLesson',
    Domain: 'CourseLesson',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const removeRelationsCourseLesson = (body) => {
  const dataSend = {
    CommandName: 'RemoveRelationsCourseLesson',
    Domain: 'CourseLesson',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const updateDisplayOrderRelationsCourseLesson = (body) => {
  const dataSend = {
    CommandName: 'UpdateDisplayOrderRelationsCourseLesson',
    Domain: 'CourseLesson',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const updateDisplayOrderCourseLessons = (ids) => {
  //lessons.map(e => e.id)
  const body = {
    Id: Common.guid(),
    Ids: ids,
    ModifiedDate: new Date(),
    ModifiedBy: userId,
  }

  const dataSend = {
    CommandName: 'UpdateDisplayOrderCourseLessons',
    Domain: 'CourseLesson',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  Axios.post(api_url, dataSend)
}

export const updateCourse = (body) => {
  const dataSend = {
    CommandName: 'UpdateInformationCourse',
    Domain: 'Course',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const createCourse = (body) => {
  const dataSend = {
    CommandName: 'CreateCourse',
    Domain: 'Course',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}
export const updateImagesCourse = (body) => {
  const dataSend = {
    CommandName: 'UpdateImagesCourse',
    Domain: 'Course',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const updateCategoriesCourse = (body) => {
  const dataSend = {
    CommandName: 'UpdateCategoriesCourse',
    Domain: 'Course',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const deleteCourse = (body) => {
  const dataSend = {
    CommandName: 'DeleteCourseProduct',
    Domain: 'Sale',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const changeStatusCourses = (body) => {
  const dataSend = {
    CommandName: 'ChangeStatusPublishCourse',
    Domain: 'Sale',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const createCourseLesson = (body) => {
  const dataSend = {
    CommandName: 'CreateCourseLesson',
    Domain: 'CustomerRelationshipManagement',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const updateCourseLesson = (body) => {
  const dataSend = {
    CommandName: 'UpdateCourseLesson',
    Domain: 'CourseLesson',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const changeStatusPublishCourseLesson = (body) => {
  const dataSend = {
    CommandName: 'ChangeStatusPublishCourseLesson',
    Domain: 'CourseLesson',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const deleteCourseLessons = (body) => {
  const dataSend = {
    CommandName: 'DeleteCourseLessons',
    Domain: 'CourseLesson',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const getCourses = (options = {}) => {
  const limit = options.limit === undefined ? 100000 : options.limit
  const offset = options.offset === undefined ? 0 : options.offset
  const order = options.order === undefined ? 'desc' : options.order
  const sort = options.sort === undefined ? 'createdDate' : options.sort
  const keyword = options.keyword === undefined ? '' : options.keyword
  const languageId =
    options.languageId === undefined
      ? localStorage.getItem(Configuration.tokenLanguage)
      : options.languageId
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ''
      : `categoryIds:[${options.categoryIds
          .map((val) => `"${val}"`)
          .join(',')}]`
  return getData.getDataGraphQl(`
                  {
                    tempcourses(param:{keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                          ,languageId:"${languageId}",merchantId:"${localStorage.getItem(
    'merchantId'
  )}", ${categoryIds}})
                      {
                          totalCount,
                          message, 
                          success,
                          items{
                            product{
                              id,
                              name,
                              price,
                              categories{
                                id,
                                name,
                              }
                            }
                              id,
                              studentQuantity,
                              name,
                              countLesson,
                              courseLessons {
                                  id,
                                  conditionValue,
                                  courseId,
                                  createdDate,
                                  createdBy,
                                  description,
                                  displayOrder,
                                  duration,
                                  name,
                                  published,
                                  subDescription
                              },
                              createdBy,
                              createdDate,
                              images {
                                  id,
                                  path
                              },
                              categories {
                                  id,
                                  name
                              },
                              id,
                              published,
                              subDescription,
                              type
              
                          },
                          
                      }
                    }
                  `)
}

export const getCourse = (id, languageId) => {
  return getData.getDataGraphQl(`
        {course(param:{id: "${id}", languageId:"${
    languageId ? languageId : localStorage.getItem(Configuration.tokenLanguage)
  }",
         merchantId: "${localStorage.getItem('merchantId')}"})
        {
            courseLessons {
                id,
                conditionValue,
                courseId,
                displayOrder,
                duration,
                name,
                published,
                subDescription
               
              },
            images {
                id,
                path,
                isFeatured
            },
            categories {
                id,
                name
            },
            numberOfTimesAllowed
            description,
            subDescription,
            createdBy,
            createdDate,
            id,
            name,
            period,
            published,
            type,
            product{
                id,
                sku
            }
            quizs {
              name
              id
            }
            conditionValue
            template {
              id
            }
        }
    }
    `)
}

export const getLessons = (options = {}) => {
  const limit = options.limit === undefined ? 100000 : options.limit

  const offset = options.offset === undefined ? 0 : options.offset
  const order = options.order === undefined ? 'desc' : options.order
  const sort = options.sort === undefined ? 'createdDate' : options.sort
  const keyword = options.keyword === undefined ? '' : options.keyword
  const languageId =
    options.languageId === undefined
      ? localStorage.getItem(Configuration.tokenLanguage)
      : options.languageId
  const targetId =
    options.targetId === undefined ? '' : `targetId:"${options.targetId}"`
  return getData.getDataGraphQl(`
                  {
                    courselessons(param:{keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                          ,languageId:"${languageId}",merchantId:"${localStorage.getItem(
    'merchantId'
  )}", ${targetId}})
                      {
                          totalCount,
                          message, 
                          success,
                          items{
                              id,
                              courseId,
                              name,
                              createdBy,
                              createdDate,
                              courseId,
                              courseName,
                              description,
                              displayOrder,
                              published,
                              subDescription,
                              images {
                                  id,
                                  path
                              },
                              duration,
                              conditionValue
                          },
                          
                      }
                    }
                  `)
}

export const getLesson = (id, languageId) => {
  return getData.getDataGraphQl(`
        {courselesson(param:{id: "${id}", languageId:"${
    languageId ? languageId : localStorage.getItem(Configuration.tokenLanguage)
  }",
         merchantId: "${localStorage.getItem('merchantId')}"})
        {
            books {
                id,
                name,
                files {
                  id,
                  name,
                  path
                }
              },
            images {
                id,
                path,
                isFeatured
            },
            quizs {
                id,
                name,
              
            },
            slides {
                id,
                name,
              files {
                id,
                name,
                path
              }
            },
            videos {
                id,
                name,
            },
          	articles {
          	  id,
              name
            },
            videoQuizs{
              id,
              name
            }
            relations{
                relationId,
                relationName,
                relationEntity,
                displayOrder,
            }
            duration,
            displayOrder,
            courseId,
            conditionValue,
            description,
            subDescription,
            createdBy,
            createdDate,
            id,
            name,
        }
    }
    `)
}
