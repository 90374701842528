import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
// import PageTitle from '../../Layout/AppMain/PageTitle';
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
// import ThemeOptions from '../../Layout/ThemeOptions'
import AppSidebar from '../../Layout/AppSidebar'
import WarehouseDetail from './WarehouseForm'
import WarehouseList from './WarehouseList'

class Warehouse extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  <Route
                    path={`${this.props.match.url}/list`}
                    component={WarehouseList}
                  />
                  <Route
                    path={`${this.props.match.url}/detail/:id`}
                    component={WarehouseDetail}
                  />
                  <Route
                    path={`${this.props.match.url}/create`}
                    component={WarehouseDetail}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Warehouse
