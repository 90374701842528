import React from "react"
import Ionicon from "react-ionicons"
import { useSelector } from "react-redux"
import Tabs from "react-responsive-tabs"
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap"
import city3 from "../../assets/utils/images/dropdown-header/city3.jpg"
import SysErrEx from "../../Layout/AppFooter/Components/TabsContent/SystemExample"
import Message from "./Mesage"

//import Message from '../../Layout/'

const tabsContent = [
  {
    title: "Thông báo",
    content: <Message />,
  },
  // ,
  // {
  //     title: 'Sự kiện',
  //     content: <TimelineEx />
  // }
  {
    title: "Hệ thống",
    content: <SysErrEx />,
  },
]

function getTabs() {
  return tabsContent.map((tab, index) => ({
    title: tab.title,
    getContent: () => tab.content,
    key: index,
  }))
}

function Index() {
  const notification = useSelector((state) => state.Notification.notifications)
  return (
    <UncontrolledDropdown>
      <DropdownToggle className="p-0 mr-2" color="link">
        <div className="icon-wrapper icon-wrapper-alt rounded-circle">
          <div className="icon-wrapper-bg bg-danger" />
          <Ionicon beat={true} color="#d92550" fontSize="23px" icon="md-notifications-outline" />
          <div className="badge badge-dot badge-dot-sm badge-danger">Thông báo</div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-xl rm-pointers">
        <div className="dropdown-menu-header mb-0">
          <div className="dropdown-menu-header-inner bg-deep-blue">
            <div
              className="menu-header-image opacity-1"
              style={{
                backgroundImage: "url(" + city3 + ")",
              }}
            />
            <div className="menu-header-content text-dark">
              <h5 className="menu-header-title">Thông báo</h5>
              <h6 className="menu-header-subtitle">
                Bạn có <b>{notification != null && notification.totalCount}</b> tin nhắn chưa đọc
              </h6>
            </div>
          </div>
        </div>
        <Tabs
          tabsWrapperClass="body-tabs body-tabs-alt"
          transform={false}
          showInkBar={true}
          items={getTabs()}
        />
        <Nav vertical>
          <NavItem className="nav-item-divider" />
          <NavItem className="nav-item-btn text-center">
            <Button size="sm" className="btn-shadow btn-wide btn-pill" color="focus">
              View Latest Changes
            </Button>
          </NavItem>
        </Nav>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default Index
