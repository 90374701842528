import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import { createOrderCardProductSale } from '../../Services/OrderService'
import { getUsers } from '../../Services/UserService'
import Common from '../../utils/common'
import LoadingButton from '../CommonComponent/Button/LoadingButton'

function ModalCreateOrderProductMember({ isOpen, toggle, dataModal }) {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  const { handleSubmit, control } = useForm()

  useEffect(() => {
    getUsers({
      limit: 100000,
    }).then(
      (res) => {
        var temp = []
        res.users.items.map((item, i) =>
          temp.push({
            value: item.id,
            label: item.name + ' - ' + item.mobile,
          })
        )
        //console.log(temp);
        setUsers(temp)
      }
      //    {
      //        users.map((user) => (
      //            <option value={user.id}>
      //                {user.name}-{user.mobile}
      //            </option>
      //        ));
      //    }
      //setUsers(res.users.items)
    )
    return () => {}
  }, [])

  const onSubmit = (values) => {
    Swal.fire({
      title: 'Cảnh báo',
      text: 'Bạn có chắc chắn muốn tạo đơn hàng',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'hủy',
    }).then(({ value }) => {
      if (value) {
        // const body = {
        //     Id: Common.guid(),
        //     UserId: ,
        //     MerchantId: merchantId,
        //     ProductId: dataModal.id,
        //     CreatedDate: Common.getCurrentDate(),
        //     CreatedBy: localStorage.getItem("userId"),
        // };

        setLoading(true)
        createOrderCardProductSale({
          customerId: values.userId.value,
          productId: dataModal.id,
        })
          .then(Common.handleResponse)
          .catch(Common.handleError)
          .finally(() => {
            setLoading(false)
            toggle()
          })
      }
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Tạo đơn hàng</ModalHeader>
      <ModalBody>
        <Controller
          as={Select}
          control={control}
          name="userId"
          //className="form-control"
          onChange={([selected]) => {
            // React Select return object instead of value for selection
            return { value: selected }
          }}
          options={users}
        />
      </ModalBody>
      <ModalFooter>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          className="btn btn-success"
        >
          Gửi
        </LoadingButton>
      </ModalFooter>
    </Modal>
  )
}

export default ModalCreateOrderProductMember
