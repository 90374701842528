import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import arrayMove from 'array-move'
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import Ionicon from 'react-ionicons'
import Loader from 'react-loaders'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import PickSortableListModal from './PickListModal'

function PickSortablePartial({ items = [], updateItems, title, apiCommand }) {
  const [isSending, setIsSending] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    return () => {
      updateItems([])
    }
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateItems([...arrayMove(items, oldIndex, newIndex)])
  }

  return (
    <BlockUi
      tag="div"
      blocking={isSending}
      className="block-overlay-dark"
      loader={<Loader color="#ffffff" active />}
    >
      <PickSortableListModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        checkItems={items}
        setCheckItems={updateItems}
        apiCommand={apiCommand}
      />
      <Card className="mb-2">
        <CardHeader>
          {title}
          <div className="btn-actions-pane-right actions-icon-btn">
            <Button
              className="btn-icon btn-icon-only"
              color="link"
              onClick={() => setIsOpen(!isOpen)}
            >
              <i className="pe-7s-cloud-download btn-icon-wrapper" />
            </Button>
          </div>
        </CardHeader>
        <CardBody
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
          }}
        >
          <SortablePartialContainer
            items={items}
            updateItems={updateItems}
            onSortEnd={onSortEnd}
            pressDelay={100}
          />
        </CardBody>
      </Card>
    </BlockUi>
  )
}

export const SortablePartialContainer = SortableContainer(
  ({ items, updateItems, deleteCallback }) => (
    <ListGroup>
      {items != undefined && items.length > 0 ? (
        items.map((value, index) => (
          <SortablePartialItem
            key={`item-${value.id}`}
            index={index}
            value={value}
            items={items}
            updateItems={updateItems}
            deleteCallback={deleteCallback}
          />
        ))
      ) : (
        <p className="mb-0">Trống</p>
      )}
    </ListGroup>
  )
)

const SortablePartialItem = SortableElement(
  ({ value, items, updateItems, deleteCallback }) => (
    <Items
      value={value}
      items={items}
      updateItems={updateItems}
      deleteCallback={deleteCallback}
    />
  )
)

const Items = ({ value, items, updateItems, deleteCallback }) => {
  console.log(value)
  const deleteFileHandler = (id) => {
    if (updateItems != undefined) {
      updateItems(items.filter((item) => item.id !== id))
    }
    if (deleteCallback != undefined) {
      deleteCallback(id)
    }
  }
  var icon = ''
  if (value.type != undefined) {
    switch (value.type) {
      case 1:
        icon = 'lnr-chart-bars'
        break
      case 2:
        icon = 'lnr-film-play'
        break
      case 3:
        icon = 'lnr-question-circle'
        break
      case 0:
        icon = 'lnr-text-format'
        break
      case 4:
        icon = 'lnr-camera-video'
        break
    }
  }

  return (
    <ListGroupItem
      key={value.id}
      style={{
        zIndex: 8,
      }}
    >
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left">
            <div className="widget-heading">
              <div
                size="sm"
                className="btn-icon btn-icon-only"
                color="light"
                id={'PopoverCustomT-1'}
                style={{
                  cursor: 'move',
                }}
                // onClick={this.togglePop1}
              >
                <Ionicon
                  color="#484d52"
                  icon="ios-keypad-outline"
                  className="mr-2"
                />
                <i className={icon} style={{ fontSize: '18px' }}>
                  {' '}
                </i>

                {value.name}
              </div>
            </div>
          </div>
          <div className="widget-content-right">
            <Button
              className="border-0 btn-transition"
              outline
              color="danger"
              onClick={() => deleteFileHandler(value.id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        </div>
      </div>
    </ListGroupItem>
  )
}

export default PickSortablePartial
