import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  active,
  deactive,
  genCodes,
  getPromotions,
  sendGiftCodeToUsers,
} from '../Services/PromotionService'
import { FAILURE, REQUEST, SUCCESS } from './ActionType'

export const PROMOTION = {
  GET_PROMOTIONS: 'promotion/GET_PROMOTIONS',
  ACTIVE: 'promotion/ACTIVE',
  DEACTIVE: 'promotion/DEACTIVE',
  GEN_CODES: 'promotion/GEN_CODES',
  SEND_GIFTCODES_TO_USERS: 'promotion/SEND_GIFTCODES_TO_USERS',
}

export const getPromotionsAction = (option) => ({
  type: PROMOTION.GET_PROMOTIONS,
  payload: getPromotions(option),
})

export const activeAction = (id) => ({
  type: PROMOTION.ACTIVE,
  id: id,
})

export const deactiveAction = (id) => ({
  type: PROMOTION.DEACTIVE,
  id: id,
})

export const genCodesAction = (value) => ({
  type: PROMOTION.GEN_CODES,
  payload: genCodes(value),
})

export const sendGiftCodeToUsersAction = (value) => ({
  type: PROMOTION.SEND_GIFTCODES_TO_USERS,
  payload: sendGiftCodeToUsers(value),
})

const initialState = {
  promotions: [],
}
export const reducer = persistReducer(
  {
    storage,
    key: 'promotion',
    blacklist: ['promotions', 'isLoading'],
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case PROMOTION.ACTIVE:
        active(action.id)
        return {
          ...state,
          promotions: {
            ...state.promotions,
            items: state.promotions.items.map((item) => {
              if (item.id === action.id) {
                return {
                  ...item,
                  active: true,
                }
              }
              return item
            }),
          },
        }
      case PROMOTION.DEACTIVE:
        deactive(action.id)
        return {
          ...state,
          promotions: {
            ...state.promotions,
            items: state.promotions.items.map((item) => {
              if (item.id === action.id) {
                return {
                  ...item,
                  active: false,
                }
              }
              return item
            }),
          },
        }

      case REQUEST(PROMOTION.GET_PROMOTIONS):
        return {
          ...state,

          isLoading: true,
        }
      case FAILURE(PROMOTION.GET_PROMOTIONS):
        return {
          ...state,

          isLoading: false,
        }
      case SUCCESS(PROMOTION.GET_PROMOTIONS):
        return {
          ...state,

          promotions: action.payload.promotions,
          isLoading: false,
        }

      default:
        return state
    }
  }
)
