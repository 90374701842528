import React, { Fragment } from "react";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";

const NotPermittedPage = () => {
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className={"row"}>
              <div className={"col-md-3"}></div>
              <div className={"col-md-6 text-center"}>
                <img src={process.env.PUBLIC_URL + "/404.svg"} className={"w-100"} />
                <h1>401 Not permitted</h1>
                <button
                  className={"btn btn-primary"}
                  onClick={() => {
                    window.history.go(-1);
                  }}
                >
                  Trở lại
                </button>
              </div>
              <div className={"col-md-3"}></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotPermittedPage;
