import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Select from 'react-select'
import { getData } from "../../utils/data";
import { Controller, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import Common from '../../utils/common'
import Swal from "sweetalert2";
import Configuration from "../../utils/configuration";
import SelectServerSide from '../CommonComponent/SelectServerSide2'
import { getProducts } from '../../Services/ProductService'

function ModalUpdateQuota({
  isOpen, 
  toggle,
  modalId, 
  modalValue, 
  setModalValue, 
  quotaQuantities, 
  setQuotaQuantities,
  setRefetch}) {
  const { register, errors, handleSubmit, setValue, control } = useForm()
  // const [evoucherList, setEvoucherList] = useState(null)
  // useEffect(() => {   
   
  //    getProducts({
  //       type: Configuration.ProductTypes.EVoucher
  //     }).then(
  //       (res) => {
  //         var temp = []
  //         res.products.items.map((item, i) =>
  //           temp.push({
  //             value: item.id,
  //             label: item.name,
  //           })
  //         )
  //         //console.log(temp, "88");
  //         setEvoucherList(temp)
  //       }
  //     )
    
  // }, []);
  const onSubmitModal = (values) => {
    const {unPaidQuota} = values
    // const idValue = (dataModal && Object.keys(dataModal).length > 0) ? dataModal.Id : Common.guid()
    // const paid = (dataModal && Object.keys(dataModal).length > 0) ? dataModal.Paid : false
    // const sold = (dataModal && Object.keys(dataModal).length > 0) ? dataModal.Sold : false
    const data = {
      Id: modalId,
      Value: unPaidQuota,
      QuotaQuantities: quotaQuantities,
      ActionBy: localStorage.getItem(Configuration.userId)
    } 
    console.log(data, "submit data")
    
    Common.sendSyncCommand(
      "UpdateQuotaCategory",
      "category",
      data,
      () => {
        Swal.fire("Thông báo!", "Lưu hạn mức thành công", "success");
        setRefetch(true)
      },
      () => {
        Swal.fire("Thông báo!", "Lưu hạn mức thất bại", "error");
      }
    );
    toggle()
    setQuotaQuantities([])
    setRefetch(false)
  }
  const handleFormChange = (index, event) => {
    let data = [...quotaQuantities];
    data[index][event.target.name] = event.target.value;
    setQuotaQuantities(data);
    console.log(errors, "error data")
  }
  const handleSelectFormChange = (index, event) => {
    let data = [...quotaQuantities];
    data[index]['TargetId'] = event.value;
    data[index]['Name'] = event.label;
    setQuotaQuantities(data);
  }
  const addFields = () => {
    let newfield = {TargetId: '', Quantity: ''}
    setQuotaQuantities([...quotaQuantities, newfield])
  }
  const removeFields = (index) => {
    let data = [...quotaQuantities];
    data.splice(index, 1)
    setQuotaQuantities(data)
  }

  return (
    <Modal size="lg" isOpen={isOpen} toggle={() => toggle(null)}>
      <ModalHeader>Cài đặt hạn mức</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>
            Hạn mức công nợ (VNĐ) <span className="red">*</span>
          </Label>
          <input
            onWheel={(e) => e.target.blur()}
            min={0}
            ref={register({
              required: 'Trường bắt buộc',
              min: {
                value: 0,
                message: 'Giá trị không được âm',
              },
            })}
            // onchange={setPrice}
            minLength="0"
            value={modalValue}
            type="number"
            name="unPaidQuota"
            className="form-control"
            onChange={(e) => setModalValue(e.target.value)}
          />
          {errors.unPaidQuota ? (
            <FormFeedback className="d-block">
              {errors.unPaidQuota.message}
            </FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>
            Quyền truy cập evoucher
          </Label>
          {quotaQuantities.map((input, index) => {
            
            return (
            <Row justify="space-between" className="mb-3" key={index}>
              <Col xs="7">
                {/* <Select
                  isMulti={false}
                  name='TargetId'
                  options={
                    evoucherList &&
                    evoucherList.map((val) => ({
                      label: val.name,
                      value: val.id,
                    }))
                  }
                  value={input.TargetId}
                  onChange={(event) => handleSelectFormChange(index, event)}
                />*/}                
                <SelectServerSide                  
                  name='TargetId'
                  value={input.TargetId ? [{value: input.TargetId, label: input.Name}] : []}
                  onChange={(event) => handleSelectFormChange(index, event)}
                  isMulti={false}
                  loadOptions={(options) =>
                    getData.getProducts({
                      type: Configuration.ProductTypes.EVoucher,
                      ...options,
                    })
                  }
                  className="form-control"
                  /> 
                {/* <input
                  name='TargetId'
                  className="form-control"
                  placeholder='Sp Voucher'
                  value={input.TargetId}
                  onChange={event => handleFormChange(index, event)}
                /> */}
              </Col>
              <Col xs="3">              
              <input
                onWheel={(e) => e.target.blur()}
                min={0}
                ref={register({
                  required: 'Trường bắt buộc',
                  min: {
                    value: 0,
                    message: 'Giá trị không được âm',
                  },
                })}
                name='Quantity'
                type="number"
                className="form-control"
                placeholder='Số lượng'
                value={input.Quantity}
                onChange={event => handleFormChange(index, event)}
              />
              {errors.Quantity ? ( 
                <FormFeedback className="d-block">
                  {errors.Quantity.message}
                </FormFeedback>
              ) : null}
              </Col>
              <Col xs="2" className="m-auto"> 
              <Button
                className="border-0 btn-transition"
                outline
                color="danger"
                onClick={() => removeFields(index)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
              </Col>
            </Row>
            )
          }
        )}  
        </FormGroup>
        <button className="mb-2 mr-2 mt-2 btn-icon btn btn-success" onClick={addFields}>
          <i className="pe-7s-plus btn-icon-wrapper" >{" "}</i>
          Thêm quyền
        </button>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit(onSubmitModal)}>
          Lưu
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalUpdateQuota
