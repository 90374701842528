import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import EvoucherByMem from './EvoucherByMem'
import EvoucherCodeUsed from './EvoucherCodeUsed'
// import EmailList from './EmailList';
import EvoucherForm from './EvoucherForm'
import EvoucherList from './EvoucherList'
import EvoucherSold from './EvoucherSold'
import ExportCode from './ExportCode'
import GenerateEVoucherCodes from './GenerateEVoucherCodes'
import ImportEvouchercode from './ImportEVoucherCodes'
import UseEVoucherCodes from './UseEVoucherCodes'

class Evoucher extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  <Route
                    path={`${this.props.match.url}/list`}
                    component={EvoucherList}
                  />
                  <Route
                    path={`${this.props.match.url}/create`}
                    component={EvoucherForm}
                  />
                  <Route
                    path={`${this.props.match.url}/edit/:id`}
                    component={EvoucherForm}
                  />
                  <Route
                    path={`${this.props.match.url}/generate-code/:id`}
                    component={GenerateEVoucherCodes}
                  />
                  <Route
                    path={`${this.props.match.url}/importevouchercode/:id`}
                    component={ImportEvouchercode}
                  />
                  <Route
                    path={`${this.props.match.url}/export-code/:id`}
                    component={ExportCode}
                  />
                  <Route
                    path={`${this.props.match.url}/useevoucher`}
                    component={UseEVoucherCodes}
                  />
                  <Route
                    path={`${this.props.match.url}/evoucherused`}
                    component={EvoucherCodeUsed}
                  />
                  <Route
                    path={`${this.props.match.url}/evouchersold`}
                    component={EvoucherSold}
                  />
                  <Route
                    path={`${this.props.match.url}/evoucherbymem`}
                    component={EvoucherByMem}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Evoucher
