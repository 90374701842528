import Common from '../../../utils/common'
import Configuration from '../../../utils/configuration'

export default class LaboUploadAdapter {
  constructor(loader) {
    this.loader = loader
  }
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest()
          this._initListeners(resolve, reject, file)
          this._sendRequest(file)
        })
    )
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest())

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open('POST', Configuration.image_upload_url, true)
    xhr.responseType = 'json'
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr
    const loader = this.loader
    const genericErrorText = `Couldn't upload file: ${file.name}.`

    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const response = xhr.response

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || !response.Success) {
        return reject(response.Message)
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      const image_url = Configuration.image_url + response.Data[0].Path
      resolve({
        default: image_url,
        800: Common.cropImage(image_url, 800, 800),
        1024: Common.cropImage(image_url, 1024, 1024),
        1920: Common.cropImage(image_url, 1920, 1920),
      })
    })

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
        return
      })
    }
  }

  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData()

    data.append('token', localStorage.getItem(Configuration.userCookie))
    data.append('UploadedImage', file)

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data)
  }
  // upload(a) {

  //     return this.loader.file.then((file) => {
  //         const formData = new FormData();
  //         formData.append(
  //             "token",
  //             localStorage.getItem(Configuration.userCookie)
  //         );
  //         formData.append("UploadedImage", file);
  //         Axios.post(Configuration.image_upload_url, formData).then(
  //             (result) => {
  //                 alert(Configuration.image_url + result.data.Data[0].Path);
  //                 return Configuration.image_url + result.data.Data[0].Path;
  //             }
  //         );
  //     });
  // }

  // abort() {
  //     // Reject the promise returned from the upload() method.
  //     alert("error");
  // }
  // ...
}
