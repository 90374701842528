import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import axios from "axios";
import React from "react";
import { Button, Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap";
import CustomLoader from "../../Layout/Loading/CustomLoader";
import Common from "../../utils/common";
import Configuration from "../../utils/configuration";
import Validation from "../../utils/validation";
import Header from "../CommonComponent/Header";

class ImportEvouchercode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalInfo: {
                show: false,
                message: "",
                type: "error",
            },
            isSending: false,
        };
    }
    state = {
        code: "",
    };
    onSubmidHandler = (event, value) => {
        this.setState({
            isSending: true,
        });
        const body = {
            Id: this.props.match.params.id,
            MerchantId: localStorage.getItem("merchantId"),
            PatternCode: value.Pattern,
            QuantityOfCode: value.number,
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: localStorage.getItem("userId"),
            Codes: value.code.split(","),
        };

        // postData
        //     .sendCommand(
        //         "Evoucher",
        //         "ImportEVoucherCodes",
        //         body
        //     )
        const dataSend = {
            CommandName: "ImportEVoucherCodes",
            Domain: "Evoucher",
            Content: JSON.stringify(body),
            TimeOutSecond: 7,
        };

        const api_url = Configuration.url_api + "/Command/SendSync";
        axios
            .post(api_url, dataSend)
            .then((response) => {
                if (response.data.Success)
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thành công",
                            responesMessage: response.data.Message,
                            type: "success",
                        },
                    });
                else
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thất bại",
                            responesMessage: response.data.Message,
                            type: "error",
                        },
                    });
            })
            .catch((err) => {
                this.setState({
                    modalInfo: {
                        show: true,
                        message: "Thất bại",
                        responesMessage: err + "",
                        type: "error",
                    },
                });
            })
            .finally(() => {
                this.setState({
                    isSending: false,
                });
            });
    };

    render() {
        return (
            <CustomLoader
                isSending={this.state.isSending}
                modalInfo={this.state.modalInfo}
                redirectPath="#/evoucher/list"
            >
                <Header
                    title="Import Evoucher"
                    description="import evoucher"
                    iconClassName="pe-7s-upload"
                />
                <Row>
                    <Col md="9">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Cập nhật</CardTitle>
                                <Row>
                                    <Col md="9">
                                        <AvForm
                                            onValidSubmit={this.onSubmidHandler}
                                        >
                                            <AvGroup>
                                                <Label for="code">
                                                    Mã import <br></br>
                                                    <label
                                                        style={{ color: "red" }}
                                                    >
                                                        *Ghi chú: Các mã cách
                                                        nhau bởi dấu ','. Ví dụ:
                                                        abc,def,ghk
                                                    </label>
                                                </Label>
                                                <AvField
                                                    className="form-control"
                                                    name="code"
                                                    id="code"
                                                    type="textarea"
                                                    rows={20}
                                                    value={this.state.code}
                                                    validate={
                                                        Validation.notEmpty
                                                    }
                                                />
                                            </AvGroup>

                                            <Button
                                                size="lg"
                                                className="mt-3"
                                                color="primary"
                                            >
                                                Lưu dữ liệu
                                            </Button>
                                        </AvForm>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CustomLoader>
        );
    }
}
export default ImportEvouchercode;
