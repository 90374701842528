import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import TableList from '../TableList/TableList'

function PickSortableListModal({
  isOpen,
  setIsOpen,
  checkItems,
  setCheckItems,
  apiCommand,
  createLink,
}) {
  const [_checkItems, _setCheckItems] = useState(checkItems ? checkItems : [])

  useEffect(() => {
    if (isOpen) {
      _setCheckItems(checkItems)
    } else {
      _setCheckItems([])
    }
  }, [isOpen])

  const onSave = () => {
    setCheckItems(_checkItems)
    toggle()
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const columns = [
    {
      Header: 'Tên',
      accessor: 'name',
    },
    {
      Header: 'Trạng thái',
      accessor: 'published',
      Cell: (props) => (
        <div>
          {props.value ? (
            <div className="mb-2 mr-2 badge badge-success">Đã phát hành</div>
          ) : (
            <div className="mb-2 mr-2 badge badge-danger">Chưa phát hành</div>
          )}
        </div>
      ),
    },
  ]
  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <div className="card-header-tab card-header">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          Danh sách
        </div>
        <div className="btn-actions-pane-right text-capitalize">
          {createLink ? (
            <Link
              to={(function () {
                const hash = window.location.hash
                const indexQuestionMark = hash.indexOf('?')
                return (
                  createLink +
                  `?returnUrl=${encodeURI(
                    hash.slice(
                      1,
                      indexQuestionMark !== -1 ? indexQuestionMark : hash.length
                    )
                  )}`
                )
              })()}
            >
              <Button color="link">Tạo mới</Button>
            </Link>
          ) : null}
        </div>
      </div>
      <ModalBody>
        {/* <Link
                    className="ml-auto"
                    target="_blank"
                    to="/video-gallery/create"
                >
                    Upload video
                </Link> */}
        <TableList
          apiCommand={apiCommand}
          columns={columns}
          itemSelectable
          checkItems={_checkItems}
          setCheckItems={_setCheckItems}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSave} color="primary">
          Lưu
        </Button>
      </ModalFooter>
    </Modal>
  )
}

PickSortableListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default PickSortableListModal
