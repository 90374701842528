import { useState } from 'react'

function useFetchDataTable(fetchData) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [lastParams, setLastParams] = useState({})

  const _fetchData = ({
    pageSize,
    pageIndex,
    keyword,
    categoryIds,
    ...props
  }) => {
    setLoading(true)
    setLastParams({
      pageSize,
      pageIndex,
      keyword,
      categoryIds,
      ...props,
    })
    fetchData({
      limit: pageSize,
      offset: pageIndex * pageSize,
      keyword: keyword,
      categoryIds,
      ...props,
    })
      .then((result) => {
        
        const name = Object.keys(result)[0]
        if(result != null && result[name] != null){
          
          setData(result[name].items)
          setTotalCount(result[name].totalCount)
          setPageCount(Math.ceil(result[name].totalCount / pageSize))
        }
        
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const reload = () => {
    const { pageSize, pageIndex, keyword, categoryIds } = lastParams
    setLoading(true)
    fetchData({
      limit: pageSize,
      offset: pageIndex * pageSize,
      keyword: keyword,
      categoryIds,
    })
      .then((result) => {
        const name = Object.keys(result)[0]
        setData(result[name].items)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return [data, loading, pageCount, _fetchData, totalCount, reload]
}

export default useFetchDataTable
