import React, { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import TableList from '../../Layout/Form/TableList'

class EvoucherCodeUsed extends React.Component {
  render() {
    const columns = [
      {
        Header: 'Mã',
        accessor: 'name',
        resizable: false,
        width: 400,
      },
      {
        accessor: 'active',
        Header: 'Phát hành',
        Cell: (props) => (
          <div>
            {props.value ? (
              <div className="mb-2 mr-2 badge badge-success">Đã phát hành</div>
            ) : (
              <div className="mb-2 mr-2 badge badge-danger">Chưa phát hành</div>
            )}
          </div>
        ),
        resizable: false,
        width: 130,
      },

      {
        Header: 'Email người kích hoạt',
        accessor: 'value',
        resizable: false,
        // width: 400
      },
      {
        Header: 'Số điện thoại người kích hoạt',
        accessor: 'value',
        resizable: false,
        // width: 400
      },
      {
        Header: 'Ngày dùng',
        accessor: 'value',
        resizable: false,
        // width: 400
      },
      {
        Header: 'Tên',
        accessor: 'value',
        resizable: false,
        // width: 400
      },
      {
        Header: 'Giá trị',
        accessor: 'value',
        resizable: false,
        // width: 400
      },
      {
        Header: 'Thời hạn sử dụng',
        accessor: 'value',
        resizable: false,
        // width: 400
      },
    ]
    return (
      <Fragment>
        <div className="app-main__inner">
          <Row>
            <Col md="12">
              <TableList
                name="evouchers"
                urlName="evoucher"
                columns={columns}
              />
              {/* <Route path={`${this.props.match.url}/list`} component={EvoucherList} /> 
                                <Route path={`${this.props.match.url}/create`} component={EvoucherForm} /> */}
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

export default EvoucherCodeUsed
