import React, { Fragment } from 'react'
import { Link, Route } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import CategoryForm from '../Categories/CategoryForm'
import CategoryList from '../Categories/CategoryList'
import DetailKanban from './DetailKanban'

class Crm extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  {/* <Route
                                        path={`${this.props.match.url}/:id/lesson`}
                                        component={CourseFormDragDrop}
                                    /> */}
                  <Route
                    path={`${this.props.match.url}/kanban/edit/:id`}
                    render={(props) => (
                      <CategoryForm
                        {...props}
                        type={Configuration.categoryTypes.KANBAN}
                      />
                    )}
                  />
                  <Route
                    path={`${this.props.match.url}/kanban/detail/:id`}
                    component={DetailKanban}
                  />
                  <Route
                    path={`${this.props.match.url}/kanban/create`}
                    render={(props) => (
                      <CategoryForm
                        {...props}
                        type={Configuration.categoryTypes.KANBAN}
                      />
                    )}
                  />
                  <Route
                    path={`${this.props.match.url}/kanban/list`}
                    render={(props) => (
                      <CategoryList
                        {...props}
                        type={Configuration.categoryTypes.KANBAN}
                        urlName={this.props.match.url}
                        parentId={Common.GUID_EMPTY}
                        actions={[
                          (props) => (
                            <Link to={`/crm/kanban/detail/${props.id}`}>
                              <button
                                title="Drag & Drop"
                                className="btn-icon btn-icon-only btn btn-link btn btn-link"
                              >
                                <i className="pe-7s-next-2 btn-icon-wrapper"></i>
                              </button>
                            </Link>
                          ),
                        ]}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Crm
