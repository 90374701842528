import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
// import PromotionList from './PromotionList';
import PromotionList from './List'
// import ProductTableList from './list/ProductTableList';
import PromotionForm from './PromotionForm'
import DiscountForm from './DiscountForm'
import PromotionProductForm from './PromotionProductForm'

class Promotion extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  {/* <Route path={`${this.props.match.url}/list`} component={ProductTableList} /> */}
                  <Route
                    path={`${this.props.match.url}/create`}
                    component={PromotionForm}
                  />
                  <Route
                    path={`${this.props.match.url}/create-product`}
                    component={PromotionProductForm}
                  />
                  <Route
                    path={`${this.props.match.url}/creatediscount`}
                    component={DiscountForm}
                  />
                  <Route
                    path={`${this.props.match.url}/edit/:id`}
                    component={PromotionForm}
                  />
                  <Route
                    path={`${this.props.match.url}/list`}
                    component={PromotionList}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Promotion
