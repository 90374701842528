import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Col } from 'reactstrap'

export default function ThemeLoadingSkeleton({ count, md }) {
  const newArr = []
  for (let i = 0; i < count; i++) {
    newArr.push(i)
  }
  return (
    <React.Fragment>
      {newArr.map((val, i) => (
        <Col key={i} md={md} className="text-center">
          <Skeleton height={150} />
          {/* <div color="success mb-3">
                        <Skeleton width={50} height={25} />
                    </div> */}
        </Col>
      ))}
    </React.Fragment>
  )
}
