import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";
import React, { Component, Fragment } from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { Button, Col, Row } from "reactstrap";
import Configuration from "../../../utils/configuration";

class ListImage extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.setImages([
      ...arrayMove([...this.props.images], oldIndex, newIndex).map((image, index) => {
        return {
          ...image,
          displayOrder: index,
        };
      }),
    ]);
  };

  render() {
    return (
      <Fragment>
        <SortableImageList
          axis="xy"
          useDragHandle={true}
          items={this.props.images}
          setImages={this.props.setImages}
          onSortEnd={this.onSortEnd}
        />
      </Fragment>
    );
  }
}

const SortableImageList = SortableContainer(({ items = [], setImages }) => (
  <Row>
    {items && items.length > 0 ? (
      items.map((value, index) => (
        <SortableImageItem
          key={`item-${value.id}`}
          index={index}
          images={items}
          setImages={setImages}
          value={value}
        />
      ))
    ) : (
      <p>Không có ảnh nào được chọn</p>
    )}
  </Row>
));

const SortableImageItem = SortableElement(({ value, images, setImages }) => (
  <ImageItem value={value} setImages={setImages} images={images} />
));

const ImageItem = ({ value, images, setImages }) => {
  const DragHander = sortableHandle(() => (
    <img
      style={{
        width: "100%",
        zIndex: 7,
      }}
      src={Configuration.image_url + value.path + "?mode=Crop&width=350&height=200"}
      alt="abc"
    />
  ));
  return (
    <Col
      md={images.length > 4 ? 6 : 12}
      style={{
        padding: "10px 0px",
        zIndex: 7,
      }}
    >
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <DragHander />

          <Button
            className="border-0 btn-transition"
            outline
            style={{
              position: "absolute",
              right: 2,
              top: 2,
            }}
            color="danger"
            onClick={() => {
              setImages(images.filter((val) => val.id !== value.id));
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
          {/* </div> */}
        </div>
      </div>
    </Col>
  );
};

export default ListImage;
