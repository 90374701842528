import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { getSchedules } from '../../Services/ScheduleService'
import { getUser } from '../../Services/UserService'
import Common from '../../utils/common'
import Header from '../CommonComponent/Header'

const localizer = momentLocalizer(moment)

function UserSchedule(props) {
  const idUser = props.match.params.id
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (idUser) getUser(idUser, localStorage.getItem("merchantId")).then(res => setUser(res.user))
  }, [])

  useEffect(() => {}, [idUser])

  const [events, setEvents] = useState([
  ])

  useEffect(() => {
    getSchedules({
      from: moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss'),
      to: moment().add(1, 'months').format('YYYY-MM-DDTHH:mm:ss'),
      targetId: idUser,
    }).then(res => {
      setEvents(
        res.schedules.items.map(item => ({
          start: new Date(item.triggerDateTime),
          end: new Date(item.triggerDateTime),
          title: item.name,
        }))
      )
    })
    return () => {}
  }, [])

  const selectRangeHandle = () => {}

  const onRangeChange = dates => {
    let nextStart = null
    let nextEnd = null
    if (Array.isArray(dates)) {
      nextStart = dates[0]
      nextEnd = dates[dates.length - 1]
    } else {
      const { start, end } = dates
      nextStart = start
      nextEnd = end
    }
    nextStart = moment(nextStart)
      .startOf('date')
      .toDate()
    nextEnd = moment(nextEnd)
      .endOf('date')
      .toDate()

    getSchedules({
      from: Common.formatDateTimeMoment(nextStart),
      to: Common.formatDateTimeMoment(nextEnd),
      targetId: idUser,
    }).then(res => {
      setEvents(
        res.schedules.items.map(item => ({
          start: new Date(item.triggerDateTime),
          end: new Date(item.triggerDateTime),
          title: item.name,
        }))
      )
    })
  }

  return (
    <div>
      <Header
        title={`Lịch của ${user ? user.name : "loading..."}`}
        description="User's point"
        iconClassName="pe-7s-user"
      />
      <Card>
        <CardBody>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={selectRangeHandle}
            onRangeChange={onRangeChange}
            style={{ height: 500 }}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default UserSchedule
