import React from 'react'
import Col from 'reactstrap/lib/Col'
import ListGroup from 'reactstrap/lib/ListGroup'
import ListGroupItem from 'reactstrap/lib/ListGroupItem'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import Row from 'reactstrap/lib/Row'
import { Link } from 'react-router-dom'
import Button from 'reactstrap/lib/Button'
import Card from 'reactstrap/lib/Card'

function ProfileUserModal({ isOpen, toggle, dataModal }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Card className="card-shadow-primary profile-responsive card-border">
        <div className="dropdown-menu-header">
          <div className="dropdown-menu-header-inner bg-focus">
            <div
              className="menu-header-image opacity-3"
              style={{
                backgroundImage:
                  'url(https://i.etsystatic.com/8804460/r/il/cc5182/936371015/il_570xN.936371015_nmon.jpg)',
              }}
            />
            <div className="menu-header-content btn-pane-right">
              <div className="avatar-icon-wrapper mr-2 avatar-icon-xl">
                <div className="avatar-icon rounded">
                  <img
                    src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg"
                    alt="Avatar 5"
                  />
                </div>
              </div>
              <div>
                <h5 className="menu-header-title">Vuong Bao Long</h5>
                <h6 className="menu-header-subtitle">0829400301</h6>
              </div>
              <div className="menu-header-btn-pane">
                {/* <LaddaButton
                      className="btn btn-pill btn-dark"
                      loading={true}
                      // onClick={() =>
                      //     this.toggle2("expSlideRight")
                      // }
                      data-style={SLIDE_RIGHT}Link
                    >
                      Load Click
                    </LaddaButton> */}
              </div>
            </div>
          </div>
        </div>
        <ListGroup flush>
          <ListGroupItem className="p-0">
            <div className="grid-menu grid-menu-2col">
              <Row className="no-gutters">
                <Col sm="6">
                  {/* <Link to={`/profile`}> */}
                    <Button
                      className="btn-icon-vertical btn-square btn-transition"
                      outline
                      color="link"
                    >
                      <i className="lnr-license btn-icon-wrapper btn-icon-lg mb-3">
                        {' '}
                      </i>
                      View Profile
                    </Button>
                  {/* </Link> */}
                </Col>
                <Col sm="6">
                  {/* <Link to={`/sale`}> */}
                    <Button
                      className="btn-icon-vertical btn-square btn-transition"
                      outline
                      color="link"
                    >
                      <i className="lnr-map btn-icon-wrapper btn-icon-lg mb-3">
                        {' '}
                      </i>
                      Sales Reports
                    </Button>
                  {/* </Link> */}
                </Col>
              </Row>
            </div>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Modal>
  )
}

export default ProfileUserModal
