import React, { Fragment, useState, useEffect } from 'react'
import GridImage from './GridImage'
import Common from '../../../utils/common'
import Configuration from '../../../utils/configuration'
import Pagination from 'react-js-pagination'

function IzziLibrary({ pickImages, setPickImages }) {
  const [images, setImages] = useState(null)
  const [totalCount, setTotalCount] = useState(0)

  const [activePage, setActivePage] = useState(1)
  const PER_PAGE = 12

  const loadImagesIzzi = function (options) {
    if (!options.limit) options.limit = 500
    if (!options.offset) options.offset = 0

    return Common.getDataGraphQl(
      `
        {
            images(param:{type:128,keyword:"",limit:${options.limit},offset:${
        options.offset
      },order:"asc",
            sort:"asc",
            languageId:"${localStorage.getItem(
              Configuration.tokenLanguage
            )}",merchantId:"${Configuration.libraryMerchantId}"})
            {
                message,
                success,
                totalCount,
                items{
                        id,
                        name,
                        path,
                        displayOrder,
                        isFeatured
                }
            }    
        }
        `
    )
  }

  useEffect(() => {
    // Load Image IZZI CMS
    loadImagesIzzi({
      limit: PER_PAGE,
      offset: PER_PAGE * (activePage - 1),
    }).then((response) => {
      let allImages = response.images.items.map((val) => ({
        id: val.id,
        path: val.path,
        name: val.name,
        active: false,
      }))
      setImages(allImages)
      setTotalCount(response.images.totalCount)
    })
  }, [])

  const handlePageChange = (page) => {
    setImages(null)

    loadImagesIzzi({ limit: PER_PAGE, offset: PER_PAGE * (page - 1) }).then(
      (result) => {
        setImages(result.images.items)
        setTotalCount(result.images.totalCount)
      }
    )
    setActivePage(page)
  }

  return (
    <Fragment>
      <GridImage
        images={images}
        setPickImages={function (image) {
          setPickImages([...pickImages, image])
        }}
        removePickImage={function (image) {
          setPickImages(pickImages.filter((img) => image.id !== img.id))
        }}
        pickImages={pickImages}
      />
      <div className="d-flex justify-content-center">
        <Pagination
          prevPageText="prev"
          nextPageText="next"
          firstPageText="first"
          lastPageText="last"
          activePage={activePage}
          itemsCountPerPage={PER_PAGE}
          totalItemsCount={totalCount}
          onChange={handlePageChange}
        />
      </div>
    </Fragment>
  )
}

export default IzziLibrary
