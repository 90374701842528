import arrayMove from 'array-move'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  changeStatusCourses,
  createCourse,
  createCourseLesson,
  deleteCourse,
  getCourse,
  getLesson,
  getLessons,
  updateCourse,
  updateDisplayOrderCourseLessons,
} from '../Services/CourseService'
import Configuration from '../utils/configuration'
import { FAILURE, REQUEST, SUCCESS } from './ActionType'

export const SET_COURSE_DATA = 'SET_COURSE_DATA'
export const ADD_LESSONS = 'ADD_LESSONS'
export const SET_NAME = 'SET_NAME'
export const SET_SUBDESCRIPTION = 'SET_SUBDESCRIPTION'
export const SET_PERIOD = 'SET_PERIOD'
export const SET_TYPE = 'SET_TYPE'
export const MOVE_LESSION = 'MOVE_LESSION'
export const EDIT_LESSION = 'EDIT_LESSION'
export const SET_LESSONS = 'SET_LESSONS'
export const CLEAR_COURSE_DATA = 'CLEAR_COURSE_DATA'

export const COURSE = {
  CREATE: 'course/CREATE',
  UPDATE: 'course/UPDATE',
  DELETE: 'course/DELETE',
  CHANGE_STATUS: 'course/CHANGE_STATUS',
  GET_BY_ID: 'course/GET_BY_ID',
  CREATE_LESSON: 'course/CREATE_LESSON',
  GET_COURSE_LESSONS: 'course/GET_COURSE_LESSONS',
  GET_COURSE_LESSON: 'course/GET_COURSE_LESSON',
  UPDATE_LESSON: 'course/UPDATE_LESSON',
  DELETE_LESSON: 'course/DELETE_LESSON',
  UPDATE_DISPLAY_ORDER_LESSONS: 'course/UPDATE_DISPLAY_ORDER_LESSONS',
  CHANGE_STATUS_LESSON: 'course/CHANGE_STATUS_LESSON',

  ADD_LESSON_ITEMS: 'course/ADD_LESSON_ITEMS',
  REMOVE_LESSON_ITEMS: 'course/REMOVE_LESSON_ITEMS',
  UPDATE_DISPLAY_ORDER_LESSON_ITEMS: 'course/UPDATE_DISPLAY_ORDER_LESSON_ITEMS',
}

export const addRelationsCourseLessonAction = (items) => ({
  type: COURSE.ADD_LESSON_ITEMS,
  items: items,
})

export const removeRelationsCourseLessonAction = (ids) => ({
  type: COURSE.REMOVE_LESSON_ITEMS,
  ids: ids,
})

export const updateDisplayOrderRelationsCourseLessonAction = (
  oldIndex,
  newIndex
) => ({
  type: COURSE.UPDATE_DISPLAY_ORDER_LESSON_ITEMS,
  oldIndex,
  newIndex,
})

export const updateDisplayOrderLessonsAction = (oldIndex, newIndex) => ({
  type: COURSE.UPDATE_DISPLAY_ORDER_LESSONS,
  oldIndex,
  newIndex,
})

export const changeStatusCoursesAction = (body) => ({
  type: COURSE.CHANGE_STATUS,
  payload: changeStatusCourses(body),
})

export const createCourseLessonAction = (body) => ({
  type: COURSE.CREATE_LESSON,
  payload: createCourseLesson(body),
})

export const getLessonsAction = (options) => ({
  type: COURSE.GET_COURSE_LESSONS,
  payload: getLessons(options),
})

export const getLessonAction = (id) => ({
  type: COURSE.GET_COURSE_LESSON,
  payload: getLesson(id),
})

export const getCourseAction = (id) => ({
  type: COURSE.GET_BY_ID,
  payload: getCourse(id),
})

export const createCourseAction = (body) => ({
  type: COURSE.CREATE,
  payload: createCourse(body),
})

export const deleteCourseAction = (body) => ({
  type: COURSE.DELETE,
  payload: deleteCourse(body),
})

export const updateCourseAction = (body) => ({
  type: COURSE.UPDATE,
  payload: updateCourse(body),
})

export const setCourseData = (payload) => ({
  type: SET_COURSE_DATA,
  payload,
})

export const addLesson = (lesson) => ({
  type: ADD_LESSONS,
  lesson,
})

export const setName = (name) => ({
  type: SET_NAME,
  name,
})

export const setSubDescription = (subDescription) => ({
  type: SET_SUBDESCRIPTION,
  subDescription,
})

export const setPeriod = (period) => ({
  type: SET_PERIOD,
  period,
})

export const setType = (type) => ({
  type: SET_TYPE,
  courseType: type,
})

export const moveLesson = (oldIndex, newIndex) => ({
  type: MOVE_LESSION,
  oldIndex,
  newIndex,
})

export const editLesson = (lessonId, lessonInfo) => ({
  type: EDIT_LESSION,
  lessonId,
  lessonInfo,
})

export const setLessons = (lessons) => ({
  type: SET_LESSONS,
  lessons,
})

export const clearCourseData = () => ({
  type: CLEAR_COURSE_DATA,
})

const initialState = {
  name: '',
  subDescription: '',
  period: 0,
  type: Configuration.courseType.DEFAULT.value,
  lessons: [],
  course: {},

  courseLesson: {},
  isLoading: false,
  returnData: null,
  process: 0,
}
export const reducer = persistReducer(
  {
    storage,
    key: 'course',
    //blacklist: ["courses", "isLoading"],
  },
  (state = initialState, action) => {
    // export default function reducer(state = initialState, action) {
    switch (action.type) {
      case REQUEST(COURSE.CHANGE_STATUS):
        //console.log(state.course)
        return {
          ...state,
          course: {
            ...state.course,
            published: !state.course.published,
          },
          isLoading: true,
        }

      case FAILURE(COURSE.CHANGE_STATUS):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(COURSE.CHANGE_STATUS):
        return {
          ...state,
          isLoading: false,
        }

      case REQUEST(COURSE.GET_BY_ID):
        return {
          ...state,
          isLoading: true,
        }

      case FAILURE(COURSE.GET_BY_ID):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(COURSE.GET_BY_ID):
        return {
          ...state,
          isLoading: false,
          course: action.payload.course,
        }

      case REQUEST(COURSE.GET_COURSE_LESSONS):
        return {
          ...state,
          isLoading: true,
        }

      case FAILURE(COURSE.GET_COURSE_LESSONS):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(COURSE.GET_COURSE_LESSONS):
        return {
          ...state,
          isLoading: false,
          courseLesson: action.payload.courselessons,
          lessons: action.payload.courselessons.items,
        }

      case REQUEST(COURSE.GET_COURSE_LESSON):
        return {
          ...state,
          isLoading: true,
        }

      case FAILURE(COURSE.GET_COURSE_LESSON):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(COURSE.GET_COURSE_LESSON):
        return {
          ...state,
          isLoading: false,
          courseLesson: action.payload.courselesson,
        }

      case REQUEST(COURSE.CREATE_LESSON):
        return {
          ...state,
          isLoading: true,
        }

      case FAILURE(COURSE.CREATE_LESSON):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(COURSE.CREATE_LESSON):
        var temp = action.payload
        return {
          ...state,
          isLoading: false,
          returnData: temp.data,
        }

      case REQUEST(COURSE.CREATE):
        return {
          ...state,
          isLoading: true,
        }

      case FAILURE(COURSE.CREATE):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(COURSE.CREATE):
        var temp = action.payload
        return {
          ...state,
          isLoading: false,
          returnData: temp.data,
        }

      case REQUEST(COURSE.UPDATE):
        return {
          ...state,
          isLoading: true,
        }

      case FAILURE(COURSE.UPDATE):
        return {
          ...state,
          isLoading: false,
        }
      case SUCCESS(COURSE.UPDATE):
        var temp = action.payload
        return {
          ...state,
          isLoading: false,
          returnData: temp.data,
        }

      case REQUEST(COURSE.DELETE):
        return {
          ...state,
          isLoading: true,
          process: 0,
        }

      case FAILURE(COURSE.DELETE):
        var temp = action.payload
        return {
          ...state,
          isLoading: false,
          returnData: temp.data,
        }
      case SUCCESS(COURSE.DELETE):
        var temp = action.payload
        return {
          ...state,
          isLoading: false,
          returnData: temp.data,
          process: 1,
        }

      case SET_COURSE_DATA:
        return {
          ...state,
          ...action.payload,
        }
      case ADD_LESSONS:
        return {
          ...state,
          lessons: [...state.lessons, action.lesson],
        }
      case SET_NAME:
        return {
          ...state,
          name: action.name,
        }
      case SET_SUBDESCRIPTION:
        return {
          ...state,
          subDescription: action.subDescription,
        }
      case SET_PERIOD:
        return {
          ...state,
          period: action.period,
        }
      case SET_TYPE:
        return {
          ...state,
          type: action.courseType,
          lessons: state.lessons.map((lesson) => ({
            ...lesson,
            value: 0,
          })),
        }

      case MOVE_LESSION:
        return {
          ...state,
          lessons: arrayMove(
            [...state.lessons],
            action.oldIndex,
            action.newIndex
          ),
        }

      case COURSE.UPDATE_DISPLAY_ORDER_LESSONS:
        var temp = arrayMove(
          [...state.lessons],
          action.oldIndex,
          action.newIndex
        )
        updateDisplayOrderCourseLessons(temp.map((e) => e.id))
        return {
          ...state,
          lessons: temp,
        }

      case COURSE.UPDATE_DISPLAY_ORDER_LESSON_ITEMS:
        alert('heloo')
        var temp = arrayMove(
          [...state.lessonItems],
          action.oldIndex,
          action.newIndex
        )
        //updateDisplayOrderRelationsCourseLesson(temp.map(e => e.id));
        return {
          ...state,
          lessonItems: temp,
        }

      case EDIT_LESSION:
        return {
          ...state,
          lessons: state.lessons.map((lesson) => {
            if (lesson.id == action.lessonId) {
              return {
                ...lesson,
                ...action.lessonInfo,
              }
            }
            return lesson
          }),
        }

      case SET_LESSONS:
        return {
          ...state,
          lessons: [...action.lessons],
        }

      case CLEAR_COURSE_DATA:
        return initialState

      default:
        return state
    }
  }
)
