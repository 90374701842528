import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import { getData, postData } from '../../utils/data'

function NewLaneForm({
  e,
  parentId,
  setData,
  setStateAddingLane,
  dataKanban,
  setSaving,
}) {
  const CATEGORIES = 'categories'
  const NEW_CATEGORIES = 'newCategories'

  const [newLane, setNewLane] = useState({})
  const [availableCategories, setAvailableCategories] = useState([])
  const [type, setType] = useState(CATEGORIES)
  const [newCategoryForm, setNewCategoryForm] = useState({
    name: '',
  })

  useEffect(() => {
    getData.getCategories({ parentId: parentId }).then((data) => {
      const existLaneIds = dataKanban.lanes.map((lane) => lane.id)
      return setAvailableCategories(
        data.categories.items.filter((item) => !existLaneIds.includes(item.id))
      )
    })
  }, [])

  const addLane = ({ id, title, label, cards }) => {
    setData({
      lanes: [
        ...dataKanban.lanes,
        {
          id: id,
          title: title,
          label: label,
          cards: cards,
        },
      ],
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    e.onCancel()

    if (type === CATEGORIES) {
      if (!newLane.id) return
      setStateAddingLane(true)
      setSaving(true)

      postData
        .changeStatusCommand('Category', 'ChangeStatusPublishCategories', [
          {
            Id: newLane.id,
            Publish: false,
          },
        ])
        .then(() => {
          setSaving(false)
          setStateAddingLane(false)
        })
      addLane({
        id: newLane.id,
        title: newLane.name,
        label: '',
        cards: [],
      })
    } else {
      setStateAddingLane(true)
      setSaving(true)

      createCategory(newCategoryForm)
    }
  }

  const onChangeSelect = (userCategoryId) => {
    if (userCategoryId === '') return setNewLane({})
    const newLand = availableCategories.find((val) => val.id === userCategoryId)
    setNewLane(newLand)
  }

  const onTypeChanged = (e) => {
    setType(e.currentTarget.value)
  }

  const createCategory = ({ name }) => {
    const id = Common.guid()
    Common.createCategory({
      Id: id,
      Name: name,
      ParentId: parentId,
      Type: Configuration.categoryTypes.KANBAN,
      DisplayOrder: 0,
    })
      .then((response) => {
        if (response.data.Success === true) {
          // Tao xong change status
          postData.changeStatusCommand(
            'Category',
            'ChangeStatusPublishCategories',
            [
              {
                Id: id,
                Publish: false,
              },
            ]
          )
          toast['success'](Configuration.toast_information.SUCCESS)
          addLane({
            id: id,
            title: name,
            label: '',
            cards: [],
          })
        } else {
          toast['error']('Có lỗi xảy ra')
        }
      })
      .catch((err) => {
        toast['error'](err + '')
      })
      .finally(() => {
        setStateAddingLane(false)
        setSaving(false)
        e.onCancel()
      })
  }

  return (
    <Card
      style={{
        backgroundColor: '#e3e3e3',
        margin: '5px',
        width: 400,
      }}
    >
      <CardBody>
        <FormGroup>
          <Label>
            Phân loại
            <FormGroup
              className="ml-5"
              style={{
                display: 'inline-block',
                marginBottom: 0,
              }}
            >
              <Label>
                <Input
                  type="radio"
                  name="type"
                  value={CATEGORIES}
                  checked={type === CATEGORIES}
                  onChange={onTypeChanged}
                />
                Có sẵn
              </Label>
            </FormGroup>
            <FormGroup
              className="ml-5"
              style={{
                display: 'inline-block',
                marginBottom: 0,
              }}
            >
              <Label>
                <Input
                  type="radio"
                  name="type"
                  value={NEW_CATEGORIES}
                  checked={type === NEW_CATEGORIES}
                  onChange={onTypeChanged}
                />
                Tạo mới
              </Label>
            </FormGroup>
          </Label>
          {type === 'categories' ? (
            <Input
              type="select"
              value={newLane.id}
              onChange={(event) => onChangeSelect(event.target.value)}
            >
              <option value="">...</option>
              {availableCategories.map((val, i) => {
                return (
                  <option key={i} value={val.id}>
                    {val.name}
                  </option>
                )
              })}
            </Input>
          ) : (
            <React.Fragment>
              <FormGroup>
                <Label>Tên</Label>
                <Input
                  type="text"
                  name="category"
                  value={newCategoryForm.name}
                  onChange={(event) =>
                    setNewCategoryForm({
                      ...newCategoryForm,
                      name: event.target.value,
                    })
                  }
                />
              </FormGroup>
              {/* </Form> */}
            </React.Fragment>
          )}
        </FormGroup>
        <Button onClick={onSubmit} className="mr-3" color="success">
          Thêm
        </Button>
        <Button onClick={e.onCancel} className="mr-3" color="info">
          Hủy
        </Button>
      </CardBody>
    </Card>
  )
}

export default NewLaneForm
