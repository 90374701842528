import firebase from "firebase";

const VAPI_KEY =
"BGoa5Dtg1CBkEqBqsoa9-kZfUwL6s6UC2P5ZO7htYCqCyf3Ex2LzbSCMEUblTtJzqNylXaZgJlOVWG0fGQCX2Xo";
let messaging = null;

if (firebase.messaging.isSupported()) {
  const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDzXLi8I1zi26cwA86Z7mru18qWDza5BVg",
    authDomain: "izzi-c3040.firebaseapp.com",
    projectId: "izzi-c3040",
    storageBucket: "izzi-c3040.appspot.com",
    messagingSenderId: "1054631288231",
    appId: "1:1054631288231:web:64e2a83c822669e12d2a82"
  });

  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(VAPI_KEY);
}

export { messaging, VAPI_KEY };
