//  getAttributes(options = {}) {
//         const type = options.type === undefined ? 0 : options.type;
//         const limit = options.limit === undefined ? 100000 : options.limit;
//         const offset = options.offset === undefined ? 0 : options.offset;
//         const order = options.order === undefined ? "desc" : options.order;
//         const sort = options.sort === undefined ? "createdDate" : options.sort;
//         const keyword = options.keyword === undefined ? "" : options.keyword;
//         const languageId =
//             options.languageId === undefined
//                 ? localStorage.getItem(Configuration.tokenLanguage)
//                 : options.languageId;
//         const categoryIds =
//             options.categoryIds === undefined ||
//             options.categoryIds.length === 0
//                 ? ""
//                 : `categoryIds:[${options.categoryIds
//                       .map((val) => `"${val}"`)
//                       .join(",")}]`;
//         const targetId =
//             options.targetId === undefined
//                 ? ""
//                 : `targetId:"${options.targetId}"`;
//         return Common.getDataGraphQl(`
//         {
//             attributes(param:{type:${type},keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
//                 ,languageId:"${languageId}",merchantId:"${localStorage.getItem(
//             "merchantId"
//         )}", ${categoryIds}, ${targetId}})
//             {
//                 totalCount,
//                 message,
//                 success,
//                 items{
//                     attributeValues {
//                       id,
//                       attributeId,
//                       name,
//                       value
//                     },
//                     name,
//                     id,
//                     published,
//                     createdDate
//                 },

//             }
//           }
//         `);
//     },

import Common from "../utils/common";
import Configuration from "../utils/configuration";
import { getData, postData } from "../utils/data";
import moment from "moment";

//import Configuration from '../utils/configuration'
const DATATYPE = Configuration.attributeConfiguration.dataType;
const DISPLAYTYPE = Configuration.attributeConfiguration.displayType;

export const createUserAttributeValue = ({ id, attributeId, value }) => {
  return postData.sendCommand("Attribute", "CreateUserAttributeValue", {
    AttributeValueId: id,
    Id: attributeId,
    Value: value,
    LanguageId: localStorage.getItem(Configuration.tokenLanguage),
    CreatedDate: new Date(),
    CreatedBy: localStorage.getItem("userId"),
  });
};

export const updateUserAttributeValue = ({ id, attributeId, value }) => {
  return postData.sendCommand("Attribute", "UpdateUserAttributeValue", {
    AttributeValueId: id,
    Id: attributeId,
    Value: value,
    LanguageId: localStorage.getItem(Configuration.tokenLanguage),
    CreatedDate: new Date(),
    CreatedBy: localStorage.getItem("userId"),
  });
};

export const handleAttributeFormSubmit = (
  targetId,
  attributeFormValues,
  currentAttributeValues
) => {
  //send command to create user values with type of varchar, text, datetime.
  //console.log(targetId,attributeFormValues,currentAttributeValues);
  let relationAttributeValues = [];
  let isFormValid = true;
  let errorMessages = [];

  attributeFormValues.map((item, i) => {
    const displayType = item.AttributeItem.displayType;
    const dataType = item.AttributeItem.dataType;
    //console.log("displayType", displayType);
    let userValue = "";

    let isAttributeValid = true;

    //if (displayType == 1 || displayType == 6 || displayType == 7) {
    if (
      displayType == DISPLAYTYPE.TEXTBOX ||
      displayType == DISPLAYTYPE.TEXTAREA ||
      displayType == DISPLAYTYPE.DATETIME
    ) {
      //get value from hook form
      // if (displayType == 1) {
      //     if (values[item.AttributeItem.id]) {
      //         //console.log("values[item.AttributeItem.id]", values[item.AttributeItem.id]);

      //         userValue = values[item.AttributeItem.id];
      //     }
      // }
      //if (displayType == 1 || displayType == 6) {
      if (displayType == DISPLAYTYPE.TEXTBOX || displayType == DISPLAYTYPE.TEXTAREA) {
        //get value from hook form
        console.log("textvalue:", item.Values);
        if (item.Values && item.Values.length) userValue = item.Values[0];
      }
      //get value from attribute state
      //else if (displayType == 7) {
      else if (displayType == DISPLAYTYPE.DATETIME) {
        if (item.DateValues && item.DateValues.length > 0)
          userValue = moment(item.DateValues[0]).toISOString();
      }

      if (userValue != "") {
        //remove error message
        //setAttributeErrorsMessage("", item.AttributeItem.id);

        const attributeId = item.AttributeItem.id;
        let attributeValueId = Common.guid();
        let isUpdate = false;
        if (targetId) {
          switch (dataType) {
            case DATATYPE.VARCHAR:
              if (
                currentAttributeValues.attributeVarcharValue &&
                currentAttributeValues.attributeVarcharValue.length
              ) {
                var temp = currentAttributeValues.attributeVarcharValue.filter(
                  (t) => t.attributeId == attributeId
                );
                if (temp && temp.length) {
                  attributeValueId = temp[0].id;
                  isUpdate = true;
                }
              }
              break;
            case DATATYPE.TEXT:
              if (
                currentAttributeValues.attributeTextValue &&
                currentAttributeValues.attributeTextValue.length
              ) {
                var temp = currentAttributeValues.attributeTextValue.filter(
                  (t) => t.attributeId == attributeId
                );
                if (temp && temp.length) {
                  attributeValueId = temp[0].id;
                  isUpdate = true;
                }
              }
              break;
            case DATATYPE.INT:
              if (
                currentAttributeValues.attributeIntValue &&
                currentAttributeValues.attributeIntValue.length
              ) {
                var temp = currentAttributeValues.attributeIntValue.filter(
                  (t) => t.attributeId == attributeId
                );
                if (temp && temp.length) {
                  attributeValueId = temp[0].id;
                  isUpdate = true;
                }
              }
              break;
            case DATATYPE.DECIMAL:
              if (
                currentAttributeValues.attributeDecimalValue &&
                currentAttributeValues.attributeDecimalValue.length
              ) {
                var temp = currentAttributeValues.attributeDecimalValue.filter(
                  (t) => t.attributeId == attributeId
                );
                if (temp && temp.length) {
                  attributeValueId = temp[0].id;
                  isUpdate = true;
                }
              }
              break;
            case DATATYPE.DATETIME:
              if (
                currentAttributeValues.attributeDateTimeValue &&
                currentAttributeValues.attributeDateTimeValue.length
              ) {
                var temp = currentAttributeValues.attributeDateTimeValue.filter(
                  (t) => t.attributeId == attributeId
                );
                if (temp && temp.length) {
                  attributeValueId = temp[0].id;
                  isUpdate = true;
                }
              }
              break;
            default:
              attributeValueId = Common.guid();
          }
        }

        if (isUpdate) {
          updateUserAttributeValue({
            id: attributeValueId,
            attributeId,
            value: userValue,
          }).then((response) => {
            //console.log("response", response);
            const { Id, Success } = response.data;

            // if (Success) {

            // }
          });
        } else {
          //const value = userValue;

          createUserAttributeValue({
            id: attributeValueId,
            attributeId,
            value: userValue,
          }).then((response) => {
            //console.log("response", response);
            const { Id, Success } = response.data;

            // if (Success) {

            // }
          });
        }

        //console.log("push_167", attributeValueId);

        relationAttributeValues.push({
          Id: attributeValueId,
          IsFeatured: false,
          DisplayOrder: i,
        });
      } else {
        if (item.AttributeItem.isRequired) isAttributeValid = false;
        //setAttributeErrorsMessage(item.AttributeItem.name + " is required", item.AttributeItem.id);
      }
    } else {
      //create realtion for selected values
      if (displayType === DISPLAYTYPE.SELECTION || displayType === DISPLAYTYPE.MUILTISECTION) {
        //get from hook form
        if (item.Values && item.Values.length) {
          //if (values[item.AttributeItem.id] != undefined) {
          //const itemValue = values[item.AttributeItem.id];
          const itemValue = item.Values;

          if (!Array.isArray(itemValue)) {
            console.log("userValue_4", displayType, itemValue);
            if (itemValue.value != "") {
              console.log("push_4", itemValue.value);
              relationAttributeValues.push({
                Id: itemValue.value,
                IsFeatured: false,
                DisplayOrder: i,
              });
            } else {
              if (item.AttributeItem.isRequired) isAttributeValid = false;
            }
          } else {
            if (itemValue.length > 0) {
              itemValue.map((item, index) => {
                console.log("userValue_45", displayType, item);
                if (item.value != "") {
                  console.log("push_45", item.value);
                  relationAttributeValues.push({
                    Id: item.value,
                    IsFeatured: false,
                    DisplayOrder: i + index,
                  });
                }
              });
            } else {
              if (item.AttributeItem.isRequired) isAttributeValid = false;
            }
          }
        } else {
          if (item.AttributeItem.isRequired) isAttributeValid = false;
        }
      } else {
        //console.log("Radio_checkbox_23", values[item.AttributeItem.id]);
        //get values from attribute states
        //console.log("userValue_23", item.Values);
        if (item.Values && item.Values.length) {
          item.Values.map((temp, index) => {
            if (temp != undefined) {
              console.log("push_23", displayType, temp);
              relationAttributeValues.push({
                Id: temp,
                IsFeatured: false,
                DisplayOrder: i + index,
              });
            }
          });
        } else {
          if (item.AttributeItem.isRequired) isAttributeValid = false;
        }
      }
    }

    if (!isAttributeValid) {
      isFormValid = false;
      //setAttributeErrorsMessage(item.AttributeItem.name + " is required", item.AttributeItem.id);
      errorMessages.push({
        id: item.AttributeItem.id,
        message: item.AttributeItem.name + " is required",
      });
    }
    // else {
    //     setAttributeErrorsMessage("", item.AttributeItem.id);
    // }
  });

  return {
    relationAttributeValues: relationAttributeValues,
    isFormValid: isFormValid,
    errorMessages: errorMessages,
  };
};

export const getAttributes = (options) => {
  //   const type = options.type === undefined ? 0 : options.type;
  const limit = options.limit === undefined ? 100000 : options.limit;
  const offset = options.offset === undefined ? 0 : options.offset;
  const order = options.order === undefined ? "desc" : options.order;
  const sort = options.sort === undefined ? "createdDate" : options.sort;
  const keyword = options.keyword === undefined ? "" : options.keyword;
  const subType = options.subType === undefined ? 0 : options.subType;
  let languageId = "";
  if (options.languageId === undefined) {
    languageId = Common.getParameterByName("languageId")
      ? Common.getParameterByName("languageId")
      : localStorage.getItem(Configuration.tokenLanguage);
  }
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;
  const targetId = options.targetId === undefined ? "" : `targetId:"${options.targetId}"`;
  return Common.getDataGraphQl(`
        {
            attributes(param:{
                ${options.type != undefined ? "type:" + options.type + "," : ""}
                ${options.published != undefined ? "published:" + options.published + "," : ""}
                subType:${subType},
                keyword:"${keyword}",limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
                ,languageId:"${languageId}",merchantId:"${localStorage.getItem(
    "merchantId"
  )}", ${categoryIds}, ${targetId}})
            {
                totalCount,
                message, 
                success,
                items{
                  code
                    attributeValues {
                      id,
                      attributeId
                      value
                      imagePath
                      displayOrder                      
                    },
                    attributeTextValues {
                      id
                      attributeId
                      value
                      imagePath
                      displayOrder
                      isDefaultValue
                    },
                    attributeIntValues {
                      id,
                      attributeId
                      value,
                      imagePath,
                      displayOrder,
                      isDefaultValue,
                    },
                    attributeDecimalValues {
                      id,
                      attributeId
                      value,
                      imagePath,
                      displayOrder,
                      isDefaultValue,
                    },
                    attributeDateTimeValues {
                      id,
                      attributeId
                      value,
                      imagePath,
                      displayOrder,
                      isDefaultValue,
                    },
                    attributeVarcharValues {
                      id,
                      attributeId
                      value,
                      imagePath,
                      displayOrder,
                      isDefaultValue,
                    }
                    name,
                    type,
                    subType,
                    id,
                    published,
                    createdDate,
                    displayType, 
                    dataType,
                    displayOrder,
                    isRequired,                    
                },

            }
          }
        `);
};

export const getFormAttributeValues = (options) => {
  //   const type = options.type === undefined ? 0 : options.type;
  let languageId = "";
  if (options.languageId === undefined) {
    languageId = Common.getParameterByName("languageId")
      ? Common.getParameterByName("languageId")
      : localStorage.getItem(Configuration.tokenLanguage);
  }
  const targetId = options.targetId === undefined ? "" : `targetId:"${options.targetId}"`;
  return Common.getDataGraphQl(`
        {
          formattributevalues(param:{languageId:"${languageId}", ${targetId}})
            {
                targetId,
                attributeTextValues {
                  id
                  attributeId
                  value
                  imagePath
                  displayOrder
                  isDefaultValue
                },
                attributeIntValues {
                  id,
                  attributeId
                  value,
                  imagePath,
                  displayOrder,
                  isDefaultValue,
                },
                attributeDecimalValues {
                  id,
                  attributeId
                  value,
                  imagePath,
                  displayOrder,
                  isDefaultValue,
                },
                attributeDateTimeValues {
                  id,
                  attributeId
                  value,
                  imagePath,
                  displayOrder,
                  isDefaultValue,
                },
                attributeVarcharValues {
                  id,
                  attributeId
                  value,
                  imagePath,
                  displayOrder,
                  isDefaultValue,
                }       
            }
          }
        `);
};
