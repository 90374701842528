import { toast } from 'react-toastify'
import Common from '../utils/common'
import Configuration from '../utils/configuration'
import { postData } from '../utils/data'

export const sendThemeTemplate = ({
  id,
  name,
  subDescription,
  categories,
  images,
  themeId,
  sections,
  isUpdate = false,
}) => {
  if (!sections) return toast['error']('Vui lòng chọn section')
  return postData.sendCommand(
    'Article',
    isUpdate ? 'UpdateThemeTemplateArticle' : 'CreateThemeTemplateArticle',
    {
      Id: isUpdate ? id : Common.guid(),
      MerchantId: localStorage.getItem('merchantId'),
      ThemeWebId: themeId,
      Name: name,
      SubDescription: subDescription,
      Categories: Common.createRelationObjectArray(
        categories.map((category) => category.value)
      ),
      Images: Common.createRelationObjectArray(images.map((image) => image.id)),
      Sections: Common.createRelationObjectArray(
        sections.map((section) => section.id)
      ),
      LanguageId: localStorage.getItem(Configuration.tokenLanguage),
      CreatedDate: new Date(),
      CreatedBy: localStorage.getItem('userId'),
    }
  )
}
