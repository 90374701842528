import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import { ZOOM_IN } from 'react-ladda/dist/constants'
import LaddaButton from 'react-ladda/dist/LaddaButton'
import Loader from 'react-loaders'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { getRating } from '../../Services/RatingService'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import { postData } from '../../utils/data'

function RatingForm({ ...props }) {
  const idRating = props.match.params.id
  const [rating, setRating] = useState({})

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (idRating) {
      getRating(idRating).then((res) => {
        const ratingInfo = res.rating
        console.log(ratingInfo)
        setRating(ratingInfo)
      })
    }
    return () => {}
  }, [])
  const ratingForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: rating.name,
      maxScore: rating.maxScore,
      description: rating.subDescription,
    },
    onSubmit: async function (values) {
      const { title, maxScore, description } = values

      const body = {
        Id: idRating ? idRating : Common.guid(),
        MerchantId: localStorage.getItem('merchantId'),
        Name: title,
        SubDescription: description,
        MaxScore: maxScore,
        LanguageId: localStorage.getItem(Configuration.tokenLanguage),
      }

      if (idRating) {
        body.ModifiedDate = new Date()
        body.ModifiedBy = localStorage.getItem('userId')
      } else {
        body.CreatedDate = new Date()
        body.CreatedBy = localStorage.getItem('userId')
      }

      try {
        setLoading(true)
        const result = await postData.sendCommand(
          'Rating',
          idRating ? 'UpdateRating' : 'CreateRating',
          body
        )
        setLoading(false)
        const { Message, Success } = result.data

        Swal.fire({
          title: Success ? 'Thành công' : 'Thất bại',
          text: Message,
          icon: Success ? 'success' : 'error',
          showCancelButton: true,
          confirmButtonText: 'Về danh sách',
        }).then(({ value }) => {
          if (value) props.history.push('/rating/list')
        })
      } catch (err) {
        Swal.fire({
          title: 'Thất bại',
          text: err,
          icon: 'error',
        })
      }
    },
    validate: (values) => {
      const errors = {}

      if (!values.title) errors.title = 'Tiêu đề không được để trống'
      if (!values.maxScore) errors.maxScore = 'Điểm tối đa không được để trống'

      return errors
    },
  })
  return (
    <div>
      <Row>
        <Col md="8">
          <BlockUi
            tag="div"
            blocking={loading}
            className="block-overlay-dark"
            loader={<Loader color="#ffffff" active />}
          >
            <Card>
              <CardHeader>Rating</CardHeader>
              <CardBody>
                <Form onSubmit={ratingForm.handleSubmit}>
                  <FormGroup>
                    <Label>Tiêu đề</Label>
                    <Input
                      name="title"
                      value={ratingForm.values.title}
                      onChange={ratingForm.handleChange}
                      invalid={ratingForm.errors.title}
                    />
                    <FormFeedback>{ratingForm.errors.title}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Điểm tối đa</Label>
                    <Input
                      invalid={ratingForm.errors.maxScore}
                      name="maxScore"
                      type="number"
                      value={ratingForm.values.maxScore}
                      onChange={ratingForm.handleChange}
                    />
                    <FormFeedback>{ratingForm.errors.maxScore}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Mô tả</Label>
                    <Input
                      name="description"
                      type="textarea"
                      value={ratingForm.values.description}
                      onChange={ratingForm.handleChange}
                    />
                  </FormGroup>
                  <LaddaButton
                    className="btn btn-shadow btn-success"
                    color="success"
                    loading={loading}
                    data-style={ZOOM_IN}
                  >
                    Lưu
                  </LaddaButton>
                </Form>
              </CardBody>
            </Card>
          </BlockUi>
        </Col>
      </Row>
    </div>
  )
}

export default RatingForm
