import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import React, { useEffect, useRef, useState } from 'react'
import LaboUploadAdapter from './LaboUploadAdapter'

// const ClassicEditor = require("@ckeditor/custom-build/build/ckeditor");

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new LaboUploadAdapter(loader)
  }
}

function CkEditor5Formik({ formik, name, ...props }) {
  const editorRef = useRef()
  const [editor, setEditor] = useState(null)

  useEffect(() => {
    if (editor) {
      if (editor.getData() === '') editor.setData(formik.values[name])
    }
  }, [formik.values[name]])

  useEffect(() => {
    ClassicEditor.create(editorRef.current, {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'indent',
          'outdent',
          '|',
          'MathType',
          'ChemType',
          'specialCharacters',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'undo',
          'redo',
        ],
      },
      language: 'en',
      image: {
        toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },
      licenseKey: '',
      extraPlugins: [MyCustomUploadAdapterPlugin],
    })
      .then((editor) => {
        setEditor(editor)
        console.log(editor)
        editor.model.document.on('change:data', () => {
          formik.setFieldValue(name, editor.getData())
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return <div ref={editorRef}></div>
}

CkEditor5Formik.propTypes = {}

export default CkEditor5Formik
