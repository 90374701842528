import Common from '../utils/common'
import Configuration from '../utils/configuration'
import { getData, postData } from '../utils/data'

export const getCategory = (id, options) => {
  if (options === undefined) options = {}
  if (options.merchantId === undefined)
    options.merchantId = localStorage.getItem('merchantId')
  if (options.languageId === undefined)
    options.languageId = Configuration.languageVi
  return Common.getDataGraphQl(`
        {
            category(param:{id:"${id}",languageId:"${options.languageId}",merchantId:"${options.merchantId}"})
            {
                id,
                name,                
                description,
                type,
                images {
                  id,
                  path,
                  isFeatured
                }
            }
          }
        `)
}

export const getCategories = (options) => {
  if (options === undefined) options = {}
  if (options.merchantId === undefined)
    options.merchantId = localStorage.getItem('merchantId')
  if (options.languageId === undefined)
    options.languageId = localStorage.getItem(Configuration.tokenLanguage)
  if (options.limit === undefined) options.limit = 100
  if (options.offset === undefined) options.offset = 0
  if (options.order === undefined) options.order = 'desc'
  if (options.sort === undefined) options.sort = 'createdDate'
  options.keyword = options.keyword === undefined ? '' : options.keyword
  options.published = options.published === undefined ? '' : `published: ${options.published}`
  // if (options.parentId === undefined) options.parentId = Common.GUID_EMPTY
  // if (options.type === undefined) options.type = 'articles'
  let type
  if (typeof options.type === 'number') type = options.type
  else
    switch (options.type) {
      case 'address':
        type = 1
        break
      case 'article':
        type = 2
        break
      case 'product':
        type = 3
        break
      case 'customer':
        type = 4
        break
      case 'rank':
        type = 8
        break
      case 'cart':
        type = 10
        break
      case 'home':
        type = 32
        break
      case 'menu':
        type = 256
        break
      case 'order':
        type = 1024
        break
      case 'template':
        type = 128
        break
      case 'tag':
        type = 32768
        break
      default:
        type = null
        break
    }

  return getData.getDataGraphQl(`
    {
        categories(param:{${type !== null ? `type:${type}` : ''}, keyword:"${
    options.keyword
  }",limit:${options.limit},offset:${options.offset},order:"${
    options.order
  }",sort:"${options.sort}"
          ,languageId:"${options.languageId}",merchantId:"${
    options.merchantId
  }", ${
    options.parentId !== undefined ? `parentId:"${options.parentId}"` : ''
  }, ${options.published}}) 
        {
            totalCount,
            message, 
            success,
            items{
                id,
                name,
                friendlyUrl,
                subDescription,
                pathName,
                description,
                images {
                    id, 
                    path,
                    isFeatured,
                    displayOrder
                },
                parentId,
                articleCategories {
                    id
                },
                productCategories {
                    id,
                    name
                },
                displayOrder,
                published,
                createdDate,
                modifiedDate,
            }
        }
    }
    `)
}

export const sortCategories = (categoryIds) => {
  return postData.sendCommand('Category', 'SortCategories', {
    Id: Common.guid(),
    CategoryIds: categoryIds,
    ModifiedDate: Common.formatDateTimeMoment(new Date()),
    ModifiedBy: localStorage.getItem('userId'),
  })
}

export const getMappingMerchantTypeAndMerchantTypeNumber = () => {
  return getData.getDataGraphQl(
    `
{categories(param: {languageId: "838aef56-78bb-11e6-b5a6-00155d582814", 
  merchantId: "00000000-0000-0000-0000-000000000000", type:2097152, limit:1000}){
  items{
    id,
    name,
    categories{
      id
      name
    }
  }
}}
`
  )
}

export const getCategoriesIgnoreChildren = (id, type, languageId) => {
  const _languageId =
    languageId || localStorage.getItem(Configuration.tokenLanguage)

  return getData.getDataGraphQl(`
  {
    parentignorechildren(param: {targetId: "${id}", languageId: "${_languageId}", 
      merchantId: "${localStorage.getItem('merchantId')}", type:${type}}){
      items{
        id,
        name,
        pathName,
      }
    }
  }
  `)
}
