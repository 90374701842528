import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import Common from '../../utils/common'
import { getData, postData } from '../../utils/data'
import useFetchDataTable from '../CommonComponent/CustomHooks/useFetchDataTable'
import Header from '../CommonComponent/Header'
import ReactTableList from '../CommonComponent/TableList/ReactTableList'

const columns = [
  {
    Header: 'Tên',
    accessor: 'name',
    resizable: false,
  },
  {
    accessor: 'active',
    Header: 'Hoạt động',
    Cell: (props) => (
      <div>
        {props.value ? (
          <div className="mb-2 mr-2 badge badge-success">Hoạt động</div>
        ) : (
          <div className="mb-2 mr-2 badge badge-danger">Không hoạt động</div>
        )}
      </div>
    ),
    resizable: false,
    width: 150,
  },

  {
    id: 'availableTo',
    Header: 'Từ ngày',
    accessor: (d) => {
      const date = new Date(d.availableTo)
      return Common.formatDateTime(date, 'dd-mm-yyyy')
    },
    resizable: false,
    width: 130,
  },
  {
    id: 'availableFrom',
    Header: 'Đến ngày',
    accessor: (d) => {
      const date = new Date(d.availableFrom)
      return Common.formatDateTime(date, 'dd-mm-yyyy')
    },
    resizable: false,
    width: 130,
  },
  {
    Header: 'Giá trị',
    accessor: 'value',
    //resizable: false,
    // width: 400
    Cell: (props) => Common.formatMoney(props.value, 0, 3),
  },
  {
    id: 'totalCode',
    Header: 'Tổng',
    accessor: 'totalCode',
    resizable: false,
    width: 100,
  },
  {
    id: 'totalImported',
    Header: 'Tồn',
    accessor: 'totalImported',
    resizable: false,
    width: 100,
  },
  {
    id: 'totalLocked',
    Header: 'Đang xử lý',
    accessor: 'totalLocked',
    resizable: false,
    width: 100,
  },
  {
    id: 'totalCodeExported',
    Header: 'Đã xuất',
    accessor: 'totalCodeExported',
    resizable: false,
    width: 100,
  },
  {
    id: 'evoucherCode',
    Header: 'Đã dùng',
    accessor: 'totalCodeUsed',
    resizable: false,
    width: 100,
  },
  {
    Header: 'Thao tác',
    id: 'thao-tac',
    accessor: (d) => d,
    Cell: (props) => {
      const isActive = props.value.active
      return (
        <div>
          <Link
            to={`/evoucher/generate-code/${props.value.id}`}
            className={`${!isActive ? 'disabled' : ''}`}
            title={
              !isActive
                ? 'Evoucher không hoạt động, không thể sử dụng tính năng này'
                : 'Gene code'
            }
          >
            <button className="btn-icon btn-icon-only btn btn-link btn btn-link pl-1 pr-1">
              <i className="pe-7s-plus btn-icon-wrapper"></i>
            </button>
          </Link>

          <Link
            to={`/evoucher/importevouchercode/${props.value.id}`}
            className={`${!isActive ? 'disabled' : ''}`}
            title={
              !isActive
                ? 'Evoucher không hoạt động, không thể sử dụng tính năng này'
                : 'Import code'
            }
          >
            <button className="btn-icon btn-icon-only btn btn-link btn btn-link pl-1 pr-1">
              <i className="pe-7s-upload btn-icon-wrapper"></i>
            </button>
          </Link>

          {props.value.totalImported > 0 ? (
            <Link
              to={`/evoucher/export-code/${props.value.id}?totalImported=${props.value.totalImported}`}
              className={`${!isActive ? 'disabled' : ''}`}
              title={
                !isActive
                  ? 'Evoucher không hoạt động, không thể sử dụng tính năng này'
                  : 'Export code'
              }
            >
              <button className="btn-icon btn-icon-only btn btn-link btn btn-link pl-1 pr-1">
                <i className="pe-7s-download btn-icon-wrapper"></i>
              </button>
            </Link>
          ) : null}
        </div>
      )
    },
    filterable: false,
    resizable: false,
    width: 250,
  },
]

function EvoucherList(props) {
  const [data, loading, pageCount, fetchData, totalCount] = useFetchDataTable(
    getData.getEvoucher
  )

  return (
    <Fragment>
      <Header
        title="Danh sách evoucher"
        iconClassName="pe-7s-safe"
        actionsComponent={
          <Fragment>
            <Link className="mr-2" to={`/evoucher/useevoucher`}>
              <Button color="focus">Kích hoạt</Button>
            </Link>
            <Link to={`/evoucher/create`}>
              <Button color="focus">Tạo mới</Button>
            </Link>
          </Fragment>
        }
      />
      <ReactTableList
        columnsSelection
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalCount={totalCount}
        deleteCommand={(ids) =>
          postData.deleteItemCommand('Evoucher', 'DeleteEVouchers', ids)
        }
        changeStatusCommand={(changeStatuses) =>
          postData.changeStatusCommand(
            'Evoucher',
            'ChangeStatusActivateEVouchers',
            changeStatuses
          )
        }
      />
      {/* <TableList name="evouchers" urlName="evoucher" columns={columns} /> */}
      {/* <Route path={`${this.props.match.url}/list`} component={EvoucherList} /> 
                                <Route path={`${this.props.match.url}/create`} component={EvoucherForm} /> */}
    </Fragment>
  )
}

export default EvoucherList
