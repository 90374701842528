import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import axios from "axios";
import React from "react";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import CustomLoader from "../../Layout/Loading/CustomLoader";
import Common from "../../utils/common";
import Configuration from "../../utils/configuration";
import Validation from "../../utils/validation";
import Header from "../CommonComponent/Header";

class GenerateEVoucherCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalInfo: {
                show: false,
                message: "",
                type: "error",
            },
            isSending: false,
        };
    }

    state = {
        number: "",
        Pattern: "",
    };
    onSubmidHandler = (event, value) => {
        this.setState({
            isSending: true,
        });

        const body = {
            Id: this.props.match.params.id,
            MerchantId: localStorage.getItem("merchantId"),
            PatternCode: value.Pattern,
            QuantityOfCode: value.number,
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: localStorage.getItem("userId"),
        };

        // postData
        //     .sendCommand(
        //         "Evoucher",
        //         "GenerateEVoucherCodes",
        //         body
        // )

        const dataSend = {
            CommandName: "GenerateEVoucherCodes",
            Domain: "Evoucher",
            Content: JSON.stringify(body),
            TimeOutSecond: 7,
        };

        const api_url = Configuration.url_api + "/Command/SendSync";
        axios
            .post(api_url, dataSend)
            .then((response) => {
                if (response.data.Success)
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thành công",
                            responesMessage: response.data.Message,
                            type: "success",
                        },
                    });
                else
                    this.setState({
                        modalInfo: {
                            show: true,
                            message: "Thất bại",
                            responesMessage: response.data.Message,
                            type: "error",
                        },
                    });
            })
            .catch((err) => {
                this.setState({
                    modalInfo: {
                        show: true,
                        message: "Thất bại",
                        responesMessage: err + "",
                        type: "error",
                    },
                });
            })
            .finally(() => {
                this.setState({
                    isSending: false,
                });
            });
    };

    render() {
        return (
            <CustomLoader
                isSending={this.state.isSending}
                modalInfo={this.state.modalInfo}
                redirectPath="#/evoucher/list"
            >
                <Header
                    title="Sinh mã Evoucher"
                    description="gene code"
                    iconClassName="pe-7s-plus"
                />
                <Row>
                    <Col md="9">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <Row>
                                    <Col md="9">
                                        <AvForm
                                            onValidSubmit={this.onSubmidHandler}
                                        >
                                            <AvGroup>
                                                <Label for="number">
                                                    Số lượng mã cần thêm{" "}
                                                    <label
                                                        style={{ color: "red" }}
                                                    >
                                                        *
                                                    </label>
                                                </Label>
                                                <AvField
                                                    className="form-control"
                                                    name="number"
                                                    id="number"
                                                    type="number"
                                                    value={this.state.number}
                                                    validate={
                                                        Validation.notEmpty
                                                    }
                                                />
                                            </AvGroup>

                                            <AvGroup>
                                                <Label for="Pattern">
                                                    Định dạng genecode{" "}
                                                    <label
                                                        style={{ color: "red" }}
                                                    >
                                                        *
                                                    </label>
                                                </Label>
                                                <AvField
                                                    className="form-control"
                                                    name="Pattern"
                                                    id="Pattern"
                                                    type="text"
                                                    placeholder="[A.8] : 8 kí tự chữ, [N.4] : 4 kí tự số,[AN.6] : 6 kí tự chữ và số, GIFT-[A.4]-[AN.6]"
                                                    value={this.state.number}
                                                    validate={
                                                        Validation.notEmpty
                                                    }
                                                />
                                            </AvGroup>

                                            <Button
                                                size="lg"
                                                className="mt-3"
                                                color="primary"
                                            >
                                                Thêm mã gene mới
                                            </Button>
                                        </AvForm>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CustomLoader>
        );
    }
}
export default GenerateEVoucherCodes;
