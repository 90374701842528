import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getWarehouses } from '../../Services/WarehouseService'
import useFetchDataTable from '../CommonComponent/CustomHooks/useFetchDataTable'
import Header from '../CommonComponent/Header'
import ReactTableList from '../CommonComponent/TableList/ReactTableList'
import moment from 'moment'

function WarehouseList() {
  const [data, loading, pageCount, fetchData, totalCount] = useFetchDataTable(
    getWarehouses
  )

  const columns = [
    {
      Header: 'Tên',
      accessor: 'name',
    },
    {
      Header: 'Địa chỉ',
      accessor: 'address',
      Cell: ({ value }) => value || null,
    },
    {
      Header: 'Loại',
      accessor: 'type',
      Cell: () => <>-</>,
    },
    {
      accessor: 'status',
      Header: 'Trạng thái',
      filterable: false,
      Cell: (props) => (
        <div>
          {props.value ? (
            <div className="mb-2 mr-2 badge badge-success">Hoạt động</div>
          ) : (
            <div className="mb-2 mr-2 badge badge-danger">Không hoạt động</div>
          )}
        </div>
      ),
    },
    {
      Header: 'Ngày tạo',
      accessor: 'createdDate',
      Cell: ({ value }) => (
        <>{moment(new Date(value)).format('DD-MM-YYYY HH:mm')}</>
      ),
    },
    {
      Header: 'Ngày sửa cuối',
      accessor: 'modifiedDate',
      Cell: ({ value }) => (
        <>{moment(new Date(value)).format('DD-MM-YYYY HH:mm')}</>
      ),
    },
    {
      Header: 'Chức năng',
      accessor: (a) => a,
      Cell: ({ value }) => {
        return (
          <Link to={`/warehouse/detail/${value.id}`}>
            <button
              title="Sửa"
              className="btn-icon btn-icon-only btn btn-link btn btn-link"
            >
              <i className="pe-7s-note btn-icon-wrapper" />
            </button>
          </Link>
        )
      },
    },
  ]
  return (
    <div>
      <Header title="Danh sách kho" iconClassName="pe-7s-user" />

      <ReactTableList
        columnsSelection
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalCount={totalCount}
        deleteCommand={(ids) => console.log(ids)}
        changeStatusCommand={(changeStatuses) => console.log(changeStatuses)}
      />
    </div>
  )
}

export default WarehouseList
