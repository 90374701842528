import $ from 'jquery'
import React, { Fragment, useState } from 'react'
import BlockUi from 'react-block-ui'
import Dropzone from 'react-dropzone'
import { Loader } from 'react-loaders'
import { toast } from 'react-toastify'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import text from '../../utils/text'
import FormGroup from 'reactstrap/es/FormGroup'
import Label from 'reactstrap/es/Label'

function ModalImportCSV({ isOpen, toggle }) {
  const [isSending, setIsSending] = useState(false);
  const [file, setFile] = useState(null);
  const [defaultPassword, setDefaultPassword] = useState("");
  // const toggle = () => {
  //     setIsOpen(!isOpen);
  // };
  const onDrop = (files) => {
    setFile(files[0]);
  };

  const uploadHandle = () => {
    if (!file) toast["error"]("Vui lòng chọn file");

    setIsSending(true);

    const formData = new FormData();
    formData.append("token", localStorage.getItem(Configuration.userCookie));
    formData.append("UploadedFile", file);
    formData.append("languageId", localStorage.getItem(Configuration.tokenLanguage));
    formData.append("password", defaultPassword);

    var ajaxRequest = $.ajax({
      type: 'POST',
      url: `${Configuration.url_api}/File/ImportExcel`,
      contentType: false,
      processData: false,
      data: formData,
    });

    ajaxRequest.done((response, textStatus) => {
      if (response.Success) {
        toast["success"](text("SUCCESS"));
        console.log(response.Data);
      } else toast["error"](text("ERROR"));
      // setIsSending(false);
      // setFile(null);
      // setIsOpen(false);
      Swal.fire(text("SUCCESS"), "Đang trong quá trình import, dữ liệu sẽ đươc cập nhật trong giây lát.", "success").then((result) =>
        window.location.reload()
      );
    });
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Import Excel</ModalHeader>
        <BlockUi
          tag="div"
          blocking={isSending}
          className="block-overlay-dark"
          loader={<Loader color="#ffffff" active />}
        >
          <ModalBody>
            <FormGroup>
              <Label>Mật khẩu mặc định</Label>
              <input
                value={defaultPassword}
                onChange={e => setDefaultPassword(e.target.value)}
                className={"form-control"}
                placeholder={"Nếu để trống, mật khẩu mặc định sẽ là số điện thoại"}
              />
            </FormGroup>
            {file ? (
              <Row>
                <Col md="6">
                  <p>{file ? file.name : null}</p>
                </Col>
                <Col md="6">
                  <Button className="d-block ml-auto" color="success" onClick={uploadHandle}>
                    Upload
                  </Button>
                </Col>
              </Row>
            ) : null}
            <div className="dropzone-wrapper">
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input id="file" {...getInputProps()} />
                    <div className="dropzone-content">
                      <p>Try dropping some files here, or click to select files to upload.</p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </ModalBody>
        </BlockUi>
      </Modal>
    </Fragment>
  );
}

export default ModalImportCSV;
