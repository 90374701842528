const navItemOptions = [
  {
    label: "Dashboard",
    value: "/#/dashboards/home",
  },
  {
    label: "Đơn hàng",
    options: [
      { label: "Danh sách đơn hàng", value: "/#/report/order" },
      { label: "Luồng đơn hàng", value: "/#/order/list" },
      { label: "Đơn hàng dạng pivottable", value: "/#/report/orderpivot" },
    ],
  },
  {
    label: "Báo cáo",
    options: [
      { label: "Đơn hàng", value: "/#/report/order" },
      { label: "EVoucher", value: "/#/report/evoucher" },
      {
        label: "Đơn hàng affiliate pivottable",
        value: "/#/report/orderaffiliate",
      },
    ],
  },
  {
    label: "Bài viết",
    options: [
      { label: "Danh sách bài viết", value: "/#/articles/list" },
      { label: "Tạo mới bài viết", value: "/#/articles/create" },
      {
        label: "Danh sách chuyên mục bài viết",
        value: "/#/articles/categories/list",
      },
      {
        label: "Tạo mới chuyên mục bài viết",
        value: "/#/articles/categories/create",
      },
      {
        label: "Quyền đọc chuyên mục bài viết",
        value: "/#/articles/read-privilege",
      },
      {
        label: "Bài viết liên merchant",
        value: "/#/articles/list-multi-merchant",
      },
    ],
  },
  {
    label: "Dữ liệu việc làm",
    options: [
      { label: "Danh sách", value: "/#/articles/board-opening/list" },
      { label: "Tạo mới", value: "/#/articles/board-opening/create" },
    ],
  },
  {
    label: "Sản phẩm",
    options: [
      { label: "Danh sách sản phẩm", value: "/#/product/list" },
      { label: "Tạo mới bài viết", value: "/#/articles/create" },
      {
        label: "Tạo mới sản phẩm",
        value: "/#/product/create",
      },
      {
        label: "Danh mục sản phẩm",
        value: "/#/product-categories/list",
      },
      {
        label: "Tạo mới danh mục sản phẩm",
        value: "/#/product-categories/create",
      },
      {
        label: "Danh sách thuộc tính sản phẩm",
        value: "/#/attribute/list",
      },
      {
        label: "Tạo mới thuộc tính sản phẩm",
        value: "/#/attribute/create",
      },
    ],
  },

  {
    label: "Sản phẩm E-Voucher",
    options: [
      {
        label: "Danh sách sản phẩm e-voucher",
        value: "/#/product-evoucher/list",
      },
      {
        label: "Tạo mới sản phẩm e-voucher",
        value: "/#/product-evoucher/create",
      },
    ],
  },
  {
    label: "Sản phẩm kỳ nghỉ",
    options: [
      {
        label: "Danh sách sản phẩm kỳ nghỉ",
        value: "/#/product/vacation/list",
      },
      {
        label: "Tạo mới sản phẩm kỳ nghỉ",
        value: "/#/product/vacation/create",
      },
    ],
  },
  {
    label: "Sản phẩm sub merchant",
    options: [
      {
        label: "Danh sách sub merchant",
        value: "/#/product/sub-merchant/list",
      },
    ],
  },
  {
    label: "Sản phẩm thành viên",
    options: [
      {
        label: "Danh sách thành viên",
        value: "/#/product-member/list",
      },
      {
        label: "Tạo mới thành viên",
        value: "/#/product-member/create",
      },
      {
        label: "Danh sách hội viên",
        value: "/#/member/list",
      },
      {
        label: "Danh sách hạng",
        value: "/#/member-categories/list",
      },
      {
        label: "Tạo mới hạng",
        value: "/#/member-categories/create",
      },
      {
        label: "Điểm thành viên",
        value: "/#/user-point/list",
      },
    ],
  },
  {
    label: "Sản phẩm member subcription",
    options: [
      {
        label: "Danh sách sản phẩm member subcription",
        value: "/#/product-member-subcription/list",
      },
      {
        label: "Tạo mới sản phẩm member subcription",
        value: "/#/product-member-subcription/create",
      },
    ],
  },
  {
    label: "Thư viện video",
    options: [
      {
        label: "Thư viện",
        value: "/#/video-gallery/list",
      },
      {
        label: "Tải lên",
        value: "/#/video-gallery/create",
      },
      {
        label: "Danh sách",
        value: "/#/video/list",
      },
      {
        label: "Tạo mới",
        value: "/#/video/create",
      },
    ],
  },
  {
    label: "Thư viện ảnh",
    options: [
      {
        label: "Thư viện",
        value: "/#/gallery/list",
      },
      {
        label: "Tải lên",
        value: "/#/gallery/upload",
      },
      {
        label: "Phân loại ảnh",
        value: "/#/image-categories/list",
      },
    ],
  },
  {
    label: "Form builder",
    options: [
      {
        label: "Tạo form",
        value: "/#/form-builder/create",
      },
      {
        label: "Danh sách form",
        value: "/#/form-builder/list",
      },
    ],
  },
  {
    label: "Khóa học",
    options: [
      {
        label: "Danh sách khóa học",
        value: "/#/course/v2/list",
      },
      {
        label: "Tạo mới khóa học",
        value: "/#/course/v2/create",
      },
    ],
  },

  {
    label: "Sản phẩm khóa học",
    options: [
      {
        label: "Danh sách sản phẩm khóa học",
        value: "/#/course/list",
      },
      {
        label: "Tạo mới sản phẩm khóa học",
        value: "/#/course/create",
      },
    ],
  },

  {
    label: "Học liệu chia sẻ",
    options: [
      {
        label: "Danh sách học liệu chia sẻ",
        value: "/#/course/categories",
      },
      {
        label: "Tạo mới học liệu chia sẻ",
        value: "/#/course/categories/create",
      },
      {
        label: "Phân quyền học liệu chia sẻ",
        value: "/#/course/read-privilege",
      },
    ],
  },
  {
    label: "Thư viện sách",
    options: [
      {
        label: "Danh sách sách",
        value: "#/books/list",
      },
      {
        label: "Tạo mới sách",
        value: "#/books/create",
      },
      {
        label: "Phân loại sách",
        value: "#/books/categories",
      },
      {
        label: "Tạo mới phân loại sách",
        value: "#/books/categories/create",
      },
      {
        label: "Danh sách sách liên merchant",
        value: "#/books/list-multi-merchant",
      },
    ],
  },
  {
    label: "Thư viện slide bài giảng",
    options: [
      {
        label: "Danh sách",
        value: "#/slide/list",
      },
      {
        label: "Tạo mới",
        value: "#/slide/create",
      },
    ],
  },
  {
    label: "Thư viện video bài giảng",
    options: [
      {
        label: "Danh sách video bài giảng",
        value: "/#/video-gallery/list",
      },
      {
        label: "Tạo mới video bài giảng",
        value: "/#/video-gallery/create",
      },
      {
        label: "Danh sách video kèm nội dung",
        value: "/#/video/list",
      },
      {
        label: "Tạo mới video kèm nội dung",
        value: "/#/video/create",
      },
    ],
  },
  {
    label: "Thư viện video Quiz",
    options: [
      {
        label: "Tạo mới video quiz",
        value: "/#/quiz-video/create",
      },
      {
        label: "Danh sách video quiz",
        value: "/#/quiz-video/list",
      },
    ],
  },
  {
    label: "Thư viện câu hỏi, kiểm tra",
    options: [
      {
        label: "Danh sách câu hỏi, kiểm tra",
        value: "/#/quiz/list",
      },
      {
        label: "Tạo mới câu hỏi, kiểm tra",
        value: "/#/quiz/create",
      },
      {
        label: "Danh sách kết quả",
        value: "/#/quiz/result",
      },
    ],
  },
  {
    label: "Thư viện câu hỏi, kiểm tra",
    options: [
      {
        label: "Danh sách câu hỏi, kiểm tra",
        value: "/#/quiz/list",
      },
      {
        label: "Tạo mới câu hỏi, kiểm tra",
        value: "/#/quiz/create",
      },
      {
        label: "Danh sách kết quả",
        value: "/#/quiz/result",
      },
    ],
  },
  {
    label: "Thành viên",
    options: [
      {
        label: "Danh sách thành viên",
        value: "/#/user/list",
      },
      {
        label: "Tạo mới thành viên",
        value: "/#/user/create",
      },
      {
        label: "Nhóm thành viên",
        value: "/#/user-categories/list",
      },
      {
        label: "Tạo mới nhóm",
        value: "/#/user-categories/create",
      },
      {
        label: "Chăm sóc thành viên",
        value: "/#/user/kanban",
      },
    ],
  },
  {
    label: "Email",
    options: [
      {
        label: "Danh sách email",
        value: "/#/email/list",
      },
      {
        label: "Tạo mới email",
        value: "/#/email/create",
      },
      {
        label: "Email đã gửi",
        value: "/#/email/sended/list",
      },
    ],
  },
  {
    label: "Evoucher",
    options: [
      {
        label: "Danh sách evoucher",
        value: "/#/evoucher/list",
      },
      {
        label: "Tạo mới evoucher",
        value: "/#/evoucher/create",
      },
      {
        label: "Kích hoạt evoucher",
        value: "/#/evoucher/useevoucher",
      },
    ],
  },
  {
    label: "Giao diện",
    options: [
      {
        label: "Thư viện giao diện",
        value: "/#/settings/theme",
      },
      {
        label: "Tùy chỉnh giao diện",
        value: "/#/theme",
      },
    ],
  },
  {
    label: "Thanh điều hướng",
    options: [
      {
        label: "Danh sách thanh điều hướng",
        value: "/#/navigation/list",
      },
      {
        label: "Tạo mới thanh điều hướng",
        value: "/#/navigation/create",
      },
    ],
  },
  {
    label: "Landing Page",
    options: [
      {
        label: "Danh sách landing page",
        value: "/#/landing-page/list",
      },
      {
        label: "Tạo mới landing page",
        value: "/#/landing-page/create",
      },
    ],
  },
  {
    label: "Tiếp thị liên kết",
    options: [
      {
        label: "Cài đặt tiếp thị liên kết",
        value: "/#/report/setting",
      },
      {
        label: "Báo cáo tiếp thị liên kết",
        value: "/#/report/orderaffiliate",
      },
    ],
  },
  {
    label: "Sự kiện",
    options: [
      {
        label: "Danh sách",
        value: "/#/events/list",
      },
      {
        label: "Tạo mới",
        value: "/#/events/create",
      },
    ],
  },
  {
    label: "Cài đặt",
    options: [
      {
        label: "Cài đặt chung",
        value: "/#/settings/config-merchant",
      },
      {
        label: "Kết nối",
        value: "/#/settings/service-connectivites",
      },
      {
        label: "Cài đặt trang học viên",
        value: "/#/settings/config-elearning",
      },
    ],
  },
  {
    label: "Rating",
    options: [
      {
        label: "Danh sách rating",
        value: "/#/rating/list",
      },
      {
        label: "Tạo mới rating",
        value: "/#/rating/create",
      },
    ],
  },
  {
    label: "Thông báo",
    options: [
      {
        label: "Danh sách thông báo",
        value: "#/setting-notification/list",
      },
      {
        label: "Notification transactions",
        value: "#/setting-notification/transactions",
      },
      {
        label: "Tạo mới thông báo",
        value: "#/setting-notification/create",
      },
      {
        label: "Gửi thông báo",
        value: "#/setting-notification/send",
      },
    ],
  },
  {
    label: "Địa chỉ",
    options: [
      {
        label: "Danh sách địa chỉ",
        value: "/#/address/list",
      },
      {
        label: "Tạo mới địa chỉ",
        value: "/#/address/create",
      },
      {
        label: "Danh sách phân loại địa chỉ",
        value: "/#/address-categories/list",
      },
      {
        label: "Tạo mới phân loại địa chỉ",
        value: "/#/address-categories/create",
      },
    ],
  },
  {
    label: "Cài đặt theme web",
    options: [
      {
        label: "Danh sách theme",
        value: "#/theme-web/list",
      },
      {
        label: "Tạo mới theme",
        value: "#/theme-web/create",
      },
      {
        label: "Phân loại theme",
        value: "#/theme-web/categories/list",
      },
    ],
  },
  {
    label: "Cài đặt sections",
    options: [
      {
        label: "Danh sách",
        value: "#/theme-web/list-section",
      },
      {
        label: "Tạo mới",
        value: "#/theme-web/section/create",
      },
      {
        label: "Phân loại",
        value: "#/theme-web/section/categories/list",
      },
    ],
  },
  {
    label: "Theme template",
    options: [
      {
        label: "Danh sách",
        value: "/#/theme-template/list",
      },
      {
        label: "Tạo mới",
        value: "/#/theme-template/create",
      },
      {
        label: "Phân loại",
        value: "#/theme-template/categories/list",
      },
    ],
  },
  {
    label: "Khuyến mại",
    options: [
      {
        label: "Tạo mới khuyến mại đơn hàng",
        value: "/#/promotion/create",
      },
      {
        label: "Tạo mới khuyến mại sản phẩm",
        value: "/#/promotion/create-product",
      },
      {
        label: "Danh sách khuyến mại",
        value: "/#/promotion/list",
      },
    ],
  },
  {
    label: "Quản lý kho",
    options: [
      {
        label: "Danh sách kho",
        value: "#/warehouse/list",
      },
      {
        label: "Tạo mới kho",
        value: "#/warehouse/create",
      },
    ],
  },
  {
    label: "Bảng Kanban",
    options: [
      {
        label: "Danh sách kanban",
        value: "/#/crm/kanban/list",
      },
      {
        label: "Tạo mới kanban",
        value: "/#/crm/kanban/create",
      },
    ],
  },
  {
    label: "Loại merchant",
    options: [
      {
        label: "Danh sách loại merchant",
        value: "/#/merchant-type/list",
      },
      {
        label: "Tạo mới tính năng",
        value: "/#/merchant-type/create",
      },
    ],
  },
  {
    label: "Tính năng Merchant",
    options: [
      {
        label: "Danh sách tính năng",
        value: "/#/feature/list",
      },
      {
        label: "Tạo mới tính năng",
        value: "/#/feature/create",
      },
      {
        label: "Phân quyền tính năng theo merchant",
        value: "/#/feature/features-merchant",
      },
      {
        label: "Phân quyền tính năng theo user",
        value: "/#/feature/features-user",
      },
    ],
  },
  {
    label: "Sản phẩm Merchant",
    options: [
      {
        label: "Danh sách sản phẩm Merchant",
        value: "/#/product-mechant/list",
      },
      {
        label: "Tạo mới sản phẩm Merchant",
        value: "/#/product-mechant/create",
      },
    ],
  },
  {
    label: "Phòng họp",
    options: [
      {
        label: "Đặt phòng",
        value: "/#/room-booking/rooms",
      },
      {
        label: "Phân loại phòng",
        value: "/#/room-booking/room-category",
      },
    ],
  },
  {
    label: "Office",
    options: [
      {
        label: "Đặt Office",
        value: "/#/office-booking/book",
      },
    ],
  },
  {
    label: "Merchant Type Code",
    options: [
      {
        label: "Danh sách",
        value: "/#/merchant-type-code/list",
      },
      {
        label: "Tạo mới",
        value: "/#/merchant-type-code/create",
      },
      {
        label: "Mapping",
        value: "/#/merchant-type-code/mapping-merchant-type",
      },
    ],
  },
  {
    label: "Certificate",
    options: [
      {
        label: "Danh sách certificate",
        value: "/#/certificate/list",
      },
      {
        label: "Tạo mới certificate",
        value: "/#/certificate/create",
      },
    ],
  },
  {
    label: "Bài viết khóa học",
    options: [
      {
        label: "Danh sách bài viết khóa học",
        value: "/#/course-article/list",
      },
      {
        label: "Tạo mới bài viết khóa học",
        value: "/#/course-article/create",
      },
    ],
  },
  {
    label: "Sản phẩm kỳ nghỉ exchange",
    options: [
      {
        label: "Danh sách sản phẩm kỳ nghỉ exchange",
        value: "/#/exchange-vacation/list",
      },
      {
        label: "Tạo mới sản phẩm kỳ nghỉ exchange",
        value: "/#/exchange-vacation/create",
      },
    ],
  },
  {
    label: "Sản phẩm phí",
    options: [
      {
        label: "Danh sách sản phẩm phí",
        value: "/#/fee-product/list",
      },
      {
        label: "Tạo mới sản phẩm phí",
        value: "/#/fee-product/create",
      },
      {
        label: "Danh sách phân loại sản phẩm phí",
        value: "/#/fee-product/categories/list",
      },
      {
        label: "Tạo mới phân loại sản phẩm phí",
        value: "/#/fee-product/categories/create",
      },
    ],
  },
  {
    label: "Deposit",
    options: [
      {
        label: "Danh sách deposit",
        value: "/#/exchange-vacation/deposit/list",
      },
      {
        label: "Tạo mới deposit",
        value: "/#/exchange-vacation/deposit/create",
      },
    ],
  },
  {
    label: "Hợp đồng",
    value: "/#/contract/list",
  },
  {
    label: "Danh sách công nợ",
    value: "/#/order/listdebt",
  },
  {
    label: "Cài đặt đặt lịch",
    value: "/#/room-booking/setting/config",
  },
  {
    label: "File",
    options: [
      {
        label: "Danh sách file",
        value: "/#/file/list",
      },
      {
        label: "Tạo mới file",
        value: "/#/file/create",
      },
    ],
  },
  {
    label: "Quyền user",
    options: [
      {
        label: "Danh sách quyền user",
        value: "/#/user-right/list",
      },
      {
        label: "Tạo mới quyền user",
        value: "/#/user-right/create",
      },
    ],
  },
  {
    label: "Meeting",
    options: [
      {
        label: "Danh sách meeting",
        value: "/#/meeting/list",
      },
      {
        label: "Tạo mới meeting",
        value: "/#/meeting/create",
      },
      {
        label: "Tạo mới recurring meeting",
        value: "/#/meeting/recurrence/create",
      },
    ],
  },
  {
    label: "Notification template",
    options: [
      {
        label: "Danh sách Notification template",
        value: "/#/notification-template/list",
      },
      {
        label: "Tạo mới Notification template",
        value: "/#/notification-template/create",
      },
    ],
  },
  {
    label: "Sản phẩm điểm",
    options: [
      {
        label: "Danh sách sản phẩm điểm",
        value: "/#/product-exchange-point/list",
      },
      {
        label: "Tạo mới sản phẩm điểm",
        value: "/#/product-exchange-point/create",
      },
      {
        label: "Sửa sản phẩm điểm",
        value: "/#/product-exchange-point/edit",
      },
    ],
  },
];

export default navItemOptions;
