import React from 'react'
import Select from 'react-select'

function SelectFormik({ formik, name, options, ...props }) {
  const onChange = (items) => {
    if (Array.isArray(items)) {
      const itemIds = items.map((item) => item.value)
      formik.setFieldValue(
        name,
        options.filter((option) => itemIds.includes(option.id))
      )
    } else {
      const item = options.find((option) => option.id === items.value)
      formik.setFieldValue(name, item)
    }
  }

  let value = null
  if (formik.values[name]) {
    if (Array.isArray(formik.values[name])) {
      value = formik.values[name].map((item) => ({
        value: item.id,
        label: item.name,
      }))
    } else {
      value = {
        value: formik.values[name].id,
        label: formik.values[name].name,
      }
    }
  }

  return (
    <div>
      <Select
        options={options.map((item) => ({
          value: item.id,
          label: item.pathName || item.name,
        }))}
        onChange={onChange}
        value={value}
        {...props}
      />
    </div>
  )
}

SelectFormik.propTypes = {}

export default SelectFormik
