import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import Configuration from '../../utils/configuration'
import CategoryForm from '../Categories/CategoryForm'
import CategoryList from '../Categories/CategoryList'
import ThemeTemplateForm from './ThemeTemplateForm'
import ThemeTemplateList from './ThemeTemplateList'

class ThemeWeb extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  <Route
                    path={`${this.props.match.url}/create`}
                    component={ThemeTemplateForm}
                  />
                  <Route
                    path={`${this.props.match.url}/edit/:id`}
                    component={ThemeTemplateForm}
                  />
                  <Route
                    path={`${this.props.match.url}/list`}
                    component={ThemeTemplateList}
                  />

                  <Route
                    path={`${this.props.match.url}/categories/list`}
                    render={(props) => (
                      <CategoryList
                        {...props}
                        type={Configuration.categoryTypes.THEME_WEB_TEMPLATE}
                        urlName={`${this.props.match.url}/categories`}
                      />
                    )}
                  />
                  <Route
                    path={`${this.props.match.url}/categories/create`}
                    render={(props) => (
                      <CategoryForm
                        {...props}
                        type={Configuration.categoryTypes.THEME_WEB_TEMPLATE}
                      />
                    )}
                  />
                  <Route
                    path={`${this.props.match.url}/categories/edit/:id`}
                    render={(props) => (
                      <CategoryForm
                        {...props}
                        type={Configuration.categoryTypes.THEME_WEB_TEMPLATE}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ThemeWeb
