import React from 'react'
import { Col } from 'reactstrap'

function OfficeConfigGeneral() {
  return (
    <div>
      <Col lg="12" xl="9">
        {/* <FormGroup row>
                    <Label sm="6">Partern Code</Label>
                    <Col sm="6">
                        <input
                            className="form-control"
                            name="parternCode"
                            ref={register({
                                required: "Không được để trống",
                            })}
                        />
                        <FormFeedback style={{ display: "block" }}>
                            {errors.parternCode && errors.parternCode.message}
                        </FormFeedback>
                    </Col>
                </FormGroup>

                <LoadingButton
                    type="submit"
                    loading={loading}
                    onClick={handleSubmit(onsubmit)}
                    className="btn btn-primary btn-square d-block ml-auto"
                >
                    Lưu
                </LoadingButton> */}
      </Col>
    </div>
  )
}

export default OfficeConfigGeneral
