import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import LaboUploadAdapter from './LaboUploadAdapter'
// import MathType from '@wiris/mathtype-ckeditor5/src/plugin';

// const ClassicEditor = require("@ckeditor/custom-build/build/ckeditor");
const ClassicEditor = require('@ckeditor/ckeditor5-build-classic')

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new LaboUploadAdapter(loader)
  }
}

function CkEditor5({ ...props }, ref) {
  const editorRef = useRef()
  const [editor, setEditor] = useState(null)

  useImperativeHandle(ref, () => ({
    setData: setData,
    getData: getData,
  }))

  function getData() {
    return editor.getData()
  }

  function setData(data) {
    return editor.setData(data)
  }

  useEffect(() => {
    // const ClassicEditor = window.ClassicEditor

    ClassicEditor.create(editorRef.current, {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'indent',
          'outdent',
          '|',
          'MathType',
          'ChemType',
          'specialCharacters',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'undo',
          'redo',
        ],
      },
      language: 'en',
      image: {
        toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },
      licenseKey: '',
      extraPlugins: [MyCustomUploadAdapterPlugin],
    })
      .then((editor) => {
        setEditor(editor)
        console.log(editor)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return <div ref={editorRef}></div>
}

CkEditor5.propTypes = {}

export default forwardRef(CkEditor5)
