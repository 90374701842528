import 'bootstrap-daterangepicker/daterangepicker.css'
import React, { Fragment, useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, {
  Comparator,
  selectFilter,
  textFilter,
} from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {
  ColumnToggle,
  CSVExport,
} from 'react-bootstrap-table2-toolkit'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import Swal from 'sweetalert2'
import {
  activeAction,
  deactiveAction,
  genCodesAction,
  getPromotionsAction,
  sendGiftCodeToUsersAction,
} from '../../reducers/Promotion'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'
import { getData } from '../../utils/data'
import Footer from '../CommonComponent/Footer'
import Select from '../CommonComponent/Select'

//import { getData } from '../../utils/data';
const { ExportCSVButton } = CSVExport
function List() {
  const { handleSubmit, register, errors } = useForm()
  const [users, setUsers] = useState([])
  const onSubmit = (value) => {
    var body = {
      Id: promotionCode.id,
      MerchantId: promotionCode.merchantId,
      QuantityCode: value.quantityofcode,
      PatternCode: value.typeofcode,
      ExpireAfter: value.date,
      Active: true,
      MaximumUsed: value.timeuse,
      MaximumNumberOfUsersPerGiftCode: value.oneperuse,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: localStorage.getItem(Configuration.userId),
    }
    genCodesAction(body)
    if (value.quantityofcode > 100) {
      Swal.fire('Hệ thống đang tạo mã, có thể mất một lúc...')
    }
    toggleModalGenCode()
  }
  const convertData = (pluginData) => {
    switch (pluginData.pluginId) {
      case 'f033a5b6-8810-4053-9934-68a19035c760':
        var temp1 = JSON.parse(pluginData.data)
        return Common.formatMoney(temp1.MinimumGrandTotal, 0, 3)
      case 'f033a5b6-8810-4053-9934-68a19035c768':
        var temp2 = JSON.parse(pluginData.data)
        return (
          'Từ ' +
          Common.formatDateTime(temp2.From) +
          ' đến ' +
          Common.formatDateTime(temp2.To)
        )
      case 'f033a5b6-8810-4053-9934-68a19035c763':
        var temp3 = JSON.parse(pluginData.data)
        return Common.formatMoney(temp3.Amount, 0, 3)
      case 'f033a5b6-8810-4053-9934-68a19035c767':
        var temp4 = JSON.parse(pluginData.data)
        return (
          'Giảm ' +
          temp4.Percent +
          '% nhưng không quá ' +
          Common.formatMoney(temp4.MaxDiscountAmount, 0, 3)
        )
      default:
        break
    }
  }

  const selectOptions = [
    { value: 0, label: 'Đang hoạt đông' },
    { value: 1, label: 'Chưa hoạt động' },
  ]
  const selectTypeOptions = [
    { value: 1, label: 'Giảm giá sản phẩm' },
    { value: 2, label: 'Giảm giá đơn hàng' },
    { value: 4, label: 'Giảm giá đơn hàng theo mã' },
  ]
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Hiển thị {from} đến {to} trên {size} kết quả.
    </span>
  )

  var currentDate = new Date()
  var from = new Date(currentDate.getTime() - 60 * 24 * 60 * 60 * 1000)

  const [option, getPage] = useState({
    search: '',
    type: null,
    active: null,
    offset: 0,
    sort: 'createdDate',
    order: 'desc',
    limit: 10,
    from: Common.formatDateTime(from),
    to: Common.formatDateTime(currentDate),
  })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPromotionsAction(option))
  }, [option])
  const item = useSelector((state) => state.Promotion.promotions)

  option.totalSize = item.totalCount
  option.showTotal = true
  option.paginationTotalRenderer = customTotal
  option.sizePerPageList = [
    {
      text: '10',
      value: 10,
    },
    {
      text: '50',
      value: 50,
    },
    {
      text: '100',
      value: 100,
    },
    {
      text: 'Tất cả',
      value: item.totalCount,
    },
  ]

  var handleSort = (sort, order) => {
    option.sort = sort
    option.order = order
  }
  var handleTableChange = (
    type,
    { page, sizePerPage, sort, order, searchText, filters }
  ) => {
    const offset = (page - 1) * sizePerPage

    var active = null

    if (filters.active !== undefined) {
      active = filters.active.filterVal
    }

    var type = null

    if (filters.type !== undefined) {
      type = filters.type.filterVal
    }

    var search = ''
    if (filters.code !== undefined) {
      search = filters.code.filterVal
    }
    getPage({
      search: search,
      active: active,
      type: type,
      offset: offset,
      limit: sizePerPage,
      sort: option.sort,
      order: option.order,
      from: Common.formatDateTime(option.from),
      to: Common.formatDateTime(option.to),
    })
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Tên chương trình',
      csvText: 'Tên chương trình',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: (cell, row) => <b>{cell}</b>,
      onSort: handleSort,
      filter: textFilter({
        //delay: 1000, // default is 500ms
        comparator: Comparator.LIKE,
        placeholder: 'Nhập tên',
      }),
      footer: '',
    },
    {
      dataField: 'type',
      text: 'Loại',
      csvText: 'Loại',
      align: 'ceter',
      csvFormatter: (cell, row) => {
        switch (cell) {
          case 1:
            return 'Giảm giá sản phẩm'
          case 2:
            return 'Giảm giá đơn hàng'
          case 4:
            return 'Giảm giá đơn hàng theo mã'
          default:
            return '-'
        }
      },
      formatter: (cell, row) => {
        switch (cell) {
          case 1:
            return 'Giảm giá sản phẩm'
          case 2:
            return 'Giảm giá đơn hàng'
          case 4:
            return 'Giảm giá đơn hàng theo mã'
          default:
            return '-'
        }
      },

      headerAlign: 'center',
      filter: selectFilter({
        placeholder: 'Lựa chọn',
        options: selectTypeOptions,
      }),
      footer: '',
    },
    {
      dataField: 'active',
      text: 'Hoạt động',
      align: 'center',
      formatter: (cell, row) =>
        cell ? (
          <div className="mx-auto d-block badge badge-success">
            Đang hoạt dộng
          </div>
        ) : (
          <div className="mx-auto d-block badge badge-danger">
            Chưa hoạt động
          </div>
        ),

      headerAlign: 'center',
      filter: selectFilter({
        placeholder: 'Lựa chọn',
        options: selectOptions,
      }),
      footer: '',
    },
    {
      dataField: 'note',
      text: 'Ghi chú',
      headerAlign: 'center',
      align: 'left',
      sort: true,
      footer: '',
    },
    {
      dataField: 'totalUsed',
      text: 'Số lần sử dụng',
      headerAlign: 'center',
      align: 'right',
      sort: true,
      footerAlign: 'right',

      footer: (columnData) =>
        Common.formatNumber(
          columnData.reduce((acc, item) => acc + item, 0),
          0,
          3
        ), // console.log(columnData)
    },
    {
      dataField: 'totalCodes',
      text: 'Tổng sỗ mã',
      headerAlign: 'center',
      align: 'right',
      sort: true,
      // formatter: (cell, row) =>
      //     Common.formatMoney(cell, 0, 3),
      footerAlign: 'right',
      footer: (columnData) =>
        Common.formatNumber(
          columnData.reduce((acc, item) => acc + item, 0),
          0,
          3
        ), // console.log(columnData)
    },
    {
      dataField: 'totalAmountUsed',
      text: 'Tổng tiền đã dùng',
      headerAlign: 'center',
      formatter: (cell, row) => Common.formatMoney(cell, 0, 3),
      align: 'right',
      sort: true,
      footerAlign: 'right',
      footer: (columnData) =>
        Common.formatMoney(
          columnData.reduce((acc, item) => acc + item, 0),
          0,
          3
        ), // console.log(columnData)
    },
    {
      dataField: 'createdDate',
      text: 'Ngày tạo',
      align: 'center',
      headerAlign: (column, colIndex) => 'center',
      formatter: (cell, row) => Common.formatDateTime(cell, 'dd-mm-yyyy hh:mm'),
      sort: true,
      onSort: handleSort,
      footer: '',
    },
  ]
  const conditionColumns = [
    {
      dataField: 'pluginName',
      text: 'Tên',
      headerAlign: 'center',
      align: 'left',
      sort: true,
      formatter: (cell, row) => <b>{cell}</b>,
    },
    {
      dataField: 'priority',
      text: 'Độ ưu tiên',
      headerAlign: 'center',
      align: 'right',
      sort: true,
      formatter: (cell, row) => <b>{cell}</b>,
    },
    {
      dataField: 'data',
      text: 'Dữ liệu',
      align: 'left',
      headerAlign: 'center',
      formatter: (cell, row) => convertData(row),
    },
  ]
  const resultColumns = [
    {
      dataField: 'pluginName',
      text: 'Tên',
      headerAlign: 'center',
      align: 'left',
      sort: true,
      formatter: (cell, row) => <b>{cell}</b>,
    },
    {
      dataField: 'priority',
      text: 'Độ ưu tiên',
      headerAlign: 'center',
      align: 'right',
      sort: true,
      formatter: (cell, row) => <b>{cell}</b>,
    },
    {
      dataField: 'data',
      text: 'Dữ liệu',
      align: 'left',
      headerAlign: 'center',
      formatter: (cell, row) => convertData(row),
    },
  ]
  const expandRow = {
    renderer: (row) => (
      <Container>
        <Row>
          <Col md="6">
            <h4>Điều kiện</h4>
            <BootstrapTable
              keyField="id"
              wrapperClasses="table-responsive"
              data={row.pluginDatas.filter((e) => e.pluginType === 1)}
              columns={conditionColumns}
              striped
              hover
              condensed
            />
          </Col>
          <Col md="6">
            <h4>Kết quả</h4>
            <BootstrapTable
              keyField="id"
              wrapperClasses="table-responsive"
              data={row.pluginDatas.filter((e) => e.pluginType === 32)}
              columns={resultColumns}
              striped
              hover
              condensed
            />
          </Col>
        </Row>
        <hr></hr>
        <div className="float-right">
          {!row.active ? (
            <Button
              color="success mr-2"
              onClick={() => {
                dispatch(activeAction(row.id))
              }}
            >
              Kích hoạt
            </Button>
          ) : (
            <Button
              color="danger mr-2"
              onClick={() => {
                dispatch(deactiveAction(row.id))
              }}
            >
              Dừng
            </Button>
          )}
          {row.type === 4 && (
            <Fragment>
              <Button
                className="btn-shadow mr-2"
                color="info"
                onClick={() => toggleModalGenCode(row)}
              >
                Tạo mã
              </Button>
              <Button
                className="btn-icon btn-shadow btn btn-primary"
                onClick={() => toggleModalGetCode(row)}
              >
                {' '}
                <i className="pe-7s-mail btn-icon-wrapper"> </i>
                Lấy mã
              </Button>
            </Fragment>
          )}
        </div>
      </Container>
    ),
  }
  const handleEvent = (event, picker) => {
    getPage({
      search: option.search,
      active: option.active,
      type: option.type,
      offset: option.offset,
      limit: option.limit,
      sort: option.sort,
      order: option.order,
      from: picker.startDate.toDate(),
      to: picker.endDate.toDate(),
    })
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => {
    console.log(columns)
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => {
          setDropdownOpen(!dropdownOpen)
        }}
        direction="down"
      >
        <DropdownToggle color="primary" title="Các cột hiển thị">
          <i className="pe-7s-edit"></i> Cột
        </DropdownToggle>
        <DropdownMenu left>
          {columns
            .map((column) => ({
              ...column,
              toggle: toggles[column.dataField],
            }))
            .map((column) => {
              return (
                <div className="ml-5">
                  <FormGroup>
                    <Label>
                      <Input
                        key={column.dataField}
                        type="checkbox"
                        name="column"
                        checked={column.toggle}
                        onChange={() => onColumnToggle(column.dataField)}
                      />
                      {column.text}
                    </Label>
                  </FormGroup>
                </div>
              )
            })}
        </DropdownMenu>
      </Dropdown>
    )
  }

  const [modalGenCode, setModalGenCode] = useState(false)
  const [modalGetCode, setModalGetCode] = useState(false)
  const [promotionCode, setPromotionCode] = useState()
  //const [modal, setModal] = useState(false);
  const toggleModalGenCode = (row) => {
    console.log(row)
    //doing
    setPromotionCode(row)
    setModalGenCode(!modalGenCode)
  }
  const toggleModalGetCode = (row) => {
    console.log(row)
    //doing
    setPromotionCode(row)
    setModalGetCode(!modalGetCode)
    // setModalGenCode(!modalGenCode)
  }

  const sendGiftCodeToUsers = () => {
    if (users.length === 0) {
      Swal.fire('Cần chọn thành viên nhận mã!')
      return
    }

    const body = {
      Id: promotionCode.id,
      UserIds: users.map((e) => e.id),
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: localStorage.getItem(Configuration.userId),
    }

    var temp = sendGiftCodeToUsersAction(body)
    console.log(temp)
    setUsers([])
    toggleModalGetCode()
  }
  const { ToggleList } = ColumnToggle

  return (
    <div>
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <i className="pe-7s-display1 icon-gradient bg-malibu-beach"></i>
            </div>
            <div>
              Danh sách chương trình khuyến mãi
              <div className="page-title-subheading">
                Các chương trình có trong hệ thống
              </div>
            </div>
          </div>
          <div className="page-title-actions d-none d-lg-block"></div>
        </div>
      </div>
      <Card className="main-card mb-3">
        <CardBody>
          <Form>
            <FormGroup check inline>
              <Label check>
                <DateRangePicker
                  startDate={option.from}
                  endDate={option.to}
                  onApply={handleEvent}
                >
                  <button className="btn-pill btn-shadow mr-3 btn btn-info">
                    Dữ liệu từ ngày{' '}
                    {Common.formatDateTime(option.from, 'dd-mm-yyyy')} đến{' '}
                    {Common.formatDateTime(option.to, 'dd-mm-yyyy')}
                  </button>
                </DateRangePicker>
              </Label>
            </FormGroup>
          </Form>
          <div className="divider" />
          {item != null && item.items ? (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              search
              data={item.items}
              columnToggle
              exportCSV={{
                fileName: 'KhuyenMai.csv',
                //separator: ',',
                //ignoreHeader: false,

                noAutoBOM: true,
                blobType: 'text/csv;charset=utf-8',
              }}
            >
              {(props) => (
                <div>
                  <Row className="ml-2">
                    <ExportCSVButton
                      className="btn btn-primary mr-2"
                      {...props.csvProps}
                    >
                      Export CSV
                    </ExportCSVButton>
                    {/* <CustomToggleList  {...props.columnToggleProps} /> */}
                    <ToggleList {...props.columnToggleProps} />
                  </Row>

                  <hr />
                  <BootstrapTable
                    {...props.baseProps}
                    wrapperClasses="table-responsive"
                    remote
                    keyField="id"
                    data={item.items}
                    columns={columns}
                    pagination={paginationFactory(option)}
                    onTableChange={handleTableChange}
                    striped
                    hover
                    condensed
                    filter={filterFactory()}
                    expandRow={expandRow}
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : (
            <h3 className="kt-portlet__head-title">
              Hiện chưa có chương trình
            </h3>
          )}
        </CardBody>
      </Card>
      <Footer />

      <Modal isOpen={modalGenCode} toggle={toggleModalGenCode}>
        <ModalHeader>
          {promotionCode !== undefined && promotionCode.name}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="quantityofcode">Số lượng mã*</Label>

              <input
                className="form-control"
                type="number"
                min="1"
                name="quantityofcode"
                defaultValue={1}
                placeholder="Số lượng mã"
                ref={register({
                  required: 'Trường này không được để trống',
                  // pattern: {
                  //     value: /^[1-9]\d*(\.\d+)?$/i,
                  //     message: "Số lượng phải là số nguyên dương."
                  // }
                })}
              />
              {errors.quantityofcode && (
                <b style={{ color: 'red' }}>{errors.quantityofcode.message}</b>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="typeofcode">
                Định dạng mã* <br />{' '}
                <b>
                  Theo cấu trúc: [A.8] : 8 kí tự chữ, [N.4] : 4 kí tự số,[AN.6]
                  : 6 kí tự chữ và số, GIFT-[A.4]-[AN.6]
                </b>
              </Label>

              <input
                className="form-control"
                type="text"
                name="typeofcode"
                placeholder="[A.8] : 8 kí tự chữ, [N.4] : 4 kí tự số,[AN.6] : 6 kí tự chữ và số, GIFT-[A.4]-[AN.6]"
                ref={register({
                  required: 'Trường này không được để trống',
                })}
              />
              {errors.typeofcode && (
                <b style={{ color: 'red' }}>{errors.typeofcode.message}</b>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="date">
                Hết hạn sau (n ngày)*
                <br />
                <b>Chú ý: Số ngày tồn tại. Giá trị {0}: không giới hạn.</b>
              </Label>

              <input
                className="form-control"
                type="number"
                min="0"
                name="date"
                defaultValue={0}
                placeholder="Số ngày tồn tại. Giá trị {0}: không giới hạn."
                ref={register({
                  required: 'Trường này không được để trống',
                  // pattern: {
                  //     value: /^[1-9]\d*(\.\d+)?$/i,

                  //     message: "Số lượng phải là số nguyên dương."
                  // }
                })}
              />
              {errors.date && errors.date.message}
            </FormGroup>
            <FormGroup>
              <Label for="timeuse">
                Số lần tối đa sử dụng*
                <br />
                <b>
                  Chú ý: Số lần được dùng tối đa. Giá trị {0}: không giới hạn.
                </b>
              </Label>

              <input
                className="form-control"
                defaultValue={0}
                type="number"
                min="0"
                name="timeuse"
                placeholder="Số lần được dùng tối đa. Giá trị {0}: không giới hạn."
                ref={register({
                  required: 'Trường này không được để trống',
                  // pattern: {
                  //     value: /^[1-9]\d*(\.\d+)?$/i,

                  //     message: "Số lượng phải là số nguyên dương."
                  // }
                })}
              />
              {errors.timeuse && (
                <b style={{ color: 'red' }}>{errors.timeuse.message}</b>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="oneperuse">
                Số lần tối đã 1 người sử dụng*
                <br />
                <b>
                  Chú ý: Số lần được dùng tối đa của mỗi người. Giá trị {0}:
                  không giới hạn.
                </b>
              </Label>

              <input
                className="form-control"
                defaultValue={0}
                type="number"
                min="0"
                name="oneperuse"
                placeholder="Số lần được dùng tối đa của mỗi người. Giá trị {0}: không giới hạn."
                ref={register({
                  required: 'Trường này không được để trống',
                  // pattern: {
                  //     value: /^[1-9]\d*(\.\d+)?$/i,

                  //     message: "Số lượng phải là số nguyên dương."
                  // }
                })}
              />
              {errors.oneperuse && (
                <b style={{ color: 'red' }}>{errors.oneperuse.message}</b>
              )}
            </FormGroup>

            <Button color="primary" className="mt-1">
              Tạo mã
            </Button>
          </form>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="link" onClick={() => toggleModalGenCode()}>Đóng</Button> */}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalGetCode} toggle={toggleModalGetCode}>
        <ModalHeader>
          {promotionCode !== undefined && promotionCode.name}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Thành viên</Label>
            <Select
              autosuggest
              isMulti
              apiCommand={(options) =>
                getData.getUsers({ ...options, type: 1048576 })
              }
              onChange={(pickUsers) => setUsers(pickUsers)}
            />
          </FormGroup>
          <Button
            type="button"
            color="primary"
            onClick={() => sendGiftCodeToUsers()}
            className="mt-1"
          >
            Lấy mã
          </Button>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="link" onClick={() => toggleModalGetCode()}>Đóng</Button> */}
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default List
