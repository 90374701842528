import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import { loadPickedImages } from "../../../reducers/Partial";
import ListImages from "./ListImages";
import ModalImage from "./ModalImage";
import ModalImageCallBack from "./ModalImageCallBack";
import ListImageCallback from "./ListImagesCallback";

function ImagePartialCallBack({
  thumbnail,
  pickOne,
  getPickImages,
  loadPickedImages,
  loadPickedThumbnailImages,
  images = [],
  thumbnailImages = [],
  title,
}) {
  return (
    <Card className="main-card mb-2">
      <CardHeader className="card-header-tab">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          {title ? title : thumbnail ? "Ảnh đại diện" : "Ảnh"}
        </div>
        <div className="btn-actions-pane-right mr-3">
          <ModalImageCallBack
            thumbnail={thumbnail}
            pickOne={pickOne}
            getPickImages={getPickImages}
            loadPickedImages={loadPickedImages}
            loadPickedThumbnailImages={loadPickedThumbnailImages}
          />
        </div>
      </CardHeader>
      <CardBody
        style={{
          overflowY: "auto",
          maxHeight: "400px",
        }}
      >
        <ListImageCallback
          images={images}
          thumbnailImages={thumbnailImages}
          thumbnail={thumbnail}
          loadPickedImages={loadPickedImages}
          loadPickedThumbnailImages={loadPickedThumbnailImages}
        />
      </CardBody>
    </Card>
  );
}

export default ImagePartialCallBack;
