import React from 'react'
import LaddaButton from 'react-ladda'
import { ZOOM_IN } from 'react-ladda/dist/constants'
import './LoadingButton.scss'

function LoadingButton({
  loading,
  children,
  color,
  className,
  spinerColor,
  disabled,
  ...props
}) {
  return (
    <span className={`izzi-button ${disabled ? 'disabled' : ''}`}>
      <LaddaButton
        {...props}
        className={className ? className : 'btn btn-shadow btn-success z0'}
        color={color ? color : 'success'}
        loading={loading}
        data-progress={0}
        data-style={ZOOM_IN}
        data-spinner-color={spinerColor || '#fff'}
      >
        {children}
      </LaddaButton>
    </span>
  )
}

export default LoadingButton
