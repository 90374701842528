import React, { useEffect, useState } from 'react'
import 'react-pivottable/pivottable.css'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers'
import TableRenderers from 'react-pivottable/TableRenderers'
import Plot from 'react-plotly.js'
import { Card, CardBody } from 'reactstrap'
import { getRatingTransactions } from '../../Services/RatingService'
import Common from '../../utils/common'
import Header from '../CommonComponent/Header'

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot)
const rows = ['relationName']
const cols = ['userName']
const vals = ['score']
// see documentation for supported input formats
export default function RatingReport(props) {
  const ratingId = props.match.params.id
  const [state, setState] = useState({})
  const [data, setData] = useState([])

  useEffect(() => {
    getRatingTransactions(ratingId).then((res) =>
      setData(res.ratingtransactions.items)
    )
    return () => {}
  }, [])
  return (
    <div>
      <Header
        title={`Báo cáo Rating ${Common.getParameterByName('name')}`}
        iconClassName="pe-7s-star"
      />
      <Card>
        <CardBody>
          <PivotTableUI
            aggregatorName="Average"
            data={data}
            rows={rows}
            cols={cols}
            vals={vals}
            onChange={(s) => setState(s)}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            {...state}
          />
        </CardBody>
      </Card>
    </div>
  )
}
