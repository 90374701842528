import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
// import ThemeOptions from '../../Layout/ThemeOptions'
import AppSidebar from '../../Layout/AppSidebar'
import ProductMemberForm from './ProductMemberForm'
import ProductMemberList from './ProductMemberList'

// import CategoryFormCreate from './Create/CategoryFormCreate';

class ProductMember extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  <Route
                    path={`${this.props.match.url}/list`}
                    component={ProductMemberList}
                  />
                  <Route
                    path={`${this.props.match.url}/create`}
                    component={ProductMemberForm}
                  />
                  <Route
                    path={`${this.props.match.url}/edit/:id`}
                    component={ProductMemberForm}
                  />
                  {/* <Route path={`${this.props.match.url}/category/list`} component={ProductVacationForm} />
                                    <Route path={`${this.props.match.url}/category/create`} component={ProductVacationForm} /> */}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ProductMember
