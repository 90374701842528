import { reducer as Course } from './Course'
import Kanban from './Kanban'
import { reducer as Notification } from './Notification'
import { reducer as Order } from './Order'
import Product from './Product'
import { reducer as Promotion } from './Promotion'
import System from './System'
import ThemeOptions from './ThemeOptions'
import Navigation from './Navigation'

export default {
  ThemeOptions,
  Kanban,
  Order,
  Notification,
  Course,
  Product,
  Promotion,
  System,
  Navigation
}
