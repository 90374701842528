import React from 'react'
import { getMembers, getPoints } from '../../Services/CrmService'
import { getProducts } from '../../Services/ProductService'
import Configuration from '../../utils/configuration'
import useFetchDataTable from '../CommonComponent/CustomHooks/useFetchDataTable'
import useModal from '../CommonComponent/CustomHooks/useModal'
import Header from '../CommonComponent/Header'
import ReactTableList from '../CommonComponent/TableList/ReactTableList'
import ModalDetailUserPoint from './ModalDetailUserPoint'
import _ from 'lodash'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ProfileUserModal from './ProfileUserModal'

function UserPointList() {
  const [data, loading, pageCount, fetchData, totalCount] = useFetchDataTable(
    getMembers
  )

  const [isOpen, toggle, dataModal] = useModal()

  const [isOpenModalProfile, toggleModalProfile, dataModalProfile] = useModal()

  const columns = [
    {
      Header: 'Tên',
      accessor: (a) => a,
      Cell: ({ value }) => (
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left mr-3">
              <div className="widget-content-left">
                <img
                  width={52}
                  className=""
                  src={
                    _.get(value, 'images[0]')
                      ? Configuration.image_url + value.images[0].path + "?mode=crop&width=52&height=52"
                      : 'https://thumbs.dreamstime.com/b/default-avatar-profile-image-vector-social-media-user-icon-potrait-182347582.jpg'
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="widget-content-left flex2">
              <div className="widget-heading">{value.userName}</div>
              <div className="widget-subheading opacity-10">
                <span className="pr-2">
                  {value.email} - {value.mobile}
                </span>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: 'Hạng thành viên',
      accessor: 'categories',
      Cell: ({ value }) => {
        var temp = [];
        value.map((item,i) => 
          temp.push(item.name)
        );
        return <div>{temp.join(",")}</div>
      },
    },
    {
      Header: 'Điểm đã sử dụng',
      accessor: 'totalNegativePoint',
      Cell: ({ value }) => {
        return <div>{value}</div>
      },
    },
    {
      Header: 'Điểm còn lại',
      accessor: 'totalPositvePoint',
      Cell: ({ value }) => {
        return <div>{value}</div>
      },
    },
    {
      Header: 'Tổng điểm',
      accessor: 'totalPoint',
      Cell: ({ value }) => {
        return <div>{value}</div>
      },
    },
    // {
    //   Header: 'Danh mục',
    //   accessor: 'categories',
    //   Cell: ({ value }) => {
    //     return (
    //       <div>
    //         {value.length > 0 ? value.map((val) => val.name).join(', ') : 'N/A'}
    //       </div>
    //     )
    //   },
    // },
    {
      Header: 'Action',
      accessor: (a) => a,
      Cell: ({ value }) => (
        <Fragment>
          {/* <button
            className="btn-icon btn-icon-only btn btn-link btn btn-link"
            title="Thông tin cá nhân"
            onClick={() => toggleModalProfile(value)}
          >
            <i className="pe-7s-id btn-icon-wrapper"></i>
          </button> */}
          <button
            className="btn-icon btn-icon-only btn btn-link btn btn-link"
            title="Chi tiết đổi điểm"
            onClick={() => toggle(value)}
          >
            <i className="pe-7s-date btn-icon-wrapper"></i>
          </button>
          <Link to={'/schedule/detail/' + value.id}>
            <button
              className="btn-icon btn-icon-only btn btn-link btn btn-link"
              title="Lịch thành viên"
              onClick={() => toggle(value)}
            >
              <i className="pe-7s-note2 btn-icon-wrapper"></i>
            </button>
          </Link>
        </Fragment>
      ),
    },
  ]

  return (
    <div>
      <Header
        title="Danh sách hội viên"
        description="User's point"
        iconClassName="pe-7s-user"
      />

      <ModalDetailUserPoint
        isOpen={isOpen}
        toggle={toggle}
        dataModal={dataModal}
      />

      <ProfileUserModal
        isOpen={isOpenModalProfile}
        toggle={toggleModalProfile}
        dataModal={dataModalProfile}
      />

      <ReactTableList
        // columnsSelection
        data={data}
        loading={loading}
        columns={columns}
        pageCount={pageCount}
        totalCount={totalCount}
        fetchData={fetchData}
        // categoryType={Configuration.categoryTypes.PRODUCT}
      />
    </div>
  )
}

export default UserPointList