import React from "react";

const SearchMenu = ({ value, onChange }) => {
  return (
    <div
      style={{
        padding: "10px 1.5rem 0px 1.5rem",
      }}
    >
      <div className="input-group">
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{
            backgroundColor: "#494e54",
            color: "rgba(255, 255, 255, 0.7)",
          }}
          placeholder={"Tìm kiếm chức năng"}
          className="form-control"
          type="search"
          // id="example-search-input"
        />
        <span className="input-group-append lg-invisible">
          <button
            className="btn btn-outline-secondary"
            type="button"
            style={{
              backgroundColor: "#494e54",
              color: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <i className="pe-7s-search"></i>
          </button>
        </span>
      </div>
      {/*<input*/}
      {/*  className={"form-control"}*/}

      {/*/>*/}
    </div>
  );
};

export default SearchMenu;
