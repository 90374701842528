import _ from "lodash";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import Loader from "react-loaders";
import ReactTable from "react-table-6";
import { CustomInput } from "reactstrap";

function TableList(
  {
    columns = [],
    apiCommand,
    defaultPageSize = 5,
    setCheckItems,
    checkItems = [],
    itemSelectable,
    searchKeyword,
  },
  ref
) {
  const [data, setData] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [pages, setPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setpageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState(searchKeyword);
  const [filterCategories, setFilterCategories] = useState(null);
  const [targetId, setTargetId] = useState(null);

  const tableRef = useRef(null);

  useImperativeHandle(ref, () => ({
    load: load,
    search: search,
    filterByCategories: filterByCategories,
    filterByTargetId: filterByTargetId,
    getTotalCount: getTotalCount,
  }));

  const getTotalCount = () => {
    return totalCount;
  };

  const search = (string) => {
    setSearchText(string);
  };

  const filterByCategories = (categoryIds) => {
    setFilterCategories(categoryIds);
    setPage(0);
  };

  const filterByTargetId = (targetId) => {
    setTargetId(targetId);
    setPage(0);
  };

  useEffect(() => {
    load();
  }, [filterCategories, targetId]);

  const isCheckboxChecked = (id) => {
    return checkItems.map((val) => val.id).includes(id);
  };

  const isCheckAllChecked = () => {
    const checkItemsId = checkItems.map((item) => item.id);
    for (let item of data) {
      if (!checkItemsId.includes(item.id)) return false;
    }
    return true;
  };

  const checkboxHandler = (event, value) => {
    let checkItemsNew;
    if (event.target.checked) {
      checkItemsNew = [...checkItems, value];
    } else {
      checkItemsNew = checkItems.filter((item) => item.id !== value.id);
    }
    setCheckItems(checkItemsNew);
  };

  const checkAllHandler = (event) => {
    let checkItemsNew;
    if (event.target.checked) {
      const checkItemsId = checkItems.map((item) => item.id);
      checkItemsNew = [...checkItems, ...data.filter((item) => !checkItemsId.includes(item.id))];
    } else {
      const idDataCurrentPage = data.map((item) => item.id);
      checkItemsNew = [...checkItems.filter((item) => !idDataCurrentPage.includes(item.id))];
    }
    setCheckItems(checkItemsNew);
  };

  const load = (sorted = [], _pageSize, _page) => {
    let { id, desc } = sorted[0]
      ? sorted[0]
      : {
          id: undefined,
          desc: undefined,
        };

    if (desc !== undefined) {
      desc = desc ? "desc" : "asc";
    }

    const tempPageSize = _pageSize ? _pageSize : pageSize;
    const tempPage = _page !== undefined ? _page : page;

    let options = {
      limit: tempPageSize,
      offset: tempPage * tempPageSize,
      order: desc,
      sort: id,
    };

    if (searchText) {
      options.keyword = searchText;
    }

    if (filterCategories) {
      options.categoryIds = filterCategories;
    }

    if (targetId) {
      options.targetId = targetId;
    }

    setIsSending(true);
    apiCommand(options).then((result) => {
      // setPage(state.page);
      if (result[Object.keys(result)[0]]) {
        const totalCount = result[Object.keys(result)[0]].totalCount;
        setPages(Math.ceil(totalCount / pageSize));
        setTotalCount(totalCount);
        setData(
          result[Object.keys(result)[0]].items.map((item, index) => {
            return {
              index: tempPage * tempPageSize + index + 1,
              ...item,
            };
          })
        );
      }
      setIsSending(false);
    });
  };

  useEffect(() => {
    if (searchText !== null) load();
  }, [searchText]);

  useEffect(() => {
    if (searchKeyword) setSearchText(searchKeyword);
  }, [searchKeyword]);

  let renderColumns;

  if (itemSelectable)
    renderColumns = [
      {
        id: "id",
        Header: () => (
          <CustomInput
            type="checkbox"
            id="selectAllCheckBox"
            defaultChecked={isCheckAllChecked()}
            onClick={checkAllHandler}
          />
        ),
        accessor: (d) => d,
        Cell: ({ value }) => (
          <CustomInput
            className="mx-auto"
            type="checkbox"
            id={value.id}
            defaultChecked={isCheckboxChecked(value.id)}
            onClick={(event) => checkboxHandler(event, value)}
          />
        ),
        filterable: false,
        sortable: false,
        width: 50,
        resizable: false,
      },
      ...columns,
    ];
  else {
    renderColumns = [...columns];
  }

  renderColumns = renderColumns.filter((column) =>
    _.get(column, "isShow") !== undefined ? column.isShow : true
  );

  return (
    <BlockUi
      tag="div"
      blocking={isSending}
      className="block-overlay-dark"
      loader={<Loader color="#ffffff" active />}
    >
      <ReactTable
        ref={tableRef}
        data={data}
        manual
        // filterable
        page={page}
        pages={pages}
        columns={renderColumns}
        onFetchData={(state, instance) => {
          load(state.sorted, state.pageSize, state.page);
        }}
        pageSize={pageSize}
        onSortedChange={() => setPage(0)}
        onPageSizeChange={(newPageSize, pageIndex) => {
          setpageSize(newPageSize);
          setPage(0);
          setPages(Math.ceil(totalCount / newPageSize));
        }}
        onPageChange={(pageIndex) => {
          setPage(pageIndex);
        }}
        defaultPageSize={defaultPageSize}
      />
    </BlockUi>
  );
}

TableList.propTypes = {
  columns: PropTypes.array.isRequired,
  apiCommand: PropTypes.func.isRequired,
  defaultPageSize: PropTypes.number,
  setCheckItems: PropTypes.func,
  checkItems: PropTypes.array,
  itemSelectable: PropTypes.bool,
  searchText: PropTypes.string,
};

export default forwardRef(TableList);
