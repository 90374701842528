import { NOTIFICATION } from "../config/Constants/NotificationConstant";
import { getNotifications, readNotification } from "../Services/NotificationService";

export const getNotificationsAction = (limt, offset) => ({
  type: NOTIFICATION.GET_NOTIFICATIONS,
  payload: getNotifications(limt, offset),
});

export const readNotificationAction = (id, urlReturn) => ({
  type: NOTIFICATION.READ_NOTIFICATION,
  payload: readNotification(id, urlReturn),
});

export const pushNotification = (notification) => ({
  type: NOTIFICATION.PUSH_NOTIFICATION,
  payload: notification,
});
