import $ from 'jquery'
import React, { Fragment } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Loader } from 'react-loaders'
import { connect } from 'react-redux'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import SweetAlert from 'sweetalert-react'
import city2 from '../../assets/utils/images/dropdown-header/city2.jpg'
import { updateStateSectionBlocking } from '../../reducers/ThemeNav'
import Common from '../../utils/common'
import Configuration from '../../utils/configuration'

class Language extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      show: false,

      languageCurrent: {},
      arrayLanguage: [],

      title: 'Thông báo!',
      content:
        'Bạn chưa có dữ liệu ngôn ngữ tiếng anh.Bạn có muốn chuyển ngôn ngữ Tiếng Việt sang không.Bạn có thể chỉnh sửa lại nội dung!',
      tempContent: '...',
      showCancelButton: true,
      showConfirmButton: true,
      loaderType: 'ball-triangle-path',
    }
  }
  componentDidMount() {}
  changeLanguage(languageId) {
    Common.setCookie(Configuration.tokenLanguage, languageId)
    localStorage.setItem(Configuration.tokenLanguage, languageId)
    if ($('#iframeWeb').length > 0) {
      var dataSendIframe = {
        action: 'sendLanguageId',
        languageId: languageId,
      }
      Common.sendDataPreview('iframeWeb', dataSendIframe)
    }
    window.location.reload()
  }
  render() {
    let pathImgFlag
    // console.log(localStorage.getItem(Configuration.tokenLanguage))
    if (localStorage.getItem(Configuration.tokenLanguage) !== '') {
      pathImgFlag = this.props.allLanguage.filter(
        (e) => e.id === localStorage.getItem(Configuration.tokenLanguage)
      )
      // console.log()
    } else {
      pathImgFlag = this.props.allLanguage.filter((e) => e.isFeatured === true)
    }
    return (
      <Fragment>
        <UncontrolledDropdown>
          <DropdownToggle className="p-0 mr-2" color="link">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
              <div className="icon-wrapper-bg bg-focus" />
              <div className="language-icon">
                <img
                  src={
                    pathImgFlag[0] === undefined ? '' : pathImgFlag[0].imagePath
                  }
                  height="40"
                  width="40"
                  className="mr-3 opacity-8"
                  alt=""
                ></img>
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu right className="rm-pointers">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                <div
                  className="menu-header-image opacity-05"
                  style={{
                    backgroundImage: 'url(' + city2 + ')',
                  }}
                />
                <div className="menu-header-content text-center text-white">
                  <h6 className="menu-header-subtitle mt-0">Chọn ngôn ngữ</h6>
                </div>
              </div>
            </div>

            {this.props.allLanguage.map((item, i) => (
              <DropdownItem
                key={i}
                onClick={() => {
                  this.changeLanguage(item.id)
                }}
              >
                <img
                  src={item.imagePath}
                  alt={item.name}
                  height="24"
                  width="24"
                  className="mr-3 opacity-8"
                ></img>
                {item.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <div style={{ display: 'none' }} id="notificationChangeLanguage">
          <button onClick={() => this.setState({ modalCloneData: true })}>
            Alert
          </button>
          <SweetAlert
            show={this.state.modalCloneData}
            title={this.state.title}
            text={this.state.content}
            cancelButtonText="Thoát"
            confirmButtonText="Xác nhận"
            showCancelButton={this.state.showCancelButton}
            showConfirmButton={this.state.showConfirmButton}
            confirmButtonColor="#30b1ff"
            html={true}
            onConfirm={() => {
              this.cloneData()
              this.setState({
                title: renderToStaticMarkup(<Loader type="ball-pulse" />),
                content: 'Xin chờ!',
                showCancelButton: false,
                showConfirmButton: false,
              })
            }}
            onCancel={() => {
              this.setState({ modalCloneData: false }, () => {})
            }}
          ></SweetAlert>
        </div>
        <div className="d-none">
          <Button
            color="success"
            id={'btnNotificationSuccess'}
            onClick={() => this.setState({ show: true })}
          >
            Show Alert
          </Button>
          <SweetAlert
            title="Thành công!"
            confirmButtonColor=""
            show={this.state.show}
            text="You clicked the button!"
            type="success"
            onConfirm={() => this.setState({ show: false })}
          />
        </div>
      </Fragment>
    )
  }
}
function mapStateToProps(state) {
  return {
    themeId: state.ThemeNav.themeId,
    allLanguage: state.Partial.allLanguage,
  }
}
const mapDispatchToProps = {
  updateStateSectionBlocking,
}
export default connect(mapStateToProps, mapDispatchToProps)(Language)
