import React, { Fragment, useRef } from 'react'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'

function LaboFormGroup({ type, label, error, name, options, row, ...props }) {
  const ipRef = useRef()
  let input
  switch (type) {
    case 'select':
      input = (
        <Input id={name} type="select" {...props}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Input>
      )
      break
    case 'radio':
      input = (
        <Fragment>
          {options.map((option, i) => {
            return (
              <FormGroup key={i} check>
                <Label check>
                  <Input
                    type="radio"
                    name={name}
                    {...props}
                    value={option.value}
                  />{' '}
                  {option.title}
                </Label>
              </FormGroup>
            )
          })}
        </Fragment>
      )
      break
    case 'checkbox':
      input = (
        <Fragment>
          {options.map((option, i) => {
            return (
              <FormGroup key={i} check>
                <Label check>
                  <Input
                    type="checkbox"
                    name={option.name}
                    {...props}
                    value={option.value}
                  />{' '}
                  {option.title}
                </Label>
              </FormGroup>
            )
          })}
        </Fragment>
      )
      break
    default:
      input = (
        <input
          className={`form-control ${error ? 'is-invalid' : ''}`}
          ref={ipRef}
          onWheel={(e) => ipRef.current.blur()}
          id={props.name}
          invalid={error}
          type={type}
          name={name}
          {...props}
        />
      )
      break
  }
  return (
    <FormGroup row={row ? true : false}>
      <Label
        sm={row ? 2 : null}
        for={props.name}
        dangerouslySetInnerHTML={{ __html: label }}
      ></Label>
      {row ? (
        <Col sm="10">
          {input}
          {error ? <FormFeedback>{error}</FormFeedback> : null}
        </Col>
      ) : (
        input
      )}
      {error ? <FormFeedback>{error}</FormFeedback> : null}
    </FormGroup>
  )
}

LaboFormGroup.propTypes = {}

export default LaboFormGroup
