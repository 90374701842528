import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { Button, Col, Row } from "reactstrap";
import { loadPickedImages, loadPickedThumbnailImages } from "../../../reducers/Partial";
import Configuration from "../../../utils/configuration";

class ListImageCallback extends Component {
  constructor(props) {
    super(props);
    this.setImage = props.thumbnail ? props.loadPickedThumbnailImages : props.loadPickedImages;
    // this.images = props.thumbnail ?
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    let images = this.props.thumbnail ? this.props.thumbnailImages : this.props.images;
    this.setImage([
      ...arrayMove(images, oldIndex, newIndex).map((image, index) => {
        return {
          ...image,
          displayOrder: index,
        };
      }),
    ]);
  };

  render() {
    let images = this.props.thumbnail ? this.props.thumbnailImages : this.props.images;
    return (
      <Fragment>
        <SortableImageList
          axis="xy"
          useDragHandle={true}
          items={images}
          onSortEnd={this.onSortEnd}
          thumbnail={this.props.thumbnail}
          images={images}
          thumbnailImages={this.props.thumbnailImages}
          loadPickedImages={this.props.loadPickedImages}
          loadPickedThumbnailImages={this.props.loadPickedThumbnailImages}
        />
      </Fragment>
    );
  }
}

const SortableImageList = SortableContainer(
  ({
    items = [],
    thumbnail,
    images,
    thumbnailImages,
    loadPickedImages,
    loadPickedThumbnailImages,
  }) => (
    <Row>
      {items && items.length > 0 ? (
        items.map((value, index) => (
          <SortableImageItem
            key={`item-${value.id}`}
            index={index}
            value={value}
            thumbnail={thumbnail}
            images={images}
            thumbnailImages={thumbnailImages}
            loadPickedImages={loadPickedImages}
            loadPickedThumbnailImages={loadPickedThumbnailImages}
          />
        ))
      ) : (
        <Col>
          <p>Chưa có ảnh</p>
        </Col>
      )}
    </Row>
  )
);

const SortableImageItem = SortableElement(
  ({ value, thumbnail, images, thumbnailImages, loadPickedImages, loadPickedThumbnailImages }) => (
    <ImageItem
      value={value}
      thumbnail={thumbnail}
      images={images}
      thumbnailImages={thumbnailImages}
      loadPickedImages={loadPickedImages}
      loadPickedThumbnailImages={loadPickedThumbnailImages}
    />
  )
);

const ImageItem = ({
  value,
  images = [],
  thumbnailImages = [],
  loadPickedImages,
  loadPickedThumbnailImages,
  thumbnail,
}) => {
  let updateImages = thumbnail ? loadPickedThumbnailImages : loadPickedImages;
  let imgs = thumbnail ? thumbnailImages : images;
  const DragHander = sortableHandle(() => (
    <img
      style={{
        width: "100%",
      }}
      src={Configuration.image_url + value.path + "?mode=Crop&width=350&height=200"}
      alt="abc"
    />
  ));
  return (
    <Col
      md={thumbnail ? (thumbnailImages.length > 4 ? 6 : 12) : images.length > 4 ? 6 : 12}
      style={{
        padding: "10px 0px",
        zIndex: 6,
      }}
    >
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <DragHander />

          <Button
            className="border-0 btn-transition"
            outline
            style={{
              position: "absolute",
              right: 2,
              top: 2,
            }}
            color="danger"
            onClick={() => {
              updateImages(imgs.filter((val) => val.id !== value.id));
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
          {/* </div> */}
        </div>
      </div>
    </Col>
  );
};

export default ListImageCallback;
