import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { getRatings } from '../../Services/RatingService'
import Header from '../CommonComponent/Header'
import FooterActions from '../CommonComponent/TableList/FooterActions'
import TableList from '../CommonComponent/TableList/TableList'

function RatingList() {
  const tableRef = useRef()
  const [checkItems, setCheckItems] = useState([])
  const columns = [
    {
      Header: 'STT',
      accessor: 'index',
      Cell: ({ value }) => <div className="mx-auto">{value}</div>,
      width: 50,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      accessor: 'published',
      Header: 'Trạng thái',
      filterable: false,
      Cell: (props) =>
        props.value ? (
          <div className="mx-auto d-block badge badge-success">Hoạt động</div>
        ) : (
          <div className="mx-auto d-block badge badge-danger">
            Không hoạt động
          </div>
        ),
      width: 150,
    },
    {
      Header: 'Max Score',
      accessor: 'maxScore',
    },
    {
      Header: 'Thao tác',
      id: 'thao-tac',
      accessor: (d) => d,
      Cell: ({ value }) => (
        <div style={{ margin: 'auto' }}>
          <Link to={`/rating/edit/${value.id}`}>
            <Button color="primary">Sửa</Button>
          </Link>
          <Link to={`/rating/report/${value.id}?name=${value.name}`}>
            <Button color="link">Báo cáo</Button>
          </Link>
        </div>
      ),
    },
  ]

  return (
    <div>
      <Header
        title="Danh sách Rating"
        description="Rating list"
        iconClassName="pe-7s-star"
      />
      <Card>
        <CardBody>
          <TableList
            ref={tableRef}
            columns={columns}
            apiCommand={getRatings}
            itemSelectable
            checkItems={checkItems}
            setCheckItems={setCheckItems}
          />
        </CardBody>
      </Card>
      <FooterActions
        checkItems={checkItems}
        setCheckItems={setCheckItems}
        load={_.get(tableRef, 'current.load')}
        commands={{
          delete: {
            domain: 'Rating',
            commandName: 'DeleteRatings',
          },
          changeStatus: {
            domain: 'Rating',
            commandName: 'ChangeStatusPublishRatings',
          },
        }}
      />
    </div>
  )
}

export default RatingList
