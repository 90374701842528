import Common from '../utils/common'
import { getData } from '../utils/data'

export const getSchedules = ({ from, to, targetId }) => {
  return getData.getDataGraphQl(`
    {
        schedules(param:{merchantId: "${localStorage.getItem(
          'merchantId'
        )}", from:"${from}", to:"${to}", targetId:"${targetId}"})
        {
            items{
                id
                name
                targetId
                triggerDateTime
            }
            totalCount
        }
    }
    `)
}
