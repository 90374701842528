import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import Common from "../utils/common";
import Configuration from "../utils/configuration";
import { getData, postData } from "../utils/data";

//truyen params vao day nhe
export const getMerchantTypeWithNavs = ({
  limit = 100,
  keyword = "",
  offset = 0,
  order = "createdDate",
  sort = "desc",
}) => {
  return getData
    .getDataGraphQl(
      `
  {
    permissionmerchantmenu(param:{ limit:${limit}, keyword:"${keyword}",offset:${offset},order:"${order}",sort:"${sort}", languageId:"${localStorage.getItem(
        Configuration.tokenLanguage
      )}", merchantId:"${Common.GUID_EMPTY}"})
  {
      items {
        id,
        name,
        cMSMenuCategories {
          id,
          parentId
        }
      }
    }   
  }
  `
    )
    .then((res) => res.permissionmerchantmenu.items);
};

export const getMerchantTypeWithNavsForMemberSite = ({
  limit = 100,
  keyword = "",
  offset = 0,
  order = "createdDate",
  sort = "desc",
}) => {
  return getData
    .getDataGraphQl(
      `
  {
    membermenumerchant(param:{ limit:${limit}, keyword:"${keyword}",offset:${offset},order:"${order}",sort:"${sort}", languageId:"${localStorage.getItem(
        Configuration.tokenLanguage
      )}", merchantId:"${Common.GUID_EMPTY}"})
  {
      items {
        id,
        name,
        cMSMenuCategories {
          id,
          parentId
        }
      }
    }   
  }
  `
    )
    .then((res) => res.membermenumerchant.items);
};

export const getUserRights = ({
  limit = 100,
  keyword = "",
  offset = 0,
  order = "createdDate",
  sort = "desc",
}) => {
  return getData.getDataGraphQl(
    `
  {
    userrights(param:{ limit:${limit}, keyword:"${keyword}",offset:${offset},order:"${order}",sort:"${sort}", languageId:"${localStorage.getItem(
      Configuration.tokenLanguage
    )}", merchantId:"${localStorage.getItem("merchantId")}"})
  {
          items {
            id
            name
            navigation
            cMSMenuCategories {
              id
            }
          }
          totalCount
          }
  }
  `
  );
};

export const getMembershipMenuNavigations = ({
  limit = 100,
  keyword = "",
  offset = 0,
  order = "createdDate",
  sort = "desc",
}) => {
  return getData.getDataGraphQl(
    `
  {
    usermembershipmenuconfiguration(param:{ limit:${limit}, keyword:"${keyword}",offset:${offset},order:"${order}",sort:"${sort}", languageId:"${localStorage.getItem(
      Configuration.tokenLanguage
    )}", merchantId:"${localStorage.getItem("merchantId")}"})
  {
          items {
            id
            name
            navigation
            cMSMenuCategories {
              id
            }
          }
          totalCount
          }
  }
  `
  );
};

export const getNav = (id) => {
  return getData.getDataGraphQl(
    `
{
    navigation(param:{id:"${id}",tokenUser:"${localStorage.getItem(
      Configuration.userCookie
    )}",languageId:"${localStorage.getItem(Configuration.tokenLanguage)}",merchantId:"${
      Common.GUID_EMPTY
    }"})
   {
        id,
        name,
        description
        navigation
    } 
   }
 
`
  );
};

export const getNavsByMerchantType = async (navIds, itemCheckedIds) => {
  const navs = await Promise.all(
    navIds.map(
      (navId) =>
        new Promise((resolve, reject) => {
          getNav(navId).then((res) => {
            let nav = JSON.parse(res.navigation.navigation);
            const updateNav = (nav) => {
              // khong dc tich
              nav.id = nav.Id;
              if (!itemCheckedIds.includes(nav.id)) {
                nav.delete = true;
                return;
              }
              nav.label = nav.Name;
              nav.icon = nav.SubDescription;
              nav.to = nav.Description;

              if (nav.Categories) {
                nav.content = nav.Categories;
                nav.content.forEach((child) => {
                  updateNav(child);
                });
              }
            };

            nav.forEach((item) => {
              updateNav(item);
            });

            const deleteNullNode = (navs) => {
              const newNavs = navs.filter((nav) => {
                return !nav.delete;
              });

              return newNavs.map((nav) => {
                if (Array.isArray(nav.content))
                  return {
                    ...nav,
                    content: deleteNullNode(nav.content),
                  };
                else {
                  return nav;
                }
              });
            };

            const newNavs = deleteNullNode(nav);
            // console.log(newNavs)
            return resolve({
              ...res.navigation,
              navigation: newNavs,
              description: res.navigation.description,
            });
          });
        })
    )
  );
  return navs;
};

export const getTreeViewNavsByMerchantType = async (navIds, itemCheckedIds) => {
  const navs = await Promise.all(
    navIds.map(
      (navId) =>
        new Promise((resolve, reject) => {
          getNav(navId).then((res) => {
            let nav = JSON.parse(res.navigation.navigation);
            const updateNav = (nav) => {
              nav.id = nav.Id;
              if (!itemCheckedIds.includes(nav.id)) {
                nav.delete = true;
                return;
              }
              nav.icon = nav.SubDescription;
              nav.name = nav.Name;
              nav.name += ` /${nav.Description}`;
              // if (itemCheckedIds.includes(nav.id)) {
              //     nav.isChecked = true
              // }

              if (nav.Categories) {
                nav.children = nav.Categories;
                nav.children.forEach((child) => {
                  updateNav(child);
                });
              }
            };
            nav.forEach((item) => {
              updateNav(item);
            });

            const deleteNullNode = (navs) => {
              const newNavs = navs.filter((nav) => {
                return !nav.delete;
              });

              return newNavs.map((nav) => {
                if (Array.isArray(nav.children))
                  return {
                    ...nav,
                    children: deleteNullNode(nav.children),
                  };
                else {
                  return nav;
                }
              });
            };
            const newNavs = deleteNullNode(nav);
            return resolve({
              ...res.navigation,
              children: newNavs,
              description: res.navigation.description,
            });
          });
        })
    )
  );
  return navs;
};

export const getNavsByMerchantTypeForConfig = async (navIds, itemCheckedIds) => {
  const navs = await Promise.all(
    navIds.map(
      (navId) =>
        new Promise((resolve, reject) => {
          getNav(navId).then((res) => {
            let nav = JSON.parse(res.navigation.navigation);
            console.log(nav);
            const updateNav = (nav) => {
              nav.id = nav.Id;
              nav.icon = nav.SubDescription;
              nav.name = nav.Name;
              nav.name += ` ${nav.Description}`;
              if (!nav.Published) nav.name += " (Ẩn)";
              if (itemCheckedIds.includes(nav.id)) {
                nav.isChecked = true;
              }

              if (nav.Categories) {
                nav.children = nav.Categories;
                nav.children.forEach((child) => {
                  updateNav(child);
                });
              }
            };
            nav.forEach((item) => {
              updateNav(item);
            });
            return resolve({
              ...res.navigation,
              children: nav,
              description: res.navigation.description,
            });
          });
        })
    )
  );
  return navs;
};

export const updateUserRightsRelationCategory = (userRightId, relationIds) => {
  return postData.sendCommand("Category", "UpdateUserRightsRelationCategory", {
    Id: userRightId,
    ModifiedDate: Common.getCurrentDate(),
    ModifiedBy: localStorage.getItem("userId"),
    UserRights: Common.createRelationObjectArray(relationIds),
  });
};

export const updateMembershipRelationCategory = (membershipId, relationIds) => {
  return postData.sendCommand("Category", "UpdateMembershipRelationCategory", {
    Id: membershipId,
    ModifiedDate: Common.getCurrentDate(),
    ModifiedBy: localStorage.getItem("userId"),
    MembershipMenu: Common.createRelationObjectArray(relationIds),
  });
};

export const getNavByUser = (tokenUser, MerchantType, AllType) => {
  return getData
    .getDataGraphQl(
      `
    {
      usermenunavigations(param: {
        tokenUser: "${tokenUser}",
        languageId: "${Configuration.languageVi}", 
        merchantId: "${localStorage.getItem("merchantId")}"}) {
        items {
          id
          name
          navigation
        }
      }
    }
    `
    )
    .then((res) => {
      if (!res.usermenunavigations.items) return [];
      return res.usermenunavigations.items.map((item) => {
        const navigation = JSON.parse(item.navigation) || [];

        const updateNav = (nav) => {
          // khong dc tich
          nav.id = nav.Id;
          nav.label = nav.Name;
          nav.icon = nav.SubDescription;
          nav.to = nav.Description;

          if (nav.Categories) {
            nav.content = nav.Categories;
            nav.content.forEach((child) => {
              updateNav(child);
            });
          }
        };

        navigation.forEach((item) => {
          updateNav(item);
        });

        return {
          id: item.id,
          name: item.name,
          navigation: navigation,
        };
      });
    }).then(navs => {
      var adminMenu = [];
      if ((MerchantType & 2048) == 2048 && (AllType & 1048576) == 1048576) {
        adminMenu = [
          {
            id: "003323f7-95e6-47d4-a483-8f8c856908ad",
            name: "ADMIN",
            navigation: [
              {
                id: "",
                label: "Cài đặt tính năng",
                icon: "pe-7s-monitor",
                to: "",
                Published: true,
                content: [
                  {
                    label: "Danh sách khối tính năng",
                    icon: "",
                    to: "/#/feature/list",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Sửa khối tính năng",
                    icon: "",
                    to: "/#/feature/edit",
                    id: "",
                    Published: false,
                    content: [],
                  },
                  {
                    label: "Tạo mới khối tính năng",
                    to: "/#/feature/create",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Phân quyền tính năng",
                    to: "/#/feature/features-merchant",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Phân quyền tính năng theo thành viên",
                    to: "/#/feature/features-user",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Danh sách quyền thành viên",
                    to: "/#/user-right/list",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Sửa quyền thành viên",
                    to: "/#/user-right/edit",
                    id: "",
                    Published: false,
                    content: [],
                  },
                  {
                    label: "Tạo quyền thành viên",
                    to: "/#/user-right/create",
                    id: "",
                    Published: false,
                    content: [],
                  },
                  {
                    label: "Phân quyền thành viên",
                    to: "/#/user-right/auth",
                    id: "",
                    Published: false,
                    content: [],
                  },
                ],
              },
              {
                id: "",
                label: "Cài đặt tính năng member",
                icon: "pe-7s-monitor",
                to: "",
                Published: true,
                content: [
                  {
                    label: "Danh sách khối tính năng",
                    icon: "",
                    to: "/#/feature/member/list",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Sửa khối tính năng",
                    icon: "",
                    to: "/#/feature/member/edit",
                    id: "",
                    Published: false,
                    content: [],
                  },
                  {
                    label: "Tạo mới khối tính năng",
                    to: "/#/feature/member/create",
                    id: "",
                    Published: false,
                    content: [],
                  },
                  {
                    label: "Phân quyền tính năng theo merchant",
                    to: "/#/feature/member/features-merchant",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Phân quyền tính năng theo hạng thành viên",
                    to: "/#/feature/member/features-user",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  // {
                  //   label: "Danh sách hạng thành viên",
                  //   to: "/#/user/config-member-right/list",
                  //   id: "",
                  //   content: [],
                  // },
                ],
              },
            ],
          },
        ];
      } else if ((AllType & 1048576) == 1048576) {
        adminMenu = [
          {
            id: "003323f7-95e6-47d4-a483-8f8c856908ad",
            name: "ADMIN",
            navigation: [
              {
                id: "",
                label: "Cài đặt tính năng",
                icon: "pe-7s-monitor",
                to: "",
                Published: true,
                content: [
                  {
                    label: "Phân quyền tính năng theo thành viên",
                    to: "/#/feature/features-user",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Danh sách quyền thành viên",
                    to: "/#/user-right/list",
                    id: "",
                    Published: true,
                    content: [],
                  },
                  {
                    label: "Sửa quyền thành viên",
                    to: "/#/user-right/edit",
                    id: "",
                    Published: false,
                    content: [],
                  },
                  {
                    label: "Tạo quyền thành viên",
                    to: "/#/user-right/create",
                    id: "",
                    Published: false,
                    content: [],
                  },
                  {
                    label: "Phân quyền thành viên",
                    to: "/#/user-right/auth",
                    id: "",
                    Published: false,
                    content: [],
                  },
                ],
              },
              {
                id: "",
                label: "Cài đặt tính năng member",
                icon: "pe-7s-monitor",
                to: "",
                Published: true,
                content: [
                  {
                    label: "Phân quyền tính năng theo hạng thành viên",
                    to: "/#/feature/member/features-user",
                    id: "",
                    Published: true,
                    content: [],
                  },
                ],
              },
            ],
          },
        ];
      }
      return adminMenu.concat(navs)
    })
};

export const getNavPublished = (navigation) => {
  const result = []
  navigation.forEach((item) => {
    if (item.Published) {
      const newItem = { ...item, content: [] };
      if (item.content && item.content.length > 0) {
        newItem.content = getNavPublished(item.content);
      }
      result.push(newItem);
    }
  });  
  return result;
};
