import $ from 'jquery'
import React, { useState } from 'react'
import BlockUi from 'react-block-ui'
import Dropzone from 'react-dropzone'
import Loader from 'react-loaders'
import { toast } from 'react-toastify'
import Common from '../../../utils/common'
import Configuration from '../../../utils/configuration'

function UploadImagePartial({ setImages, images }) {
  //const [files, setFiles] = useState([]);
  const [isSending, setIsSending] = useState(false)

  const onDrop = function (dropFiles) {
    console.log(dropFiles)
    dropFiles.forEach((file) => {
      if (file.size > Configuration.MAX_FILE_SIZE)
        return toast['error'](
          'Yêu cầu file có dung lượng không lớn hơn ' +
            Configuration.MAX_FILE_SIZE +
            'KB'
        )
    })

    let promises = []
    dropFiles.forEach((file, i) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      promises.push(
        new Promise(function (resolve) {
          reader.addEventListener(
            'load',
            () => {
              resolve({
                file: file,
                name: file.name,
                path: reader.result,
              })
            },
            false
          )
        })
      )
    })
    Promise.all(promises).then((value) => {
      //setFiles(value)

      const formData = new FormData()
      formData.append('token', localStorage.getItem(Configuration.userCookie))

      value.forEach((file) => formData.append('UploadedImage', file.file))

      var ajaxRequest = $.ajax({
        type: 'POST',
        url: Configuration.image_upload_url,
        contentType: false,
        processData: false,
        data: formData,
      })
      setIsSending(true)
      ajaxRequest.done((response, textStatus) => {
        if (response.Success) {
          //toggleTab("1");
          //setFiles([]);
          const imagesUploadRecently = response.Data.map((image) => ({
            id: image.Id,
            name: image.Name,
            SeoFileName: image.Name,
            AltAttribute: image.Name,
            TitleAttribute: image.Name,
            path: image.Path,
            active: false,
          }))
          const pageSize = images.length
          const tempImgs = [...imagesUploadRecently, ...images]
          setImages(tempImgs.filter((img, index) => index < pageSize))
          toast['success']('Thành công')
        } else toast['error']('Thất bại')
        setIsSending(false)
      })
    })
  }

  // const onSubmit = () => {
  //     setIsSending(true);
  //     const formData = new FormData();
  //     formData.append("token", localStorage.getItem(Configuration.userCookie));

  //     files.forEach((file) => formData.append("UploadedImage", file.file));

  //     var ajaxRequest = $.ajax({
  //         type: "POST",
  //         url: Configuration.image_upload_url,
  //         contentType: false,
  //         processData: false,
  //         data: formData,
  //     });

  //     ajaxRequest.done((response, textStatus) => {
  //         if (response.Success) {
  //             toggleTab("1");
  //             setFiles([]);
  //             const imagesUploadRecently = response.Data.map((image) => ({
  //                 id: image.Id,
  //                 name: image.Name,
  //                 path: image.Path,
  //                 active: false,
  //             }));
  //             const pageSize = images.length;
  //             const tempImgs = [...imagesUploadRecently, ...images];
  //             setImages(tempImgs.filter((img, index) => index < pageSize));
  //             toast["success"]("Thành công");
  //         } else toast["error"]("Thất bại");
  //         setIsSending(false);
  //     });
  // };

  return (
    <BlockUi
      tag="div"
      blocking={isSending}
      className="block-overlay-dark"
      loader={<Loader color="#ffffff" active />}
    >
      <div
        className="dropzone-wrapper dropzone-wrapper-lg"
        style={{ height: '210px' }}
      >
        <Dropzone
          onDrop={onDrop}
          onDropRejected={(errFiles) => {
            toast['error'](
              'Files không phù hợp:' +
                errFiles.map((errFile) => errFile.name).join(', ')
            )
          }}
          maxSize={Configuration.MAX_FILE_SIZE}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input id="file" {...getInputProps()} />
              <div className="dropzone-content">
                <p>
                  Hãy thử thả một số tệp vào đây hoặc bấm để chọn tệp để tải
                  lên.
                </p>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      {/* <Button
                color="success"
                className={`mx-auto d-block mt-3 ${
                    files.length < 1 ? "disabled" : ""
                    }`}
                onClick={onSubmit}
            >
                Tải lên
                    </Button> */}
    </BlockUi>
  )
}

export default UploadImagePartial
