import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
// import PageTitle from '../../Layout/AppMain/PageTitle';
import { Col, Row } from 'reactstrap'
import AppHeader from '../../Layout/AppHeader'
// import ThemeOptions from '../../Layout/ThemeOptions'
import AppSidebar from '../../Layout/AppSidebar'
import RatingForm from './RatingForm'
import RatingList from './RatingList'
import RatingReport from './RatingReport'

// import CategoryFormCreate from './Create/CategoryFormCreate';

class Products extends React.Component {
  render() {
    return (
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Row>
                <Col md="12">
                  <Route
                    path={`${this.props.match.url}/create`}
                    component={RatingForm}
                  />
                  <Route
                    path={`${this.props.match.url}/edit/:id`}
                    component={RatingForm}
                  />
                  <Route
                    path={`${this.props.match.url}/list`}
                    component={RatingList}
                  />
                  <Route
                    path={`${this.props.match.url}/report/:id`}
                    component={RatingReport}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Products
