import React from 'react'
import { Link } from 'react-router-dom'
import Badge from 'reactstrap/lib/Badge'
import DropdownItem from 'reactstrap/lib/DropdownItem'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import UncontrolledButtonDropdown from 'reactstrap/lib/UncontrolledButtonDropdown'
import { getUsers } from '../../Services/UserService'
import useFetchDataTable from '../CommonComponent/CustomHooks/useFetchDataTable'
import Header from '../CommonComponent/Header'
import ReactTableList from '../CommonComponent/TableList/ReactTableList'
import moment from 'moment'

function UserScheduleList() {
  const [data, loading, pageCount, fetchData, totalCount] = useFetchDataTable(
    getUsers
  )

  const columns = [
    {
      Header: 'Tên',
      accessor: 'name',
    },

    {
      accessor: 'active',
      Header: 'Trạng thái',
      filterable: false,
      Cell: props => (
        <div>
          {props.value ? (
            <div className="mb-2 mr-2 badge badge-success">Hoạt động</div>
          ) : (
            <div className="mb-2 mr-2 badge badge-danger">Không hoạt động</div>
          )}
        </div>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: props => <p className="text-break">{props.value}</p>,
    },
    {
      Header: 'Số điện thoại',
      accessor: 'mobile',
      Cell: props => (
        <p className="text-break">{props.value ? props.value : 'N/A'}</p>
      ),
    },
    {
      Header: 'Ngày tạo',
      accessor: 'createdDate',
      Cell: ({ value }) => (
        <p className="text-break">
          {moment(new Date(value)).format('DD-MM-YYYY')}
        </p>
      ),
    },
    {
      Header: 'Thao tác',
      accessor: a => a,
      Cell: ({ value }) => {
        return (
          <Link to={`/schedule/detail/${value.id}`}>
            <button
              title="Lịch"
              className="btn-icon btn-icon-only btn btn-link btn btn-link"
            >
              <i className="pe-7s-date btn-icon-wrapper"></i>
            </button>
          </Link>
        )
      },
    },
  ]
  return (
    <div>
      <Header title="Danh sách user" iconClassName="pe-7s-user" />

      <ReactTableList
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalCount={totalCount}
      />
    </div>
  )
}

export default UserScheduleList
