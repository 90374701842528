import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Board from 'react-trello'
import { CardTitle } from 'reactstrap'
import { getCategory, sortCategories } from '../../Services/CategoryService'
import {
  ChangeCategoryForOrder,
  getKanbanCategories,
} from '../../Services/CrmService'
import Common from '../../utils/common'
import { postData } from '../../utils/data'
import useModal from '../CommonComponent/CustomHooks/useModal'
import ModalInfoCardKanban from './ModalInfoCardKanban'
import NewLaneForm from './NewLandForm'

function DetailKanban(props) {
  const id = props.match.params.id
  const [category, setCategory] = useState({})
  const [dataKanban, setDataKanban] = useState({
    lanes: [],
  })
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(null)

  useEffect(() => {
    saveIndexCategories()
  }, [dataKanban.lanes.length])

  useEffect(() => {
    getKanbanCategories(id).then((res) => console.log(res))
    getCategory(id).then((res) => setCategory(res))
  }, [])

  const [isOpen, toggle, dataModal, setDataModal, setData] = useModal()

  useEffect(() => {
    if (saving === true) {
      setTimeout(() => {
        setSaving(null)
      }, 3000)
    }
  }, [saving])

  const load = () => {
    setLoading(true)
    getKanbanCategories(id)
      .then((res) => {
        let totalCard = 0
        res.kanbancategories.items.forEach((item) => {
          totalCard += item.orders.length
        })
        return setDataKanban({
          lanes: res.kanbancategories.items.map((lane) => ({
            id: lane.id,
            title: lane.name,
            label: `${lane.orders.length}/${totalCard}`,
            cards: lane.orders.map((order) => ({
              id: order.id,
              title: `Mã #${order.code}`,
              description: `Tổng tiền: ${Common.formatNumber(
                order.grandTotal,
                0
              )}\nKhách hàng: ${order.userName}`,
              label: moment(new Date(order.createdDate)).fromNow(),
              ...order,
            })),
          })),
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    setInterval(() => {
      load()
    }, 1000 * 30)
    return () => {}
  }, [])

  const components = {
    NewLaneForm: (e) => {
      return (
        <NewLaneForm
          e={e}
          parentId={id}
          setData={setDataKanban}
          setStateAddingLane={setLoading}
          setSaving={setSaving}
          dataKanban={dataKanban}
          saveIndexCategories={saveIndexCategories}
        />
      )
    },
    NewLaneSection: (e) => (
      <div
        onClick={e.onClick}
        className="text-center d-flex justify-content-center align-items-center"
        style={{
          width: 200,
          height: 40,
          backgroundColor: '#2b6aa2',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        {loading ? 'loading...' : '+ Thêm trường mới'}
      </div>
    ),
  }

  const getCardFromId = (cardId) => {
    let card = null
    dataKanban.lanes.forEach((lane) => {
      lane.cards.forEach((c) => {
        if (c.id === cardId) {
          card = c
        }
      })
    })
    return card
  }

  const saveIndexCategories = () => {
    return sortCategories(dataKanban.lanes.map((lane) => lane.id))
  }

  return (
    <div>
      <ModalInfoCardKanban
        isOpen={isOpen}
        toggle={toggle}
        dataModal={dataModal}
      />
      <div className="d-flex justify-content-between">
        <CardTitle>Kanban {category.name}</CardTitle>
        <div>
          <p>
            {saving !== null
              ? saving
                ? 'Saving...'
                : 'Đã lưu tất cả thay đổi!'
              : null}
          </p>
        </div>
      </div>

      <Board
        data={dataKanban}
        components={components}
        draggable
        editable
        canAddLanes
        canAddCards={false}
        onCardClick={(cardId, metaData, laneId) => {
          const card = getCardFromId(cardId)
          if (!card) return alert('Some thing when wrongs')
          toggle(card)
        }}
        onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) => {
          if (toLaneId === fromLaneId) return
          setSaving(true)
          const card = getCardFromId(cardId)
          const newLanes = dataKanban.lanes.map((lane) => {
            if (lane.id === fromLaneId)
              return {
                ...lane,
                cards: lane.cards.filter((card) => card.id !== cardId),
              }

            if (lane.id === toLaneId)
              return {
                ...lane,
                cards: [
                  ...lane.cards,
                  {
                    ...card,
                    version: parseInt(card.version) + 1,
                  },
                ],
              }
            return lane
          })

          setDataKanban({
            lanes: newLanes,
          })
          ChangeCategoryForOrder({
            orderId: cardId,
            fromCategoryId: fromLaneId,
            toCategoryId: toLaneId,
            version: card.version,
          }).then((data) => {
            setSaving(false)
          })
        }}
        handleLaneDragEnd={(fromIndex, toIndex) => {
          const tempLanes = dataKanban.lanes
          // swap from and to
          const temp = dataKanban.lanes[toIndex]
          dataKanban.lanes[toIndex] = dataKanban.lanes[fromIndex]
          dataKanban.lanes[fromIndex] = temp
          setSaving(true)
          setDataKanban({
            lanes: tempLanes,
          })
          sortCategories(tempLanes.map((lane) => lane.id)).then(() =>
            setSaving(false)
          )
        }}
        onLaneDelete={(laneId) => {
          setSaving(true)
          setDataKanban({
            lanes: dataKanban.lanes.filter((lane) => lane.id !== laneId),
          })
          postData
            .changeStatusCommand('Category', 'ChangeStatusPublishCategories', [
              {
                Id: laneId,
                Publish: true,
              },
            ])
            .then(() => setSaving(false))
        }}
      />
    </div>
  )
}

export default DetailKanban
