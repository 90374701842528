export const CHANGE_VIEW_EDIT_SECTION = 'CHANGE_VIEW_EDIT_SECTION'
export const BACK_VIEW_MAIN_COMPONENT = 'BACK_VIEW_MAIN_COMPONENT'
export const CHANGE_VIEW_CUSTOM_SECTION = 'CHANGE_VIEW_CUSTOM_SECTION'
export const ADDITION_SECTION = 'ADDITION_SECTION'
export const EDIT_CODE_SECTION = 'EDIT_CODE_SECTION'

export const UPDATE_PARENT_ID_COMPONENT = 'UPDATE_PARENT_ID_COMPONENT'
export const UPDATE_ITEM_COMPONENT = 'UPDATE_ITEM_COMPONENT'
export const UPDATE_ARRAY_COMPONENT_ROOT = 'UPDATE_ARRAY_COMPONENT_ROOT'
export const UPDATE_QUANTITY_ITEM = 'UPDATE_QUANTITY_ITEM'
export const UPDATE_DEFAULT_COMPONENT = 'UPDATE_DEFAULT_COMPONENT'
export const UPDATE_STATE_SECTION_BLOCKING = 'UPDATE_STATE_SECTION_BLOCKING'
export const UPDATE_CHILD_COMPONENT = 'UPDATE_CHILD_COMPONENT'
export const UPDATE_LIST_ITEM_CHOOSE = 'UPDATE_LIST_ITEM_CHOOSE'
export const UPDATE_STATE_DISPLAY_QUICK_ADD = 'UPDATE_STATE_DISPLAY_QUICK_ADD'
export const UPDATE_CURRENT_COMPONENT = 'UPDATE_CURRENT_COMPONENT'
export const UPDATE_ID_MENU = 'UPDATE_ID_MENU'

export const LOAD_DATA = 'LOAD_DATA'
export const LOAD_ALL_SECTION = 'LOAD_ALL_SECTION'
export const LOAD_ALL_PROMOTION = 'LOAD_ALL_PROMOTION'
export const LOAD_ALL_EVOUCHER = 'LOAD_ALL_EVOUCHER'
export const LOAD_ALL_ARTICLE = 'LOAD_ALL_ARTICLE'
export const LOAD_ALL_USER = 'LOAD_ALL_USER'
export const LOAD_ALL_EVENT = 'LOAD_ALL_EVENT'
export const LOAD_ALL_PRODUCT = 'LOAD_ALL_PRODUCT'
export const LOAD_ALL_FORM = 'LOAD_ALL_FORM'
export const LOAD_CATEGORY_USER = 'LOAD_CATEGORY_USER'
export const LOAD_CATEGORY_MENU = 'LOAD_CATEGORY_MENU'
export const LOAD_CATEGORY_ARTICLE = 'LOAD_CATEGORY_ARTICLE'
export const LOAD_CATEGORY_PRODUCT = 'LOAD_CATEGORY_PRODUCT'

export const loadAllEvoucher = (data) => ({
  type: LOAD_ALL_EVOUCHER,
  data,
})
export const loadAllEvent = (data) => ({
  type: LOAD_ALL_EVENT,
  data,
})
export const loadAllPromotion = (data) => ({
  type: LOAD_ALL_PROMOTION,
  data,
})
export const updateCurrentComponent = (objData) => ({
  type: UPDATE_CURRENT_COMPONENT,
  objData,
})

export const changeViewEditSection = (id) => ({
  type: CHANGE_VIEW_EDIT_SECTION,
  id,
})
export const updateListItemChoose = (data) => ({
  type: UPDATE_LIST_ITEM_CHOOSE,
  data,
})
export const loadAllArticle = (data) => ({
  type: LOAD_ALL_ARTICLE,
  data,
})
export const loadAllProduct = (data) => ({
  type: LOAD_ALL_PRODUCT,
  data,
})
export const loadAllUser = (data) => ({
  type: LOAD_ALL_USER,
  data,
})
export const loadAllForm = (data) => ({
  type: LOAD_ALL_FORM,
  data,
})
export const loadCategoryUser = (data) => ({
  type: LOAD_CATEGORY_USER,
  data,
})
export const loadCategoryMenu = (data) => ({
  type: LOAD_CATEGORY_MENU,
  data,
})
export const loadCategoryArticle = (data) => ({
  type: LOAD_CATEGORY_ARTICLE,
  data,
})
export const loadCategoryProduct = (data) => ({
  type: LOAD_CATEGORY_PRODUCT,
  data,
})

export const updateChildComponent = (data) => ({
  type: UPDATE_CHILD_COMPONENT,
  data,
})

export const backViewMainComponent = () => ({
  type: BACK_VIEW_MAIN_COMPONENT,
})
export const additionSection = () => ({
  type: ADDITION_SECTION,
})
export const customSection = () => ({
  type: CHANGE_VIEW_CUSTOM_SECTION,
})
export const editCodeSection = () => ({
  type: EDIT_CODE_SECTION,
})

export const loadData = (data) => ({
  type: LOAD_DATA,
  data,
})

export const loadAllSection = (data) => ({
  type: LOAD_ALL_SECTION,
  data,
})
export const updateStateSectionBlocking = () => ({
  type: UPDATE_STATE_SECTION_BLOCKING,
})

export const updateParentIdComponent = (data) => ({
  type: UPDATE_PARENT_ID_COMPONENT,
  data,
})
export const updateItemComponent = (items) => ({
  type: UPDATE_ITEM_COMPONENT,
  items,
})
export const updateArrayComponentRoot = (items) => ({
  type: UPDATE_ARRAY_COMPONENT_ROOT,
  items,
})
export const updateQuantityItem = (items) => ({
  type: UPDATE_QUANTITY_ITEM,
  items,
})
export const updateDefaultComponent = (items) => ({
  type: UPDATE_DEFAULT_COMPONENT,
  items,
})
export const updateStateDisplayQuickAdd = () => ({
  type: UPDATE_STATE_DISPLAY_QUICK_ADD,
})
export const updateIdMenu = (id) => ({
  type: UPDATE_ID_MENU,
  id,
})

const initialState = {
  //component added
  idInitComponentRoot: '',
  nameInitComponentRoot: 'home',
  arrayComponentRoot: [],
  defaultComponent: [], // can sua lai ten
  allSectionTheme: [],
  allArticle: [],
  allProduct: [],
  allUser: [],
  allForm: [],
  allEvoucher: [],
  allEvent: [],
  allPromotion: [],
  allCategoryUser: [],
  allCategoryMenu: [],
  allCategoryArticle: [],
  allCategoryProduct: [],

  listItemChoose: [],
  idMenu: '',

  themeId: '',
  id: '', // id Deatail component ///xoa
  sidebar: 'NavTheme',
  quantityItemCurrent: '', // can bo state

  currentComponent: {
    id: '',
    name: '',
    html: '',
  },

  //block loading
  sectionBlocking: false,
  // quick add - them moi
  displayComponentQuickAdd: 'block',
}
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ID_MENU:
      return {
        ...state,
        idMenu: action.id,
      }
    case LOAD_ALL_EVENT:
      return {
        ...state,
        allEvent: action.data,
      }
    case LOAD_ALL_USER:
      return {
        ...state,
        allUser: action.data,
      }
    case LOAD_ALL_EVOUCHER:
      return {
        ...state,
        allEvoucher: action.data,
      }

    case LOAD_ALL_PROMOTION:
      return {
        ...state,
        allPromotion: action.data,
      }
    case UPDATE_CURRENT_COMPONENT:
      return {
        ...state,
        currentComponent: action.objData,
      }
    case CHANGE_VIEW_EDIT_SECTION:
      let tempCurrentData = state.defaultComponent.filter(
        (e) => e.id === action.id
      )
      if (tempCurrentData.length > 0) {
        return {
          ...state,
          currentComponent: {
            id: action.id,
            name: tempCurrentData[0].name,
            html: tempCurrentData[0].description,
          },
          id: action.id,
          sidebar: 'EditSection',
        }
      }
      return
    case UPDATE_LIST_ITEM_CHOOSE:
      return {
        ...state,
        listItemChoose: action.data,
      }
    case UPDATE_STATE_DISPLAY_QUICK_ADD:
      return {
        ...state,
        displayComponentQuickAdd: 'none',
      }
    case UPDATE_CHILD_COMPONENT:
      let tempData = [...state.defaultComponent]
      let tempComponent = action.data
      // console.log(tempData);

      tempData.forEach((data) => {
        if (data.id === tempComponent.id) {
          data.name = tempComponent.name
          data.description = tempComponent.description
        }
      })
      return {
        ...state,
        defaultComponent: tempData,
      }

    case BACK_VIEW_MAIN_COMPONENT:
      return {
        ...state,
        sidebar: 'NavTheme',
      }
    case LOAD_ALL_ARTICLE:
      return {
        ...state,
        allArticle: action.data,
      }
    case LOAD_ALL_PRODUCT:
      return {
        ...state,
        allProduct: action.data,
      }
    case LOAD_ALL_FORM:
      return {
        ...state,
        allForm: action.data,
      }
    case LOAD_CATEGORY_USER:
      return {
        ...state,
        allCategoryUser: action.data,
      }
    case LOAD_CATEGORY_MENU:
      return {
        ...state,
        allCategoryMenu: action.data,
      }
    case LOAD_CATEGORY_ARTICLE:
      return {
        ...state,
        allCategoryArticle: action.data,
      }
    case LOAD_CATEGORY_PRODUCT:
      return {
        ...state,
        allCategoryProduct: action.data,
      }
    case ADDITION_SECTION:
      return {
        ...state,
        sidebar: 'AdditionSection',
      }
    case CHANGE_VIEW_CUSTOM_SECTION:
      return {
        ...state,
        sidebar: 'CustomSection',
      }
    case EDIT_CODE_SECTION:
      return {
        ...state,
        sidebar: 'SectionEditCode',
      }
    case LOAD_DATA:
      if (action.data.merchant.components.length > 0) {
        let defaultComponent = action.data.merchant.components
        defaultComponent = defaultComponent.filter((e) => e.name !== null) // code này k cần checkk null bên dưới componentRoot
        let ComponentRoot = defaultComponent.filter((e) => {
          let lowerName = e.name.toLowerCase()
          if (e.name !== null) {
            if (
              lowerName !== 'header' &&
              lowerName !== 'footer' &&
              e.parentId === '00000000-0000-0000-0000-000000000000'
            ) {
              return true
            }
          }
        })
        let initComponentRoot = defaultComponent.filter((e) =>
          e.name == null
            ? e.name
            : e.name.toLowerCase() === state.nameInitComponentRoot
        )
        if (initComponentRoot.length > 0) {
          var childComponent = defaultComponent.filter(
            (e) => e.parentId === initComponentRoot[0].id
          )
          return {
            ...state,
            defaultComponent: defaultComponent,
            idInitComponentRoot: initComponentRoot[0].id,
            arrayComponentRoot: ComponentRoot,
            themeId: action.data.merchant.themeWeb.id,
            quantityItemCurrent: childComponent.length,
          }
        }
      } else {
        return {
          ...state,
        }
      }
      return {
        ...state,
      }
    case LOAD_ALL_SECTION:
      return {
        ...state,
        allSectionTheme: action.data,
      }
    case UPDATE_PARENT_ID_COMPONENT:
      return {
        ...state,
        idInitComponentRoot: action.data,
      }
    case UPDATE_ITEM_COMPONENT:
      return {
        ...state,
        itemComponent: action.items,
      }
    case UPDATE_ARRAY_COMPONENT_ROOT:
      return {
        ...state,
        arrayComponentRoot: action.items,
      }
    case UPDATE_QUANTITY_ITEM:
      return {
        ...state,
        quantityItemCurrent: action.items.length,
      }
    case UPDATE_DEFAULT_COMPONENT:
      return {
        ...state,
        defaultComponent: action.items,
      }
    case UPDATE_STATE_SECTION_BLOCKING:
      // alert("vao day")
      return {
        ...state,
        sectionBlocking: !state.sectionBlocking,
      }
    default:
      return state
  }
}
