import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import Select, { Creatable } from 'react-select'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'
import { getProduct, getProducts } from '../../../Services/ProductService'
import Common from '../../../utils/common'
import Configuration from '../../../utils/configuration'
import { postData } from '../../../utils/data'
import AddressPartialFormik from '../../CommonComponent/AddressPartialFormik'
import LoadingButton from '../../CommonComponent/Button/LoadingButton'
import CategoryPartial from '../../CommonComponent/CategoryPartial'
import CkEditor5Formik from '../../CommonComponent/CkEditor5/CkEditor5formik'
import Footer from '../../CommonComponent/Footer'
import LaboFormikGroup from '../../CommonComponent/Form/LaboFormikGroup'
import ImagePartial from '../../CommonComponent/ImagePartial2/ImagePartial'

const defaultOption = { value: Common.GUID_EMPTY, label: '-' }

function RoomBookingSettingForm(props) {
  const matchUrl = props.match.url
  const idProduct = props.match.params.id
  const [loading, setLoading] = useState(false)
  const [references, setReferences] = useState([])
  const [productInfo, setProductInfo] = useState({
    sku: '',
    name: '',
    price: 0,
    subDescription: '',
    categories: [],
    images: [],
    thumbnailImages: [],
  })

  const isRoomForm = useMemo(() => {
    return matchUrl.startsWith('/room-booking/setting/room/')
  }, [matchUrl])

  useEffect(() => {
    if (idProduct) {
      getProduct(idProduct).then((result) => {
        setProductInfo({
          ...result.product,
          images: result.product.images.filter((img) => !img.isFeatured),
          thumbnailImages: result.product.images.filter(
            (img) => img.isFeatured
          ),
          address:
            result.product.addresses.length > 0
              ? {
                  value: result.product.addresses[0].id,
                  label: result.product.addresses[0].name,
                }
              : null,
        })
      })
    }
  }, [])

  useEffect(() => {
    if (!props.type) return
    getProducts({
      type: props.type,
    })
      .then((result) => {
        const name = Object.keys(result)[0]
        let nextReferences = result[name].items
        setReferences(nextReferences)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [props.type])

  const referenceOptions = useMemo(() => {
    let result = []
    function findChilds(references, id, result) {
      let childs = []
      for (let i = 0; i < references.length; i++) {
        const room = references[i]
        if (room.referenceId === id) {
          childs = [...childs, room]
        }
      }
      if (childs.length) {
        result = [...result, childs]
        childs.forEach((f) => {
          result = findChilds(references, f.id, result)
        })
      }
      return result
    }
    const toAvoidRooms = findChilds(references, idProduct, result)
      .reduce((flat, toFlatten) => {
        return flat.concat(toFlatten)
      }, [])
      .map((m) => m.id)

    return [
      defaultOption,
      ...references
        .filter((f) => !toAvoidRooms.includes(f.id))
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    ]
  }, [references, idProduct])

  const referenceSelectLoading = useMemo(() => {
    return references.length === 0
  }, [references])

  const formProduct = useFormik({
    enableReinitialize: true,
    initialValues: productInfo,
    onSubmit: (values) => {
      const {
        sku,
        name,
        price,
        capacity,
        utilities,
        reference,
        friendlyUrl,
        subDescription,
        description,
        categories,
        images,
        thumbnailImages,
        address,
      } = values

      const formatPrice = price.toString().replace(/,/g, '')

      const relationAddresses = Common.createRelationObjectArray([
        address.value,
      ])

      const body = {
        Id:
          props.match.params.id !== undefined
            ? props.match.params.id
            : Common.guid(),
        MerchantId: localStorage.getItem('merchantId'),
        Name: name,
        SubDescription: subDescription,
        Description: description,
        Sku: sku,
        Type: props.type,
        Price: formatPrice,
        Capacity: capacity,
        referenceId: reference || productInfo.referenceId || Common.GUID_EMPTY,
        Utilities: utilities,
        FriendlyUrl: friendlyUrl,
        Categories: Common.createRelationObjectArray(
          categories.map((val) => val.id)
        ),
        Images: Common.createImagesRelationObjectArray(images, thumbnailImages),
        Addresses: relationAddresses,
        CommandInformation: window.location.href,
        LanguageId: props.match.params.id
          ? Common.getParameterByName('languageId')
          : localStorage.getItem(Configuration.tokenLanguage),
      }
      if (props.match.params.id) {
        body.ModifiedDate = Common.formatDateTime(new Date())
        body.CreatedBy = localStorage.getItem('userId')
      } else {
        body.CreatedDate = Common.formatDateTime(new Date())
        body.CreatedBy = localStorage.getItem('userId')
      }

      setLoading(true)
      postData
        .sendCommand(
          'Product',
          props.match.params.id
            ? 'UpdateInformationRoomProduct'
            : 'CreateRoomProduct',
          body
        )
        .then(Common.handleResponse)
        .then((res) => {
          if (res.success) {
            if (props.type === Configuration.ProductTypes.RoomBooking) {
              props.history.push('/room-booking/setting/room/list')
            } else {
              props.history.push('/room-booking/setting/office/list')
            }
          }
        })
        .catch(Common.handleError)
        .finally(() => setLoading(false))
    },
    validate: (values) => {
      const errors = {}
      const friendlyUrlRegex = new RegExp('^[a-zA-Z0-9-]*$')
      if (!values.friendlyUrl || !friendlyUrlRegex.test(values.friendlyUrl))
        errors.friendlyUrl =
          "Link thân thiện chỉ có thể tồn tại chữ không dấu và dấu '-'"
      if (!values.name) errors.name = 'Trường bắt buộc!'
      if (!values.price) {
        if (values.price !== 0) errors.price = 'Trường bắt buộc!'
      }
      if (!values.address) errors.address = 'Trường bắt buộc!'
      if (!values.capacity) errors.capacity = 'Trường bắt buộc!'
      if (parseInt(values.capacity) < 0)
        errors.capacity = 'Giá trị phải lớn hơn 0!'
      if (parseInt(values.price) < 0) errors.price = 'Giá trị phải lớn hơn 0!'
      return errors
    },
  })

  const selectedValue = useMemo(() => {
    const { referenceId } = productInfo
    const id =
      formProduct.values['reference'] || referenceId || Common.GUID_EMPTY
    if (id === Common.GUID_EMPTY) return defaultOption
    const reference = references.find((f) => f.id === id)
    if (!reference) return null
    return { value: reference.id, label: reference.name }
  }, [references, formProduct])

  return (
    <div className="mb-5">
      {/* <Header
                title="Phòng"
                description="room"
                iconClassName="pe-7s-home"
            /> */}
      <Row>
        <Col xl="8">
          <Card className="mb-2">
            <CardHeader>Thông tin phòng</CardHeader>
            <CardBody>
              {isRoomForm && (
                <div className="row form-group">
                  <label className="col-sm-2 col-form-label">Thuộc phòng</label>
                  <div className="col-sm-10">
                    <Select
                      isSearchable
                      isLoading={referenceSelectLoading}
                      value={selectedValue}
                      onChange={(item) => {
                        formProduct.setFieldValue('reference', item.value)
                      }}
                      options={referenceOptions}
                    />
                  </div>
                </div>
              )}
              <LaboFormikGroup
                label="Mã"
                formik={formProduct}
                name="sku"
                placeholder="Mã tự sinh nếu bỏ trống"
                row
              />
              <LaboFormikGroup
                label="Tên phòng <span class='red'>*</span>"
                formik={formProduct}
                name="name"
                row
                onBlur={() => {
                  formProduct.setFieldValue(
                    'friendlyUrl',
                    Common.rewriteUrl(formProduct.values['name'])
                  )
                }}
              />
              <LaboFormikGroup
                label="Link thân thiện <span class='red'>*</span>"
                formik={formProduct}
                name="friendlyUrl"
                row
              />
              <FormGroup row>
                <Label sm="2">
                  Giá (VNĐ) <span className="red">*</span>
                </Label>
                <Col sm="10">
                  <NumberFormat
                    value={formProduct.values['price']}
                    name="price"
                    onChange={formProduct.handleChange}
                    className="form-control"
                    thousandSeparator={true}
                    allowNegative={false}
                  />
                </Col>
              </FormGroup>
              {/* <LaboFormikGroup
                                label="Giá (VNĐ) <span class='red'>*</span>"
                                formik={formProduct}
                                type="number"
                                name="price"
                                min="0"
                                row
                            /> */}
              <LaboFormikGroup
                label="Sức chứa <span class='red'>*</span>"
                formik={formProduct}
                type="number"
                name="capacity"
                min="0"
                row
              />
              <div className="row form-group">
                <label className="col-sm-2 col-form-label">Tiện ích</label>
                <div className="col-sm-10">
                  <Creatable
                    isMulti
                    value={
                      formProduct.values['utilities']
                        ? formProduct.values['utilities']
                            .split(',')
                            .map((item) => ({
                              value: item,
                              label: item,
                            }))
                        : ''
                    }
                    onChange={(items) => {
                      formProduct.setFieldValue(
                        'utilities',
                        items.map((item) => item.value).join(',')
                      )
                    }}
                    options={[
                      {
                        value: 'Bảng',
                        label: 'Bảng',
                      },
                      {
                        value: 'Màn chiếu',
                        label: 'Màn chiếu',
                      },
                      {
                        value: 'Tivi',
                        label: 'Tivi',
                      },
                    ]}
                  />
                </div>
              </div>
              <LaboFormikGroup
                label="Trích dẫn"
                formik={formProduct}
                type="textarea"
                name="subDescription"
                row
              />
              <FormGroup row>
                <Label sm="2" for="description">
                  Miêu tả
                </Label>
                <Col sm="10">
                  <CkEditor5Formik formik={formProduct} name="description" />
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4">
          <CategoryPartial
            formik={formProduct}
            name="categories"
            type={Configuration.categoryTypes.ROOM_BOOKING}
          />
          <ImagePartial
            formik={formProduct}
            name="thumbnailImages"
            title="Ảnh đại diện"
          />
          <ImagePartial formik={formProduct} name="images" title="Ảnh" />
          <AddressPartialFormik formik={formProduct} name="address" />
        </Col>
      </Row>
      <Footer
        left={
          <LoadingButton
            color="success"
            type="button"
            loading={loading}
            onClick={formProduct.handleSubmit}
          >
            Lưu
          </LoadingButton>
        }
      />
    </div>
  )
}

export default RoomBookingSettingForm
