import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { postData } from "../../../utils/data";

const GoogleLoginCallback = (props) => {
  console.log(props.location.search);
  const param = props.location.search;
  if (param.indexOf("error=") > 0) {
    Swal.fire({
      title: "Thất bại",
      text: "",
      icon: "error",
    }).then(({ value }) => {
      //if (value && props.redirectPath) props.history.push(props.redirectPath);
      var returnUrl = localStorage.getItem("returnUrl");
      if (returnUrl) {
        //window.location.href = returnUrl;
        localStorage.removeItem("returnUrl");
        props.history.push(returnUrl);
      } else {
        //window.location.href = "/";
        props.history.push("/");
      }
    });
  } else {
    postData.getGoogleToken(param).then((data) => {
      console.log(data);
      if (data) {
        Swal.fire({
          title: data.Success == true ? "Thành công" : "Thất bại",
          text: "",
          icon: data.Success == true ? "success" : "error",
        }).then(({ value }) => {
          //if (value && props.redirectPath) props.history.push(props.redirectPath);
          var returnUrl = localStorage.getItem("returnUrl");
          if (returnUrl) {
            //window.location.href = returnUrl;
            localStorage.removeItem("returnUrl");
            props.history.push(returnUrl);
          } else {
            //window.location.href = "/";
            props.history.push("/");
          }
        });
      } else {
        window.location.href = "/";
      }
    });
  }

  return <div></div>;
};

export default GoogleLoginCallback;
