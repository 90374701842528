import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import './SearchFunction.css'
import useOnClickOutside from '../CustomHooks/useOnClickOutside'
import { Link } from 'react-router-dom'
import navItemOptions from '../../../Layout/AppNav/NavItemOptions'

function SearchFunction() {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenRecommend, setIsOpenRecommend] = useState(false)
  const [recommendItems, setRecommendItems] = useState([])

  const [text, setText] = useState('')
  const ref = useRef()
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef) inputRef.current.focus()
    setIsOpenRecommend(false)
    setText('')
  }, [isOpen])

  useEffect(() => {
    if (text) {
      setIsOpenRecommend(true)
      const reg = new RegExp(text.toLowerCase())
      setRecommendItems(
        navItemOptions.filter((option) => {
          const { label } = option
          return reg.test(label.toLowerCase())
        })
      )
    } else {
      setIsOpenRecommend(false)
    }
  }, [text])

  useOnClickOutside(ref, () => setIsOpen(false))

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'F') {
        setIsOpen(true)
      }
    })
  }, [])
  return (
    <div
      ref={ref}
      className={classnames({
        'search-func-container': true,
        'search-func-invisible': !isOpen,
      })}
    >
      <input
        ref={inputRef}
        className="form-control"
        value={text}
        onChange={(e) => {
          return setText(e.target.value)
        }}
      />
      <div
        className={classnames({
          'search-func-recommend': true,
          'search-func-invisible': !isOpenRecommend,
        })}
      >
        <div className="search-func-recommend-container">
          {recommendItems.map((item, i) => {
            if (item.options)
              return (
                <div>
                  {' '}
                  <div className="heading-recommend">{item.label}</div>
                  <ul>
                    {item.options.map((option) => (
                      <li>
                        <Link
                          to={option.value.slice(2)}
                          onClick={() => setIsOpen(false)}
                          // key={item.path}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          {option.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            else if (item.value) {
              return (
                <ul>
                  <li>
                    <Link
                      to={item.value.slice(2)}
                      onClick={() => setIsOpen(false)}
                      // key={item.path}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      {item.label}
                    </Link>
                  </li>
                </ul>
              )
            }
            // return (
            //   <Link
            //     to={item.path}
            //     onClick={() => setIsOpen(false)}
            //     key={item.path}
            //     style={{
            //       textDecoration: 'none',
            //     }}
            //   >
            //     <li>{item.name}</li>
            //   </Link>
            // )
          })}
        </div>
      </div>
    </div>
  )
}

export default SearchFunction
