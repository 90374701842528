import { getData } from '../utils/data'
import Common from '../utils/common'

export const getEmailTemplates = (options) => {
  if (options === undefined) options = {}
  if (options.merchantId === undefined)
    options.merchantId = localStorage.getItem('merchantId')
  if (options.limit === undefined) options.limit = 10000000
  if (options.offset === undefined) options.offset = 0
  options.keyword = options.keyword === undefined ? '' : options.keyword

  return getData.getDataGraphQl(`
    {
        emailtemplates(param:{limit:${options.limit},offset:${options.offset},order:"asc",sort:"createdDate"
        ,merchantId:"${options.merchantId}", keyword:"${options.keyword}"})
        {
          items{
            id,
            merchantId,
            subject,
            template,
            templateName,
            templateSystemName
          },
          totalCount,
          success,
          message
        }
    }
    `)
}

export const getEmailTemplate = (id, merchantId) => {
  return getData.getDataGraphQl(`
  {
    emailtemplate(param:{id:"${id}", merchantId:"${merchantId}"}) {
      id
      subject
      template
      templateName
      templateSystemName
    }
  }
  `)
}
